import { IntlStore } from "../../../../init";
import React from "react";
import { Component, FormField, FormInput, FormPreview, GenericForm, SideBar, ToolBar } from "../../../../ui";
import { setItem } from "./HaceraNetworkNodeActions";
import HaceraNetworkNodeStore from "./HaceraNetworkNodeStore";
import HaceraNetworksStore from "./HaceraNetworksStore";

function setNodeItem(networkId, nodeId, networks) {
    const network = networks.value(networkId);

    setItem(network && network.nodes.value(nodeId));
}

class HaceraNetworkNode extends Component {
    componentWillMount() {
        const { params: { networkId, nodeId }, items: { item: { networks } } } = this.props;

        setNodeItem(networkId, nodeId, networks);
    }

    componentWillReceiveProps(nextProps) {
        const { params: { networkId, nodeId }, items: { item: { networks } } } = nextProps;
        const {
            params: { networkId: currNetworkId, nodeId: currNodeId },
            items: { item: { networks: currNetworks } }
        } = this.props;

        if (networkId !== currNetworkId || nodeId !== currNodeId || networks !== currNetworks) {
            setNodeItem(networkId, nodeId, networks);
        }
    }

    renderToolBar() {
        const { data: { item } } = this.props;

        return (
            <ToolBar>
                <div className={ this.element("title") }>{ item.nodeName }</div>
            </ToolBar>
        );
    }


    render() {
        const { data: { item }, intl } = this.props;

        if (!item) {
            return null;
        }

        return (
            <SideBar toolBar={ this.renderToolBar() } className={ this.block() }>
                <GenericForm { ...{ intl } } header={ false } submit={ false } cancel={ false }>
                    <FormField name="type" label={ this.formatMessage("type-label") }>
                        <FormInput value={ item.nodeType } />
                    </FormField>
                    <FormField name="url" label={ this.formatMessage("url-label") }>
                        <FormInput value={ item.nodeURL } />
                    </FormField>
                    <FormField name="sshKey" label={ this.formatMessage("ssh-key-label") }>
                        <FormPreview value={ item.nodeSshKey } />
                    </FormField>
                </GenericForm>
            </SideBar>
        );
    }
}

export default HaceraNetworkNode.connect({ intl: IntlStore, data: HaceraNetworkNodeStore, items: HaceraNetworksStore });
