import PropTypes from "prop-types";
import React from "react";
import { Component } from "../Component";

export class FormInput extends Component {
    init(...args) {
        super.init(...args);

        this.currPos = 0;
        this.onChange = this.onChange.bind(this);
    }

    componentWillMount() {
        this.currPos = (this.props.value || "").length;
    }

    setSelection() {
        const { input } = this;

        if (input && input.type === "text" || input.type === "password") {
            input.setSelectionRange(this.currPos, this.currPos);
        }
    }

    componentDidUpdate() {
        this.setSelection();
    }

    onChange(event) {
        const { target, target: { value } } = event;
        const { name, onChange } = this.props;

        event.preventDefault();
        this.currPos = target.selectionEnd || 0;

        if (onChange) {
            onChange(value, name);
        }
    }

    renderInput() {
        const { type, name, value, placeholder, readOnly, autoComplete, maxLength } = this.props;

        return (
            <input ref={ (el) => this.input = el } value={ value || "" }
                { ...{ type, readOnly, autoComplete, placeholder, maxLength } }
                className={ this.block({ readOnly, [type]: true, [name]: true }) }
                onChange={ this.onChange } />
        );
    }

    render() {
        return this.renderInput();
    }
}

FormInput.initProps({
    name: { type: PropTypes.string },
    type: { type: PropTypes.string.isRequired, value: "text" },
    readOnly: { type: PropTypes.bool, value: false },
    autoComplete: { type: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]), value: false },
    placeholder: { type: PropTypes.string },
    onChange: { type: PropTypes.func }
});
