import React from "react";
import PropTypes from "prop-types";
import { Component } from "./Component";

export default class Spinner extends Component {
    render() {
        const { center } = this.props;

        return (
            <div className={ this.block({ center }) }>
                <div className="bounce spinner__bounce"></div>
                <div className="bounce spinner__bounce"></div>
                <div className="bounce spinner__bounce"></div>
            </div>
        );
    }
}

Spinner.initProps({
    center: { type: PropTypes.bool }
});
