/**
 * @param {EventTarget} element
 * @param {string} events
 * @param {function} listener
 * @param {boolean} capture
 */
export default function addEventListener(element, events, listener, capture = false) {
    const addListener = (event) => {
        element.addEventListener(event, listener, capture);

        return () => element.removeEventListener(event, listener, capture);
    };

    const removeListeners = (removers) => () => removers.forEach((remover) => remover());

    return removeListeners(events.split(/\s+/).map(addListener));
}
