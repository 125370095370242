module.exports = {
    "transactions-tool-bar-search": {
        "en-US": "Search"
    },

    "transactions-side-bar-actions-label": {
        "en-US": "Export to"
    },

    "transactions-side-bar-stock-allocation": {
        "en-US": "Stock Allocation"
    },
    "transactions-side-bar-user-allocation": {
        "en-US": "User Allocation"
    },

    "transactions-users-allocation-widget-allocation-other": {
        "en-US": "Other (Combined)"
    },
    "transactions-users-allocation-widget-aggregate-label": {
        "en-US": "Aggr. Threshold"
    },
    "transactions-users-allocation-widget-aggregate-placeholder": {
        "en-US": "No Aggregation"
    },
    "transactions-users-allocation-widget-aggregate-null": {
        "en-US": "No Aggregation"
    }
};
