import { Action } from "../../utils/dataflow";
import { FormEditActions } from "../../components/Form";

class ForgotPasswordActions extends FormEditActions {
    constructor() {
        super();

        Object.assign(this, {
            forgotPassword: new Action
        });
    }
}

const Actions = new ForgotPasswordActions();

export default Actions;
export const forgotPassword = Actions.forgotPassword.trigger;
export const setItem = Actions.setItem.trigger;
export const setItemField = Actions.setItemField.trigger;
