module.exports = {
    "certificates-title": {
        "en-US": "Credentials - Certificates"
    },
    "certificates-loading": {
        "en-US": "Loading..."
    },

    "certificates-tool-bar-search": {
        "en-US": "Search"
    },
    "certificates-tool-bar-create": {
        "en-US": "Add Certificate"
    },

    "certificates-table-column-num": {
        "en-US": "#"
    },
    "certificates-table-column-name": {
        "en-US": "Alias"
    },
    "certificates-table-column-common-name": {
        "en-US": "Common Name"
    },
    "certificates-table-column-serial": {
        "en-US": "Serial"
    },
    "certificates-table-column-not-after": {
        "en-US": "Not After"
    },
    "certificates-table-column-revocation": {
        "en-US": "Revocation"
    },
    "certificates-table-column-actions": {
        "en-US": ""
    },

    "certificates-row-edit": {
        "en-US": "View Certificate"
    },
    "certificates-row-delete": {
        "en-US": "Delete Certificate"
    },


    "certificate-delete-title": {
        "en-US": "Delete Certificate"
    },
    "certificate-delete-failed-title": {
        "en-US": "Delete Failed"
    },
    "certificate-delete-message": {
        "en-US": "Are you sure you want to delete Certificate **{alias}**?"
    },
    "certificate-delete-alert-ok": {
        "en-US": "OK"
    },

    "certificate-edit-title": {
        "en-US": "View Certificate"
    },
    "certificate-edit-create-title": {
        "en-US": "Add Certificate"
    },

    "certificate-edit-ok": {
        "en-US": "OK"
    },
    "certificate-edit-submit": {
        "en-US": "Save"
    },

    "certificate-edit-name-label": {
        "en-US": "Alias"
    },
    "certificate-edit-name-placeholder": {
        "en-US": ""
    },

    "certificate-edit-pem-label": {
        "en-US": "Certificate"
    },
    "certificate-edit-pem-placeholder": {
        "en-US": "Select File..."
    },
    "certificate-edit-pem-clear": {
        "en-US": "Clear"
    },

    "certificate-edit-pem-view-label": {
        "en-US": ""
    },
    "certificate-edit-pem-view-placeholder": {
        "en-US": ""
    },

    "certificate-edit-attributes-label": {
        "en-US": "Attributes"
    },

    "error-certificate-name-exists": {
        "en-US": "Certificate already exists"
    },
    "error-certificate-name-empty": {
        "en-US": "Alias is required"
    },
    "error-certificate-name-contains-space": {
        "en-US": "Certificate alias cannot contain spaces"
    },

    "error-certificate-pem-empty": {
        "en-US": "Certificate is required"
    },
    "error-certificate-pem-invalid": {
        "en-US": "Invalid Certificate provided"
    }
};
