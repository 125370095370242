import React from "react";
import { Route, IndexRedirect } from "react-router";

import CSRs from "./CSRs";
import CSRDelete from "./CSRDelete";
import CSRCreate from "./CSRCreate";
import CSRImport from "./CSRImport";
import CSRView from "./CSRView";

export default function route(path) {
    return (
        <Route path={ path }>
            <IndexRedirect to="all/" />
            <Route path=":status/" component={ CSRs }>
                <Route path="create" component={ CSRCreate } />
                <Route path="import" component={ CSRImport } />
                <Route path=":itemId/view" component={ CSRView } />
                <Route path=":itemId/delete" component={ CSRDelete } />
            </Route>
        </Route>
    );
};
