import { TableFilterModel, TableModel } from "../../../../components/Table";
import { Users, Groups, Permissions, Transactions } from "../../../../model";

class LiveFilter extends TableFilterModel {
    init(...args) {
        super.init({ users: [], groups: [], permissions: [], sort: { created: false } }, ...args);
    }

    toJSON() {
        const { users, groups, permissions } = this;

        return Object.assign(super.toJSON(), {
            userId: users.keys,
            groupId: groups.keys,
            permissionId: permissions.keys
        });
    }
}

LiveFilter.connect({
    users: Users,
    groups: Groups,
    permissions: Permissions
});

export default class LiveModel extends TableModel.of(Transactions, LiveFilter) {
}
