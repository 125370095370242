module.exports = {
    "login-title": {
        "en-US": "Sign In"
    },
    "login-username-label": {
        "en-US": "Email / Username"
    },
    "login-username-placeholder": {
        "en-US": ""
    },

    "login-password-label": {
        "en-US": "Password"
    },
    "login-password-placeholder": {
        "en-US": ""
    },

    "login-submit": {
        "en-US": "Log In"
    },
    "login-forgot-password": {
        "en-US": "Forgot?"
    },
    "login-register": {
        "en-US": "Sign Up"
    },

    "forgot-password-title": {
        "en-US": "Password Recovery"
    },
    "reset-password-title": {
        "en-US": "Reset Password"
    },

    "forgot-password-intro": {
        "en-US": ""
    },

    "forgot-password-email-label": {
        "en-US": "Email or Username"
    },
    "forgot-password-email-placeholder": {
        "en-US": ""
    },

    "forgot-password-submit": {
        "en-US": "Forgot Password"
    },
    "forgot-password-login": {
        "en-US": "Log In"
    },


    "reset-password-intro": {
        "en-US": ""
    },
    "reset-password-password-label": {
        "en-US": "New password"
    },
    "reset-password-password-placeholder": {
        "en-US": ""
    },
    "reset-password-submit": {
        "en-US": "Set Password"
    },
    "reset-password-login": {
        "en-US": "Log In"
    },


    "register-title": {
        "en-US": "Create Account"
    },
    "register-intro": {
        "en-US": ""
    },
    "register-submit": {
        "en-US": "Sign Up"
    },
    "register-login": {
        "en-US": "Log In"
    },

    "register-first-name-label": {
        "en-US": "First Name"
    },
    "register-first-name-placeholder": {
        "en-US": ""
    },

    "register-last-name-label": {
        "en-US": "Last Name"
    },
    "register-last-name-placeholder": {
        "en-US": ""
    },

    "register-email-label": {
        "en-US": "Email"
    },
    "register-email-placeholder": {
        "en-US": ""
    },

    "register-username-label": {
        "en-US": "Username (alias)"
    },
    "register-username-placeholder": {
        "en-US": ""
    },

    "register-password-label": {
        "en-US": "Password"
    },
    "register-password-placeholder": {
        "en-US": ""
    },

    "register-country-label": {
        "en-US": "Country"
    },
    "register-country-placeholder": {
        "en-US": ""
    },

    "register-phone-label": {
        "en-US": "Phone number"
    },
    "register-phone-placeholder": {
        "en-US": "650.123.1234"
    },

    "register-captcha-placeholder": {
        "en-US": "I'm not a robot"
    },

    "register-agree-placeholder": {
        "en-US": "I agree to the <a class=\"link\" href=\"/terms.html\" target=\"_blank\">Terms of Use</a> and <a class=\"link\" href=\"/privacy.html\" target=\"_blank\">Privacy Policy</a>."
    },


    "register-message-complete-title": {
        "en-US": "Thank You For Signing Up"
    },
    "register-message-complete-intro": {
        "en-US": `Thank you for signing up. Watch for an email from us requesting that you confirm your email address and complete the registration process.

Please note that for quality assurance, we selectively review some of the sign-ups, so it may take up to 1 business day for you to receive that email from us.

– The HACERA team`
    },
    "register-message-complete-submit": {
        "en-US": "OK"
    },


    "register-message-success-title": {
        "en-US": "Success"
    },
    "register-message-success-intro": {
        "en-US": "You have successfully registered"
    },
    "register-message-success-submit": {
        "en-US": "Log In"
    },


    "register-message-error-title": {
        "en-US": "Error"
    },
    "register-message-error-intro": {
        "en-US": "An error occurred during registration"
    },
    "register-message-error-submit": {
        "en-US": "Back"
    },


    "register-message-recovery-title": {
        "en-US": "Password Recovery"
    },
    "register-message-recovery-intro": {
        "en-US": "Thanks! Please check your email address for a link to reset your password"
    },
    "register-message-recovery-submit": {
        "en-US": "OK"
    },


    "error-empty-forgot-password-email": {
        "en-US": "Email / Username is required"
    },


    "error-registration-email-empty": {
        "en-US": "Email is required"
    },
    "error-registration-first-name-empty": {
        "en-US": "Field is required"
    },
    "error-registration-last-name-empty": {
        "en-US": "Field is required"
    },
    "error-registration-user-name-empty": {
        "en-US": "Username is required"
    },
    "error-registration-user-name-too-short": {
        "en-US": "Must be at least 6 characters long"
    },
    "error-registration-user-name-too-long": {
        "en-US": "Cannot exceed 50 characters"
    },
    "error-registration-user-name-invalid": {
        "en-US": "Cannot contain special characters"
    },
    "error-registration-password-empty": {
        "en-US": "Field is required"
    },
    "error-registration-email-invalid": {
        "en-US": "Email is invalid"
    },
    "error-registration-captcha-empty": {
        "en-US": "CAPTCHA is required"
    },
    "error-registration-captcha-expired": {
        "en-US": "Please check again."
    },
    "error-registration-phone-empty": {
        "en-US": "Phone is required"
    },
    "error-registration-phone-invalid": {
        "en-US": "Phone is invalid"
    },

    "error-registration-username-already-exists": {
        "en-US": "User already exists"
    },
    "error-registration-email-already-exists": {
        "en-US": "Email is in use"
    }
};
