import React from "react";

import { Component } from "../Component";
import Select, { SelectList, SelectListItem } from "./Select";
import { COUNTRIES } from "./SelectCountry";

class SelectCodeListItem extends SelectListItem {
    renderFlag() {
        const { item: { id } } = this.props;
        const className = "item__flag flag-icon" + (id ? " flag-icon-" + id.toLowerCase() : "");

        return <span className={ className } />;
    }

    render() {
        const { item: { id, name, phoneCode }, value, onClick } = this.props;

        return (
            <div data-id={ id }
                className={ this.cx("item list__item item_phone-code", { "list__item_selected": id === value }) }
                onClick={ onClick }>
                <div className="item__title">
                    { this.renderFlag() }
                    <span className="item__country">{ name }</span>
                    <span className="item__phone-code">{ phoneCode }</span>
                </div>
            </div>
        );
    }
}

class SelectCodeList extends SelectList {
    renderListItem(props) {
        return <SelectCodeListItem { ...props } />;
    }
}

class SelectCode extends Select {
    renderFlag(item) {
        const { id } = item;
        const className = "item__flag flag-icon" + (id ? " flag-icon-" + id.toLowerCase() : "");

        return <span className={ className } />;
    }

    getLabel(value, items) {
        const { placeholder, nullValue } = this.props;
        const item = items.value(value);

        return item ? (
            <div className="select__label">
                { this.renderFlag(item) }
                <div className="title">{ item.phoneCode }</div>
            </div>
        ) : (nullValue === true ? "Not selected" : nullValue || placeholder) || "\u00A0";
    }

    renderList(props) {
        return <SelectCodeList { ...props } />;
    }
}

export default class SelectPhoneCode extends Component {
    render() {
        return (
            <SelectCode { ...this.props } items={ COUNTRIES }
                className={ this.block() } dropClassName="drop_wide drop_phone-code" />
        );
    }
}

SelectPhoneCode.initDefaultProps({
    search: true
});
