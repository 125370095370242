import React, { PureComponent } from "react";
import { withRouter } from "react-router";
import { v1 as uuid } from "uuid";

import { bem, block, classNames, element, modifiers } from "../bem";
import { Store } from "../dataflow";
import { addEventListener } from "../html";
import { aggregate, Base } from "../index";
import { filter, forEach, map, some } from "../object";
import { toKebabCase } from "../string";
import TimerOwner from "./mixins/TimerOwner";

const lifeCycleProps = [
    "propTypes",
    "defaultProps"
];

const lifeCycleMethods = [
    "init",
    "componentWillMount",
    "componentWillUnmount",
    "componentDidMount",
    "componentWillReceiveProps",
    "componentWillUpdate",
    "componentDidUpdate"
];

function getProperty(prop) {
    return (target) => target[prop];
}

function isFunction(value) {
    return typeof value === "function";
}


function toString(props) {
    const string = Object.keys(props).map((key) => `${ key }: ${ props[key] }`).join(", ");

    return string ? `{ ${ string } }` : "{}";
}

function onStoreChange(target, prop, data) {
    if (target.connected) {
        target.setState({ [prop]: data });
    }
}

function isValidProp(key) {
    return key === "children" || key === "className" || key === "key"
        || key.startsWith("data-") || key.startsWith("aria-");
}

export function filterProps(props, include = {}, ...exclude) {
    if (include) {
        props = filter(props, (prop, key) => isValidProp(key) || include.hasOwnProperty(key));
    }

    if (exclude.length) {
        props = filter(props, (prop, key) => exclude.indexOf(key) === -1);
    }

    return props;
}

export class Component extends aggregate(PureComponent, Base) {
    static get className() {
        return toKebabCase(this.name);
    }

    get className() {
        return this.constructor.className;
    }

    get id() {
        if (!this.hasOwnProperty("_id")) {
            this.define({ _id: uuid() });
        }

        return this._id;
    }

    static aggregate(...mixins) {
        const superClasses = [this, ...mixins];
        const superProtos = superClasses.map((superClass) => superClass.prototype || superClass);

        const Combined = Base.aggregate.apply(this, mixins);

        lifeCycleProps.forEach((prop) => {
            Combined[prop] = Object.assign({}, ...superClasses.map(getProperty(prop) || null));
        });

        lifeCycleMethods.forEach((method) => {
            const superMethods = superProtos.map((superProto) => superProto[method]).filter(isFunction);

            if (superMethods.length) {
                Combined.prototype[method] = function(...args) {
                    superMethods.forEach((superMethod) => superMethod.call(this, ...args));
                };
            }
        });

        return Combined;
    }

    static bem(className, mods = null, ...other) {
        return bem(className, mods, ...other);
    }

    static block(mods = null, ...other) {
        return block(this.className, mods, ...other);
    }

    static connect(...args) {
        return connect(this, ...args);
    }

    static cx(...args) {
        return classNames(this.props.className, ...args);
    }

    static element(name, mods = null, ...other) {
        return element(this.className, name, mods, ...other);
    }

    static filterProps(props, ...exclude) {
        return filterProps(props, this.propTypes, ...exclude);
    }

    static initDefaultProps(defaultProps) {
        this.defaultProps = Object.assign({}, this.defaultProps, defaultProps);

        return this;
    }

    static initPropTypes(propTypes) {
        this.propTypes = Object.assign({}, this.propTypes, propTypes);

        return this;
    }

    static initProps(props) {
        const propTypes = Object.assign({}, this.propTypes);
        const defaultProps = Object.assign({}, this.defaultProps);

        Object.keys(props).forEach((key) => {
            const prop = props[key];

            if (prop.type) {
                propTypes[key] = prop.type;
            }

            if (prop.value) {
                defaultProps[key] = prop.value;
            }
        });

        return Object.assign(this, { propTypes, defaultProps });
    }

    static modifiers(mods = null) {
        return modifiers(this.className, mods);
    }

    static timerOwner() {
        return this.aggregate(TimerOwner);
    }

    static toString() {
        return Base.toString.call(this);
    }

    static withRouter() {
        withRouter(this);

        return this;
    }

    constructor(...args) {
        super(...args);
        this.init(...args);
    }

    addDOMListener(...args) {
        this.addUnmountListener(addEventListener(...args));
    }

    addUnmountListener(listener) {
        if (!this.unmountListeners) {
            this.define({ unmountListeners: [] });
        }

        this.unmountListeners.push(listener);

        return listener;
    }

    bem(...args) {
        return this.constructor.bem(...args);
    }

    block(modifiers, ...other) {
        return this.constructor.block(modifiers, this.props.className, ...other);
    }

    componentWillUnmount() {
        const { unmountListeners } = this;

        if (unmountListeners) {
            this.unmountListeners = void 0;
            unmountListeners.forEach((removeListener) => removeListener());
        }
    }

    cx(...args) {
        return classNames(this.props.className, ...args);
    }

    element(name, modifiers = null, ...other) {
        return this.constructor.element(name, modifiers, ...other);
    }

    initState(...states) {
        this.state = Object.assign(this.state || {}, ...states);
    }

    modifiers(modifiers) {
        return this.constructor.modifiers(modifiers);
    }

    toString() {
        return Base.prototype.toString.call(this);
    }
}

export class ConnectedComponent extends Component {
    static get connectedProps() {
        return {};
    }

    static get connectedStores() {
        return {};
    }

    filterConnected(nextProps) {
        const { connectedStores: stores, connectedProps: props } = this.constructor;

        return filter(nextProps, (value, prop) => !(stores.hasOwnProperty(prop) || props.hasOwnProperty(prop)));
    }

    static toString(...args) {
        const { connectedStores, connectedProps } = this;

        return super.toString(toString(connectedStores), toString(connectedProps), ...args);
    }

    componentWillMount() {
        const { connectedStores, connectedProps } = this.constructor;

        this.define({ connected: true });
        forEach(connectedStores, (store, prop) => {
            this.addUnmountListener(store.listen(onStoreChange.bind(null, this, prop)));
        });

        this.setState(Object.assign({},
            map(connectedStores, ({ state }) => state),
            connectedProps,
            this.filterConnected(this.props)
        ));
    }

    componentWillReceiveProps(props) {
        this.setState(this.filterConnected(props));
    }

    componentWillUnmount() {
        this.define({ connected: void 0 });

        super.componentWillUnmount();
    }
}

function createConnected(Component) {
    const connectedStores = {};
    const connectedProps = {};

    return class Connected extends ConnectedComponent {
        static get connectedProps() {
            return Object.assign({}, connectedProps);
        }

        static get connectedStores() {
            return Object.assign({}, connectedStores);
        }

        static get name() {
            return Component.name;
        }

        static connect(stores, ...props) {
            if (stores != null && some(stores, (store) => !(store instanceof Store))) {
                throw this.getError("Invalid store");
            }

            Object.assign(connectedStores, stores);
            Object.assign(connectedProps, ...props);

            return this;
        }

        render() {
            return React.createElement(Component, this.state);
        }
    };
}

export function connect(Component, stores, ...props) {
    if (!(Component.prototype instanceof ConnectedComponent)) {
        Component = createConnected(Component);
    }

    return Component.connect(stores, ...props);
}
