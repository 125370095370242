import { FormModel } from "../../../../../components/Form";
import { CertificateRequest } from "../../../../../model";

class ImportCertificateRequest extends CertificateRequest {
    static get name() {
        return CertificateRequest.name;
    }

    validate() {
        const { alias, pem } = this;

        return super.validate()
            || (pem.trim() === "" && this.getError("CSR pem empty", "pem"))
            || void 0;
    }
}

export default class CSRImportModel extends FormModel.of(ImportCertificateRequest) {
}
