import { Base } from "..";
import Model from "./Model";

export default class ModelConnector extends Base {
    static validate(entity) {
        let error;

        if (!Model.isA(entity)) {
            error = this.getError(`${Model} expected. ${entity} given.`);
        }

        return error;
    }

    static create(value, data = null) {
        return Promise.resolve(value);
    }

    static read(value, params = null) {
        return Promise.resolve(value);
    }

    static update(value, data = null) {
        return Promise.resolve(value);
    }

    static destroy(value, params = null) {
        return Promise.resolve(value);
    }
}
