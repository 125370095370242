import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router";
import moment from "moment";

import { Notifications, Notification as NotificationModel } from "../../model";
import { Component } from "../Component";
import { IntlModel } from "../../components/Intl";
import { ScrollArea } from "..";

import { NotificationActions } from "./NotificationActions";
import { NotificationTitle, NotificationBody } from "./NotificationBody";
import { NotificationCategory } from "./NotificationCategory";
import { NotificationTime } from "./NotificationTime";

export class NotificationsPreviewItem extends Component {
    render() {
        const { intl, item, onClick } = this.props;

        return (
            <div data-id={ item.id } className={ this.block() } onClick={ onClick }>
                <div className={ this.element("info") }>
                    <NotificationCategory { ...{ intl, item } } className={ this.element("category") } />
                    <NotificationTitle { ...{ intl, item } } className={ this.element("title") } />
                </div>
                <NotificationTime { ...{ intl, item } } className={ this.element("time") } />
            </div>
        );
    }
}

NotificationsPreviewItem.initProps({
    intl: { type: PropTypes.instanceOf(IntlModel).isRequired },
    item: { type: PropTypes.instanceOf(NotificationModel).isRequired }
});

export class NotificationPreview extends Component {
    render() {
        const { intl, item, style } = this.props;

        return (
            <div className={ this.block() } style={ style }>
                <NotificationCategory { ...{ intl, item } } className={ this.element("category") }>
                    <NotificationTitle { ...{ intl, item } } className={ this.element("title") } />
                </NotificationCategory>
                <div className={ this.element("info") }>
                    <NotificationTime { ...{ intl, item } } className={ this.element("time") } />
                    <NotificationCategory { ...{ intl, item } } className={ this.element("category-name") } />
                </div>
                <NotificationBody { ...{ intl, item } } className={ this.element("body") } />
                <NotificationActions { ...{ intl, item } } className={ this.element("actions") } />
            </div>
        );
    }
}

NotificationPreview.initProps({
    intl: { type: PropTypes.instanceOf(IntlModel).isRequired },
    item: { type: PropTypes.instanceOf(NotificationModel).isRequired }
});

export class NotificationsPreview extends Component {
    init(...args) {
        super.init(...args);

        this.onItemClick = this.onItemClick.bind(this);
        this.onTitleClick = this.onTitleClick.bind(this);
    }

    formatDate(time) {
        return moment(time).calendar(null, {
            sameDay: "[Today]",
            lastDay: "[Yesterday]",
            lastWeek: "dddd - DD.MM.YYYY",
            nextDay: "[Tomorrow]",
            nextWeek: "DD.MM.YYYY",
            sameElse: "DD.MM.YYYY"
        });
    }

    select(itemId) {
        const { onSelect } = this.props;

        if (onSelect) {
            onSelect(itemId);
        }
    }

    onTitleClick() {
        this.select(null);
    }

    renderTitle(item) {
        return (
            <div className={ this.element("title") } onClick={ this.onTitleClick }>
                { this.formatMessage(item ? "title-back" : "title") }
            </div>
        );
    }

    renderView(item) {
        const { props: { intl } } = this;

        return (
            <NotificationPreview { ...{ intl, item } } className={ this.element("view") } />
        );
    }

    onItemClick({ currentTarget }) {
        this.select(currentTarget.getAttribute("data-id"));
    }

    renderListItem(item) {
        const { intl } = this.props;

        return <NotificationsPreviewItem key={ item.id } { ...{ intl, item } }
            className={ this.element("item") } onClick={ this.onItemClick } />;
    }

    renderListDate(date, items) {
        return (
            <div key={ date } className={ this.element("section") }>
                <div className={ this.element("date") }>{ date }</div>
                { items.map(this.renderListItem.bind(this)) }
            </div>
        );
    }

    renderList() {
        const { items } = this.props;
        const itemsByDate = items.sort(NotificationModel.sort).groupBy((item) => this.formatDate(item.creationTime));
        const dates = Object.keys(itemsByDate);

        return (
            <div className={ this.element("list") } ref="list">
                { dates.map((date) => this.renderListDate(date, itemsByDate[date])) }
            </div>
        );
    }

    renderEmpty() {
        return (
            <div className="notice">
                { this.formatMessage("empty") }
            </div>
        );
    }

    renderFooter(item) {
        const { close } = this.props;
        const href = "/msb/activity/notifications";

        let link;
        if (!item) {
            link = (
                <Link to={ href } className="link" onClick={ close }>
                    { this.formatMessage("show-all") }
                </Link>
            );
        }

        return (
            <div className={ this.element("footer") }>
                { link }
            </div>
        );
    }

    render() {
        const { items, itemId } = this.props;

        const item = items.value(itemId);
        const list = !item;

        return (
            <div className={ this.block({ list, view: !list }) }>
                { this.renderTitle(item) }
                <ScrollArea className={ this.element("content") }>
                    { item ? this.renderView(item) : items.length ? this.renderList() : this.renderEmpty() }
                </ScrollArea>
                { this.renderFooter(item) }
            </div>
        );
    }
}

NotificationsPreview.initProps({
    intl: { type: PropTypes.instanceOf(IntlModel).isRequired },
    items: { type: PropTypes.instanceOf(Notifications).isRequired }
});
