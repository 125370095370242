import React from "react";

import { Component } from "../Component";

export class FormCheckBox extends Component {
    init(...args) {
        super.init(...args);

        this.onChange = this.onChange.bind(this);
    }

    onChange({ target: { checked } }) {
        const { name, onChange } = this.props;

        if (onChange) {
            onChange(checked, name);
        }
    }

    renderInput() {
        const { name, value, readOnly } = this.props;

        const className = this.cx("form-input form-input_checkbox form-checkbox__field", {
            [`form__input_${name}`.replace(/\W+/g, "-").toLowerCase()]: name
        });

        return (
            <input type="checkbox" id={ this.id } checked={ value || false } readOnly={ readOnly }
                className={ className } onChange={ this.onChange } />
        );
    }

    render() {
        const { value, placeholder } = this.props;

        return (
            <div className={ this.cx("form-checkbox", { "form-checkbox_checked": value }) }>
                { this.renderInput(this.id) }
                <label htmlFor={ this.id } className="form-checkbox__label">{ placeholder }</label>
            </div>
        );
    }
}
