import { toKebabCase } from "../../utils/string";
import { Store } from "../../utils/dataflow";
import { APIConnector, AuthActions } from "../../init";

export default class ViewStore extends Store {
    processError(error) {
        return Object.assign(error, {
            code: "error-" + toKebabCase(error.message)
        });
    }

    handleError(error) {
        switch (error.code) {
        case "error-token-not-specified":
        case "error-token-expired":
            AuthActions.logout.trigger();
            break;
        }

        throw error;
    }

    invoke(action, operation) {
        this.setState({ busy: true, error: null, action });

        return Promise.resolve(operation)
            .then((data) => {
                this.setState({ busy: false, error: null });

                return data;
            })
            .catch((error) => {
                error = this.processError(error);

                this.setState({ busy: false, error });
                if (error) {
                    if (process.env.NODE_ENV !== "production") {
                        console.warn(`${this}: operation ${action} failed: ${error.code}`, error);
                    }

                    this.handleError(error, action);
                }
            });
    }

    setError(error) {
        this.setState({ error });
    }

    setBusy(busy) {
        this.setState({ busy });
    }

    setItem(item) {
        this.setState({ item, error: null });
    }

    reset() {
        this.setItem(new this.Model.Item);
    }

    load() {
        return APIConnector.read(this.state.item).then((item) => this.setState({ item }));
    }

    refresh() {
        return this.invoke("refresh", this.load());
    }
}
