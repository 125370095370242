import { Header } from "../../components/Header";
import { AuthStore } from "../../init";
import React from "react";
import { Component, Spinner } from "../../ui";

import Menu from "./MainMenu";

function checkAuth(props) {
    const { auth, router, location } = props;

    if (auth.isGuest) {
        router.replace({
            pathname: "/auth/",
            query: { retUrl: location.pathname }
        });
    }
}

class Main extends Component {
    componentWillMount() {
        checkAuth(this.props);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.auth !== this.props.auth) {
            checkAuth(nextProps);
        }
    }

    render() {
        const { auth, children } = this.props;

        if (auth.isGuest) {
            return <Spinner center={ true } />;
        }

        return (
            <div className={ this.cx("layer") }>
                <Menu />
                <div className="frame">
                    <Header />
                    { children }
                </div>
            </div>
        );
    }
}

export default Main
    .connect({ auth: AuthStore })
    .withRouter();
