import { IntlStore } from "../../init";
import { PermissionedObjects } from "../../model";
import SelectItems from "./SelectItems";

const SelectObjects = SelectItems.of(PermissionedObjects);

export const SelectObjectsActions = SelectObjects.Actions;
export const SelectObjectsStore = SelectObjects.Store;
export const SelectObjectsModel = SelectObjects.Model;

export default SelectObjects.connect({ data: SelectObjectsStore, intl: IntlStore });
