import React from "react";
import { Component } from "./Component";

export class Widget extends Component {
    init(...args) {
        super.init(...args);

        this.initState({ collapsed: false });
        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState({ collapsed: !this.state.collapsed });
    }

    renderHead() {
        const { title } = this.props;

        return (
            <div className={ this.element("head") } onClick={ this.toggle }>
                { title }
            </div>
        );
    }

    renderContent() {
        const { children } = this.props;
        const expanded = !this.state.collapsed;

        return expanded ? <div className={ this.element("content") }>{ children }</div> : null;
    }

    render() {
        const { toolBar } = this.props;
        const expanded = !this.state.collapsed;

        return (
            <div className={ this.block({ expanded }) }>
                { this.renderHead() }
                { expanded ? toolBar : null }
                { this.renderContent() }
            </div>
        );
    }
}
