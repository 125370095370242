import { IntlStore } from "../../../../init";
import React from "react";
import { Component, Tab, TabBar } from "../../../../ui";

class Profile extends Component {
    renderToolBar() {
        return (
            <TabBar className={ this.element("toolbar") }>
                <Tab to="/msb/settings/profile/info/">{ this.formatMessage("info") }</Tab>
                <Tab to="/msb/settings/profile/activity/">{ this.formatMessage("activity") }</Tab>
                <Tab to="/msb/settings/profile/affiliations/">{ this.formatMessage("affiliations") }</Tab>
                <Tab to="/msb/settings/profile/password/">{ this.formatMessage("password") }</Tab>
            </TabBar>
        );
    }

    render() {
        const { children } = this.props;

        return (
            <div className={ this.block() }>
                { this.renderToolBar() }
                { children }
            </div>
        );
    }
}

export default Profile.connect({ intl: IntlStore });
