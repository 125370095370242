import { Action } from "../../utils/dataflow";
import { FormEditActions } from "../../components/Form";

class ResetPasswordActions extends FormEditActions {
    constructor() {
        super();

        Object.assign(this, {
            resetPassword: new Action
        });
    }
}

const Actions = new ResetPasswordActions();

export default Actions;
export const resetPassword = Actions.resetPassword.trigger;
export const setItem = Actions.setItem.trigger;
export const setItemField = Actions.setItemField.trigger;
