import React from "react";
import { Route, IndexRedirect } from "react-router";

import Notifications from "./Notifications";

export default function route(path) {
    return (
        <Route path={ path }>
            <IndexRedirect to="request_pending,request_approved,request_denied" />
            <Route path=":category" component={ Notifications } />
        </Route>
    );
};
