import PropTypes from "prop-types";
import React from "react";

import { ListItem } from "../List";
import { Collection } from "../../utils/dataflow";
import Select, { SelectList, SelectListItem } from "./Select";

export class MultiSelectListItem extends SelectListItem {
    render() {
        const { item: { id, name }, value, onClick } = this.props;
        const trimmedName = name.trim();
        const className = this.cx("list__item_multi", { "list__item_selected": value != null && value.contains(id) });

        return (
            <ListItem key={ id } data-id={ id } className={ className } onClick={ onClick }>
                <div className="item__title">{ trimmedName || "\u00A0" }</div>
            </ListItem>
        );
    }
}

export class MultiSelectList extends SelectList {
    init(...args) {
        super.init(...args);

        this.renderItem = this.renderItem.bind(this);
        this.isItemSelected = this.isItemSelected.bind(this);
        this.onClearSelectedClick = this.onClearSelectedClick.bind(this);
    }

    isItemSelected(item) {
        const { value } = this.props;

        return value != null && value.indexOf(item.id) !== -1;
    }

    onItemClick(event) {
        const { value } = this.props;

        const item = this.getItemFromEvent(event);
        const isSelected = this.isItemSelected(item);

        this.select(value ? isSelected ? value.removeItems(item.id) : value.insertItems(item) : [item]);
    }

    onClearSelectedClick() {
        const { value } = this.props;

        this.select(value.set([]));
    }

    renderListItem(props) {
        return <MultiSelectListItem { ...props } />;
    }

    renderItems() {
        const { items } = this.props;
        const rendered = [];
        const selected = items.filter(this.isItemSelected);
        const unselected = this.filterItems().filter((item) => !this.isItemSelected(item));

        if (selected.length) {
            rendered.push(
                <ListItem key="clear" className="select-list__clear" onClick={ this.onClearSelectedClick }>
                    Clear selected items
                </ListItem>
            );
        }

        return rendered.concat(
            selected.map(this.renderItem),
            unselected.length || selected.length === items.length ? unselected.map(this.renderItem) : this.renderEmpty()
        );
    }
}

export default class MultiSelect extends Select {
    init(...args) {
        super.init(...args);

        this.onRemoveItemClick = this.onRemoveItemClick.bind(this);
    }

    getValue(items) {
        const { value } = this.props;

        return value && value.filter((item) => items.value(item.id));
    }

    renderList(props) {
        return <MultiSelectList { ...props } />;
    }

    renderDropList() {
        const { props: { intl, search, sort, nullValue }, state: { searchValue } } = this;
        const items = this.getItems();

        return this.renderList({
            intl,
            items,
            value: this.getValue(items),
            search,
            searchValue,
            sort,
            nullValue,
            className: "select-list_multi",
            onSelect: this.onListSelect,
            onSearch: this.onListSearch
        });
    }

    getLabel(value) {
        const { placeholder, list, hideValue } = this.props;
        let label;

        if (!list && !hideValue) {
            label = value.map((item) => item.name).join(", ");
        }

        return label || placeholder || "Select...";
    }

    renderOpener(value, items) {
        const { drop } = this.state;
        const label = this.getLabel(value, items);
        const title = label.split(", ").join("\n");

        return (
            <div ref={ (el) => this.select = el } data-title={ drop ? null : title }
                className="select__opener" onClick={ this.onOpenerClick }>
                <span>{ label }</span>
            </div>
        );
    }

    onRemoveItemClick({ currentTarget }) {
        const id = currentTarget.getAttribute("data-id");

        this.onListSelect(this.props.value.removeItems(id));
    }

    renderSelectedListItem(item) {
        const { id, name } = item;

        return (
            <div key={ id } className="select-list__item">
                <div data-title={ name } className="select-list__title">{ name }</div>
                <i data-id={ id } className="select-list__icon ico ico-close"
                    onClick={ this.onRemoveItemClick } />
            </div>
        );
    }

    renderSelectedList(value) {
        return value && (
            <div className="select-list">
                { value.map(this.renderSelectedListItem.bind(this)) }
            </div>
        );
    }

    render() {
        const { props: { list }, state: { drop } } = this;

        const items = this.getItems();
        const value = this.getValue(items);

        const className = this.cx("select select_multi", {
            "select_drop": drop,
            "select_selected": value && value.length !== 0,
            "select_disabled": !items.length
        });

        return (
            <div className={ className }>
                { this.renderOpener(value, items) }
                { list === true ? this.renderSelectedList(value, items) : list || null }
            </div>
        );
    }
}

MultiSelect.initPropTypes({
    value: PropTypes.instanceOf(Collection)
});

MultiSelect.initDefaultProps({
    list: true
});
