import { IntlStore } from "../../../../init";
import React from "react";
import { Component, FormField, FormInput, FormPreview, GenericForm, Link, SideBar, ToolBar } from "../../../../ui";

import { setItem } from "./HaceraNetworkActions";
import HaceraNetworksStore from "./HaceraNetworksStore";
import HaceraNetworkStore from "./HaceraNetworkStore";

function setNetworkItem(networkId, networks) {
    setItem(networks.value(networkId));
}

class HaceraNetwork extends Component {
    componentWillMount() {
        const { params: { networkId }, items: { item: { networks } } } = this.props;

        setNetworkItem(networkId, networks);
    }

    componentWillReceiveProps(nextProps) {
        const { params: { networkId: currNetworkId }, items: { item: { networks: currNetworks } } } = this.props;
        const { params: { networkId: nextNetworkId }, items: { item: { networks: nextNetworks } } } = nextProps;

        if (nextNetworkId !== currNetworkId || nextNetworks !== currNetworks) {
            setNetworkItem(nextNetworkId, nextNetworks);
        }
    }

    renderJoinLink() {
        return (
            <Link to={ "join" } className={ this.element("join") }>
                { this.formatMessage("join") }
            </Link>
        );
    }

    renderToolBar() {
        const { data: { item }, items: { item: { configs } }, params: { networkId } } = this.props;

        return (
            <ToolBar>
                <div className={ this.element("title") }>{ item.networkName }</div>
                { configs.contains(networkId) ? this.renderJoinLink() : null }
            </ToolBar>
        );
    }

    render() {
        const { data: { item }, intl } = this.props;

        if (!item) {
            return null;
        }

        return (
            <SideBar toolBar={ this.renderToolBar() } className={ this.block() }>
                <GenericForm { ...{ intl } } header={ false } submit={ false } cancel={ false }>
                    <FormField name="sshKey" label={ this.formatMessage("ssh-key-label") }>
                        <FormPreview value={ item.sshKey } />
                    </FormField>
                    <FormField name="userId" label={ this.formatMessage("user-label") }>
                        <FormInput value={ item.userId } />
                    </FormField>
                </GenericForm>
            </SideBar>
        );
    }
}

export default HaceraNetwork.connect({
    intl: IntlStore,
    data: HaceraNetworkStore,
    items: HaceraNetworksStore
});
