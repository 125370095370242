import { FormModel } from "../../../../../components/Form";
import { CertificateRequest } from "../../../../../model";

export class CreateCertificateRequest extends CertificateRequest {
    static get name() {
        return CertificateRequest.name;
    }

    validate() {
        const { commonName, country, state, city, organization, department, keyType, keySize } = this;

        return super.validate()
            || (commonName.trim() === "" && this.getError2("empty", "commonName"))
            || (organization.trim() === "" && this.getError2("empty", "organization"))
            || (department.trim() === "" && this.getError2("empty", "department"))
            || (country.trim() === "" && this.getError2("empty", "country"))
            || (state.trim() === "" && this.getError2("empty", "state"))
            || (city.trim() === "" && this.getError2("empty", "city"))
            || (keyType.trim() === "" && this.getError2("empty", "keyType"))
            || (String(keySize).trim() === "" && this.getError2("empty", "keySize"))
            || void 0;
    }
}

export default class CSRCreateModel extends FormModel.of(CreateCertificateRequest) {
}
