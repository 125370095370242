import PropTypes from "prop-types";
import React from "react";

import { Component } from "../Component";
import { Markdown } from "../Markdown";

export class FormError extends Component {
    render() {
        const { intl, error } = this.props;

        let message;
        if (error) {
            console.log('error', error, 'intl', intl)
            message = intl.formatMessage(error.code);

            if (message === error.code) {
                message = intl.formatMessage(error.message);
            }
        }

        return message ? <Markdown data-title={ message } className={ this.block() }>{ message }</Markdown> : null;
    }
}

FormError.initPropTypes({
    error: PropTypes.instanceOf(Error)
});
