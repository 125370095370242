import { FormModel } from "../../../../../components/Form";
import { Entity } from "../../../../../model";

export class Preferences extends Entity {
    init(...args) {
        super.init(...args, { theme: "dark" });
    }

    validate() {
        const { theme } = this;

        return super.validate()
            || (theme.trim() === "" && this.getError("General theme empty", "theme"))
            || void 0;
    }
}

export default class GeneralModel extends FormModel.of(Preferences) {
    init(...args) {
        super.init({ item: {} }, ...args);
    }
}
