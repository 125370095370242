import { Entity } from "./Entity";
import { NetworkNodes } from "./NetworkNodes";

export class NetworkRequest extends Entity {
    get name() {
        return this.networkName;
    }

    init(...args) {
        super.init({ nodes: [] }, ...args);
    }

    valueOf() {
        return Object.assign(super.valueOf(), { networkName: this.networkName });
    }
}

NetworkRequest.connect({
    nodes: NetworkNodes
});
