import React from "react";
import { Component } from "../../../ui/Component";

export default class Manage extends Component {
    render() {
        const { children } = this.props;

        return children;
    }
}
