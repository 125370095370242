import React from "react";
import PropTypes from "prop-types";

import { Component } from "../Component";
import { IntlModel } from "../../components/Intl";

export default class ViewStatus extends Component.timerOwner() {
    init(...args) {
        super.init(...args);

        this.initState({ time: 10 });
        this.onWaitTimeout = this.onWaitTimeout.bind(this);
    }

    onWaitTimeout() {
        const { refresh, waitSeconds } = this.props;
        const time = (this.state.time + waitSeconds - 1) % waitSeconds;

        this.waitTimeout = null;
        this.setState({ time });

        if (time !== 0) {
            this.setWaitTimeout();
        } else if (refresh) {
            refresh();
        }
    }

    clearWaitTimeout() {
        if (this.waitTimeout) {
            this.clearTimeout(this.waitTimeout);
        }
    }

    setWaitTimeout() {
        this.clearWaitTimeout();
        this.waitTimeout = this.setTimeout(this.onWaitTimeout, 1000);
    }

    componentWillReceiveProps({ error, waitSeconds }) {
        if (error) {
            const { error: currError } = this.props;

            if (error !== currError) {
                this.setState({ time: waitSeconds });
            }

            this.setWaitTimeout();
        }
    }

    componentDidMount() {
        const { error, waitSeconds } = this.props;

        if (error) {
            this.setState({ time: waitSeconds });
            this.setWaitTimeout();
        }
    }

    renderError() {
        const { props: { intl }, state: { time } } = this;

        return (
            <div className="error">
                <div className="error__title">{ intl.formatMessage("connection-error-title") }</div>
                <div className="error__message">
                    { intl.formatMessage("connection-error-message", { time: String(time) }) }
                </div>
            </div>
        );
    }

    renderBusy() {
        const { intl } = this.props;

        return intl.formatMessage("loading");
    }

    render() {
        const { error, busy } = this.props;
        const type = error ? "error" : busy ? "busy" : "";

        return (
            <div className={ this.cx("view-status", { ["view-status_" + type]: type }) }>
                { error ? this.renderError(error) : this.renderBusy() }
            </div>
        );
    }
}

ViewStatus.initProps({
    intl: { type: PropTypes.instanceOf(IntlModel) },
    busy: { type: PropTypes.bool },
    error: { type: PropTypes.instanceOf(Error) },
    waitSeconds: { type: PropTypes.number.isRequired, value: 10 },
    refresh: { type: PropTypes.func.isRequired }
});
