import React from "react";
import PropTypes from "prop-types";

import { Component } from "../Component";
import { Collection } from "../../utils/dataflow";
import { Select } from "..";

const items = new Collection([
    { id: "1d", name: "1 Day" },
    { id: "5d", name: "5 Days" },
    { id: "1w", name: "1 Week" },
    { id: "1m", name: "1 Month" },
    { id: "3m", name: "3 Months" },
    { id: "ydt", name: "YDT" }
]);

export default class SelectDate extends Component {
    render() {
        const { value, placeholder, onChange } = this.props;

        return (
            <Select { ...{ items, value, placeholder, onChange } } search={ false } nullValue={ "All" } />
        );
    }
}
