import React, { Children } from "react";
import PropTypes from "prop-types";
import { render, unmountComponentAtNode } from "react-dom";
import { Component } from "./Component";

export class ModalOwner {
    shouldModalRender() {
        const { children } = this.props;

        return Children.count(children);
    }

    hideModal() {
        Modal.remove(this.modalId);
    }

    showModal(props) {
        const { children } = this.props;

        Modal.render(this.modalId, children, Object.assign({}, props, {
            context: this.context
        }));
    }

    updateModal() {
        if (this.shouldModalRender()) {
            this.showModal();
        } else {
            this.hideModal();
        }
    }

    componentDidMount() {
        this.updateModal();
    }

    componentDidUpdate() {
        this.updateModal();
    }

    componentWillUnmount() {
        this.hideModal();
    }
}

ModalOwner.contextTypes = {
    router: PropTypes.object.isRequired
};

export default class Modal extends Component {
    getChildContext() {
        return this.props.props.context;
    }

    close() {
        const { id } = this.props;

        this.constructor.remove(id);
    }

    render() {
        const { children, props } = this.props;

        return (
            <div className={ this.cx("modal") }>
                <div className="modal__overlay" />
                <div className="modal__content">
                    {
                        React.Children.map(children, (child) => (
                            React.cloneElement(child, props)
                        ))
                    }
                </div>
            </div>
        );
    }

    static remove(id) {
        const container = document.getElementById("modal-" + id);

        if (container) {
            unmountComponentAtNode(container);
        }
    }

    static render(id, component, props) {
        let container;

        if (!id) {
            throw this.getError("id expected");
        }

        container = document.getElementById("modal-" + id);
        if (!container) {
            container = document.body.appendChild(
                Object.assign(document.createElement("div"), {
                    id: "modal-" + id
                })
            );
        }

        render(<Modal key="modal" id={ id } props={ props }>{ component }</Modal>, container);
    }
}

Modal.childContextTypes = {
    router: PropTypes.object.isRequired
};
