import React from "react";
import { Component } from "./Component";

export class SideBar extends Component {
    render() {
        const { children, toolBar, float } = this.props;

        return (
            <aside className={ this.block({ float }) }>
                { toolBar }
                { children }
            </aside>
        );
    }
}
