import { compact } from "../object";

const { NEGATIVE_INFINITY, POSITIVE_INFINITY } = Number;

export const INFINITY_RECT = {
    left: NEGATIVE_INFINITY,
    top: NEGATIVE_INFINITY,
    width: POSITIVE_INFINITY,
    height: POSITIVE_INFINITY
};

export class Point {
    constructor(x, y) {
        if (isNaN(x) || isNaN(y)) {
            throw new TypeError("Invalid argument.");
        }

        this.x = +x;
        this.y = +y;
    }

    move(dX, dY) {
        return new Point(this.x + dX, this.y + dY);
    }

    pointTo(rect) {
        return this.move(-rect.left, -rect.top);
    }

    constrain(rect) {
        const { left, right, top, bottom } = new Rect(INFINITY_RECT, rect);
        const { x, y } = this;

        return new Point(
            Math.max(Math.min(x, right), left),
            Math.max(Math.min(y, bottom), top)
        );
    }
}

export class Bounds {
    constructor(...props) {
        const { left, top, right, bottom, width, height } = compact(...props);

        this.from = new Point(left, top);
        this.to = new Point(right, bottom);
        this.size = new Point(width || 0, height || 0);
    }

    get left() {
        return this.from.x;
    }

    get right() {
        return this.to.x;
    }

    get width() {
        return this.size.x;
    }

    get top() {
        return this.from.y;
    }

    get bottom() {
        return this.to.y;
    }

    get height() {
        return this.size.y;
    }

    get center() {
        const size = this.size;
        const cX = size.x / 2;
        const cY = size.y / 2;

        return new Bounds({
            left: this.left + cX,
            top: this.top + cY,
            right: this.right + cX,
            bottom: this.bottom + cY
        });
    }

    get rect() {
        return new Rect({
            left: this.left,
            top: this.top,
            width: this.width,
            height: this.height
        });
    }
}

export class Rect {
    constructor(...props) {
        const { left, top, width, height } = compact(...props);

        this.from = new Point(left, top);
        this.size = new Point(width, height);
    }

    get left() {
        return this.from.x;
    }

    get width() {
        return this.size.x;
    }

    get right() {
        const { left, width } = this;

        return isFinite(left) || isFinite(width) ? left + width : width;
    }

    get top() {
        return this.from.y;
    }

    get height() {
        return this.size.y;
    }

    get bottom() {
        const { top, height } = this;

        return isFinite(top) || isFinite(height) ? top + height : height;
    }

    get center() {
        return new Point(this.left + this.width / 2, this.top + this.height / 2);
    }

    move(dX, dY) {
        return Object.assign(new Rect(this), {
            from: this.from.move(dX, dY)
        });
    }

    resize(dX, dY) {
        return Object.assign(new Rect(this), {
            size: this.size.move(dX, dY)
        });
    }

    rectTo(rect) {
        return this.move(-rect.left, -rect.top);
    }

    contour(bounds) {
        return this.rectTo(bounds).resize(bounds.left + bounds.right, bounds.top + bounds.bottom);
    }

    boundsTo(rect) {
        return new Bounds({
            left: this.left - rect.left,
            top: this.top - rect.top,
            right: rect.right - this.right,
            bottom: rect.bottom - this.bottom,
            width: this.width,
            height: this.height
        });
    }

    valueOf() {
        return {
            left: this.left,
            top: this.top,
            width: this.width,
            height: this.height
        };
    }
}

function getBoundingClientRect(el) {
    const { left, top, width, height } = el.getBoundingClientRect();

    return { left, top, width, height };
}

/**
 * @param {HTMLElement} el
 * @return {Rect}
 */
export default function getRect(el) {
    return new Rect(getBoundingClientRect(el || document.body));
}
