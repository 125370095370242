import React from "react";

import { GenericForm, FormFooter, FormSubmit } from "../../../../ui";


export default class RepositoryEditForm extends GenericForm {
    renderSubmit(message) {
        const { busy, action } = this.props;

        return (
            <FormSubmit { ...{ busy: busy && action === "save-item" } }>
                { message }
            </FormSubmit>
        );
    }

    renderFooter() {
        const { intl } = this.props;

        return (
            <FormFooter>
                { this.renderSubmit(intl.formatMessage("edit-form-submit")) }
            </FormFooter>
        );
    }
}
