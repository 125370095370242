import { Entity } from "./Entity";
import { User } from "./User";

const IMPORTANCE = {
    0: "none",
    1: "min",
    2: "low",
    3: "default",
    4: "high",
    5: "max"
};

const CATEGORY = {
    request_pending: "request",
    request_denied: "denied",
    request_approved: "approved",

    CATEGORY_ALARM: "alert",
    CATEGORY_ERROR: "error",
    CATEGORY_MESSAGE: "request",
    CATEGORY_RECOMMENDATION: "approved",
    CATEGORY_SERVICE: "alert",
    CATEGORY_STATUS: "system",
    CATEGORY_SYSTEM: "system"
};

export class Notification extends Entity {
    static get name() {
        return "Notification";
    }

    get category() {
        return this._innerCategory;
    }

    set category(category) {
        this.define({
            _category: category,
            _innerCategory: CATEGORY[category] || CATEGORY.CATEGORY_ALARM
        });
    }

    get importance() {
        return this._importance;
    }

    set importance(importance) {
        this.define({ _importance: IMPORTANCE[importance] || IMPORTANCE[1] });
    }

    get readTime() {
        return this._readTime;
    }

    set readTime(readTime) {
        if (readTime) {
            this.define({ _readTime: readTime });
        }
    }

    get recipientUserId() {
        const { recipientUser } = this;

        return recipientUser && recipientUser.id;
    }

    set recipientUserId(skip) {
    }

    get senderUserId() {
        const { senderUser } = this;

        return senderUser && senderUser.id;
    }

    set senderUserId(skip) {
    }

    static sort(n1, n2) {
        return n2.creationTime.localeCompare(n1.creationTime);
    }

    init(...args) {
        super.init({ title: "", body: "", category: "", importance: "", creationTime: "" }, ...args);
    }

    valueOf() {
        const { readTime, recipientUserId, senderUserId, _category: category } = this;

        return Object.assign(super.valueOf(), {
            readTime, recipientUserId, senderUserId, category
        });
    }
}

Notification.connect({
    // recipientUser: User,
    senderUser: User
});
