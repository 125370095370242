import React from "react";
import { Component } from "../../../ui/Component";

export default class Activity extends Component {
    render() {
        const { children } = this.props;

        return children;
    }
}
