import React from "react";
import PropTypes from "prop-types";
import { render, unmountComponentAtNode, findDOMNode } from "react-dom";
import { alignTo, findScrollParents, containsOrSelf } from "../utils/html";
import { Component } from "./Component";

const CLASS_NAME = "drop-container";

export const DROP_ALIGN_VERTICAL = [
    { side: "top", to: "bottom", offset: 7 },
    { side: "bottom", to: "top", offset: 7 }
];

export const DROP_HORIZONTAL_VERTICAL = [
    { side: "center", to: "center" },
    { side: "left", to: "left", offset: -10 }
];

export const DROP_ALIGN_CENTER = [DROP_HORIZONTAL_VERTICAL, DROP_ALIGN_VERTICAL];
export const DROP_ALIGN_LEFT = [{ side: "left", to: "left", offset: -10 }, DROP_ALIGN_VERTICAL];
export const DROP_ALIGN_RIGHT = [{ side: "right", to: "right", offset: -10 }, DROP_ALIGN_VERTICAL];

export class DropOwner {
    get dropId() {
        return "drop";
    }

    initDrop(drop) {
        this.initState({ drop: drop || null });
        this.onDropHide = this.onDropHide.bind(this);
    }

    onDropHide() {
        this.hideDrop();
    }

    renderDrop() {
        const { drop } = this.state;
        const { content, props } = drop;

        Drop.render(this.dropId, content, Object.assign({}, props, {
            context: this.context,
            onHide: this.onDropHide
        }));
    }

    componentDidMount() {
        if (this.state.drop) {
            this.renderDrop();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.drop) {
            this.renderDrop();
        } else if (prevState.drop) {
            Drop.remove(this.dropId);
        }
    }

    componentWillUnmount() {
        if (this.state.drop) {
            Drop.remove(this.dropId);
        }
    }

    showDrop(content, props) {
        this.setState({ drop: { content, props } });
    }

    hideDrop() {
        this.setState({ drop: null });
    }
}

DropOwner.contextTypes = {
    router: PropTypes.object.isRequired
};

/**
 * @extends Component
 */
export class Drop extends Component {
    getChildContext() {
        return this.props.context;
    }

    init(...args) {
        super.init(...args);

        this.onParentScroll = this.onParentScroll.bind(this);
        this.onMouseDown = this.onMouseDown.bind(this);
    }

    hide() {
        this.constructor.remove(this.props.dropId);
    }

    align() {
        const { container, to, align } = this.props;

        alignTo(container, to, align, CLASS_NAME);
    }

    onParentScroll() {
        const { hideOnScroll } = this.props;

        hideOnScroll ? this.hide() : this.align();
    }

    onMouseDown({ target }) {
        const { props: { to, hideOnMouseDown }, refs: { drop } } = this;

        if (hideOnMouseDown && !containsOrSelf(drop, target) && !containsOrSelf(to, target)) {
            this.hide();
        }
    }

    addDOMListeners() {
        const { to } = this.props;
        const scrollParents = findScrollParents(findDOMNode(to));

        this.addDOMListener(document, "mousedown touchstart", this.onMouseDown);

        this.addDOMListener(window, "resize", this.onParentScroll);
        scrollParents.forEach((parent) => this.addDOMListener(parent, "scroll", this.onParentScroll));
    }

    componentDidMount() {
        this.addDOMListeners();
        this.align();
    }

    componentDidUpdate() {
        this.align();
    }

    componentWillUnmount() {
        const { onHide } = this.props;

        super.componentWillUnmount();

        if (onHide) {
            onHide();
        }
    }

    render() {
        const { children } = this.props;

        return (
            <div ref="drop" className={ this.cx("drop") }>
                <div className="drop__content">
                    { children }
                </div>
            </div>
        );
    }

    static remove(dropId) {
        const container = document.getElementById(dropId);

        if (container) {
            unmountComponentAtNode(container);
        }
    }

    static render(dropId, component, props) {
        const Drop = this;
        const container = document.getElementById(dropId) || document.body.appendChild(
            Object.assign(document.createElement("div"), { id: dropId, className: CLASS_NAME }));

        render(<Drop key={ dropId } { ...{ dropId, container } } { ...props }>{ component }</Drop>, container);
    }
}

Drop.initProps({
    hideOnMouseDown: { value: true }
});

Drop.childContextTypes = {
    router: PropTypes.object.isRequired
};
