import { toJSON } from "../../utils/object";
import { Model } from "../../utils/dataflow";
import TableSortModel from "./TableSortModel";
import TableLimitModel from "./TableLimitModel";

export default class TableFilterModel extends Model {
    init(...args) {
        super.init({ sort: {}, total: 0, limit: { page: 0, perPage: 20 } }, ...args);
    }

    get page() {
        return this.limit && this.limit.page;
    }

    set page(page) {
        if (this.limit) {
            this.limit = this.limit.set({ page });
        }
    }

    get perPage() {
        return this.limit && this.limit.perPage;
    }

    set perPage(perPage) {
        if (this.limit) {
            this.limit = this.limit.set({ perPage });
        }
    }

    valueOf() {
        const { sort, limit } = this;

        return Object.assign(super.valueOf(), { sort, limit });
    }

    toJSON() {
        const { sort, limit, search } = this;

        return Object.assign({},
            sort && { sort__: toJSON(sort) || void 0 },
            limit && { limit__: toJSON(limit) || void 0 },
            search && { search__: toJSON(search) || void 0 },
        );
    }
}

TableFilterModel.connect({
    sort: TableSortModel,
    limit: TableLimitModel
});
