import React from "react";

import { Component } from "./Component";

export class CSRStatus extends Component {
    render() {
        const { status, empty } = this.props;
        const value = (status || "").toLowerCase();
        const name = this.formatMessage(value);

        return (
            <div data-tooltip={ empty ? name : null } className={ this.block({ [value]: true }) }>
                { empty ? "\u00A0" : name }
            </div>
        );
    }
}
