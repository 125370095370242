import PropTypes from "prop-types";
import React from "react";
import ReCaptcha from "react-google-recaptcha";
import theme from "../../utils/theme";

import { Component } from "../Component";
import { FormCheckBox } from "./FormCheckBox";

export class FormCaptcha extends Component {
    init(...args) {
        super.init(...args);

        this.onChange = this.onChange.bind(this);
        this.onExpired = this.onExpired.bind(this);
    }

    onChange(value) {
        const { siteKey, onChange } = this.props;

        if (onChange) {
            onChange(value || "", siteKey);
        }
    }

    onExpired() {
        const { onExpired } = this.props;

        this.onChange("");
        if (onExpired) {
            onExpired(new Error("expired"));
        }
    }

    render() {
        const { siteKey, value, placeholder } = this.props;

        return (
            <div className={ this.cx("form-captcha", { "form-captcha_passed": value }) }>
                <FormCheckBox value={ !!value } placeholder={ placeholder } />
                <ReCaptcha ref="recaptcha" className="form-captcha__recaptcha" sitekey={ siteKey } theme={ theme }
                    onChange={ this.onChange } onExpired={ this.onExpired } />
            </div>
        );
    }
}

FormCaptcha.initPropTypes({
    onChange: PropTypes.func
});
