import React from "react";
import { Route, IndexRoute } from "react-router";

import Delegations from "./Delegations";
import DelegationsEmpty from "./DelegationsEmpty";
import DelegationManagers from "./DelegationManagers";
import DelegationJoin from "./DelegationJoin";
import { DelegationAddManager } from "./DelegationAddManager";
import { DelegationAddParticipant } from "./DelegationAddParticipant";

export default function route(path) {
    return (
        <Route path={ path } component={ Delegations }>
            <IndexRoute component={ DelegationsEmpty } />
            <Route path=":networkId/">
                <IndexRoute component={ DelegationManagers } />
                <Route path="join" component={ DelegationJoin } />
                <Route path="add-manager" component={ DelegationAddManager } />
                <Route path="add-participant" component={ DelegationAddParticipant } />
            </Route>
        </Route>
    );
}
