import React from "react";

import { Component } from "../../ui/Component";
import { Notifications } from "../Notifications";
import { UserInfo } from "../UserInfo";
import { Header } from "../../ui";

import HeaderStore from "./HeaderStore";

export class HeaderView extends Component {
    render() {
        const { data: { title } } = this.props;

        return (
            <Header title={ title }>
                <Notifications />
                <UserInfo />
            </Header>
        );
    }
}

export default HeaderView.connect({ data: HeaderStore });
