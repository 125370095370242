import React from "react";
import moment from "moment";

import { forEach, typeOf } from "../../../../../utils/object";
import { IntlStore } from "../../../../../init";
import { Component, GenericForm, FormField, FormInput, FormPreview, FormCheckBox, ScrollArea } from "../../../../../ui";

import CSRForm from "./CSRForm";
import CSRViewStore from "./CSRViewStore";
import { setItem } from "./CSRViewActions";


class AttributesTable extends Component {
    renderName(name) {
        return name.replace(/([a-z])([A-Z])/g, "$1 $2");
    }

    renderValue(value, key) {
        return typeOf(value) === "array" ? typeOf(value[0]) === "object" ? JSON.stringify(value) : value.join(", ")
            : key === "NotAfter" || key === "NotBefore" ? moment(value).format("YYYY.MM.DD HH:mm:ss")
                   : String(value);
    }

    renderGroup(rows, value, key, ...parent) {
        rows.push(
            <tr key={ [key, ...parent].join(".") } className="attribute_group">
                <td colSpan="2">{ this.renderName(key) }</td>
            </tr>
        );

        this.renderRows(rows, value, key, ...parent);
    }

    renderRow(rows, value, key, ...parent) {
        value = this.renderValue(value, key);

        rows.push(
            <tr key={ [key, ...parent].join(".") } className="attribute">
                <td>{ this.renderName(key) }</td>
                <td data-title={ value }>{ value }</td>
            </tr>
        );
    }

    renderRows(rows, items, ...parent) {
        forEach(items, (value, key) => {
            typeOf(value) === "object"
                ? this.renderGroup(rows, value, key, ...parent)
                : this.renderRow(rows, value, key, ...parent);
        });
    }

    render() {
        const { items } = this.props;
        const rows = [];

        this.renderRows(rows, items);

        return (
            <table className="table table_attributes">
                <tbody>{ rows }</tbody>
            </table>
        );
    }
}

class CSRView extends CSRForm {
    setItem(item) {
        setItem(item);
    }

    renderAttributes() {
        const { intl, data: { item: { attributes } } } = this.props;
        let field;

        if (attributes && Object.keys(attributes).length) {
            field = (
                <FormField name="attributes"
                    label={ intl.formatMessage("settings-credentials-csr-view-attributes-label") }>
                    <ScrollArea>
                        <AttributesTable items={ attributes } />
                    </ScrollArea>
                </FormField>
            );
        }

        return field;
    }

    render() {
        const { intl, data: { busy, error, item } } = this.props;

        if (!item) {
            return null;
        }

        const title = intl.formatMessage("settings-credentials-csr-view-title", item);

        return (
            <GenericForm { ...{ intl, busy, error, title } }
                submit={ intl.formatMessage("settings-credentials-csr-view-submit") }
                className={ this.block() }
                onSubmit={ this.close } onCancel={ this.close }>

                <FormField name="alias"
                    label={ intl.formatMessage("settings-credentials-csr-view-name-label") }>
                    <FormInput type="text" name="alias" value={ item.alias } readOnly={ true } />
                </FormField>

                <FormField key="commonName" name="common-name"
                    label={ intl.formatMessage("settings-credentials-csr-view-common-name-label") }>
                    <FormInput type="text" name="common-name" value={ item.commonName } readOnly={ true } />
                </FormField>

                <FormField key="organization" name="organization"
                    label={ intl.formatMessage("settings-credentials-csr-view-organization-label") }>
                    <FormInput type="text" name="organization" value={ item.organization } readOnly={ true } />
                </FormField>

                <FormField key="department" name="department"
                    label={ intl.formatMessage("settings-credentials-csr-view-department-label") }>
                    <FormInput type="text" name="department" value={ item.department } readOnly={ true } />
                </FormField>

                <FormField key="country" name="country"
                    label={ intl.formatMessage("settings-credentials-csr-view-country-label") }>
                    <FormInput type="text" name="country" value={ item.country } readOnly={ true } />
                </FormField>

                <FormField key="state" name="state"
                    label={ intl.formatMessage("settings-credentials-csr-view-state-label") }>
                    <FormInput type="text" name="state" value={ item.state } readOnly={ true } />
                </FormField>

                <FormField key="city" name="city"
                    label={ intl.formatMessage("settings-credentials-csr-view-city-label") }>
                    <FormInput type="text" name="city" value={ item.city } readOnly={ true } />
                </FormField>

                <FormField key="key" name="key"
                    label={ intl.formatMessage("settings-credentials-csr-view-key-label") }>
                    <FormInput type="text" name="keyType" value={ item.keyType + "  " + item.keySize }
                        readOnly={ true } />
                </FormField>

                <FormField key="pending" name="pending">
                    <FormCheckBox type="text" name="keyType" value={ item.pending } readOnly={ true }
                        placeholder={ intl.formatMessage("settings-credentials-csr-view-pending-label") } />
                </FormField>

                { this.renderAttributes() }

                <FormField name="pem-view"
                    label={ intl.formatMessage("settings-credentials-csr-view-pem-view-label") }>
                    <FormPreview name="pem-view" value={ item.pem } readOnly={ true }
                        placeholder={ intl.formatMessage("settings-credentials-csr-view-pem-view-placeholder") } />
                </FormField>
            </GenericForm >
        );
    }
}

export default CSRView.connect({ intl: IntlStore, data: CSRViewStore });
