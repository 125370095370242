import validator from "../object/validator";
import Publisher from "./Publisher";

export default class Action extends Publisher {
    init(...validators) {
        super.init();

        this.define({
            validate: validator(validators, "Unable to trigger action: failed param %2 validation: %1")
        });

        this.trigger = this.trigger.bind(this);
    }

    trigger(...args) {
        return Promise.resolve(this.validate(args)).then(() => super.trigger(...args));
    }
}
