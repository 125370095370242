import { setTitle } from "../../../../../components/Header";
import { View } from "../../../../../components/View";
import { AuthStore } from "../../../../../init";
import React from "react";
import { FormField, GenericForm, Main, ModalOwner, Select } from "../../../../../ui";

import { Collection } from "../../../../../utils/dataflow";
import GeneralActions, { saveItem, setItem, setItemField } from "./GeneralActions";
import { Preferences } from "./GeneralModel";
import GeneralStore from "./GeneralStore";

const THEMES = [
    {
        "id": "light",
        "name": "Light"
    },
    {
        "id": "dark",
        "name": "Dark"
    }
];

const THEMES_COLLECTION = new Collection(THEMES);

function onThemeChange(value) {
    setItemField("theme", value);
}


class General extends View.aggregate(ModalOwner) {
    get modalId() {
        return "password";
    }

    init(...args) {
        super.init(...args);

        this.onSubmit = this.onSubmit.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }

    componentWillMount() {
        const { intl, auth } = this.props;

        setItem(auth.user.preferences || new Preferences());
        setTitle(intl.formatMessage("settings-preferences-title"));
    }

    close() {
        this.navigateDown(this.props, "success");
    }

    onSuccess() {
        this.close();
    }

    onSubmit() {
        const { data: { item } } = this.props;

        saveItem(item).then(() => this.onSuccess()).catch(() => void 0);
    }

    onCancel() {
        this.close();
    }

    render() {
        const { intl, data: { busy, error, item } } = this.props;

        if (!item) {
            return null;
        }

        return (
            <Main className={ this.block() }>
                <GenericForm { ...{ intl, busy, error } }
                    close={ false } header={ false } cancel={ false }
                    className={ this.element("form") }
                    onSubmit={ this.onSubmit } onCancel={ this.onCancel }>

                    <FormField name="theme"
                        label={ intl.formatMessage("settings-preferences-general-theme-label") }>
                        <Select name="theme" items={ THEMES_COLLECTION } value={ item.theme }
                            onChange={ onThemeChange } search={ false } nullValue={ false } />
                    </FormField>
                </GenericForm>
            </Main>
        );
    }
}

export default General.of(GeneralStore, GeneralActions)
    .connect({ auth: AuthStore });
