import assign from "./assign";

export default function clone(target, ...data) {
    const proto = Object.getPrototypeOf(target);
    const prev = proto instanceof target.constructor ? proto : target;
    const next = Object.create(prev);

    return data.length === 0 && prev === target ? next
        : assign(next, prev === target ? null : target, ...data);
}
