const BASE = 36;
const MIN_UID = parseInt("10000000", BASE);
const MAX_UID = parseInt("zzzzzzzz", BASE);

let uidCount = Number.POSITIVE_INFINITY,
    uidPrefix;

function randomInt(min, max) {
    return min + Math.round(Math.random() * (max - min));
}

function init() {
    // will have fixed length(9) and will be starting with a letter till 25 May 2059
    uidPrefix = Date.now().toString(BASE);
    // has fixed length(11)
    uidCount = MIN_UID + randomInt(0, MIN_UID / BASE);
}

/**
 * Returns Unique identifier.
 *
 * @returns {string}
 */
export default function(prefix = "") {
    if (uidCount >= MAX_UID) {
        init();
    }

    uidCount++;
    return prefix + uidPrefix + uidCount.toString(BASE);
}
