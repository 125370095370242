import React from "react";

import { Component } from "../Component";

export class InfoTitle extends Component {
    render() {
        const { children } = this.props;

        return (
            <div className={ this.block() }>
                { children }
            </div>
        );
    }
}

export class InfoBlock extends Component {
    render() {
        const { title, children } = this.props;

        return (
            <div className={ this.block() }>
                { typeof title === "string" ? <InfoTitle>{ title }</InfoTitle> : title || null }
                <div className={ this.element("body") }>
                    { children }
                </div>
            </div>
        );
    }
}

export class InfoLabel extends Component {
    render() {
        const label = String(this.props.children);

        return (
            <div data-title={ label } className={ this.block() }>
                { label }
            </div>
        );
    }
}

export class InfoValue extends Component {
    render() {
        const value = String(this.props.children);

        return (
            <div data-title={ value } className={ this.block() }>
                { value }
            </div>
        );
    }
}

export class InfoRow extends Component {
    render() {
        return (
            <div className={ this.block() }>
                { this.props.children }
            </div>
        );
    }
}

export class InfoValueRow extends Component {
    render() {
        const { label, value } = this.props;

        return (
            <InfoRow className={ this.block() }>
                <InfoLabel>{ label }</InfoLabel>
                <InfoValue>{ value === "" || value == null ? "—" : value }</InfoValue>
            </InfoRow>
        );
    }
}

export class Info extends Component {
    renderTitle(title) {
        return <InfoTitle>{ this.formatMessage(title) }</InfoTitle>;
    }

    renderLabel(label) {
        return <InfoLabel>{ this.formatMessage(label) }</InfoLabel>;
    }

    renderValue(value) {
        return <InfoValue>{ value }</InfoValue>;
    }

    renderRow(label, value) {
        return (
            <InfoRow>
                { this.renderLabel(label) }
                { this.renderValue(value) }
            </InfoRow>
        );
    }

    render() {
        return (
            <div className={ this.block() }>
                { this.props.children }
            </div>
        );
    }
}
