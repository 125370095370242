import React from "react";
import { Route } from "react-router";

import Users from "./Users";
import UserCreate from "./UserCreate";

export default function route(path) {
    return (
        <Route path={ path } component={ Users }>
            <Route path="create" component={ UserCreate } />
        </Route>
    );
};
