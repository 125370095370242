import '../scss/app.scss'

import React from "react";
import ReactDOM from "react-dom";
import Routes from "./routes";

import "./init";

setTimeout(() => {
    ReactDOM.render(Routes, document.getElementById("msb"));
}, 100);
