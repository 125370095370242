module.exports = {
    "activity-notifications-menu-title": {
        "en-US": "Notifications"
    },
    "notifications-title": {
        "en-US": "Notifications"
    },
    "notifications-tool-bar-search": {
        "en-US": "Search"
    },

    "notifications-table-column-num": {
        "en-US": "#"
    },
    "notifications-table-column-title": {
        "en-US": "Title"
    },
    "notifications-table-column-category": {
        "en-US": "Category"
    },
    "notifications-table-column-importance": {
        "en-US": "!"
    },
    "notifications-table-column-time": {
        "en-US": "Date and Time"
    },

    "activity-notifications-filter-users": {
        "en-US": "Users: All"
    },
    "activity-notifications-filter-groups": {
        "en-US": "Groups: All"
    },
    "activity-notifications-message-no-data": {
        "en-US": "Found no data matching your filters"
    }
};
