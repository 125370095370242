import { Entity } from "./Entity";
import { User } from "./User";

export class UserCredentials extends Entity {
    static get name() {
        return "UserCredentials";
    }

    init(...args) {
        super.init({ username: "", password: "", email: "" }, ...args);
    }

    validate() {
        return super.validate()
            || (!this.username && this.getError("Empty username", "username"))
            || (!this.password && this.getError("Empty password", "password"))
            || this.validateEmail()
            || void 0;
    }

    validateEmail() {
        const { email } = this;

        return (email.trim() === "" && this.getError("Empty email", "email"))
            || (email.indexOf("@") === -1 && this.getError("Invalid email", "email"));
    }
}

UserCredentials.connect({
    user: User
});
