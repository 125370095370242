import { TableModel, TableFilterModel } from "../../../../../components/Table";
import { CertificateRequests } from "../../../../../model";

class CSRsFilter extends TableFilterModel {
    init(...args) {
        super.init({ status: "all", sort: { } }, ...args);
    }

    toJSON() {
        const { status } = this;

        return Object.assign(super.toJSON(), {
            sort__: void 0,
            search__: void 0,
            status: status === "all" ? void 0 : status
        });
    }
}

export default class CSRsModel extends TableModel.of(CertificateRequests, CSRsFilter) {
};
