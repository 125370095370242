import { Store } from "../../utils/dataflow";
import { IntlModel } from "./IntlModel";

/**
 * @extends Store
 */
export class IntlStore extends Store.of(IntlModel) {
    get maxListeners() {
        return 20;
    }

    setLocale(locale) {
        this.setState({ locale });
    }
}
