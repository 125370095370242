import React from "react";

import { AuthStore } from "../../init";
import { View } from "../View";
import { NotificationsIcon } from "../../ui";

import NotificationsStore from "./NotificationsStore";
import NotificationsActions, { setFilter } from "./NotificationsActions";

export class Notifications extends View.timerOwner() {
    refresh() {
        const { auth: { user } } = this.props;

        if (user.id) {
            setFilter("recipientUserId", user.id).then(() => this.setRefreshTimeout());// .catch(() => void 0);
        }
    }

    componentWillReceiveProps({ auth: nextAuth }) {
        const { auth: currAuth } = this.props;

        if (currAuth !== nextAuth) {
            this.refresh();
        }
    }

    setRefreshTimeout() {
        this.setTimeout(() => this.refresh(), 2000);
    }

    render() {
        const { intl, data: { item: items } } = this.props;

        return (
            <NotificationsIcon { ...{ intl, items } } />
        );
    }
}

export default Notifications.of(NotificationsStore, NotificationsActions)
    .connect({ auth: AuthStore });
