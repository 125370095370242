import { IntlStore } from "../../init";
import { Permissions } from "../../model";
import SelectItems from "./SelectItems";

const SelectPermissions = SelectItems.of(Permissions);

export const SelectPermissionsActions = SelectPermissions.Actions;
export const SelectPermissionsStore = SelectPermissions.Store;
export const SelectPermissionsModel = SelectPermissions.Model;

export default SelectPermissions.connect({ data: SelectPermissionsStore, intl: IntlStore });
