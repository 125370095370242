import React from "react";

import { Component } from "..";

import {
    readNotification,
    acceptRequest,
    denyRequest,
    addToKeyStore
} from "../../components/Notifications";

export class NotificationActions extends Component {
    init(...args) {
        super.init(...args);

        this.onAcceptClick = this.onAcceptClick.bind(this);
        this.onDenyClick = this.onDenyClick.bind(this);
        this.onLaterClick = this.onLaterClick.bind(this);
        this.onAddToStoreClick = this.onAddToStoreClick.bind(this);
    }

    onAcceptClick() {
        acceptRequest(this.props.item);
    }

    onDenyClick() {
        denyRequest(this.props.item);
    }

    onLaterClick() {
        readNotification(this.props.item);
    }

    onAddToStoreClick() {
        addToKeyStore(this.props.item);
    }

    renderAccept() {
        return (
            <button key="accept" type="submit" className="button button_accept" onClick={ this.onAcceptClick }>
                { this.formatMessage("accept") }
            </button>
        );
    }

    renderReject() {
        return (
            <button key="reject" className="button button_reject" onClick={ this.onDenyClick }>
                { this.formatMessage("reject") }
            </button>
        );
    }

    renderLater() {
        return (
            <button key="later" className="button button_later" onClick={ this.onLaterClick }>
                { this.formatMessage("later") }
            </button>
        );
    }

    renderAddToStore() {
        return (
            <button key="add-to-store" type="submit" className="button button_accept" onClick={ this.onAddToStoreClick }>
                { this.formatMessage("add-to-store") }
            </button>
        );
    }

    render() {
        const { item: { category, status }, later } = this.props;
        let buttons = null;

        if (status === "") {
            buttons = category === "request" ? [this.renderAccept(), this.renderReject()]
                : category === "approved" ? [this.renderAddToStore()]
                : null;
        }

        if (buttons && later) {
            buttons.push(this.renderLater());
        }

        return buttons && <div className={ this.block() }>{ buttons }</div>;
    }
}
