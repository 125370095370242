import { TableModel, TableFilterModel } from "../../../../components/Table";
import { NetworkManagers, Group } from "../../../../model";

class NetworkManagersFilter extends TableFilterModel {
    init(...args) {
        super.init({ group: {}, sort: { name: true }, limit: null }, ...args);
    }

    toJSON() {
        const { group } = this;

        return Object.assign(super.toJSON(), {
            groupId: group.id || void 0
        });
    }
}

NetworkManagersFilter.connect({
    group: Group
});

export default class NetworkManagersModel extends TableModel.of(NetworkManagers, NetworkManagersFilter) {
}
