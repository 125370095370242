import React from "react";
import { Component } from "./Component";

export class Layer extends Component {
    render() {
        const { middle, children } = this.props;

        return (
            <div className={ this.block({ middle }) }>
                <div className={ this.element("content") }>
                    { children }
                </div>
            </div>
        );
    }
}
