import PropTypes from "prop-types";
import React, { Children } from "react";

import { Component } from "../Component";
import { FormError } from "./FormError";

function findError(children) {
    let error;

    Children.forEach(children, (child) => {
        if (!error && child && child.props) {
            error = child.props.error || findError(child.props.children);
        }
    });

    return error;
}

export class FormField extends Component {
    renderError() {
        const { intl, children } = this.props;
        const error = this.props.error || findError(children);

        return error ? <FormError { ...{ intl, error } } className={ this.element("error") } /> : null;
    }

    render() {
        const { children, name, error, label } = this.props;

        return (
            <div className={ this.block({ error: Boolean(error), label: Boolean(label), [name]: Boolean(name) }) }>
                { label ? <label className={ this.element("label") }>{ label }</label> : null }
                { children }
                { this.renderError() }
            </div>
        );
    }
}

FormField.initPropTypes({
    name: PropTypes.string
});
