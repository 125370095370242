module.exports = {
    "settings-credentials-menu-title": {
        "en-US": "Credentials"
    },
    "settings-credentials-title": {
        "en-US": "Credentials"
    },

    "settings-credentials-vault-title": {
        "en-US": "Credentials - Vault"
    },
    "settings-credentials-certificates-title": {
        "en-US": "Credentials - Certificates"
    },
    "settings-credentials-recovery-info-title": {
        "en-US": "Credentials - Recovery Info"
    },
    "settings-credentials-templates-title": {
        "en-US": "Credentials - Templates"
    },
    "settings-credentials-csrs-title": {
        "en-US": "Credentials - CSRs"
    },
    "settings-credentials-clrs-title": {
        "en-US": "Credentials - CRLs"
    },

    "credentials-vault": {
        "en-US": "Vault"
    },
    "credentials-certificates": {
        "en-US": "Certificates"
    },
    "credentials-managed-csrs": {
        "en-US": "For Review"
    },
    "credentials-recovery-info": {
        "en-US": "Recovery Info"
    },
    "credentials-templates": {
        "en-US": "Templates"
    },
    "credentials-csrs": {
        "en-US": "CSRs"
    },
    "credentials-clrs": {
        "en-US": "CRLs"
    },
    "credentials-enrollment-certificates": {
        "en-US": "ECerts"
    }
};
