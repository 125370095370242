import { FormModel } from "../../components/Form";
import { Entity } from "../../model";

class ResetModel extends Entity {
    init(...args) {
        super.init({ password: "" }, ...args);
    }

    validate() {
        const { password }= this;

        return super.validate()
            || (password.trim() === "" && this.getError("Empty password", "password"));
    }
}

export default class ResetPasswordModel extends FormModel.of(ResetModel) {
}
