import { Action } from "../../utils/dataflow";
import { FormEditActions } from "../../components/Form";

class LoginActions extends FormEditActions {
    constructor() {
        super();

        Object.assign(this, {
            login: new Action
        });
    }
}

const Actions = new LoginActions();

export default Actions;
export const login = Actions.login.trigger;
export const reset = Actions.reset.trigger;
export const setItemField = Actions.setItemField.trigger;
