import React from "react";

import { Component } from "./Component";

export class Header extends Component {
    render() {
        const { title, children } = this.props;

        return (
            <header className={ this.block() }>
                <div className={ this.element("title") }>{ title }</div>
                { children }
            </header>
        );
    }
}
