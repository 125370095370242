import { LDAPEntity } from "./LDAPEntity";
import { Permissions } from "./Permissions";

export class Group extends LDAPEntity {
    get formattedName() {
        return this.name.split(".").reverse().join(".");
    }

    get groupId() {
        return this._groupId || (this.group && this.group.id);
    }

    set groupId(groupId) {
        this.define({ _groupId: groupId });
    }

    get localName() {
        return this.name.split(".").shift();
    }

    init(...args) {
        super.init({ permissions: [] }, ...args);
    }

    validate() {
        const { id, groupId } = this;

        return super.validate()
            || (groupId && groupId === id && this.getError("Child contains parent", "groupId"))
            || void 0;
    }

    valueOf() {
        const { groupId } = this;

        return Object.assign(super.valueOf(), {
            groupId
        });
    }
}

Group.connect({
    permissions: Permissions,
    group: Group
});
