import React from "react";
import { Route } from "react-router";

import Repositories from "./Repositories";
import RepositoryEdit from "./RepositoryEdit";
import RepositoryEditMessage from "./RepositoryEditMessage";
import RepositoryDelete from "./RepositoryDelete";
import RepositoryImport from "./RepositoryImport";

export default function route(path) {
    return (
        <Route path={ path } component={ Repositories }>
            <Route path="create" component={ RepositoryEdit }>
                <Route path="error" component={ RepositoryEditMessage } />
                <Route path="success" component={ RepositoryEditMessage } />
            </Route>
            <Route path=":itemId/edit" component={ RepositoryEdit }>
                <Route path="error" component={ RepositoryEditMessage } />
                <Route path="success" component={ RepositoryEditMessage } />
            </Route>
            <Route path=":itemId/delete" component={ RepositoryDelete } />

            <Route path="import" component={ RepositoryImport } />
            <Route path=":itemId/import" component={ RepositoryImport } />
        </Route>
    );
};
