const TYPE_TIMEOUT = 1;
const TYPE_INTERVAL = 2;

function setTimer(target, type, ...args) {
    const isInterval = type === TYPE_INTERVAL;
    let id;

    if (target._timers === void 0) {
        Object.defineProperties(target, {
            _timers: { value: {} }
        });
    }

    id = (isInterval ? setInterval : setTimeout)(...args);
    target._timers[id] = (isInterval ? clearInterval : clearTimeout).bind(null, id);

    return id;
}

function clearTimer(target, id) {
    const timers = target._timers;

    if (timers && timers.hasOwnProperty(id)) {
        timers[id]();
        delete timers[id];
    }
}

export default class TimerOwner {
    clearInterval(id) {
        clearTimer(this, id);
    }

    clearTimeout(id) {
        clearTimer(this, id);
    }

    componentWillUnmount() {
        if (this._timers) {
            Object.keys(this._timers).forEach((id) => clearTimer(this, id));
        }
    }

    setInterval(...args) {
        return setTimer(this, TYPE_INTERVAL, ...args);
    }

    setTimeout(...args) {
        return setTimer(this, TYPE_TIMEOUT, ...args);
    }
}
