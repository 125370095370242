import { DN } from "../utils/ldap";

export default function unique(EntityModel) {
    let entities = {};

    function find(dn) {
        return entities.hasOwnProperty(DN.stringify(dn)) && entities[dn] || void 0;
    }

    function defineEntityId(UniqueEntity, prop, Entity) {
        const propId = `_${prop}Id`;

        Object.defineProperty(UniqueEntity.prototype, prop, {
            configurable: true,
            get: function() {
                return this[propId] && Entity.get(this[propId]) || void 0;
            }
        });

        return Entity;
    }

    return class UniqueEntity extends EntityModel {
        static get name() {
            return `Unique${EntityModel.name}`;
        }

        static all() {
            return Object.keys(entities).map((key) => entities[key]);
        }

        static clear() {
            entities = {};
        }

        static connect(entities) {
            super.connect(entities);

            for (let prop in entities) {
                if (entities.hasOwnProperty(prop)) {
                    defineEntityId(this, prop, entities[prop]);
                }
            }

            return this;
        }

        static get(id) {
            return find(id);
        }

        static remove(id) {
            const entity = find(id);

            if (entity) {
                delete entities[entity.id];
            }
        }

        init(...args) {
            super.init(...args);

            if (this.validate()) {
                entities[this.id] = this;
            }
        }

        set(...args) {
            const entity = super.set(...args);

            if (entity !== this) {
                delete entities[this.id];
                entities[entity.id] = entity;
                entity.validate();
            }

            return entity;
        }

        validate() {
            let error;

            error = super.validate();
            if (!error) {
                const entity = find(this.id);

                if (entity && entity !== this) {
                    error = this.getError("Entity already defined");
                }
            }

            if (error) {
                throw error;
            }

            return true;
        }
    };
}
