import React from "react";

import NotImplemented from "../../../../components/NotImplemented";
import { IndexRedirect, Route } from "react-router";
import { CertificatesRoute } from "./Certificates";
import Credentials from "./Credentials";
import { CSRsRoute } from "./CSRs";
import { EnrollmentCertificatesRoute } from "./EnrollmentCertificates";
import { ManagedRequestsRoute } from "./ManagedRequests";

export default function route(path) {
    return (
        <Route path={ path } component={ Credentials }>
            <IndexRedirect to="certificates/" />
            { CertificatesRoute("certificates/") }
            { CSRsRoute("csrs/") }
            { ManagedRequestsRoute("managed-csrs/") }
            { EnrollmentCertificatesRoute("enrollment-certificates/") }
            <Route path="vault/" component={ NotImplemented } />
            <Route path="templates/" component={ NotImplemented } />
            <Route path="clrs/" component={ NotImplemented } />
            <Route path="recovery-info/" component={ NotImplemented } />
        </Route>
    );
};
