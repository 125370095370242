import { stratify as d3Stratify, tree as d3Tree } from "d3-hierarchy";
import PropTypes from "prop-types";
import React from "react";

import { Component } from "./Component";

export class TreeNode extends Component {
    render() {
        const { node, selected, children } = this.props;
        const leaf = !node.children;

        return (
            <g transform={ `translate(${node.y},${node.x})` }
                className={ this.block({ selected, leaf, branch: !leaf, root: node.id === "root" }) }>
                { children }
            </g>
        );
    }
}

export class TreeLink extends Component {
    render() {
        const { node: { x, y, parent: { x: parentX, y: parentY } } } = this.props;
        const middleY = (y + parentY) / 2;

        return (
            <path d={ `M${y - 10},${x}C${middleY},${x} ${middleY},${parentX} ${parentY + 10},${parentX}` }
                className={ this.block() } />
        );
    }
}

export class Tree extends Component {
    init(...args) {
        super.init(...args);

        this.initState({ collapsed: {} });

        this.onNodeClick = this.onNodeClick.bind(this);
        this.onOpenerClick = this.onOpenerClick.bind(this);
    }

    onOpenerClick(event) {
        const id = event.currentTarget.getAttribute("data-id");
        const collapsed = this.state.collapsed;

        event.stopPropagation();
        this.setState({ collapsed: Object.assign({}, collapsed, { [id]: !collapsed[id] }) });
    }

    onNodeClick() {
    }

    isCollapsed(node) {
        const { collapsed } = this.state;
        let isCollapsed = false;

        node = node.parent;
        while (node && !isCollapsed) {
            isCollapsed = collapsed[node.id];
            node = node.parent;
        }

        return isCollapsed;
    }

    renderOpener(node) {
        const { collapsed } = this.state;
        const { id, children } = node;
        const hasChildren = children || collapsed[id];

        let opener;
        if (hasChildren) {
            const icon = collapsed[id] ? "/images/plus.svg" : "/images/minus.svg";
            opener = (
                <g data-id={ id } className={ TreeNode.element("opener") } onClick={ this.onOpenerClick }>
                    <circle r="8" className={ TreeNode.element("hover") } />
                    <image height="12" x="-6" y="-6" xlinkHref={ icon } className={ TreeNode.element("opener-icon") } />
                </g>
            );
        }

        return opener;
    }

    renderLink(node) {
        return (
            <TreeLink key={ "link-" + node.id } node={ node } />
        );
    }

    renderLinks(root, tree) {
        return tree(root).descendants().slice(1).map(this.renderLink.bind(this));
    }

    renderNodeText(node, props) {
        const { data: { id, name, icon } } = node;

        return (
            <text { ...props } data-id={ id } x={ icon ? -28 : -10 } dy="3"
                className={ TreeNode.element("text") } onClick={ this.onNodeClick }>
                { name }
            </text>
        );
    }

    renderNodeIcon(node) {
        const { data: { icon } } = node;

        return icon
            ? <image x="-23" y="-9" height="14" xlinkHref={ icon } className={ TreeNode.element("icon") } />
            : null;
    }

    renderNode(node) {
        const { selectedId } = this.props;

        return (
            <TreeNode key={ "node-" + node.id } node={ node } selected={ node.id === selectedId }
                className={ this.element("node") }>
                { this.renderOpener(node) }
                { this.renderNodeText(node) }
                { this.renderNodeIcon(node) }
            </TreeNode>
        );
    }

    renderNodes(root) {
        return root.descendants().map(this.renderNode.bind(this));
    }

    getTree() {
        const { items } = this.props;
        const tree = {};

        items.forEach((item) => {
            tree[item.id] = item;
        });

        return tree;
    }

    getItems() {
        const { props: { items }, state: { collapsed } } = this;
        const tree = this.getTree();

        return items.filter((item) => {
            let isCollapsed = false;

            item = tree[item.parentId];
            while (item && !isCollapsed) {
                isCollapsed = collapsed[item.id];
                item = tree[item.parentId];
            }

            return !isCollapsed;
        });
    }

    getRoot() {
        const stratify = d3Stratify().parentId((item) => item.parentId);

        return stratify(this.getItems())
            .sort((a, b) => a.data.name.localeCompare(b.data.name));
    }

    renderTree() {
        const { nodeWidth, nodeHeight } = this.props;

        const root = this.getRoot();
        const width = root.height * nodeWidth;
        const height = root.leaves().length * nodeHeight;
        const tree = d3Tree().size([height, width]);

        return (
            <svg { ...{ width: width + nodeWidth + 20, height: height + 20 } } className="tree">
                <g className="tree__content" transform={ "translate(" + nodeWidth + ",10)" }>
                    { this.renderLinks(root, tree) }
                    { this.renderNodes(root) }
                </g>
            </svg>
        );
    }

    render() {
        const { items } = this.props;

        return items.length ? this.renderTree() : null;
    }
}

Tree.initProps({
    items: {
        type: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string.isRequired,
            parentId: PropTypes.string,
            name: PropTypes.string.isRequired,
            icon: PropTypes.string
        }))
    },
    nodeWidth: { type: PropTypes.number, value: 120 },
    nodeHeight: { type: PropTypes.number, value: 30 }
});
