import { Groups } from "./Groups";
import { LDAPEntity } from "./LDAPEntity";

export class User extends LDAPEntity {
    static get name() {
        return "User";
    }

    get fullName() {
        return [this.name || "", this.lastname || ""].filter((part) => part).join(" ");
    }

    get fullLocation() {
        return [this.location || "", this.country || ""].filter((part) => part).join(", ");
    }

    get fullPhone() {
        return
    }

    init(...args) {
        super.init({ name: "", lastname: "", groups: [], managedGroups: [] }, ...args);
    }
}

User.connect({
    groups: Groups,
    managedGroups: Groups
});
