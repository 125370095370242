import PropTypes from "prop-types";
import React from "react";

import { Component, FormField, GenericForm } from "../../ui";
import { Model } from "../../utils/dataflow";
import { compact } from "../../utils/object";
import { forEachChildren, mapChildren } from "../../utils/react";
import { FormActions } from "./FormActions";
import { FormModel } from "./FormModel";

export class FormView extends Component {
    onFieldChange = (value, name) => {
        this.setItemField(name, value);
    };

    block(...args) {
        const { form } = this.props;

        return form ? form.block(...args) : super.block(...args);
    }

    element(...args) {
        const { form } = this.props;

        return form ? form.element(...args) : super.element(...args);
    }

    modifiers(...args) {
        const { form } = this.props;

        return form ? form.modifiers(...args) : super.modifiers(...args);
    }

    formatMessage(...args) {
        const { form } = this.props;

        return form ? form.formatMessage(...args) : super.formatMessage(...args);
    }

    setItem(item) {
        const { actions } = this.props;

        actions.setItem.trigger(item || {});
    }

    setItemField(name, value) {
        const { actions } = this.props;

        if (name) {
            actions.setItemField.trigger(name, value);
        }
    }

    saveItem() {
        const { actions, data: { item } } = this.props;

        return actions.saveItem.trigger(item);
    }

    deleteItem() {
        const { actions, data: { item } } = this.props;

        return actions.deleteItem.trigger(item);
    }

    componentWillMount() {
        this.setItem(this.props.item);
    }

    componentWillReceiveProps({ item }) {
        if (this.props.item !== item) {
            this.setItem(item);
        }
    }

    processField({ props }) {
        const nextProps = {};
        const names = [];

        forEachChildren(props.children, ({ props: { name } }) => {
            if (name) {
                names.push(name);
            }
        });

        if (names.length) {
            const name = names.join("-");

            if (!props.hasOwnProperty("className")) {
                nextProps.className = this.element("field", { [name]: true });
            }

            if (!props.hasOwnProperty("label")) {
                nextProps.label = this.formatMessage(name + "-label");
            }
        }

        return nextProps;
    }

    processInput({ props }) {
        const { data: { item } } = this.props;
        const { name } = props;
        const nextProps = {};

        if (!props.hasOwnProperty("value")) {
            nextProps.value = item[name];
        }

        if (!props.hasOwnProperty("onChange")) {
            nextProps.onChange = this.onFieldChange;
        }

        if (!props.hasOwnProperty("className")) {
            nextProps.className = this.element(name);
        }

        if (!props.hasOwnProperty("placeholder")) {
            nextProps.placeholder = this.formatMessage(name + "-placeholder");
        }

        return nextProps;
    }

    renderChildren() {
        return mapChildren(this.props.children, (child) => {
            const { type, props } = child;

            return type === FormField ? this.processField(child) : props.name ? this.processInput(child) : void 0;
        });
    }

    render() {
        const { intl, data: { busy, error, item } } = this.props;

        if (!item) {
            return null;
        }

        return (
            <GenericForm { ...GenericForm.filterProps(this.props) } { ...{ busy, intl, error } }
                title={ this.formatMessage("title", item) }
                className={ this.block() }>
                { this.renderChildren() }
            </GenericForm>
        );
    }
}

FormView.propsTypes = compact(GenericForm.propTypes, {
    actions: PropTypes.instanceOf(FormActions).isRequired,
    data: PropTypes.instanceOf(FormModel).isRequired,
    item: PropTypes.instanceOf(Model)
});
