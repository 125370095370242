import React from "react";

import { Component } from "./Component";
import { Link } from "./Link";
import { ToolBar } from "./ToolBar";

export class Tab extends Component {
    render() {
        const { to, children } = this.props;

        return (
            <Link to={ to } className={ this.block() } activeClassName>{ children }</Link>
        );
    }
}

export class TabBar extends Component {
    render() {
        return <ToolBar { ...this.props } className={ this.block() } />;
    }
}
