import { IntlStore } from "../../init";
import React from "react";
import { FormField, FormInput } from "../../ui";

import { Component } from "../../ui/Component";

import AuthForm from "./AuthForm";
import { forgotPassword, setItem, setItemField } from "./ForgotPasswordActions";
import ForgotPasswordStore from "./ForgotPasswordStore";

function onFieldChange(value, name) {
    if (name) {
        setItemField(name, value);
    }
}


class ForgotPassword extends Component {
    init(...args) {
        super.init(...args);

        this.onClose = this.onClose.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentWillMount() {
        setItem(new ForgotPasswordStore.Model.Item);
    }

    onSuccess() {
        this.props.router.replace("/auth/recovery");
    }

    onSubmit() {
        const { data: { item } } = this.props;

        forgotPassword(item).then(() => this.onSuccess());
    }

    onClose() {
        this.props.router.replace("/auth");
    }

    render() {
        const { data: { item, busy, error }, intl } = this.props;

        return (
            <AuthForm { ...{ intl, busy, error } }
                title={ this.formatMessage("title") }
                cancel={ false }
                submit={ this.formatMessage("submit") }
                className={ this.block() }
                onClose={ this.onClose }
                onSubmit={ this.onSubmit }>

                <FormField name="email" label={ this.formatMessage("email-label") }>
                    <FormInput type="text" name="email" value={ item.email }
                        placeholder={ this.formatMessage("email-placeholder") }
                        onChange={ onFieldChange } />
                </FormField>
            </AuthForm>
        );
    }
}

export default ForgotPassword.connect({
    intl: IntlStore,
    data: ForgotPasswordStore
});
