import React from "react";
import { Component } from "../Component";
import { ScrollArea } from "../ScrollArea";

export class FormPreview extends Component {
    renderInput() {
        const { name, value } = this.props;

        return (
            <div className={ this.block({ [name]: Boolean(name) }) }>
                <ScrollArea>
                    { value }
                </ScrollArea>
            </div>
        );
    }

    render() {
        return this.renderInput();
    }
}
