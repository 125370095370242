import { APIConnector } from "../../../../../init";
import { FormEditStore } from "../../../../../components/Form";

import CertificateModel from "./CertificateModel";
import CertificateEditActions from "./CertificateEditActions";

class CertificateEditStore extends FormEditStore.of(CertificateModel) {
    processError(error) {
        error = super.processError(error);

        if (error.code === "error-certificate-duplicate-key-violation") {
            error = Object.assign(error, { code: "error-certificate-name-exists", field: "name" });
        } else if (!error.field) {
            error = Object.assign(error, { code: "error-certificate-pem-invalid", field: "pem" });
        }

        return error;
    }

    save(item) {
        let error, operation;

        if (item.id) {
            operation = APIConnector.update.bind(APIConnector);
        } else {
            operation = APIConnector.create.bind(APIConnector);
            item = item.set({ id: item.name });
        }

        error = item.validate();
        return error ? Promise.reject(error) : operation(item, item.toJSON());
    }
}

export default new CertificateEditStore().connect(CertificateEditActions);
