const toString = Object.prototype.toString;
const types = {};

const BASE_TYPES = ["Array", "Boolean", "Date", "Error", "Function", "Number", "Object", "RegExp", "String"];

BASE_TYPES.forEach((name) => {
    types[toString.call(new global[name])] = name.toLowerCase();
});

/**
 * Returns type of object.
 *
 * @function noid.types.object.type
 * @param {*} o
 * @return {string}
 */
export default function type(o) {
    const t = typeof o;

    return o === null ? "null" : t === "object" ? types[toString.call(o)] : t || t;
}
