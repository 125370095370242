import PropTypes from "prop-types";
import React from "react";

import { Button } from "../Button";
import { Component } from "../Component";
import Spinner from "../Spinner";

export class FormSubmit extends Component {
    init(...args) {
        super.init(...args);

        this.onClick = this.onClick.bind(this);
    }

    onClick(e) {
        const { onClick } = this.props;

        if (onClick) {
            onClick(e);
        }
    }

    render() {
        const { busy, disabled, children } = this.props;

        return (
            <Button { ...{ type: "submit", disabled: disabled || busy } } className={ this.block() }>
                { busy ? <Spinner className={ this.element("spinner") } /> : children }
            </Button>
        );
    }
}

FormSubmit.initPropTypes({
    busy: PropTypes.bool,
    onClick: PropTypes.func
});
