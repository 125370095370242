import React from "react";

import { LDAPEntity } from "../model";
import { TableToolBar } from "../ui";
import { SelectUsers, SelectGroups, SelectPermissions, SelectGroupsStore } from "./Select";

export class HierarchyToolBar extends TableToolBar {
    init(...args) {
        super.init(...args);

        this.onPermissionsChange = this.onPermissionsChange.bind(this);
        this.onGroupsChange = this.onGroupsChange.bind(this);
        this.onUsersChange = this.onUsersChange.bind(this);
    }

    setFilters(...args) {
        const { onFiltersChange } = this.props;

        if (onFiltersChange) {
            onFiltersChange(...args);
        }
    }

    getAllGroups(groups) {
        return SelectGroupsStore.state.items.getDescendants(groups.valueOf());
    }

    isPermissionsGroup(permissions, group) {
        return group.permissions ? group.permissions.some((perm) => permissions.value(perm.id)) : false;
    }

    isGroupsUser(groups, user) {
        return user.groups ? user.groups.some((group) => groups.value(group.id)) : false;
    }

    filterGroups(permissions, group) {
        return permissions.length === 0 || this.isPermissionsGroup(permissions, group);
    }

    filterUsers(permissions, groups, user) {
        const allGroups = this.getAllGroups(groups);

        if (permissions.length !== 0) {
            groups = allGroups.filter(this.isPermissionsGroup.bind(this, permissions));
        }

        return groups.length === 0 || this.isGroupsUser(groups, user);
    }

    getGroupsForPermissions(permissions) {
        const { filter: { groups } } = this.props;

        return groups.filter(this.isPermissionsGroup.bind(this, permissions));
    }

    getUsersForGroups(groups) {
        const { filter: { users } } = this.props;

        return users.filter(this.isGroupsUser.bind(this, this.getAllGroups(groups)));
    }

    onUsersChange(users) {
        const { filter: { permissions, groups } } = this.props;

        this.setFilters(permissions && { permissions }, groups && { groups }, users && { users });
    }

    onGroupsChange(groups) {
        let { filter: { permissions, users } } = this.props;

        if (groups.length && users && users.length) {
            users = this.getUsersForGroups(groups);
        }

        this.setFilters(permissions && { permissions }, groups && { groups }, users && { users });
    }

    onPermissionsChange(permissions) {
        let { filter: { groups, users } } = this.props;

        if (permissions.length && groups && groups.length) {
            groups = this.getGroupsForPermissions(permissions);

            if (users) {
                users = this.getUsersForGroups(groups);
            }
        }

        this.setFilters(permissions && { permissions }, groups && { groups }, users && { users });
    }

    renderPermissionsSelect() {
        const { intl, filter: { permissions } } = this.props;

        return (
            <SelectPermissions { ...{ intl } } value={ permissions }
                sort={ LDAPEntity.sort }
                list={ false }
                placeholder={ this.formatMessage("permissions") }
                onChange={ this.onPermissionsChange } />
        );
    }

    renderGroupsSelect() {
        const { intl, filter: { permissions, groups } } = this.props;

        return (
            <SelectGroups { ...{ intl } } value={ groups }
                filter={ this.filterGroups.bind(this, permissions) }
                sort={ LDAPEntity.sort }
                list={ false }
                placeholder={ this.formatMessage("groups") }
                dropClassName="drop_wide"
                onChange={ this.onGroupsChange } />
        );
    }

    renderUsersSelect() {
        const { intl, filter: { permissions, groups, users } } = this.props;

        return (
            <SelectUsers { ...{ intl } } value={ users }
                filter={ this.filterUsers.bind(this, permissions, groups) }
                sort={ LDAPEntity.sort }
                list={ false }
                placeholder={ this.formatMessage("users") }
                onChange={ this.onUsersChange } />
        );
    }

    render() {
        return (
            <TableToolBar { ...this.props } className={ this.block() } />
        );
    }
}
