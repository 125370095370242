const PROPS_TO_SKIP = {
    "apply": true,
    "arguments": true,
    "bind": true,
    "call": true,
    "caller": true,
    "constructor": true,
    "length": true,
    "name": true,
    "prototype": true,
    "toString": true
};

function copyProps(target, source) {
    let props = Object.getOwnPropertyNames(source).concat(Object.getOwnPropertySymbols(source));

    props.forEach((prop) => {
        if (!PROPS_TO_SKIP.hasOwnProperty(prop)) {
            Object.defineProperty(target, prop, Object.getOwnPropertyDescriptor(source, prop));
        }
    });
}

export default function aggregate(Class, ...mixins) {
    if (typeof Class !== "function") {
        throw new Error("function expected");
    }

    mixins.forEach((mixin) => {
        if (mixin !== null && mixin !== void 0) {
            copyProps(Class.prototype, mixin.prototype || mixin);
            if (typeof mixin === "function") {
                copyProps(Class, mixin);
            }
        }
    });

    return Class;
}
