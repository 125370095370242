import { FormStore } from "../../../../components/Form";
import UserGroupsActions from "./UserGroupsActions";

import UserModel from "./UserModel";

export class UserGroupsStore extends FormStore.of(UserModel) {
    setUserGroups(item, groups) {
        const nextItem = item.set({ groups });

        return nextItem === item ? void 0 : this.saveItem(nextItem);
    }
}

export default new UserGroupsStore().connect(UserGroupsActions);
