import React from "react";

import { Component } from "../Component";
import { ToolBar } from "../ToolBar";
import { TableSearch } from "./TableSearch";

export class TableToolBar extends Component {
    renderSearch() {
        const { intl, filter, onSearch } = this.props;

        return (
            <TableSearch { ...{ intl, onSearch } } value={ filter.search }
                placeholder={ this.formatMessage("search") } />
        );
    }

    render() {
        return (
            <ToolBar { ...this.props } className={ this.block() } />
        );
    }
}
