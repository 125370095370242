import React from "react";

import { IntlStore } from "../../../../init";
import { LDAP_CONFIG_UPDATE_FREQUENCIES, LDAP_CONFIG_REPO_TYPES } from "../../../../model";
import { Collection } from "../../../../utils/dataflow";
import { FormField, FormInput, FormPassword, FormCheckBox, FormFile, Spinner } from "../../../../ui";
import { Select, ModalOwner } from "../../../../ui";

import RepositoryForm from "./RepositoryForm";
import RepositoryEditStore from "./RepositoryEditStore";
import RepositoryEditForm from "./RepositoryEditForm";
import { setItem, setItemField, setItemError, saveItem, testConnection } from "./RepositoryEditActions";

const FREQUENCIES = new Collection(LDAP_CONFIG_UPDATE_FREQUENCIES);
const REPO_TYPES = new Collection(LDAP_CONFIG_REPO_TYPES);

function onFieldChange(value, name) {
    if (name) {
        setItemField(name, value);
    }
}

function onPortChange(value, name) {
    if (name) {
        setItemField(name, value === "" ? null : +value);
    }
}

function onClearCertificateClick() {
    setItemField("SSLCertificate", "");
}

function onCertificateError(error) {
    setItemError("SSLCertificate", error);
}

class RepositoryEdit extends RepositoryForm.aggregate(ModalOwner) {
    get modalId() {
        return "repository-edit";
    }

    init(...args) {
        super.init(...args);

        this.testConnection = this.testConnection.bind(this);
    }

    shouldModalRender() {
        const { data: { item } } = this.props;

        return super.shouldModalRender() && item;
    }

    setItem(item) {
        setItem(item || new RepositoryEditStore.Model.Item());
    }

    submit() {
        const { data: { item } } = this.props;

        saveItem(item).then(() => this.onSubmit());
    }

    showMessage(success) {
        const { router, location: { pathname } } = this.props;

        router.replace(pathname + (success ? "/success" : "/error"));
    }

    testConnection() {
        const { data: { item } } = this.props;

        testConnection(item).then(() => this.showMessage(true)).catch((error) => {
            if (!error.field) {
                this.showMessage(false);
            }
        });
    }

    renderTestConnection() {
        const { intl, busy, action } = this.props;
        const inProgress = busy && action === "test-connection";
        const message = inProgress ? <Spinner /> : intl.formatMessage("settings-repository-edit-test-connection");

        return (
            <a type="button" className="link repository-edit__test-connection"
                onClick={ inProgress ? null : this.testConnection }>
                { message }
            </a>
        );
    }

    renderSecure() {
        const { intl, data: { item: { useSSL, SSLCertificate } } } = this.props;

        return (
            <FormField name="useSSL SSLCertificate">
                <FormCheckBox type="text" name="useSSL" value={ useSSL }
                    placeholder={ intl.formatMessage("settings-repository-edit-secure-placeholder") }
                    onChange={ onFieldChange } />
                <FormFile type="file" name="SSLCertificate" value={ SSLCertificate }
                    accept=".crt, .per, .der" maxSize={ 102400 }
                    placeholder={ intl.formatMessage("settings-repository-edit-certificate-placeholder") }
                    onChange={ onFieldChange } onError={ onCertificateError } />
                <a className={ "link link_clear-certificate" + (SSLCertificate ? "" : " link_disabled") }
                    onClick={ onClearCertificateClick }>
                    { intl.formatMessage("settings-repository-edit-remove-certificate") }
                </a>
                { this.renderTestConnection() }
            </FormField>
        );
    }

    render() {
        const { intl, data: { busy, error, action, item } } = this.props;

        if (!item) {
            return null;
        }

        return (
            <RepositoryEditForm { ...{ intl, busy, action, error: !error || error.field ? error : null } }
                title={ intl.formatMessage(`settings-repository-${item.id ? "edit" : "create"}-title`, item) }
                className={ this.cx("form_repository repository-edit") }
                onSubmit={ this.submit } onCancel={ this.close }>

                <FormField name="name"
                    label={ intl.formatMessage("settings-repository-edit-name-label") }>
                    <FormInput type="text" name="name" value={ item.name }
                        placeholder={ intl.formatMessage("settings-repository-edit-name-placeholder") }
                        onChange={ onFieldChange } />
                </FormField>

                <FormField name="type"
                    label={ intl.formatMessage("settings-repository-edit-type-label") }>
                    <Select value={ item.type } items={ REPO_TYPES } nullValue={ null }
                        search={ false }
                        placeholder={ intl.formatMessage("settings-repository-edit-type-placeholder") }
                        dropClassName="drop_middle"
                        onChange={ (value) => onFieldChange(value, "type") } />
                </FormField>

                <FormField name="host port"
                    label={ intl.formatMessage("settings-repository-edit-host-port-label") }>
                    <FormInput type="text" name="host" value={ item.host }
                        placeholder={ intl.formatMessage("settings-repository-edit-host-placeholder") }
                        onChange={ onFieldChange } />
                    <FormInput type="number" name="port" value={ item.port || "" }
                        placeholder={ intl.formatMessage("settings-repository-edit-port-placeholder") }
                        onChange={ onPortChange } />
                </FormField>

                { this.renderSecure() }

                <FormField name="userName password"
                    label={ intl.formatMessage("settings-repository-edit-credentials-label") }>
                    <FormInput type="text" name="userName" value={ item.userName }
                        placeholder={ intl.formatMessage("settings-repository-edit-username-placeholder") }
                        onChange={ onFieldChange } />
                    <FormPassword name="password" value={ item.password }
                        placeholder={ intl.formatMessage("settings-repository-edit-password-placeholder") }
                        onChange={ onFieldChange } />
                </FormField>

                <FormField name="base"
                    label={ intl.formatMessage("settings-repository-edit-base-label") }>
                    <FormInput type="text" name="base" value={ item.base }
                        placeholder={ intl.formatMessage("settings-repository-edit-base-placeholder") }
                        onChange={ onFieldChange } />
                </FormField>

                <FormField name="groupfilter"
                    label={ intl.formatMessage("settings-repository-edit-groupfilter-label") }>
                    <FormInput type="text" name="groupfilter" value={ item.groupfilter }
                        placeholder={ intl.formatMessage("settings-repository-edit-groupfilter-placeholder") }
                        onChange={ onFieldChange } />
                </FormField>

                <FormField name="userfilter"
                    label={ intl.formatMessage("settings-repository-edit-userfilter-label") }>
                    <FormInput type="text" name="userfilter" value={ item.userfilter }
                        placeholder={ intl.formatMessage("settings-repository-edit-userfilter-placeholder") }
                        onChange={ onFieldChange } />
                </FormField>

                <FormField name="updateFrequency"
                    label={ intl.formatMessage("settings-repository-edit-update-frequency-label") }>
                    <Select value={ item.updateFrequency } items={ FREQUENCIES } nullValue={ null }
                        search={ false }
                        placeholder={ intl.formatMessage("settings-repository-edit-update-frequency-placeholder") }
                        onChange={ (value) => onFieldChange(value, "updateFrequency") } />
                </FormField>
            </RepositoryEditForm>
        );
    }
}

export default RepositoryEdit.connect({ intl: IntlStore, data: RepositoryEditStore });
