import React from "react";
import moment from "moment";

import { forEach, typeOf } from "../../../../../utils/object";
import { Component } from "../../../../../ui/Component";
import { IntlStore } from "../../../../../init";
import { GenericForm, FormField, FormInput, FormPreview, FormFile, ScrollArea, CertificateField } from "../../../../../ui";

import CertificateForm from "./CertificateForm";
import CertificateEditStore from "./CertificateEditStore";
import { setItem, setItemField, setItemError, saveItem } from "./CertificateEditActions";

function onFieldChange(value, name) {
    if (name) {
        setItemField(name, value);
    }
}

function onPemChange(value) {
    setItem({ pem: value, attributes: null });
}

function onClearPEMClick() {
    onPemChange("");
}

function onPEMError(error) {
    setItemError("pem", error);
}

class AttributesTable extends Component {
    renderName(name) {
        return name.replace(/([a-z])([A-Z])/g, "$1 $2");
    }

    renderValue(value, key) {
        return typeOf(value) === "array" ? typeOf(value[0]) === "object" ? JSON.stringify(value) : value.join(", ")
            : key === "NotAfter" || key === "NotBefore" ? moment(value).format("YYYY.MM.DD HH:mm:ss")
                   : String(value);
    }

    renderGroup(rows, value, key, ...parent) {
        rows.push(
            <tr key={ [key, ...parent].join(".") } className="attribute_group">
                <td colSpan="2">{ this.renderName(key) }</td>
            </tr>
        );

        this.renderRows(rows, value, key, ...parent);
    }

    renderRow(rows, value, key, ...parent) {
        value = this.renderValue(value, key);

        rows.push(
            <tr key={ [key, ...parent].join(".") } className="attribute">
                <td>{ this.renderName(key) }</td>
                <td data-title={ value }>{ value }</td>
            </tr>
        );
    }

    renderRows(rows, items, ...parent) {
        forEach(items, (value, key) => {
            typeOf(value) === "object"
                ? this.renderGroup(rows, value, key, ...parent)
                : this.renderRow(rows, value, key, ...parent);
        });
    }

    render() {
        const { items } = this.props;
        const rows = [];

        this.renderRows(rows, items);

        return (
            <table className="table table_attributes">
                <tbody>{ rows }</tbody>
            </table>
        );
    }
}

class CertificateEdit extends CertificateForm {
    setItem(item) {
        setItem(item || new CertificateEditStore.Model.Item());
    }

    submit() {
        const { data: { item } } = this.props;

        saveItem(item).then(() => this.onSubmit()).catch(() => void 0);
    }

    renderFile() {
        const { intl, data: { item } } = this.props;

        let file;
        if (!item.id) {
            file = (
                <CertificateField name="pem"
                    label={ this.formatMessage("pem-label") }
                    clearLabel={ this.formatMessage("pem-clear") }
                    placeholder={ this.formatMessage("pem-placeholder") }
                    onChange={ onPemChange } onError={ onPEMError } />
            );
        }

        return file;
    }

    renderAttributes() {
        const { data: { item: { attributes } } } = this.props;
        let field;

        if (attributes && Object.keys(attributes).length) {
            field = (
                <FormField name="attributes"
                    label={ this.formatMessage("attributes-label") }>
                    <ScrollArea>
                        <AttributesTable items={ attributes } />
                    </ScrollArea>
                </FormField>
            );
        }

        return field;
    }

    render() {
        const { intl, data: { busy, error, item } } = this.props;

        if (!item) {
            return null;
        }

        const isView = !!item.id;
        const title = this.formatMessage((item.id ? "" : "create-") + "title", item);

        return (
            <GenericForm { ...{ intl, busy, error, title } }
                submit={ this.formatMessage("" + (isView ? "ok" : "submit")) }
                className="form_certificate"
                onSubmit={ this.submit } onCancel={ this.close }>

                <FormField name="alias"
                    label={ this.formatMessage("name-label") }>
                    <FormInput type="text" name="alias" value={ item.alias } readOnly={ isView }
                        placeholder={ this.formatMessage("name-placeholder") }
                        onChange={ onFieldChange } />
                </FormField>

                { this.renderFile() }
                { this.renderAttributes() }

                <FormField name="pem-view"
                    label={ this.formatMessage("pem-view-label") }>
                    <FormPreview name="pem-view" value={ item.pem } readOnly={ true }
                        placeholder={ this.formatMessage("pem-view-placeholder") } />
                </FormField>
            </GenericForm >
        );
    }
}

export default CertificateEdit.connect({ intl: IntlStore, data: CertificateEditStore });
