import pluralize from "pluralize";

import { Url } from "..";
import { Collection, ModelConnector } from "../dataflow";
import { valueOf } from "../object";
import "whatwg-fetch";

export default class RestConnector extends ModelConnector {
    static get baseUrl() {
        return new Url();
    }

    static toString() {
        return super.toString(this.baseUrl);
    }

    static getName(entity) {
        const Type = Collection.isA(entity) ? entity.Type : entity.constructor;

        if (!Type.name) {
            throw this.getError("Named entity expected");
        }

        return pluralize(Type.name);
    }

    static buildUrl(path, params) {
        return this.baseUrl
            .relative(path)
            .params(params)
            .toString();
    }

    static getUrl(entity, id = null, params = null) {
        const name = this.getName(entity);

        return this.buildUrl(name + "/" + (id !== null ? id : ""), params);
    }

    static getId(entity) {
        let id;

        if (!Collection.isA(entity)) {
            id = entity.id;

            if (!id) {
                throw this.getError("Entity id should be defined");
            }
        }

        return id;
    }

    static parse(text) {
        let json = null;

        try {
            if (text !== "") {
                json = JSON.parse(text);
            }
        } catch (ignore) {
            // empty
        }

        return json;
    }

    static stringify(data) {
        return JSON.stringify(data);
    }

    static response(response) {
        return response.text().then((text) => this.parse(text));
    }

    static fetch(url, params) {
        return fetch(url, params);
    }

    static create(entity, data) {
        return this.fetch(this.getUrl(entity), {
                method: "post",
                body: this.stringify(data),
                headers: {
                    "content-type": "application/json"
                }
            })
            .then((response) => this.response(response));
    }

    static read(entity, params = null) {
        return this.fetch(this.getUrl(entity, this.getId(entity), valueOf(params)))
            .then((response) => this.response(response));
    }

    static update(entity, data) {
        let id;

        if (!Collection.isA(entity)) {
            id = entity.id;

            if (!id) {
                throw this.getError("id should be defined");
            }
        }

        return this.fetch(this.getUrl(entity, this.getId(entity)), {
                method: "put",
                body: this.stringify(data),
                headers: {
                    "content-type": "application/json"
                }
            })
            .then((response) => this.response(response));
    }

    static destroy(entity, params = null) {
        return this.fetch(this.getUrl(entity, this.getId(entity), params), { method: "delete" })
            .then((response) => this.response(response));
    }

    static connect(url) {
        return class RestConnector extends this {
            static get baseUrl() {
                return Url.parse(url);
            }
        };
    }
}
