import React from "react";

import { IntlStore } from "../../../../init";
import { Component, TabBar, Tab } from "../../../../ui";

class PreferencesToolBar extends Component {
    render() {
        return (
            <TabBar className={ this.block() }>
                <Tab to="/msb/settings/preferences/general/">{ this.formatMessage("general") }</Tab>
            </TabBar>
        );
    }
}

class Preferences extends Component {
    render() {
        const { intl, children } = this.props;

        return (
            <div className={ this.block() }>
                <PreferencesToolBar { ...{ intl } } />
                { children }
            </div>
        );
    }
}

export default Preferences.connect({ intl: IntlStore });
