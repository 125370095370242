import { APIConnector } from "../../../../../init";
import { FormEditStore } from "../../../../../components/Form";

import PasswordModel from "./PasswordModel";
import PasswordActions from "./PasswordActions";

class PasswordStore extends FormEditStore.of(PasswordModel) {
    processError(error) {
        const { item } = this.state;

        error = super.processError(error);

        if (!error.field) {
            error = super.processError(item.getError("incorrect", "password"));
        }

        return error;
    }

    doPassword(item) {
        const url = APIConnector.buildUrl("UserCredentials/change");
        const { password, nextPassword } = item;

        return APIConnector.fetch(url, {
            method: "POST",
            body: APIConnector.stringify({ password, nextPassword })
        }).then((data) => APIConnector.response(data));
    }

    resetPassword(item) {
        const error = item.validate();

        return this.invoke("resetPassword", error ? Promise.reject(error) : this.doPassword(item));
    }
}

export default new PasswordStore().connect(PasswordActions);
