module.exports = {
    "settings-preferences-menu-title": {
        "en-US": "Preferences"
    },
    "settings-preferences-title": {
        "en-US": "Preferences"
    },

    "preferences-tool-bar-general": {
        "en-US": "General"
    },


    "settings-preferences-general-title": {
        "en-US": "General"
    },
    "settings-preferences-general-theme-label": {
        "en-US": "UI Theme"
    },
    "settings-preferences-general-success-title": {
        "en-US": "Preferences"
    },
    "settings-preferences-general-success-message": {
        "en-US": "Your preferences were successfully updated"
    },
};
