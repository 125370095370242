import { Model } from "../../utils/dataflow";

import ScrollBarModel from "./ScrollBarModel";
import { ORIENTATION_HORIZONTAL, ORIENTATION_VERTICAL } from "./utils";

export default class ScrollAreaModel extends Model {
    valueOf() {
        return Object.assign(super.valueOf(), {
            [ORIENTATION_HORIZONTAL]: this[ORIENTATION_HORIZONTAL],
            [ORIENTATION_VERTICAL]: this[ORIENTATION_VERTICAL]
        });
    }
}

ScrollAreaModel.connect({
    [ORIENTATION_HORIZONTAL]: ScrollBarModel,
    [ORIENTATION_VERTICAL]: ScrollBarModel
});
