import { setTitle } from "../../../../components/Header";
import { View } from "../../../../components/View";
import React from "react";

import { Main, Tree } from "../../../../ui";

import HaceraNetworksActions from "./HaceraNetworksActions";
import HaceraNetworksStore from "./HaceraNetworksStore";

class HaceraNetworksTree extends Tree {
    onNodeClick({ currentTarget }) {
        const { onSelect } = this.props;
        const networkId = currentTarget.getAttribute("data-network-id");

        if (onSelect && networkId) {
            const nodeId = currentTarget.getAttribute("data-node-id");

            onSelect(networkId, nodeId);
        }
    }

    renderNodeText(node) {
        const { data: { networkId, nodeId } } = node;

        return super.renderNodeText(node, { "data-network-id": networkId || "", "data-node-id": nodeId || "" });
    }
}

export class HaceraNetworks extends View {
    init(...args) {
        super.init(...args);

        this.onItemSelect = this.onItemSelect.bind(this);
    }

    componentWillMount() {
        setTitle(this.formatMessage("title"));

        super.componentWillMount();
    }

    onItemSelect(networkId, nodeId) {
        const { router } = this.props;

        router.replace(`/msb/manage/hacera-networks/${networkId}/${(nodeId ? nodeId : "")}`);
    }

    getData() {
        const { data: { item: { networks } } } = this.props;
        const items = [];

        networks.forEach(({ id: networkId, networkName, nodes }) => {
            items.push({
                id: networkId,
                parentId: "root",
                name: networkName,
                networkId
            });

            nodes.forEach(({ id: nodeId, nodeName, nodeType }) => {
                items.push({
                    id: nodeId,
                    parentId: networkId,
                    name: nodeName + " (" + nodeType + ")",
                    networkId,
                    nodeId
                });
            });
        });

        if (items.length) {
            items.push({
                id: "root",
                name: this.formatMessage("root"),
                icon: "/images/networks.svg"
            });
        }

        return items.sort((first, second) => first.name.localeCompare(second.name));
    }

    renderSideBar() {
        return this.props.children;
    }

    render() {
        const { data: { item }, intl, params: { nodeId, networkId } } = this.props;

        if (!item) {
            return null;
        }

        return (
            <Main sideBar={ this.renderSideBar() } status={ this.renderStatus() } className={ this.block() }>
                <HaceraNetworksTree { ...{ intl, items: this.getData(), selectedId: nodeId || networkId } }
                    nodeWidth={ 150 } className={ this.element("tree") } onSelect={ this.onItemSelect } />
            </Main>
        );
    }
}

export default HaceraNetworks.of(HaceraNetworksStore, HaceraNetworksActions);
