import React from "react";
import PropTypes from "prop-types";
import { Component } from "../Component";
import ScrollAreaModel from "./ScrollAreaModel";

import ScrollBar from "./ScrollBar";
import {
    getScrollState,
    ORIENTATION_HORIZONTAL,
    ORIENTATION_VERTICAL,
    OVERFLOW_HIDDEN,
    OVERFLOW_SCROLL,
    OVERFLOWS
} from "./utils";

let scrollSize;

export default class ScrollArea extends Component.timerOwner() {
    init(...args) {
        super.init(...args);

        this.initState({
            scroll: new ScrollAreaModel,
            scrollSize: scrollSize
        });

        this.setScrollState = this.setScrollState.bind(this);
        this.onBarScroll = this.onBarScroll.bind(this);
        this.onWindowResize = this.onWindowResize.bind(this);
    }

    setScrollState() {
        const { container } = this.refs;

        if (container) {
            const { overflowX, overflowY } = this.props;
            const { scroll } = this.state;
            const HORIZONTAL = ORIENTATION_HORIZONTAL;
            const VERTICAL = ORIENTATION_VERTICAL;

            if (scrollSize === void 0) {
                scrollSize = container.offsetWidth - container.clientWidth;
            }

            this.setState({
                scrollSize,
                scroll: scroll.set({
                    [HORIZONTAL]: overflowX === OVERFLOW_HIDDEN ? null : getScrollState(container, HORIZONTAL),
                    [VERTICAL]: overflowY === OVERFLOW_HIDDEN ? null : getScrollState(container, VERTICAL)
                })
            });
        }
    }

    onWindowResize() {
        this.setTimeout(this.setScrollState, 300);
    }

    componentDidUpdate() {
        this.setScrollState();
    }

    componentDidMount() {
        this.setScrollState();
        this.addDOMListener(window, "resize", this.onWindowResize);
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    setScroll(orientation, scrollPos) {
        this.refs.container[orientation === ORIENTATION_HORIZONTAL ? "scrollLeft" : "scrollTop"] = scrollPos;
    }

    onBarScroll(orientation, scrollPos) {
        this.setScroll(orientation, scrollPos);
    }

    renderScrollBar(orientation, overflow) {
        const scroll = this.state.scroll[orientation];
        const shouldRender = overflow !== OVERFLOW_HIDDEN && (overflow === OVERFLOW_SCROLL || !!scroll);
        let bar;

        if (shouldRender) {
            bar = <ScrollBar { ...{ orientation, scroll } } onScroll={ this.onBarScroll } />;
        }

        return bar;
    }

    render() {
        const { overflowX, overflowY, children } = this.props;
        const { scroll, scrollSize } = this.state;

        const className = this.cx(
            "scroll-area", "scroll-area_overflow_x_" + overflowX, "scroll-area_overflow_y_" + overflowY,
            {
                ["scroll-area_" + ORIENTATION_HORIZONTAL]: scroll[ORIENTATION_HORIZONTAL],
                ["scroll-area_" + ORIENTATION_VERTICAL]: scroll[ORIENTATION_VERTICAL]
            }
        );

        const style = scrollSize === void 0 ? { visibility: "hidden" }
            : { marginRight: -scrollSize, marginBottom: -scrollSize };

        return (
            <div className={ className } onMouseEnter={ this.setScrollState }>
                <div ref="container" className="scroll-area__inner" style={ style }
                    onScroll={ this.setScrollState }>
                    <div className="scroll-area__content">
                        { children }
                    </div>
                </div>
                { this.renderScrollBar(ORIENTATION_VERTICAL, overflowY) }
                { this.renderScrollBar(ORIENTATION_HORIZONTAL, overflowX) }
            </div>
        );
    }
}

ScrollArea.initProps({
    overflowX: { type: PropTypes.oneOf(OVERFLOWS).isRequired, value: "auto" },
    overflowY: { type: PropTypes.oneOf(OVERFLOWS).isRequired, value: "auto" }
});
