import React from "react";

import { setTitle } from "../../../../components/Header";
import { View } from "../../../../components/View";
import { Main, Tree } from "../../../../ui";
import DelegationsActions from "./DelegationsActions";
import DelegationsStore from "./DelegationsStore";

class DelegationsTree extends Tree {
    onNodeClick(event) {
        const { onSelect } = this.props;

        if (onSelect) {
            onSelect(event.currentTarget.getAttribute("data-id"));
        }
    }

    onOpenerClick(event) {
        const id = event.currentTarget.getAttribute("data-id");
        const collapsed = this.state.collapsed;

        event.stopPropagation();
        this.setState({ collapsed: Object.assign({}, collapsed, { [id]: !collapsed[id] }) });
    }


    renderNodeText(node) {
        const { data: { path } } = node;

        return super.renderNodeText(node, { "data-path": path });
    }
}

export class Delegations extends View {
    init(...args) {
        super.init(...args);

        this.onItemSelect = this.onItemSelect.bind(this);
    }

    componentWillMount() {
        setTitle(this.formatMessage("title"));
        super.componentWillMount();
    }

    onItemSelect(id) {
        const { router } = this.props;

        router.replace(`/msb/manage/delegations/${id}/`);
    }

    getData() {
        const { data: { item: groups } } = this.props;
        let items;

        items = groups.map(({ id, groupId, name }) => {
            name = name.split(".");

            return {
                id,
                parentId: groupId || "root",
                name: name[0],
                path: name.reverse().join(".")
            };
        });

        if (items.length) {
            items.push({
                id: "root", name: this.formatMessage("root"), icon: "/images/networks.svg"
            });
        }

        return items;
    }

    renderSideBar() {
        return this.props.children;
    }

    render() {
        const { intl, params: { networkId } } = this.props;

        return (
            <Main sideBar={ this.renderSideBar() } status={ this.renderStatus() } className={ this.block() }>
                <DelegationsTree { ...{ intl, items: this.getData(), selectedId: networkId } }
                    className={ this.element("tree") } onSelect={ this.onItemSelect } />
            </Main>
        );
    }
}

export default Delegations.of(DelegationsStore, DelegationsActions);
