import { clone, isDefined, nvl, toJSON, valueOf } from "../object";
import Model from "./Model";

function validate(target) {
    let keys, removed;

    if (target._keys === null) {
        target._keys = keys = {};
        removed = 0;

        target._values = target._values.filter((item, i) => {
            if (item === null) {
                removed++;
            } else {
                keys[target.keyOf(item)] = i - removed;
            }

            return item !== null;
        });
    }

    return target;
}

function reset(target, values = [], keys = null) {
    return target.define({
        _values: values,
        _keys: keys || (values.length ? null : {})
    });
}

function create(target, items) {
    return reset(
        clone(validate(target)),
        items || target._values.concat(),
        items ? null : Object.assign({}, target._keys)
    );
}

function insert(target, data) {
    let key, inserted;

    key = target.keyOf(data);
    inserted = target.indexOf(key) === -1;

    if (inserted) {
        target._keys[key] = target._values.length;
        target._values.push(target.Type.from(data));
    }

    return inserted;
}

function update(target, data) {
    const index = target.indexOf(target.keyOf(data));
    const Type = target.Type;
    let updated = false;

    if (index !== -1) {
        const curr = target._values[index];
        const next = Type.isA(data) ? data : curr.set(data);

        updated = next !== curr;

        if (updated) {
            target._values[index] = next;
        }
    }

    return updated;
}

function remove(target, key) {
    const index = target.indexOf(key);
    const removed = index !== -1;

    if (removed) {
        target._values[index] = null;
        target._keys = null;
    }

    return removed;
}

export default class Collection extends Model {
    init(data) {
        reset(this);

        if (isDefined(data)) {
            valueOf(data).forEach(insert.bind(null, this));
        }
    }

    get keys() {
        return Object.keys(validate(this)._keys);
    }

    get values() {
        return validate(this)._values.slice(0);
    }

    get length() {
        return validate(this)._values.length;
    }

    get first() {
        return validate(this)._values[0];
    }

    get last() {
        const values = validate(this)._values;

        return values[values.length - 1];
    }

    get Type() {
        return this.constructor.Type;
    }

    keyOf(data) {
        return isDefined(data) ? data.id : void 0;
    }

    indexOf(key) {
        const keys = validate(this)._keys;

        return keys.hasOwnProperty(key) ? keys[key] : -1;
    }

    contains(key) {
        return this.indexOf(key) !== -1;
    }

    value(key) {
        return this._values[this.indexOf(key)];
    }

    valueAtIndex(index) {
        return validate(this)._values[+index];
    }

    isEqual(from) {
        let retValue = this === from || ((from instanceof Collection) && this.Type === from.Type);

        if (retValue && this !== from) {
            const values = this._values;
            const fromValues = from._values;

            retValue = values.length === fromValues.length && values.every((value, i) => value.isEqual(fromValues[i]));
        }

        return retValue;
    }

    set(...items) {
        const Type = this.Type;
        let next;

        items = items.filter(isDefined);
        if (items.length) {
            next = create(this, []);

            items = [].concat(...items).map((item) => {
                const curr = this.value(this.keyOf(item));

                return curr && !Type.isA(item) ? curr.set(item) : item;
            });

            items.forEach((item) => insert(next, item));
        }

        return !next || this.isEqual(next) ? this : next;
    }

    insertItems(...items) {
        const next = create(this);

        return items.reduce((changed, item) => insert(next, item) || changed, false) ? next : this;
    }

    updateItems(...items) {
        const next = create(this);

        return items.reduce((changed, item) => update(next, item) || changed, false) ? next : this;
    }

    upsertItems(...items) {
        const next = create(this);

        return items.reduce((changed, item) => insert(next, item) || update(next, item) || changed, false)
            ? next
            : this;
    }

    removeItems(...keys) {
        const next = create(this);

        return keys.reduce((changed, key) => remove(next, key) || changed, false) ? next : this;
    }

    updateItem(id, ...data) {
        const item = this.value(id);

        return item ? this.updateItems(item.set(...data)) : this;
    }

    updateAllItems(...data) {
        return this.updateItems(...validate(this)._values.map((item) => item.set(...data)));
    }

    forEach(handle) {
        validate(this)._values.forEach((item, index) => {
            handle(item, index, this);
        });
    }

    map(handle) {
        return validate(this)._values.map((item, index) => handle(item, index, this));
    }

    update(handle) {
        let changed = false;

        const items = validate(this)._values.map((item, index) => {
            const nextItem = item.set(handle(item, index, this));

            changed = nextItem !== item;

            return nextItem;
        });

        return changed ? new this.constructor(items) : this;
    }

    filter(handle) {
        let changed = false;

        const items = validate(this)._values.filter((item, index) => {
            const result = handle(item, index, this);

            changed = changed || !result;

            return result;
        });

        return changed ? new this.constructor(items) : this;
    }

    sort(sorter) {
        const items = this.values.sort(sorter);

        return new this.constructor(items);
    }

    some(handle) {
        return validate(this)._values.some((item, index) => handle(item, index, this));
    }

    every(handle) {
        return validate(this)._values.every((item, index) => handle(item, index, this));
    }

    find(handle) {
        return validate(this)._values.find((item, index) => handle(item, index, this));
    }

    groupBy(field) {
        const isFunction = typeof field === "function";
        const groups = {};

        validate(this)._values.forEach((value) => {
            const group = isFunction ? field(value) : value[field];

            if (!groups.hasOwnProperty(group)) {
                groups[group] = [];
            }

            groups[group].push(value);
        });

        return groups;
    }

    valueOf() {
        return this.map(valueOf);
    }

    toJSON() {
        return this.map(toJSON);
    }

    toString() {
        return super.toString(this.Type.name);
    }

    static get Type() {
        return Model;
    }

    static toString() {
        return super.toString(this.Type.name);
    }

    static of(Type, key) {
        let keyOf;

        if (!Model.is(Type)) {
            throw new Error(`${Collection}: Model expected`);
        }

        if (typeof key === "function") {
            keyOf = (data) => isDefined(data) && key(data);
        } else {
            key = nvl(key, "id");
            keyOf = (data) => isDefined(data) && data[key];
        }

        return class Collection extends this {
            keyOf(data) {
                return keyOf(data);
            }

            static get Type() {
                return Type;
            }
        };
    }
}
