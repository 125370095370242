import { Action } from "../../utils/dataflow";
import { TableActions } from "../Table";

export class NotificationsActions extends TableActions {
    constructor() {
        super();

        Object.assign(this, {
            readNotification: new Action,
            acceptRequest: new Action,
            denyRequest: new Action,
            addToKeyStore: new Action
        });
    }
}

const Actions = new NotificationsActions();

export default Actions;
export const refresh = Actions.refresh.trigger;
export const setFilter = Actions.setFilter.trigger;

export const readNotification = Actions.readNotification.trigger;
export const acceptRequest = Actions.acceptRequest.trigger;
export const denyRequest = Actions.denyRequest.trigger;
export const addToKeyStore = Actions.addToKeyStore.trigger;
