import React from "react";

import { IntlStore } from "../../../../../init";
import { Component } from "../../../../../ui/Component";
import { Confirmation } from "../../../../../ui/Form";

class GeneralSuccess extends Component {
    init(...args) {
        super.init(...args);

        this.close = this.close.bind(this);
    }

    close() {
        this.props.router.replace("/msb/settings/preferences/general");
    }

    render() {
        const { intl } = this.props;

        return (
            <Confirmation intl={ intl }
                title={ intl.formatMessage("settings-preferences-general-success-title") }
                message={ intl.formatMessage("settings-preferences-general-success-message") }
                submit={ intl.formatMessage("alert-ok") } cancel={ false }
                onSubmit={ this.close } onCancel={ this.close } />
        );
    }
}

export default GeneralSuccess.connect({ intl: IntlStore });
