import { AuthStore } from "../init";
import React from "react";
import { Component, TooltipManager } from "../ui";

const STORAGE_KEY = "msb-theme";

function getTheme(props) {
    const { location: { query }, auth: { user } } = props;

    let theme;
    if (query.theme) {
        theme = query.theme;
    } else if (user && user.preferences && user.preferences.theme) {
        theme = user.preferences.theme;
    } else {
        theme = sessionStorage.getItem(STORAGE_KEY);
    }

    return theme === "dark" ? "dark" : "light";
}

function applyTheme(nextTheme, currTheme) {
    const { body } = document;

    sessionStorage.setItem(STORAGE_KEY, nextTheme);
    body.classList.add("theme", "theme_" + nextTheme);
    if (currTheme) {
        body.classList.remove("theme_" + currTheme);
    }
}

class App extends Component {
    componentWillMount() {
        applyTheme(getTheme(this.props));
    }

    componentWillReceiveProps(nextProps) {
        const currTheme = getTheme(this.props);
        const nextTheme = getTheme(nextProps);

        if (currTheme !== nextTheme) {
            applyTheme(nextTheme, currTheme);
        }
    }

    render() {
        const { children } = this.props;

        return (
            <TooltipManager className={ this.block() }>
                { children }
            </TooltipManager>
        );
    }
}

export default App.connect({ auth: AuthStore });
