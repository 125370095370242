import React from "react";
import { Route } from "react-router";

import General from "./General";
import GeneralSuccess from "./GeneralSuccess";

export default function route(path) {
    return (
        <Route path={ path } component={ General }>
            <Route path="success" component={ GeneralSuccess } />
        </Route>
    );
};
