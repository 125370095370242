import { TableFilterModel, TableModel } from "../../../../../components/Table";
import { NetworkCertificates } from "../../../../../model";

class EnrollmentCertificatesFilter extends TableFilterModel {
    init(...args) {
        super.init({ sort: {} }, ...args);
    }
}

export default class EnrollmentCertificatesModel extends TableModel.of(
    NetworkCertificates, EnrollmentCertificatesFilter) {
}
