import { FormEditStore } from "../../components/Form";
import { AuthActions } from "../../init";
import LoginActions from "./LoginActions";

import LoginModel from "./LoginModel";

class LoginStore extends FormEditStore.of(LoginModel) {
    processError(error) {
        error = super.processError(error);

        if (error.code === "error-incorrect-password" || error.code === "error-user-not-found") {
            error = Object.assign(error, { field: "username" });
        }

        return error;
    }

    login(item) {
        const error = item.validate();

        return this.invoke("login",
            error ? Promise.reject(error) : AuthActions.login.trigger(item.username, item.password));
    }
}

export default new LoginStore().connect(LoginActions);
