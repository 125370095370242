import { AuthStore, IntlStore } from "../../init";
import React from "react";
import { Component, Layer, ThemeSelector } from "../../ui";

function checkAuth(props) {
    const { auth, router, location } = props;

    if (!auth.isGuest) {
        router.replace(location.query.retUrl || "/");
    }
}

const STORAGE_KEY = "msb-theme";

function getTheme(props) {
    const { location: { query }, auth: { user } } = props;

    let theme;
    if (user && user.preferences && user.preferences.theme) {
        theme = user.preferences.theme;
    } else if (query.theme) {
        theme = query.theme;
    } else {
        theme = sessionStorage.getItem(STORAGE_KEY);
    }

    return theme === "dark" ? "dark" : "light";
}

class Auth extends Component {
    componentWillMount() {
        checkAuth(this.props);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.auth !== this.props.auth) {
            checkAuth(nextProps);
        }
    }

    render() {
        const { intl } = this.props;
        const theme = getTheme(this.props);

        return (
            <Layer middle={ true } className={ this.block() }>
                <ThemeSelector { ...{ intl, value: theme } } className={ this.element("theme-selector") } />
                { this.props.children }
            </Layer>
        );
    }
}

export default Auth
    .connect({ auth: AuthStore, intl: IntlStore })
    .withRouter();
