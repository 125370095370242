import React from "react";
import moment from "moment";

import { Component } from "../Component";

export class NotificationTime extends Component {
    render() {
        const { item: { time }, full } = this.props;

        const momentTime = moment(time);
        const isToday = momentTime.isSame(moment(), "day");

        return (
            <span className={ this.block({ full: full || !isToday }) }>
                { momentTime.format(isToday ? "HH:mm" : "DD.MM.YYYY HH:mm") }
            </span>
        );
    }
}
