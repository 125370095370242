module.exports = {
    "settings-profile-menu-title": {
        "en-US": "My Profile"
    },
    "settings-profile-title": {
        "en-US": "My Profile"
    },

    "profile-info": {
        "en-US": "Info"
    },
    "profile-activity": {
        "en-US": "Activity"
    },
    "profile-affiliations": {
        "en-US": "Affiliations"
    },
    "profile-password": {
        "en-US": "Password"
    }
};
