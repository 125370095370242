import { IntlModel } from "../../components/Intl";
import PropTypes from "prop-types";
import React from "react";
import { DropOwner } from "..";

import { Component } from "../Component";

import { Model } from "../../utils/dataflow";

const DROP_ALIGN_VERTICAL = [
    { side: "top", to: "bottom" },
    { side: "bottom", to: "top" }
];

export default class TableRow extends Component.aggregate(DropOwner) {
    init(...args) {
        super.init(...args);

        this.initDrop();
        this.onMenuClick = this.onMenuClick.bind(this);
    }

    renderMenu() {
    }

    showMenu(cell) {
        const menu = this.renderMenu();

        if (menu) {
            this.showDrop(menu, {
                to: cell,
                align: [{ side: "right", to: "center", offset: -19 }, DROP_ALIGN_VERTICAL],
                className: "drop_common drop_menu drop_arrow"
            });
        }
    }

    onMenuClick(event) {
        event.stopPropagation();

        this.showMenu(event.currentTarget);
    }

    renderCell(content, props) {
        return <td { ...props }>{ content }</td>;
    }

    renderNumCell() {
        return this.renderCell(void 0, { className: "table-row__num" });
    }

    renderMenuCell(message = "table-menu") {
        const { intl, item } = this.props;

        return (
            <td data-id={ item.id } className="table__menu" onClick={ this.onMenuClick }>
                { intl.formatMessage(message) }
            </td>
        );
    }

    render() {
        const { item, enumerable, children, onClick } = this.props;

        return (
            <tr data-id={ item.id } className={ this.block({ enumerable }) } onClick={ onClick }>
                { children }
            </tr>
        );
    }
}

TableRow.initPropTypes({
    intl: PropTypes.instanceOf(IntlModel).isRequired,
    item: PropTypes.instanceOf(Model).isRequired
});
