import { Store } from "../../utils/dataflow";

import HeaderModel from "./HeaderModel";
import HeaderActions from "./HeaderActions";

export class HeaderStore extends Store.of(HeaderModel) {
    setTitle(title) {
        this.setState({ title });
    }
}

export default new HeaderStore().connect(HeaderActions);
