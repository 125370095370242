import { Entity } from "./Entity";

class CertificateAttributes extends Entity {
}


export class Certificate extends Entity {
    get commonName() {
        const { attributes } = this;

        return attributes && attributes.Subject && attributes.Subject.CommonName || "";
    }

    get notAfter() {
        const { attributes } = this;

        return attributes && attributes.NotAfter || "";
    }

    get revocation() {
        return "";
    }

    get serialNumber() {
        const { attributes } = this;

        return attributes && attributes.SerialNumber || "";
    }

    init(...args) {
        super.init({ alias: "", pem: "", attributes: {} }, ...args);
    }

    validate() {
        const { alias, pem } = this;

        return super.validate()
            || (/\s/.test(alias) && this.getError("Certificate name contains space", "alias"))
            || (alias.trim() === "" && this.getError("Certificate name empty", "alias"))
            || (pem.trim() === "" && this.getError("Certificate pem empty", "pem"))
            || void 0;
    }
}

Certificate.connect({
    attributes: CertificateAttributes
});
