module.exports = {
    "certificate-common": {
        "en-US": "Certificate"
    },
    "certificate-issued": {
        "en-US": "Issued"
    },
    "certificate-expires": {
        "en-US": "Expires"
    },

    "certificate-ssl": {
        "en-US": "SSL"
    },
    "certificate-name": {
        "en-US": "Name"
    },
    "certificate-brand": {
        "en-US": "Brand"
    },
    "certificate-type": {
        "en-US": "Type"
    },
    "certificate-serial": {
        "en-US": "Serial"
    },
    "certificate-key-size": {
        "en-US": "Key size"
    },

    "certificate-attributes": {
        "en-US": "Attributes"
    },
    "certificate-common-name": {
        "en-US": "Common Name"
    },
    "certificate-san": {
        "en-US": "SAN"
    },
    "certificate-organization": {
        "en-US": "Organization"
    },
    "certificate-organization-unit": {
        "en-US": "Organization Unit"
    },
    "certificate-country": {
        "en-US": "Country"
    },
    "certificate-state": {
        "en-US": "State"
    },
    "certificate-city": {
        "en-US": "City"
    },
    "certificate-address": {
        "en-US": "Address"
    },
    "certificate-postal-code": {
        "en-US": "Postal Code"
    },

    "certificate-issuer": {
        "en-US": "Issuer"
    },
    "certificate-subject": {
        "en-US": "Subject"
    },

    "certificate-advanced": {
        "en-US": "Advanced"
    },
    "certificate-fingerprint-sha1": {
        "en-US": "Fingerprint (SHA1)"
    },
    "certificate-fingerprint-md5": {
        "en-US": "Fingerprint (MD5)"
    },

    "certificate-raw": {
        "en-US": "Raw"
    }
};
