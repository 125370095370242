import React from "react";
import { Route } from "react-router";

import EnrollmentCertificates from "./EnrollmentCertificates";

export default function route(path) {
    return (
        <Route path={ path } component={ EnrollmentCertificates } />
    );
}
