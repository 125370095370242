import pluralize from "pluralize";
import { DN } from "../utils/ldap";
import { nvl } from "../utils/object";
import { Entity } from "./Entity";

export class LDAPEntity extends Entity {
    get id() {
        return this._idString;
    }

    set id(id) {
        let parsedId = DN.parse(id);

        this.define({
            _id: parsedId,
            _idString: DN.stringify(parsedId)
        });
    }

    get name() {
        return this._name;
    }

    set name(name) {
        this.define({ _name: String(nvl(name, "")) });
    }

    static sort(entity1, entity2) {
        return entity1.name.localeCompare(entity2.name);
    }

    static uid() {
        const dc = pluralize(this.name.replace(/([a-z])([A-Z])/g, "$1-$2")).toLowerCase();

        return DN.stringify(`uid=${ super.uid() },dc=${ dc },dc=msb`);
    }

    init(...args) {
        super.init({ id: "", name: "" }, ...args);
    }

    isChildOf(entity) {
        return entity instanceof Entity && this._id && this._id.childOf(entity.id);
    }

    isEqualDN(dn) {
        return this._id && this._id.equals(dn);
    }

    isParentOf(entity) {
        return entity instanceof Entity && this._id && this._id.parentOf(entity.id);
    }

    toString() {
        return super.toString(this.id || void 0);
    }

    validate() {
        const { id, name } = this;

        return super.validate()
            || (name.trim() === "" && this.getError("Invalid name", "name"))
            || void 0;
    }

    valueOf() {
        return Object.assign(super.valueOf(), {
            id: this.id,
            name: this.name
        });
    }
}
