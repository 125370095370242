import PropTypes from "prop-types";
import React from "react";
import { Component } from "./Component";

import { nvl } from "../utils/object";

export class Search extends Component.timerOwner() {
    init(...args) {
        super.init(...args);

        this.onInput = this.onInput.bind(this);
        this.onInputTimeout = this.onInputTimeout.bind(this);
    }

    onInputTimeout() {
        const { props: { onSearch }, input } = this;

        this.inputTimeoutId = null;

        if (input && onSearch) {
            onSearch(input.value);
        }
    }

    clearInputTimeout() {
        if (this.inputTimeoutId) {
            this.clearTimeout(this.inputTimeoutId);
        }
    }

    setInputTimeout() {
        this.inputTimeoutId = this.setTimeout(this.onInputTimeout, this.props.timeout);
    }

    onInput() {
        this.clearInputTimeout();
        this.setInputTimeout();
    }

    render() {
        const { value, placeholder, autoFocus } = this.props;

        return (
            <div className={ this.block() }>
                <input ref={ (el) => this.input = el } type="text" defaultValue={ value }
                    autoFocus={ autoFocus }
                    placeholder={ nvl(placeholder, this.formatMessage("placeholder")) }
                    className={ this.element("input") }
                    onInput={ this.onInput } />
            </div>
        );
    }
}

Search.initProps({
    onSearch: { type: PropTypes.func },
    timeout: { type: PropTypes.number, value: 500 }
});
