import { APIConnector } from "../../../../init";
import { TableStore } from "../../../../components/Table";
import RepositoriesModel from "./RepositoriesModel";

export class RepositoriesStore extends TableStore.of(RepositoriesModel) {
    doPlaceBefore(item, beforeItem) {
        const url = APIConnector.buildUrl("LDAPConfigs/" + item.id + "/placeBefore");
        const params = {
            method: "POST",
            body: APIConnector.stringify(beforeItem ? { id: beforeItem.id } : "")
        };

        return APIConnector.fetch(url, params)
            .then((data) => APIConnector.response(data))
            .then((items) => this.setItem(items));
    }

    placeBefore(item, beforeItem) {
        return this.invoke("placeBefore", this.doPlaceBefore(item, beforeItem));
    }

    placeItemBefore(item, targetItem) {
        const items = this.state.item;

        this.setItem(items.setItemIndex(item, targetItem.index));
    }

    placeItemAfter(item, targetItem) {
        const items = this.state.item;

        this.setItem(items.setItemIndex(item, targetItem.index + 1));
    }

    moveRowBefore(itemId, afterItemId) {
        const items = this.state.item;

        const item = items.value(itemId);
        const afterItem = items.value(afterItemId);

        if (afterItem) {
            this.placeItemAfter(item, afterItem);
        } else {
            this.placeItemBefore(item, items.first);
        }
    }

    commitRowPosition(itemId) {
        const items = this.state.item;
        const index = items.indexOf(itemId);

        return this.placeBefore(items.value(itemId), items.valueAtIndex(index + 1));
    }

    moveRowUp(itemId) {
        const items = this.state.item;
        const index = items.indexOf(itemId);

        let operation;
        if (index > 0) {
            const item = items.valueAtIndex(index);

            this.placeItemBefore(item, items.valueAtIndex(index - 1));
            operation = this.placeBefore(item, items.valueAtIndex(index - 1));
        }

        return operation || Promise.resolve(false);
    }

    moveRowDown(itemId) {
        const items = this.state.item;
        const index = items.indexOf(itemId);

        let operation;
        if (index < items.length - 1) {
            const item = items.valueAtIndex(index);

            this.placeItemAfter(item, items.valueAtIndex(index + 1));
            operation = this.placeBefore(item, items.valueAtIndex(index + 2));
        }

        return operation || Promise.resolve(false);
    }
}

export default new RepositoriesStore();
