import { Collection } from "../utils/dataflow";
import { Group } from "./Group";

export class Groups extends Collection.of(Group) {
    getChildren(target = null) {
        return this.filter((group) => (group.groupId || null) === (target && target.id));
    }

    getAncestors(group) {
        const ancestors = [];

        let ancestor = group;
        while (ancestor) {
            ancestors.push(ancestor);
            ancestor = this.value(ancestor.groupId);
        }

        return ancestors;
    }

    getDescendants(groups) {
        return this.filter((group) => {
            let isDescendant = false;

            if (groups && groups.length) {
                while (group && !isDescendant) {
                    isDescendant = groups.some(({ id }) => group.id === id);
                    group = this.value(group.groupId);
                }
            }

            return isDescendant;
        });
    }

    getTopLevel() {
        return this.filter((group) => !group.groupId);
    }
}
