import { NetworkManagerStore, saveItem } from "../../../../../components/NetworkManager";
import { View } from "../../../../../components/View";
import React from "react";

import { SideBar, Table, TableRow, TableToolBar, ToolBarTitle } from "../../../../../ui";

import DelegationsStore from "../DelegationsStore";
import DelegationAddManagerActions, { setFilter, setPage, setSearch, setSort } from "./DelegationAddManagerActions";
import DelegationAddManagerStore from "./DelegationAddManagerStore";

class DelegationAddManagerRow extends TableRow {
    init(...args) {
        super.init(...args);
    }

    renderNameCell() {
        const { item: { name } } = this.props;

        return (
            <td className={ this.element("name") } data-title={ name }>
                { name }
            </td>
        );
    }

    renderLocationCell() {
        const { item: { location } } = this.props;

        return (
            <td className={ this.element("location") } data-title={ location }>
                { location }
            </td>
        );
    }

    render() {
        const { item: { managedGroups }, network } = this.props;
        const disabled = managedGroups && managedGroups.some((group) => group.id === network.id);

        return (
            <TableRow { ...this.props } enumerable={ true } className={ this.block({ disabled }) }>
                { this.renderNumCell() }
                { this.renderNameCell() }
                { this.renderLocationCell() }
            </TableRow>
        );
    }
}

class DelegationAddManagerTable extends Table {
    sortByColumn(column) {
        setSort(column);
    }

    setPage(page) {
        setPage(page);
    }

    renderHead() {
        return (
            <tr>
                { this.renderHeadNum() }
                { this.renderHeadCell("name") }
                { this.renderHeadCell("location") }
            </tr>
        );
    }

    renderRow(item) {
        const { intl, network, onRowClick } = this.props;

        return <DelegationAddManagerRow key={ item.id } { ...{ intl, item, network } } onClick={ onRowClick } />;
    }
}

class DelegationAddManagerToolBar extends TableToolBar {
    render() {
        return (
            <TableToolBar className={ this.block() }>
                { this.renderSearch() }
            </TableToolBar>
        );
    }
}

export class DelegationAddManager extends View {
    init(...args) {
        super.init(...args);

        this.onCancel = this.onCancel.bind(this);
        this.onTableRowClick = this.onTableRowClick.bind(this);
    }

    refresh(group) {
        if (group) {
            setFilter("group", group);
        }
    }

    setItem(networkId) {
        const { delegations: { item: delegations } } = this.props;

        this.refresh(delegations.value(networkId));
    }

    componentWillMount() {
        this.setItem(this.props.params.networkId);
    }

    componentWillReceiveProps(nextProps) {
        const { delegations: currDelegations, params: { networkId: currNetworkId } } = this.props;
        const { delegations: nextDelegations, params: { networkId: nextNetworkId } } = nextProps;

        if (currNetworkId !== nextNetworkId || currDelegations !== nextDelegations) {
            this.setItem(nextNetworkId);
        }
    }

    onCancel() {
        const { router, params: { networkId } } = this.props;

        router.replace(`/msb/manage/delegations/${networkId}/`);
    }

    onTableRowClick({ currentTarget }) {
        const userId = currentTarget.getAttribute("data-id");
        const { params: { networkId } } = this.props;

        saveItem(new NetworkManagerStore.Model.Item({ userId, groupId: networkId }))
            .then(() => this.onCancel());
    }

    renderMainToolBar() {
        const { delegations: { item: delegations } } = this.props;
        const { params: { networkId } } = this.props;

        const network = delegations.value(networkId);

        return (
            <ToolBarTitle key="main" retUrl={ `/msb/manage/delegations/${networkId}/` }>
                { this.formatMessage("title", network.getProps("formattedName")) }
            </ToolBarTitle>
        );
    }

    renderSearchToolBar() {
        const { intl, data: { filter } } = this.props;

        return (
            <DelegationAddManagerToolBar key="search" { ...{ intl, filter } } onSearch={ setSearch } />
        );
    }

    renderToolBars() {
        return [
            this.renderMainToolBar(),
            this.renderSearchToolBar()
        ];
    }

    render() {
        const { intl, data: { item: items, filter }, delegations: { item: delegations } } = this.props;
        const { params: { networkId } } = this.props;

        const network = delegations.value(networkId);

        if (!network) {
            return null;
        }

        return (
            <SideBar toolBar={ this.renderToolBars() } className={ this.block() }>
                <DelegationAddManagerTable { ...{ intl, filter, items, network } }
                    className={ this.element("table") } onRowClick={ this.onTableRowClick } />
            </SideBar>
        );
    }
}

export default DelegationAddManager.of(DelegationAddManagerStore, DelegationAddManagerActions)
    .connect({ delegations: DelegationsStore });
