import React from "react";
import { Router, Route, IndexRedirect, browserHistory } from "react-router";

import App from "./app/App";
import { Route as AuthRoute } from "./app/Auth";
import { Route as MainRoute } from "./app/Main";
import NotFound from "./app/NotFound";

export default (
    <Router history={ browserHistory }>
        <Route path="/" component={ App }>
            <IndexRedirect to="msb/" />
            { AuthRoute("auth/") }
            { MainRoute("msb/") }
            <Route path="*" component={ NotFound } />
        </Route>
    </Router>
);
