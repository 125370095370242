import { Entity } from "./Entity";
import { User } from "./User";

const DEFAULT_COLOR = "navy";
const COLORS = {
    "aapl": "#3182BD",
    "csco": "#62abe0",
    "goog": "#868ae4"
};

export class Transaction extends Entity {
    static get name() {
        return "Transaction";
    }

    get color() {
        return COLORS[this.stock && this.stock.toLowerCase()] || DEFAULT_COLOR;
    }

    set userId(userId) {
        // do nothing to avoid error
    }

    init(...args) {
        super.init({ user: {} }, ...args);
    }
}

Transaction.connect({
    user: User
});
