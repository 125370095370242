import { Model } from "../../utils/dataflow";

export class Options extends Model {
    init(...data) {
        super.init({ localeMatcher: "best fit" }, ...data);
    }
}

export class DateTimeOptions extends Options {
    init(...data) {
        super.init({
            formatMatcher: "best fit"
        }, ...data);
    }
}

export class NumberOptions extends Options {
    init(...data) {
        super.init({
            useGrouping: true
        }, ...data);
    }
}

export class MoneyOptions extends NumberOptions {
    init(...data) {
        super.init({
            style: "currency",
            currencyDisplay: "symbol"
        }, ...data);
    }
}

export class DateTimeFormats extends Model {
}

DateTimeFormats.connect({
    short: DateTimeOptions,
    long: DateTimeOptions
});

export class IntlFormats extends Model {
}

IntlFormats.connect({
    date: DateTimeFormats,
    time: DateTimeFormats,
    number: NumberOptions,
    money: MoneyOptions
});
