import React from "react";
import { Component } from "./Component";
import { ScrollArea } from "./ScrollArea";

export class Main extends Component {
    render() {
        const { children, toolBar, sideBar, status } = this.props;

        return (
            <div className={ this.block() }>
                <div className={ Main.element("content") }>
                    { toolBar }
                    <ScrollArea className="content">
                        { children }
                    </ScrollArea>
                </div>
                { sideBar }
                { status }
            </div>
        );
    }
}
