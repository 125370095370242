import { FormModel } from "../../components/Form";
import { Entity } from "../../model";

class ForgotModel extends Entity {
    init(...args) {
        super.init({ email: "" }, ...args);
    }

    validate() {
        const { email } = this;

        return super.validate()
            || (email.trim() === "" && this.getError("Empty forgot password email", "email"));
    }

    valueOf() {
        return { email: this.email };
    }
}

export default class ForgotPasswordModel extends FormModel.of(ForgotModel) {
}
