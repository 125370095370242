import { Entity } from "./Entity";

export const CSR_KEY_TYPES = [
    { id: "RSA", name: "RSA" },
    { id: "DSA", name: "DSA" },
    { id: "EC", name: "EC" }
];

export const CSR_KEY_SIZES = [
    { id: 2048, name: "2048" },
    { id: 4096, name: "4096" },
    { id: 256, name: "256" }
];

const defaults = {
    alias: "",
    pem: "",
    attributes: null,
    commonName: "",
    country: "US",
    state: "",
    city: "",
    organization: "",
    department: "",
    keyType: "RSA",
    keySize: 2048
};

class CertificateRequestAttributes extends Entity {
}

export class CertificateRequest extends Entity {
    init(...args) {
        super.init(defaults, ...args);
    }

    validate() {
        const { alias } = this;

        return super.validate()
            || (alias.trim() === "" && this.getError2("empty", "alias"))
            || void 0;
    }
}

CertificateRequest.connect({
    attributes: CertificateRequestAttributes
});
