import { IntlStore } from "../../../../init";
import React from "react";
import { Component, SideBar, ToolBarTitle } from "../../../../ui";
import GroupEdit from "../Groups/GroupEdit";

import { setItem } from "../Groups/GroupEditActions";
import GroupEditStore from "../Groups/GroupEditStore";

const EMPTY = {};

export class NetworkAdd extends Component {
    constructor(...args) {
        super(...args);

        this.onCancel = this.onCancel.bind(this);
    }

    onCancel() {
        const { router, params: { networkId } } = this.props;

        router.replace(`/msb/manage/networks/${networkId}/`);
    }

    setParams(props) {
        const { params: { networkId, alias } } = props;

        setItem({ groupId: networkId, name: alias + ".New" });
    }

    componentWillMount() {
        this.setParams(this.props);
    }

    componentWillReceiveProps(nextProps) {
        const { params: { networkId: currNetworkId, alias: currAlias } } = this.props;
        const { params: { networkId: nextNetworkId, alias: nextAlias } } = nextProps;

        if (currNetworkId !== nextNetworkId || currAlias !== nextAlias) {
            this.setParams(nextProps);
        }
    }

    renderToolBar() {
        const { params: { networkId } } = this.props;

        return (
            <ToolBarTitle retUrl={ `/msb/manage/networks/${networkId}/` }>
                { this.formatMessage("title") }
            </ToolBarTitle>
        );
    }

    render() {
        return (
            <SideBar toolBar={ this.renderToolBar() } className={ this.block() }>
                <GroupEdit { ...this.props } item={ EMPTY }
                    header={ false } cancel={ false }
                    submit={ this.formatMessage("submit") }
                    close={ false }
                    className={ this.element("form") } />
            </SideBar>
        );
    }
}

export default NetworkAdd.connect({ intl: IntlStore, data: GroupEditStore });
