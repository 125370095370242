import { IntlStore } from "../../init";
import { Users } from "../../model";
import SelectItems from "./SelectItems";

const SelectUsers = SelectItems.of(Users);

export const SelectUsersActions = SelectUsers.Actions;
export const SelectUsersStore = SelectUsers.Store;
export const SelectUsersModel = SelectUsers.Model;

export default SelectUsers.connect({ data: SelectUsersStore, intl: IntlStore });
