import React from "react";

import { Component } from "./Component";
import { Link } from "./Link";
import { Markdown } from "./Markdown";
import { ToolBar } from "./ToolBar";

export class ToolBarTitle extends Component {
    render() {
        return (
            <ToolBar className={ this.block() }>
                <Markdown className={ this.element("title") }>
                    { this.props.children }
                </Markdown>
                <Link to={ this.props.retUrl } notActive={ true } className={ this.element("close") }>
                    <i className="ico ico-close" />
                </Link>
            </ToolBar>
        );
    }
}
