import React from "react";

import { Component } from "../Component";

export class NotificationCategory extends Component {
    render() {
        const { item: { category }, children } = this.props;
        const text = children || this.formatMessage(category);

        return (
            <div data-title={ this.formatMessage(category) } className={ this.block({ [category]: true }) }>
                { text }
            </div>
        );
    }
}
