import getRect from "./getRect";

/**
 * Returns rect of element relative to another one.
 *
 * @param {HTMLElement} el
 * @param {HTMLElement} [relEl=el.offsetParent]
 * @return {Rect}
 */
export default function getRectTo(el, relEl) {
    return getRect(el).rectTo((relEl || document.body).getBoundingClientRect());
}
