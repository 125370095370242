import React, { Children } from "react";

import { ModalOwner, SideBar } from "../../ui";
import { View } from "../View";

export default class TableView extends View.aggregate(ModalOwner) {
    get modalId() {
        return this.constructor.name.toLowerCase();
    }

    getItem() {
        const { data: { item }, params: { itemId } } = this.props;

        return item.value(itemId);
    }

    showModal() {
        return super.showModal({ item: this.getItem() });
    }

    shouldModalRenderForPath(pathname) {
        return pathname.endsWith("create") || this.getItem();
    }

    shouldModalRender() {
        const { location: { pathname } } = this.props;

        return super.shouldModalRender() && this.shouldModalRenderForPath(pathname);
    }

    processChildren(children) {
        const item = this.getItem();

        return Children.map(children, (child) => {
            if (child) {
                child = React.cloneElement(child, Object.assign({}, child.props, { item }));
            }

            return child;
        });
    }

    renderSideBar() {
        const { children } = this.props;

        return children ? <SideBar float={ true }>{ this.processChildren(children) }</SideBar> : null;
    }
}
