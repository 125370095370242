import React from "react";

import { Component } from "./Component";
import { DropOwner, DROP_ALIGN_RIGHT } from "./Drop";
import List from "./List";

export default class Menu extends Component.aggregate(DropOwner) {
    init(...args) {
        super.init(...args);

        this.initDrop();
        this.onClick = this.onClick.bind(this);
    }

    showDrop() {
        const { props: { children, align, dropClassName }, refs: { label } } = this;

        super.showDrop(
            <List className="list_menu" onClick={ this.onDropHide }>{ children }</List>,
            {
                to: label,
                align: align || DROP_ALIGN_RIGHT,
                className: "drop_menu drop_common drop_arrow" + (dropClassName ? " " + dropClassName : "")
            }
        );
    }

    onClick() {
        const { drop } = this.state;

        drop ? this.hideDrop() : this.showDrop();
    }

    render() {
        const { label } = this.props;

        return (
            <div ref="label" className={ this.block() } onClick={ this.onClick }>
                { label || <span>&#8226;&#8226;&#8226;</span> }
            </div>
        );
    }
}
