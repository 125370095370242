import { FormModel } from "../../../../components/Form";
import { RegistrationUser } from "../../../../model";

export class Registration extends RegistrationUser {
    init(...args) {
        super.init({ captcha: "fake-captcha" }, ...args);
    }
}

export default class UserCreateModel extends FormModel.of(Registration) {
}
