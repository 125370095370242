import PropTypes from "prop-types";
import React from "react";
import { DROP_ALIGN_CENTER, DropOwner, ScrollArea, Search } from "..";
import { Component } from "../Component";

import { Collection } from "../../utils/dataflow";

export class SelectListItem extends Component {
    render() {
        const { item: { id, name }, value, onClick } = this.props;
        const trimmedName = name.trim();

        return (
            <div key={ id } data-id={ id }
                className={ this.cx("item list__item", { "list__item_selected": id === value }) }
                onClick={ onClick }>
                <div className="item__title">{ trimmedName || "\u00A0" }</div>
            </div>
        );
    }
}

export class SelectList extends Component {
    init(...args) {
        super.init(...args);

        this.onItemClick = this.onItemClick.bind(this);
    }

    getItemFromEvent({ currentTarget }) {
        const { items } = this.props;
        const id = currentTarget.getAttribute("data-id");

        return items.value(id);
    }

    select(value) {
        const { onSelect } = this.props;

        if (onSelect) {
            onSelect(value);
        }
    }

    onItemClick(event) {
        const item = this.getItemFromEvent(event);

        this.select(item && item.id || null);
    }

    renderListItem(props) {
        return <SelectListItem { ...props } />;
    }

    renderItem(item) {
        const { props: { value } } = this;

        return this.renderListItem({
            key: item.id,
            item,
            value,
            onClick: this.onItemClick
        });
    }

    filterItems() {
        let { items, searchValue, sort } = this.props;

        if (searchValue !== "") {
            searchValue = searchValue.toLowerCase();

            items = items.filter((item) => item.name.toLowerCase().indexOf(searchValue) !== -1);
        }

        if (sort) {
            items = items.sort(sort);
        }

        return items.valueOf();
    }

    renderEmpty() {
        return (
            <div key="empty" className="notice">
                { this.formatMessage("empty-message") }
            </div>
        );
    }

    renderItems() {
        const items = this.filterItems();
        let rendered;

        if (items.length) {
            const { nullValue } = this.props;

            rendered = items.map(this.renderItem.bind(this));
            if (nullValue) {
                rendered.unshift(this.renderItem({
                    id: "",
                    name: nullValue === true ? this.formatMessage("not-selected") : nullValue
                }));
            }
        } else {
            rendered = this.renderEmpty();
        }

        return rendered;
    }

    renderSearch() {
        const { intl, searchValue: value, onSearch } = this.props;

        return (
            <Search { ...{ intl, value, onSearch, autoFocus: true } } className={ this.element("search") } />
        );
    }

    render() {
        const { search } = this.props;

        return (
            <div className="select-list">
                { search === true ? this.renderSearch() : search || null }
                <ScrollArea className="list">
                    { this.renderItems() }
                </ScrollArea>
            </div>
        );
    }
}

export default class Select extends Component.aggregate(DropOwner) {
    init(...args) {
        super.init(...args);

        this.initDrop();
        this.initState({ searchValue: "" });
        this.onOpenerClick = this.onOpenerClick.bind(this);
        this.onListSelect = this.onListSelect.bind(this);
        this.onListSearch = this.onListSearch.bind(this);
        this.onInputKeyDown = this.onInputKeyDown.bind(this);
    }

    onListSearch(searchValue) {
        this.setState({ searchValue }, () => {
            this.showDrop();
        });
    }

    onListSelect(value) {
        const { onChange, name } = this.props;

        if (onChange) {
            this.onDropHide();
            onChange(value, name);
        }
    }

    getItems() {
        const { items, filter } = this.props;

        return filter ? items.filter(filter) : items;
    }

    getValue(items) {
        const { value } = this.props;
        const selected = value ? items.value(value) : null;

        return selected && selected.id;
    }

    renderList(props) {
        return <SelectList { ...props } />;
    }

    renderDropList() {
        const { props: { intl, search, sort, nullValue }, state: { searchValue } } = this;

        const items = this.getItems();

        return this.renderList({
            intl,
            items,
            value: this.getValue(items),
            search,
            searchValue,
            sort,
            nullValue,
            onSelect: this.onListSelect,
            onSearch: this.onListSearch
        });
    }

    showDrop() {
        const { props: { dropClassName, dropAlign }, select } = this;

        super.showDrop(
            this.renderDropList(),
            {
                to: select,
                align: dropAlign || DROP_ALIGN_CENTER,
                className: "drop_arrow drop_common drop_select" + (dropClassName ? " " + dropClassName : "")
            }
        );
    }

    onOpenerClick() {
        const { drop } = this.state;

        drop ? this.hideDrop() : this.showDrop();
    }

    onInputKeyDown(event) {
        const { keyCode } = event;
        const { drop } = this.state;

        if (!drop && (keyCode === 32 || keyCode === 13)) {
            event.preventDefault();
            event.stopPropagation();
            this.showDrop();
        } else if (drop && keyCode === 27) {
            event.stopPropagation();
            this.hideDrop();
        }
    }

    getLabel(value, items) {
        const { placeholder, nullValue } = this.props;

        return value ? items.value(value).name
            : (nullValue === true ? this.formatMessage("not-selected") : nullValue || placeholder) || "\u00A0";
    }

    renderOpener(value, items) {
        return (
            <div ref={ (el) => this.select = el } className="select__opener" onClick={ this.onOpenerClick }>
                <span onKeyDown={ this.onInputKeyDown }>{ this.getLabel(value, items) }</span>
            </div>
        );
    }

    render() {
        const { drop } = this.state;

        const items = this.getItems();
        const value = this.getValue(items);
        const enabled = items.length;

        const className = this.cx("select", {
            "select_drop": drop,
            "select_disabled": !enabled,
            "select_selected": items.value(value)
        });

        return (
            <div className={ className }>
                { this.renderOpener(value, items) }
            </div>
        );
    }
}

Select.initPropTypes({
    items: PropTypes.instanceOf(Collection).isRequired,
    value: PropTypes.any,
    placeholder: PropTypes.string,
    search: PropTypes.oneOfType([PropTypes.bool, PropTypes.element]),
    nullValue: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    sort: PropTypes.func,
    filter: PropTypes.func,
    onChange: PropTypes.func
});

Select.initDefaultProps({
    value: null,
    search: true,
    nullValue: true
});
