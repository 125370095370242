import PropTypes from "prop-types";
import React from "react";
import { Button } from "../Button";
import { Component } from "../Component";

export class FormCancel extends Component {
    init(...args) {
        super.init(...args);

        this.onClick = this.onClick.bind(this);
    }

    onClick(event) {
        const { onClick } = this.props;

        event.preventDefault();
        if (onClick) {
            onClick(event);
        }
    }

    render() {
        const { children, disabled } = this.props;

        return (
            <Button type="cancel" disabled={ disabled } className={ this.block() } onClick={ this.onClick }>
                { children }
            </Button>
        );
    }
}

FormCancel.initPropTypes({
    onClick: PropTypes.func
});
