import capitalize from "../string/capitalize";
import Publisher from "./Publisher";
import Model from "./Model";

export default class Store extends Publisher {
    init(...args) {
        this.state = new this.Model(...args);

        super.init("change");
    }

    get Model() {
        return this.constructor.Model;
    }

    trigger() {
        super.trigger(this.state);
    }

    replaceState(nextState) {
        if (this.state !== nextState) {
            this.state = nextState;
            this.trigger();
        }
    }

    setState(nextState) {
        if (!this.Model.isA(nextState)) {
            nextState = this.state.set(nextState);
        }

        this.replaceState(nextState);
    }

    listenTo(publisher, name, ...names) {
        let handler;

        if (name === "") {
            name = names.shift() || "";
        }

        name += names.map(capitalize).join("");
        handler = this[name];

        if (typeof handler === "function") {
            publisher.listen(this.bindTo(handler));

            if (publisher.children) {
                this.connect(publisher.children, name);
            }
        }
    }

    connect(publishers, prefix = "") {
        Object.keys(publishers).forEach((name) => this.listenTo(publishers[name], prefix, name));

        return this;
    }

    toString() {
        return super.toString(this.Model.name);
    }

    static get Model() {
        return Model;
    }

    static toString() {
        return super.toString(this.Model.name);
    }

    /** @return this */
    static of(Type) {
        if (!Model.is(Type)) {
            throw this.getError(`${Model.name} expected`);
        }

        return class Store extends this {
            static get Model() {
                return Type;
            }
        };
    }
}
