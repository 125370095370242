import React from "react";
import { Component, Spinner } from "../ui";

class NotFound extends Component {
    navigate(props) {
        this.navigateUp(props);
    }

    componentWillMount() {
        this.navigate(this.props);
    }

    componentWillReceiveProps(nextProps) {
        this.navigate(nextProps);
    }

    render() {
        return <Spinner center={ true } />;
    }
}

export default NotFound.withRouter();
