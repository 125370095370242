import { Action } from "../../utils/dataflow";

export class AuthActions {
    constructor() {
        Object.assign(this, {
            login: new Action,
            logout: new Action,
            updateSession: new Action,
            updateCompany: new Action
        });
    }
}
