import PropTypes from "prop-types";
import React from "react";

import { compact } from "../../utils/object";
import { Component } from "../Component";
import { ScrollArea } from "../ScrollArea";
import { Form } from "./Form";
import { FormCancel } from "./FormCancel";
import { FormError } from "./FormError";
import { FormFields } from "./FormFields";
import { FormFooter } from "./FormFooter";
import { FormHeader } from "./FormHeader";
import { FormSubmit } from "./FormSubmit";

export class GenericForm extends Component {
    static defaultProps = compact(Form.defaultProps, {
        header: true,
        footer: true,
        close: true,
        autoComplete: false,
        submit: true,
        cancel: true
    });

    static propTypes = compact(Form.propTypes, {
        header: PropTypes.oneOfType([PropTypes.bool, PropTypes.element]),
        footer: PropTypes.oneOfType([PropTypes.bool, PropTypes.element]),
        close: PropTypes.oneOfType([PropTypes.bool, PropTypes.element]),
        cancel: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.element]),
        submit: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.element]),
        title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
        onClose: PropTypes.func
    });

    renderHeader() {
        const { header, title, close, onClose, onCancel } = this.props;

        return header === true
            ? (
                <FormHeader { ...{ close, onClose: onClose || onCancel } } className={ this.element("header") }>
                    { title }
                </FormHeader>
            )
            : header || null;
    }

    renderFields() {
        return (
            <FormFields className={ this.element("fields") }>
                { this.props.children }
            </FormFields>
        );
    }

    renderSubmit() {
        const { submit, busy, disabled, onSubmit } = this.props;

        return submit === true || typeof submit === "string"
            ? (
                <FormSubmit { ...{ busy, disabled, onClick: onSubmit } } className={ this.element("submit") }>
                    { submit === true ? this.formatMessage("submit") : submit }
                </FormSubmit>
            )
            : submit || null;
    }

    renderCancel() {
        const { cancel, onCancel } = this.props;

        return cancel === true || typeof cancel === "string"
            ? (
                <FormCancel { ...{ onClick: onCancel } } className={ this.element("cancel") }>
                    { cancel === true ? this.formatMessage("cancel") : cancel }
                </FormCancel>
            )
            : cancel || null;
    }

    renderError() {
        const { intl, error } = this.props;

        return error && !error.field ? <FormError { ...{ intl, error } } /> : null;
    }

    renderFooter() {
        const { footer } = this.props;

        return footer === true
            ? (
                <FormFooter className={ this.element("footer") }>
                    { this.renderSubmit() }
                    { this.renderCancel() }
                </FormFooter>
            )
            : footer || null;
    }

    render() {
        return (
            <Form { ...this.props } className={ this.block() }>
                { this.renderHeader() }
                <ScrollArea className={ this.element("main") }>
                    { this.renderFields() }
                    { this.renderError() }
                    { this.renderFooter() }
                </ScrollArea>
            </Form>
        );
    }
}
