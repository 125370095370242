import { TableModel, TableFilterModel } from "../../../../../components/Table";
import { Users, Group } from "../../../../../model";

class UsersFilter extends TableFilterModel {
    init(...args) {
        super.init({ group: {}, sort: { name: true } }, ...args);
    }

    toJSON() {
        const { group } = this;

        return Object.assign(super.toJSON(), {
            groupId: group.id || void 0
        });
    }
}

UsersFilter.connect({
    group: Group
});

export default class DelegationAddManagerModel extends TableModel.of(Users, UsersFilter) {
}
