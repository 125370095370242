import { setTitle } from "../../../../components/Header";

import { TableView } from "../../../../components/Table";
import moment from "moment";
import React from "react";
import { Link, List, Main, Table, TableRow, TableToolBar } from "../../../../ui";

import { contains, MOVE_VERTICAL, MoveHandle } from "../../../../utils/html";
import RepositoriesActions, {
    commitRowPosition,
    moveRowBefore,
    moveRowDown,
    moveRowUp,
    setPage,
    setSort
} from "./RepositoriesActions";

import RepositoriesStore from "./RepositoriesStore";

function getRowId(table, target) {
    let id = null;

    while (id === null && contains(table, target)) {
        id = target.getAttribute("data-id") || null;
        target = target.parentNode;
    }

    return id;
}

class RepositoryRow extends TableRow {
    init(...args) {
        super.init(...args);

        this.onArrowUpClick = this.onArrowUpClick.bind(this);
        this.onArrowDownClick = this.onArrowDownClick.bind(this);
        this.onMouseDown = this.onMouseDown.bind(this);
    }

    startMove(event) {
        const { moveHandle } = this;

        moveHandle.startMove(event, {
            ghost: null,
            rowId: event.currentTarget.getAttribute("data-id"),
            direction: MOVE_VERTICAL
        });
    }

    stopMove() {
        const { moveHandle } = this;

        if (moveHandle) {
            moveHandle.stopMove();
        }
    }

    onMove(event) {
        const { moveHandle } = this;

        if (moveHandle) {
            const table = document.querySelector(".repositories-table");
            let target = event.target;

            if (event.type === "touchmove" && event.changedTouches.length) {
                const { clientX, clientY } = event.changedTouches[0];

                target = document.elementFromPoint(clientX, clientY);
            }

            if (contains(table, target)) {
                const rowId = getRowId(table, target);

                if (rowId !== moveHandle.rowId) {
                    moveRowBefore(moveHandle.rowId, rowId);
                }
            }
        }
    }

    onStartMove() {
        this.setState({ moving: true });

        if (this.props.onStartMove) {
            this.props.onStartMove();
        }
    }

    onStopMove(moveHandle) {
        if (moveHandle.rowId) {
            commitRowPosition(moveHandle.rowId);
            delete moveHandle.rowId;
        }

        this.setState({ moving: false });
        if (this.props.onStopMove) {
            this.props.onStopMove();
        }
    }

    onMouseDown(event) {
        this.startMove(event);
    }

    onArrowUpClick({ currentTarget }) {
        moveRowUp(currentTarget.getAttribute("data-id"));
    }

    onArrowDownClick({ currentTarget }) {
        moveRowDown(currentTarget.getAttribute("data-id"));
    }

    renderDragCell() {
        const { item } = this.props;

        return (
            <td className="table-row__drag">
                <a data-id={ item.id } className="link action action_drag"
                    onMouseDown={ this.onMouseDown } onTouchStart={ this.onMouseDown }>{ "\u00A0" }</a>
            </td>
        );
    }

    renderArrowsCell() {
        const { item } = this.props;

        return (
            <td className="repository-row__arrows">
                <a data-id={ item.id } className="link action action_move-up" onClick={ this.onArrowUpClick }>
                    { "\u00A0" }
                </a>
                <a data-id={ item.id } className="link action action_move-down" onClick={ this.onArrowDownClick }>
                    { "\u00A0" }
                </a>
            </td>
        );
    }

    renderNameCell() {
        const { item, item: { name, host } } = this.props;
        const displayName = name || host;

        const tooltip = this.formatMessage("name-tooltip", Object.assign(item.valueOf(), {
            displayName
        }));

        return (
            <td className="repository-row__name">
                <span data-tooltip={ tooltip }>{ displayName }</span>
            </td>
        );
    }

    renderTypeCell() {
        const { item: { type } } = this.props;

        return <td data-title={ type } className="repository-row__type">{ type }</td>;
    }

    renderUpdateFrequencyCell() {
        const { item: { updateFrequency } } = this.props;

        return <td className="repository-row__update-frequency">{ updateFrequency || "Manual" }</td>;
    }

    renderLastSynchCell() {
        const { item: { lastSynch } } = this.props;

        return (
            <td className="repository-row__last-synch">
                { lastSynch && moment(lastSynch).format("YYYY-MM-DD HH:mm:ss") }
            </td>
        );
    }

    renderActionsCell() {
        const { item, intl } = this.props;

        return (
            <td className="repository-row__actions">
                <Link to={ `${item.id}/import` } className="link action action_import">
                    { intl.formatMessage("settings-repositories-import") }
                </Link>
            </td>
        );
    }

    renderMenu() {
        const { props: { intl, item }, onDropHide } = this;

        return (
            <List onClick={ onDropHide }>
                <Link to={ `${item.id}/edit` } className="link">{ intl.formatMessage(
                    "settings-repositories-edit") }</Link>
                <Link to={ `${item.id}/delete` } className="link">{ intl.formatMessage(
                    "settings-repositories-delete") }</Link>
            </List>
        );
    }

    componentDidMount() {
        this.moveHandle = new MoveHandle({
            moveTo: () => void 0,
            onStartMove: () => this.onStartMove(),
            onStopMove: (event) => this.onStopMove(event),
            onMove: (event) => this.onMove(event)
        });
    }

    componentWillUnmount() {
        this.stopMove();
        delete this.moveHandle;
    }

    render() {
        const { moving } = this.state;

        return (
            <TableRow { ...this.props } enumerable={ true }
                className={ this.block({ moving }) }>
                { this.renderDragCell() }
                { this.renderNumCell() }
                { this.renderArrowsCell() }
                { this.renderNameCell() }
                { this.renderTypeCell() }
                { this.renderUpdateFrequencyCell() }
                { this.renderLastSynchCell() }
                { this.renderActionsCell() }
                { this.renderMenuCell() }
            </TableRow>
        );
    }
}

class RepositoriesTable extends Table {
    init(...args) {
        super.init(...args);

        this.initState({ moving: false });
        this.onStartMove = this.onStartMove.bind(this);
        this.onStopMove = this.onStopMove.bind(this);
    }

    sortByColumn(column) {
        setSort(column);
    }

    setPage(page) {
        setPage(page);
    }

    onStartMove() {
        this.setState({ moving: true });
    }

    onStopMove() {
        this.setState({ moving: false });
    }

    renderHead() {
        return (
            <tr>
                { this.renderHeadCell("drag") }
                { this.renderHeadNum() }
                { this.renderHeadCell("sort") }
                { this.renderHeadCell("name") }
                { this.renderHeadCell("repo-type") }
                { this.renderHeadCell("update-frequency") }
                { this.renderHeadCell("last-synch") }
                { this.renderHeadCell("actions") }
                { this.renderHeadCell("menu") }
            </tr>
        );
    }

    renderRow(item) {
        const { intl } = this.props;

        return <RepositoryRow key={ item.id } { ...{ intl, item } }
            onStartMove={ this.onStartMove } onStopMove={ this.onStopMove } />;
    }

    renderTable() {
        const { filter: { page, perPage } } = this.props;
        const { moving } = this.state;

        return (
            <table className={ this.block({ moving }, "table") } style={ { counterReset: "rows " + (page * perPage) } }>
                <thead>
                { this.renderHead() }
                </thead>
                <tbody>
                { this.renderRows() }
                </tbody>
            </table>
        );
    }
}

class RepositoriesToolBar extends TableToolBar {
    render() {
        const { intl } = this.props;

        return (
            <TableToolBar className={ this.block() }>
                { this.renderSearch() }
                <Link to="import" className="action action_import link_accent">
                    { intl.formatMessage("settings-repositories-import-all") }
                </Link>
                <Link to="create" className="action action_add link_accent">
                    { intl.formatMessage("settings-repositories-create") }
                </Link>
            </TableToolBar>
        );
    }
}

export class Repositories extends TableView {
    componentWillMount() {
        const { intl } = this.props;

        setTitle(intl.formatMessage("settings-repositories-title"));
        super.componentWillMount();
    }

    shouldModalRenderForPath(pathname) {
        return pathname.endsWith("import")
            || pathname.endsWith("create/error")
            || super.shouldModalRenderForPath(pathname);
    }

    renderToolBar() {
        const { intl, data: { filter } } = this.props;

        return (
            <RepositoriesToolBar { ...{ intl, filter } } />
        );
    }

    render() {
        const { intl, data: { item: items, filter } } = this.props;

        return (
            <Main toolBar={ this.renderToolBar() } status={ this.renderStatus() } className={ this.block() }>
                <RepositoriesTable { ...{ intl, filter, items } } />
            </Main>
        );
    }
}

export default Repositories.of(RepositoriesStore, RepositoriesActions);
