module.exports = {
    "user-view-general": {
        "en-US": "General"
    },
    "user-view-first-name": {
        "en-US": "First Name"
    },
    "user-view-last-name": {
        "en-US": "Last name"
    },
    "user-view-username": {
        "en-US": "Username"
    },
    "user-view-affiliation": {
        "en-US": "Affiliation"
    },

    "user-view-contact-info": {
        "en-US": "Contact Info"
    },
    "user-view-location": {
        "en-US": "Location"
    },
    "user-view-company": {
        "en-US": "Company"
    },
    "user-view-email": {
        "en-US": "Email"
    },
    "user-view-phone": {
        "en-US": "Phone Number"
    },

    "user-view-affiliations": {
        "en-US": "Affiliations"
    },
    "user-view-groups-placeholder": {
        "en-US": "Add Group"
    }
};
