import { RegistrationUser } from "./RegistrationUser";

export class Registration extends RegistrationUser {
    init(...args) {
        super.init({ captcha: "", agree: true }, ...args);
    }

    validate() {
        return super.validate()
            || this.validateCaptcha()
            || void 0;
    }

    validateCaptcha() {
        const { captcha } = this;

        return captcha.trim() === "" && this.getError("empty", "captcha");
    }
}
