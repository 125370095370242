import { TableModel, TableFilterModel } from "../Table";
import { Notifications } from "../../model";

export class NotificationsFilter extends TableFilterModel {
    toJSON() {
        const { category, status, recipientUserId } = this;

        return Object.assign(super.toJSON(), { category, status, recipientUserId });
    }
}

export default class NotificationsModel extends TableModel.of(Notifications, NotificationsFilter) {
    init(...args) {
        super.init({
            item: [],
            filter: {
                category: ["request_pending", "request_approved"], // ,request_denied
                status: "",
                page: 0,
                perPage: 5,
                sort: { creationTime: false }
            }
        }, ...args);
    }
}
