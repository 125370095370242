import { TableStore } from "../../../../../components/Table";
import EnrollmentCertificatesModel from "./EnrollmentCertificatesModel";
import { JSAPIConnector } from "../../../../../init";

export class EnrollmentCertificatesStore extends TableStore.of(EnrollmentCertificatesModel) {
    load() {
        return JSAPIConnector.read(this.state).then((state) => this.setState(state));
    }
}

export default new EnrollmentCertificatesStore();
