import React from "react";
import { Component } from "../Component";

export class FormFooter extends Component {
    render() {
        const { children } = this.props;

        return (
            <footer className={ this.block() }>
                { children }
            </footer>
        );
    }
}
