import { Action } from "../../../../../utils/dataflow";
import { FormEditActions } from "../../../../../components/Form";

export class InfoActions extends FormEditActions {
    constructor() {
        super();

        Object.assign(this, {
            resetPassword: new Action
        });
    }
}

const Actions = new InfoActions();

export default Actions;
export const saveItem = Actions.saveItem.trigger;
export const setItem = Actions.setItem.trigger;
export const setItemField = Actions.setItemField.trigger;
export const setItemError = Actions.setItemError.trigger;
export const resetPassword = Actions.resetPassword.trigger;
