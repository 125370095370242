import React from "react";
import PropTypes from "prop-types";
import { Collection } from "../../utils/dataflow";

import { IntlModel } from "../../components/Intl";
import { TableFilterModel } from "../../components/Table";
import { Component } from "../Component";

import TablePagination from "./TablePagination";

export class TableHeader extends Component {
    render() {
        const { field, children, onClick } = this.props;
        const sortable = field !== null;

        let order;
        if (sortable) {
            const { filter: { sort } } = this.props;

            if (sort[field] !== void 0) {
                order = sort[field] ? "asc" : "desc";
            }
        }

        return (
            <th data-column={ field } className={ this.block({ sortable, order }) } onClick={ onClick }>
                { children }
            </th>
        );
    }
}

export default class Table extends Component {
    init(...args) {
        super.init(...args);

        this.onHeaderClick = this.onHeaderClick.bind(this);
        this.onPaginationChange = this.onPaginationChange.bind(this);
    }

    getColumnFromEvent({ currentTarget }) {
        return currentTarget.getAttribute("data-column");
    }

    renderRow() {
    }

    sortByColumn(column) {

    }

    onHeaderClick(event) {
        const column = this.getColumnFromEvent(event);

        if (column) {
            this.sortByColumn(column);
        }
    }

    renderHeadCell(message, field = null, props) {
        const { filter } = this.props;

        return (
            <TableHeader { ...{ field, filter } } className={ this.element(message) }
                onClick={ this.onHeaderClick } { ...props }>
                { this.formatMessage("column-" + message) }
            </TableHeader>
        );
    }

    renderHeadNum() {
        return this.renderHeadCell("num", null, { className: "table__num" });
    }

    renderHead() {
    }

    setPage() {
    }

    onPaginationChange(page) {
        this.setPage(page);
    }

    renderPagination() {
        const { intl, filter } = this.props;

        return <TablePagination { ...{ intl, filter } } onChange={ this.onPaginationChange } />;
    }

    renderRows() {
        const { items } = this.props;

        return items.map(this.renderRow.bind(this));
    }

    renderTable() {
        const { filter: { page, perPage }, className } = this.props;

        return (
            <table className={ this.cx("table", className||'') } style={ { counterReset: "rows " + (page * perPage) } }>
                <thead>
                { this.renderHead() }
                </thead>
                <tbody>
                { this.renderRows() }
                </tbody>
            </table>
        );
    }

    renderEmpty(message) {
        const { intl } = this.props;

        return (
            <div className="notice">
                { intl.formatMessage(message) }
            </div>
        );
    }

    render() {
        const { items: { length }, filter: { total, limit } } = this.props;
        let pagination;

        if (limit && length < total) {
            pagination = this.renderPagination();
        }

        return (
            <div className="table__container">
                { length === 0 ? this.renderEmpty("table-no-data") : this.renderTable() }
                { pagination }
            </div>
        );
    }
}

Table.initPropTypes({
    intl: PropTypes.instanceOf(IntlModel).isRequired,
    items: PropTypes.instanceOf(Collection).isRequired,
    filter: PropTypes.instanceOf(TableFilterModel).isRequired
});

Table.initDefaultProps({
    pagination: true
});
