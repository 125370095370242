import { ViewStore } from "../View";
import { APIConnector, MPSConnector } from "../../init";

export default class TableStore extends ViewStore {
    load() {
        const connector = window.localStorage['msb-company']==='mipasa' ? MPSConnector : APIConnector;
        return connector.read(this.state).then((state) => this.setState(state));
    }

    reset() {
        this.setState({ data: [] });
        return super.reset();
    }

    refresh() {
        return this.invoke("refresh", this.load());
    }

    setFilter(prop, value) {
        this.setState({ filter: { page: 0, [prop]: value } });

        return this.refresh();
    }

    setFilters(...filters) {
        this.setState({ filter: Object.assign({ page: 0 }, ...filters) });

        return this.refresh();
    }

    setSort(prop) {
        const { filter: { sort: currSort } } = this.state;
        const currValue = currSort && currSort[prop];

        this.setState(this.state.set({ filter: { sort: null } }).set({ filter: { sort: { [prop]: !currValue } } }));

        return this.refresh();
    }

    setLocalSort(prop) {
        const { filter: { sort: currSort }, item } = this.state;
        const currValue = !(currSort && currSort[prop]);

        this.setState(this.state.set({ filter: { sort: null } }).set({
            filter: { sort: { [prop]: currValue } },
            item: item.sort((i1, i2) => {
                const first = currValue ? i1 : i2;
                const second = currValue ? i2 : i1;

                return String(first[prop]).localeCompare(String(second[prop]));
            })
        }));
    }
}
