module.exports = {
    "settings-repositories-title": {
        "en-US": "Data Repositories"
    },
    "settings-repositories-menu-title": {
        "en-US": "Data Repositories"
    },

    "repositories-tool-bar-search": {
        "en-US": "Search Repositories"
    },

    "repositories-table-column-drag": {
        "en-US": ""
    },
    "repositories-table-column-num": {
        "en-US": "#"
    },
    "repositories-table-column-sort": {
        "en-US": ""
    },
    "repositories-table-column-name": {
        "en-US": "Repo Name"
    },

    "repositories-table-column-name-tooltip": {
        "en-US": `*{displayName}*
***
Address: **{host}:{port}**
User: {userName}
Group Filter: {groupfilter}
User filter: {userfilter}`
    },
    "repositories-table-column-repo-type": {
        "en-US": "Repo Type"
    },
    "repositories-table-column-update-frequency": {
        "en-US": "Update Frequency"
    },
    "repositories-table-column-last-synch": {
        "en-US": "Last Synch"
    },
    "repositories-table-column-actions": {
        "en-US": ""
    },
    "repositories-table-column-menu": {
        "en-US": ""
    },

    "settings-repositories-create": {
        "en-US": "Create"
    },
    "settings-repositories-edit": {
        "en-US": "Edit Repository"
    },
    "settings-repositories-delete": {
        "en-US": "Delete Repository"
    },
    "settings-repositories-import": {
        "en-US": "Import"
    },
    "settings-repositories-import-all": {
        "en-US": "Import All"
    },

    "settings-repository-delete-title": {
        "en-US": "Delete Repository"
    },
    "settings-repository-delete-message": {
        "en-US": "Are you sure you want to delete Repository **{host}**?"
    },
    "settings-repository-delete-failed-title": {
        "en-US": "Failed"
    },


    "settings-repository-import-title": {
        "en-US": "Import"
    },
    "settings-repository-import-message": {
        "en-US": "Are you sure you want to import *Users* and *Groups* from Repository **{host}**?"
    },
    "settings-repository-import-all-message": {
        "en-US": "Are you sure you want to import *Users* and *Groups* from all repositories?"
    },

    "settings-repository-import-success-title": {
        "en-US": "Success"
    },
    "settings-repository-import-success-message": {
        "en-US": "*{usersCount}* {usersCount, plural, =1 {User} other {Users}}"
        + " and *{groupsCount}* {groupsCount, plural, =1 {Group} other {Groups}}"
        + " successfully imported from **{host}**"
    },
    "settings-repository-import-success-all-message": {
        "en-US": "*{usersCount}* {usersCount, plural, =1 {User} other {Users}}"
        + " and *{groupsCount}* {groupsCount, plural, =1 {Group} other {Groups}}"
        + " successfully imported"
    },

    "settings-repository-import-failed-title": {
        "en-US": "Failed"
    },

    "settings-repository-create-title": {
        "en-US": "Add Repository"
    },
    "settings-repository-edit-title": {
        "en-US": "Edit Repository"
    },

    "settings-repository-edit-name-label": {
        "en-US": "Name"
    },
    "settings-repository-edit-name-placeholder": {
        "en-US": "Repo Name"
    },

    "settings-repository-edit-type-label": {
        "en-US": "Type"
    },
    "settings-repository-edit-type-placeholder": {
        "en-US": "Repo Type"
    },

    "settings-repository-edit-host-port-label": {
        "en-US": "Address"
    },
    "settings-repository-edit-host-placeholder": {
        "en-US": "Host"
    },
    "settings-repository-edit-port-placeholder": {
        "en-US": "389"
    },

    "settings-repository-edit-secure-label": {
        "en-US": ""
    },
    "settings-repository-edit-secure-placeholder": {
        "en-US": "Connect over SSL"
    },

    "settings-repository-edit-certificate-placeholder": {
        "en-US": "SSL Certificate..."
    },
    "settings-repository-edit-remove-certificate": {
        "en-US": "Remove"
    },

    "settings-repository-edit-credentials-label": {
        "en-US": "Credentials"
    },
    "settings-repository-edit-username-placeholder": {
        "en-US": "User Name"
    },
    "settings-repository-edit-password-placeholder": {
        "en-US": "Password"
    },

    "settings-repository-edit-base-label": {
        "en-US": "Base"
    },
    "settings-repository-edit-base-placeholder": {
        "en-US": "Base DN"
    },

    "settings-repository-edit-userfilter-label": {
        "en-US": "User Filter"
    },
    "settings-repository-edit-userfilter-placeholder": {
        "en-US": "Users DN"
    },

    "settings-repository-edit-groupfilter-label": {
        "en-US": "Group Filter"
    },
    "settings-repository-edit-groupfilter-placeholder": {
        "en-US": "Groups DN"
    },
    "settings-repository-edit-update-frequency-label": {
        "en-US": "Update Frequency"
    },
    "settings-repository-edit-update-frequency-placeholder": {
        "en-US": ""
    },
    "settings-repository-edit-test-connection": {
        "en-US": "Test"
    },


    "settings-repository-success-title": {
        "en-US": "Connection success"
    },
    "settings-repository-success-message": {
        "en-US": "Successfully connected"
    },
    "settings-repository-error-title": {
        "en-US": "Connection error"
    },
    "settings-repository-error-message": {
        "en-US": "Server is not reachable"
    },
    "settings-repository-error-save-anyway": {
        "en-US": "Save anyway"
    },
    "settings-repository-error-cancel": {
        "en-US": "Back to form"
    },

    "error-repository-name-exists": {
        "en-US": "Repository already exists"
    },

    "settings-repositories-loading": {
        "en-US": "Loading..."
    }
};
