import React from "react";

import { IntlStore, AuthStore } from "../../../../../init";
import { Collection } from "../../../../../utils/dataflow";
import {
    Component,
    GenericForm,
    FormField,
    FormInput,
    FormFile,
    READ_FILE_AS,
    FormPassword,
    SelectPhoneCode,
    Select,
    FormPhone,
    COUNTRIES,
    Avatar
} from "../../../../../ui";

import { setTitle } from "../../../../../components/Header";

import InfoStore from "./InfoStore";
import { setItem, setItemField, setItemError, resetPassword } from "./InfoActions";

const LANGS = new Collection([
    {
        "id": "en-US",
        "name": "English"
    }
]);

function onFieldChange(value, name) {
    if (name) {
        setItemField(name, value);
    }
}

function onCountryChange(value) {
    setItemField("country", value);
}

function onAvatarError(error) {
    setItemError("avatar", error);
}

function onClearAvatarClick() {
    setItemField("avatar", "");
}

class ProfileInfo extends Component {
    init(...args) {
        super.init(...args);

        this.onSubmit = this.onSubmit.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }

    componentWillMount() {
        const { intl, auth: { user } } = this.props;

        setTitle(intl.formatMessage("settings-profile-title")
            + " - " + intl.formatMessage("settings-profile-info-title"));

        setItem(new InfoStore.Model.Item({
            firstName: user.name,
            lastName: user.lastname || "",
            organization: user.company
        }));
    }

    close() {
        setItem(new InfoStore.Model.Item);
        // this.props.router.replace("/msb/settings/preferences/password/success");
    }

    onSuccess() {
        this.close();
    }

    onSubmit() {
        const { data: { item } } = this.props;

        resetPassword(item).then(() => this.onSuccess()).catch(() => void 0);
    }

    onCancel() {
        this.close();
    }

    render() {
        const { intl, data: { busy, error, item } } = this.props;

        if (!item) {
            return null;
        }

        return (
            <GenericForm { ...{ intl, busy, error } }
                close={ false } header={ false } cancel={ false }
                className={ this.block() }
                onSubmit={ () => {} } onCancel={ this.onCancel }>

                <FormField name="avatar"
                    label={ intl.formatMessage("settings-profile-info-avatar-label") }>

                    <Avatar image={ item.avatar } name={ item.firstName + " " + item.lastName } />
                    <FormFile type="file" name="avatar" value={ item.avatar }
                        accept="image/jpeg,image/png,image/gif"
                        placeholder={ intl.formatMessage("settings-profile-info-avatar-placeholder") }
                        as={ READ_FILE_AS.DataURL }
                        onChange={ onFieldChange } onError={ onAvatarError } />
                    <a className={ "link link_crear-avatar" + (item.avatar ? "" : " link_disabled") }
                        onClick={ onClearAvatarClick }>
                        { intl.formatMessage("settings-profile-info-remove-avatar") }
                    </a>
                </FormField>

                <FormField name="firstName"
                    label={ intl.formatMessage("settings-profile-info-first-name-label") }>
                    <FormInput name="firstName" value={ item.firstName }
                        onChange={ onFieldChange } />
                </FormField>

                <FormField name="lastName"
                    label={ intl.formatMessage("settings-profile-info-last-name-label") }>
                    <FormInput name="lastName" value={ item.lastName }
                        onChange={ onFieldChange } />
                </FormField>

                <FormField name="city"
                    label={ intl.formatMessage("settings-profile-info-city-label") }>
                    <FormInput name="city" value={ item.city }
                        onChange={ onFieldChange } />
                </FormField>

                <FormField name="organization"
                    label={ intl.formatMessage("settings-profile-info-organization-label") }>
                    <FormInput name="organization" value={ item.organization }
                        onChange={ onFieldChange } />
                </FormField>

                <FormField name="email" label={ intl.formatMessage("settings-profile-info-email-label") }>
                    <FormInput type="text" name="email" value={ item.email }
                        placeholder={ intl.formatMessage("settings-profile-info-email-placeholder") }
                        onChange={ onFieldChange } />

                    <a className="link link_save-phone link_disabled">
                        { intl.formatMessage("settings-profile-info-save-email") }
                    </a>
                </FormField>

                <FormField name="country phone" label={ intl.formatMessage("settings-profile-info-phone-label") }>
                    <SelectPhoneCode name="country" value={ item.country } nullValue={ false }
                        placeholder={ intl.formatMessage("settings-profile-info-country-placeholder") }
                        onChange={ onCountryChange } />
                    <FormPhone name="phone" country={ COUNTRIES.value(item.country) } value={ item.phone }
                        placeholder={ intl.formatMessage("settings-profile-info-phone-placeholder") }
                        onChange={ onFieldChange } />

                    <a className="link link_save-phone link_disabled">
                        { intl.formatMessage("settings-profile-info-save-phone") }
                    </a>
                </FormField>

                <FormField name="lang"
                    label={ intl.formatMessage("settings-profile-info-lang-label") }>
                    <Select name="lang" items={ LANGS } value={ item.lang }
                        onChange={ () => {} } search={ false } nullValue={ false } />
                </FormField>


            </GenericForm >
        );
    }
}

export default ProfileInfo.connect({ intl: IntlStore, data: InfoStore, auth: AuthStore });
