import React from "react";
import PropTypes from "prop-types";

import { Component } from "./Component";
import { FormField, FormFile, READ_FILE_AS } from "./Form";

const BEGIN_CERTIFICATE = "-----BEGIN CERTIFICATE";

function arrayBufferToString(buffer) {
    const byteView = new Uint8Array(buffer);

    return Array.from(byteView).map((byte) => String.fromCharCode(byte)).join("");
}

export class CertificateField extends Component {
    init(...args) {
        super.init(...args);

        this.onChange = this.onChange.bind(this);
        this.onClearClick = this.onClearClick.bind(this);
    }

    onChange(buffer) {
        const { onChange } = this.props;

        let value = arrayBufferToString(buffer);
        if (value.length && value.indexOf(BEGIN_CERTIFICATE) === -1) {
            value = window.btoa(value);
        }

        if (onChange) {
            onChange(value);
        }
    }

    onClearClick() {
        this.onChange(Buffer.from(""));
    }

    render() {
        const { name, value, label, clearLabel, placeholder, error, onError } = this.props;

        return (
            <FormField { ...{ name, label, error } }>
                <FormFile type="file" name="pem" value={ value }
                    accept=".pem, .der" maxSize={ 1048576 } as={ READ_FILE_AS.Buffer }
                    placeholder={ placeholder }
                    onChange={ this.onChange } onError={ onError } />
                <a className={ "link" + (value ? "" : " link_disabled") }
                    onClick={ this.onClearClick }>
                    { clearLabel }
                </a>
            </FormField>
        );
    }
}

CertificateField.initProps({
    name: { type: PropTypes.string.isRequired }
});
