import { FormModel } from "../../../../../components/Form";
import { Entity } from "../../../../../model";

const DEFAULTS = {
    password: "",
    nextPassword: "",
    confirmPassword: ""
};

export class Profile extends Entity {
    init(...args) {
        super.init(DEFAULTS, ...args);
    }

    getError(message, field) {
        return super.getError(`${this.constructor.name} ${field} ${message}`, field);
    }

    validate() {
        const { password, nextPassword, confirmPassword } = this;

        return super.validate()
            || (password.trim() === "" && this.getError("empty", "password"))
            || (nextPassword.trim() === "" && this.getError("empty", "nextPassword"))
            || (nextPassword !== confirmPassword && this.getError("mismatch", "confirmPassword"))
            || void 0;
    }
}

export default class PasswordModel extends FormModel.of(Profile) {
    init(...args) {
        super.init({ item: {} }, ...args);
    }
}
