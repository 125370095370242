import contains from "./contains";

/**
 * Checks whether the given element is equal to another one or contains it.
 *
 * @type {Function}
 * @param {HTMLElement} el
 * @param {HTMLElement} childEl
 * @return {boolean}
 */
export default function containsOrSelf(el, childEl) {
    return el === childEl || contains(el, childEl);
}
