module.exports = {
    "settings-profile-info-title": {
        "en-US": "Info"
    },

    "settings-profile-info-avatar-label": {
        "en-US": "Avatar"
    },
    "settings-profile-info-avatar-placeholder": {
        "en-US": "Upload a new avatar"
    },
    "settings-profile-info-remove-avatar": {
        "en-US": "Clear"
    },

    "settings-profile-info-first-name-label": {
        "en-US": "First name"
    },
    "settings-profile-info-last-name-label": {
        "en-US": "Last name"
    },
    "settings-profile-info-city-label": {
        "en-US": "Town"
    },

    "settings-profile-info-organization-label": {
        "en-US": "Company"
    },

    "settings-profile-info-email-label": {
        "en-US": "Email"
    },
    "settings-profile-info-email-placeholder": {
        "en-US": ""
    },
    "settings-profile-info-save-email": {
        "en-US": "Change"
    },

    "settings-profile-info-phone-label": {
        "en-US": "Phone"
    },
    "settings-profile-info-phone-placeholder": {
        "en-US": "333.333.4444"
    },
    "settings-profile-info-save-phone": {
        "en-US": "Change"
    },

    "settings-profile-info-lang-label": {
        "en-US": "Language"
    },


    "settings-profile-info-success-title": {
        "en-US": "Reset Password"
    },
    "settings-profile-info-success-message": {
        "en-US": "Your password is successfully updated"
    }
};
