import { Entity } from "./Entity";

const defaults = {
    firstName: "",
    lastName: "",
    userName: "",
    password: "",
    email: "",
    country: "US",
    phone: ""
};

export class RegistrationUser extends Entity {
    getError(message, field) {
        return super.getError(`${this.constructor.name} ${field} ${message}`, field);
    }

    init(...args) {
        super.init(defaults, ...args);
    }

    validate() {
        return super.validate()
            || this.validateFirstName()
            || this.validateLastName()
            || this.validateUsername()
            || this.validateEmail()
            || this.validatePassword()
            || this.validatePhone()
            || void 0;
    }

    validateEmail() {
        const { email } = this;

        return (email.trim() === "" && this.getError("empty", "email"))
            || (email.indexOf("@") === -1 && this.getError("invalid", "email"));
    }

    validateFirstName() {
        const { firstName } = this;

        return firstName.trim() === "" && this.getError("empty", "firstName");
    }

    validateLastName() {
        const { lastName } = this;

        return lastName.trim() === "" && this.getError("empty", "lastName");
    }

    validatePassword() {
        const { password } = this;

        return password.trim() === "" && this.getError("empty", "password");
    }

    validatePhone() {
        const { phone } = this;
        const number = phone.trim().replace(/^\+\d+\s+/, "");

        return (number === "" && this.getError("empty", "phone"))
            || (!/^\d+(?:[ -\.]\d+)*$/i.test(number) && this.getError("invalid", "phone"));
    }

    validateUsername() {
        const { userName } = this;

        return (userName.trim() === "" && this.getError("empty", "userName"))
            || (!/^[a-z0-9\-_]+$/i.test(userName) && this.getError("invalid", "userName"))
            || (userName.length < 6 && this.getError("too short", "userName"))
            || (userName.length > 50 && this.getError("too long", "userName"));
    }
}
