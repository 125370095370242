import { Action } from "../../../../utils/dataflow";

export class UserGroupsActions {
    constructor() {
        Object.assign(this, {
            setUserGroups: new Action
        });
    }
}

export default new UserGroupsActions();
