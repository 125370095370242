module.exports ={
    "activity-historical-menu-title": {
        "en-US": "Historical"
    },

    "historical-title": {
        "en-US": "Historical"
    },

    "historical-tool-bar-users": {
        "en-US": "Users: All"
    },
    "historical-tool-bar-groups": {
        "en-US": "Groups: All"
    },
    "historical-tool-bar-permissions": {
        "en-US": "Permissions: All"
    },
    "historical-tool-bar-search": {
        "en-US": "Search"
    },
    "historical-tool-bar-export-to": {
        "en-US": "Export to:"
    },
    "historical-tool-bar-dates-range": {
        "en-US": "Date Range"
    },

    "historical-table-column-num": {
        "en-US": "#"
    },
    "historical-table-column-id": {
        "en-US": "UUID"
    },
    "historical-table-column-user": {
        "en-US": "User"
    },
    "historical-table-column-affiliation": {
        "en-US": "Affiliation"
    },
    "historical-table-column-order-type": {
        "en-US": "Order"
    },
    "historical-table-column-stock": {
        "en-US": "Stock"
    },
    "historical-table-column-type": {
        "en-US": "Transaction Type"
    },
    "historical-table-column-units": {
        "en-US": "Units"
    },
    "historical-table-column-price": {
        "en-US": "Price"
    },
    "historical-table-column-ttl": {
        "en-US": "TTL"
    },
    "historical-table-column-date": {
        "en-US": "Date and Time"
    },

    "activity-historical-credit-utilization": {
        "en-US": "Credit Utilization"
    },
    "activity-historical-filter-users": {
        "en-US": "Users: All"
    },
    "activity-historical-filter-groups": {
        "en-US": "Groups: All"
    },
    "activity-historical-filter-permissions": {
        "en-US": "Permissions: All"
    },
    "historical-message-no-data": {
        "en-US": "Found no data matching your filters"
    }
};
