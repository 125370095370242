import React from "react";

import { Component } from "../../ui/Component";
import { UserMenu } from "../../ui";

import { IntlStore, AuthStore } from "../../init";

export class UserInfo extends Component {
    render() {
        const { intl, auth: { user } } = this.props;

        return user ? <UserMenu { ...{ intl, user } } /> : null;
    }
}

export default UserInfo.connect({ intl: IntlStore, auth: AuthStore });
