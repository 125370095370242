import React from "react";
import { Route } from "react-router";

import Groups from "./Groups";
import GroupEdit from "./GroupEdit";
import GroupDelete from "./GroupDelete";

export default function route(path) {
    return (
        <Route path={ path } component={ Groups }>
            <Route path="create" component={ GroupEdit } />
            <Route path=":itemId/edit" component={ GroupEdit } />
            <Route path=":itemId/delete" component={ GroupDelete } />
        </Route>
    );
};
