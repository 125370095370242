module.exports = {
    "permissions-title": {
        "en-US": "Permission Management"
    },
    "manage-permissions-menu-title": {
        "en-US": "Permissions"
    },

    "permissions-tool-bar-search": {
        "en-US": "Search"
    },

    "permissions-table-column-num": {
        "en-US": "#"
    },
    "permissions-table-column-name": {
        "en-US": "Group Name"
    },
    "permissions-table-column-permissions": {
        "en-US": "Permissions"
    },
    "permissions-table-column-actions": {
        "en-US": ""
    },

    "manage-permissions-create": {
        "en-US": "Grant Permission"
    },
    "manage-permissions-edit": {
        "en-US": "Edit Group"
    },
    "manage-permissions-delete": {
        "en-US": "Delete Group"
    },


    "manage-permission-delete-title": {
        "en-US": "Delete Group"
    },
    "manage-permission-delete-message": {
        "en-US": "Are you sure you want to delete Group **{name}**?"
    },
    "manage-permission-delete-failed-title": {
        "en-US": "Failed"
    },


    "manage-permission-create-title": {
        "en-US": "Grant Permission"
    },
    "manage-permission-edit-title": {
        "en-US": "Edit Group"
    },
    "manage-permission-edit-name-label": {
        "en-US": "Group Name"
    },
    "manage-permission-edit-name-placeholder": {
        "en-US": ""
    },
    "manage-permission-edit-parent-label": {
        "en-US": "Parent Group"
    },
    "manage-permission-edit-parent-placeholder": {
        "en-US": ""
    },
    "manage-permission-edit-parent-unselected": {
        "en-US": "Not selected"
    },
    "manage-permission-edit-permissions-label": {
        "en-US": "Permissions"
    },
    "manage-permission-edit-permissions-placeholder": {
        "en-US": "Not Selected"
    },

    "manage-permissions-loading": {
        "en-US": "Loading..."
    }
};
