import { schemeCategory20c } from "d3-scale";
import React from "react";
import { Component, Label, Link, PieChart, Select, SideBar, ToolBar, Widget } from "../../../../ui";

import { Collection } from "../../../../utils/dataflow";

function formatPercent(intl, value) {
    return intl.formatNumber(Math.round(value * 100) / 100) + "%";
}

class TransactionsPieChartLegend extends Component {
    render() {
        const { data, labels, colors } = this.props;

        return (
            <table className="table table_legend">
                <tbody>
                {
                    data.map((item, index) => (
                        <tr key={ index } style={ { color: colors[index] } }>
                            <td>&#8226;</td>
                            <td>{ item.label }</td>
                            <td>{ labels[index] }</td>
                        </tr>
                    ))
                }
                </tbody>
            </table>
        );
    }
}

class TransactionsPieChart extends PieChart {
    getLabel(item, index) {
        const { labels } = this.props;

        return labels[index];
    }

    getColor(item, index) {
        const { colors } = this.props;

        return colors[index];
    }
}

class TransactionsPieChartWidget extends Component {
    renderPieChart() {
        const { data, colors, labels } = this.props;

        return (
            <TransactionsPieChart { ...{ data, labels, colors } }
                width={ 247 } height={ 190 } radius={ 90 } innerRadius={ 35 } />
        );
    }

    renderLegend() {
        const { data, colors, labels } = this.props;

        return (
            <TransactionsPieChartLegend { ...{ data, labels, colors } } />
        );
    }

    render() {
        const { title, toolBar } = this.props;

        return (
            <Widget { ...{ title, toolBar } }>
                { this.renderPieChart() }
                { this.renderLegend() }
            </Widget>
        );
    }
}

class TransactionsStocksAllocationWidget extends Component {
    render() {
        const { intl, title, items: { stocksAllocation: data, stocksAllocationTotal: total } } = this.props;
        const colors = data.map((item) => item.color);
        const labels = data.map((item) => formatPercent(intl, item.value / total * 100));

        return (
            <TransactionsPieChartWidget { ...{ title, data, colors, labels } } />
        );
    }
}

const aggregations = new Collection([
    { id: 5, name: "< 5%" },
    { id: 10, name: "< 10%" },
    { id: 20, name: "< 20%" }
]);

class TransactionsUsersAllocationWidget extends Component {
    init(...args) {
        super.init(...args);

        this.initState({ aggregate: 10 });
        this.onAggregateChange = this.onAggregateChange.bind(this);
    }

    onAggregateChange(aggregate) {
        this.setState({ aggregate });
    }

    renderToolBar() {
        const { intl } = this.props;
        const { aggregate } = this.state;

        return (
            <ToolBar className={ this.block() }>
                <Label>{ this.formatMessage("aggregate-label") }</Label>
                <Select { ...{ intl, items: aggregations } } value={ aggregate }
                    placeholder={ this.formatMessage("aggregate-placeholder") }
                    search={ false } nullValue={ this.formatMessage("aggregate-null") }
                    className={ this.element("select") }
                    dropClassName={ this.element("select-drop") }
                    onChange={ this.onAggregateChange } />
            </ToolBar>
        );
    }

    getData() {
        const { intl, items: { usersAllocation, usersAllocationTotal: total } } = this.props;
        const { aggregate } = this.state;

        const data = usersAllocation.filter((item) => item.value / total >= (aggregate || 0) / 100);
        const colors = data.map((item, index) => schemeCategory20c[index]);
        if (data.length < usersAllocation.length) {
            colors.push("#ccc");
            data.push({
                label: this.formatMessage("allocation-other"),
                value: total - data.reduce((v, item) => v + item.value, 0)
            });
        }

        return { data, colors, labels: data.map((item) => formatPercent(intl, item.value / total * 100)) };
    }

    render() {
        const { title } = this.props;

        return (
            <TransactionsPieChartWidget { ...{ title } } { ...this.getData() } toolBar={ this.renderToolBar() } />
        );
    }
}

export class TransactionsSideBarActions extends Component {
    render() {
        return (
            <ToolBar className={ this.block() }>
                <Label>{ this.formatMessage("label") }</Label>
                <Link to="csv" className="ico ico-csv" data-tooltip="CSV" />
                <Link to="excel" className="ico ico-xls" data-tooltip="Excel" />
                <Link to="pdf" className="ico ico-pdf" data-tooltip="PDF" />
            </ToolBar>
        );
    }
}

export class TransactionsSideBar extends Component {
    renderToolBar() {
        const { intl } = this.props;

        return (
            <TransactionsSideBarActions { ...{ intl } } />
        );
    }

    render() {
        const { intl, items } = this.props;

        return (
            <SideBar toolBar={ this.renderToolBar() } className={ this.block() }>
                {
                    items.length !== 0
                        ? <TransactionsStocksAllocationWidget { ...{ intl, items } }
                            title={ this.formatMessage("stock-allocation") } /> : null
                }
                {
                    items.length !== 0
                        ? <TransactionsUsersAllocationWidget { ...{ intl, items, maxItems: 10 } }
                            title={ this.formatMessage("user-allocation") } /> : null
                }
            </SideBar>
        );
    }
}
