module.exports = {
    "groups-title": {
        "en-US": "Group Management"
    },
    "manage-groups-menu-title": {
        "en-US": "Groups"
    },

    "groups-tool-bar-search": {
        "en-US": "Search"
    },

    "groups-table-column-num": {
        "en-US": "#"
    },
    "groups-table-column-name": {
        "en-US": "Group Name"
    },
    "groups-table-column-permissions": {
        "en-US": "Permissions"
    },
    "groups-table-column-actions": {
        "en-US": ""
    },

    "manage-groups-create": {
        "en-US": "New Group"
    },
    "manage-groups-edit": {
        "en-US": "Edit Group"
    },
    "manage-groups-delete": {
        "en-US": "Delete Group"
    },


    "manage-group-delete-title": {
        "en-US": "Delete Group"
    },
    "manage-group-delete-message": {
        "en-US": "Are you sure you want to delete Group **{name}**?"
    },
    "manage-group-delete-failed-title": {
        "en-US": "Failed"
    },


    "group-edit-title": {
        "en-US": "Edit Group"
    },
    "group-edit-create-title": {
        "en-US": "New Group"
    },

    "group-edit-name-label": {
        "en-US": "Group Name"
    },
    "group-edit-name-placeholder": {
        "en-US": ""
    },
    "group-edit-parent-label": {
        "en-US": "Parent Group"
    },
    "group-edit-parent-placeholder": {
        "en-US": ""
    },
    "group-edit-parent-unselected": {
        "en-US": "Not selected"
    },
    "group-edit-permissions-label": {
        "en-US": "Permissions"
    },
    "group-edit-permissions-placeholder": {
        "en-US": "Not Selected"
    },

    "manage-groups-loading": {
        "en-US": "Loading..."
    }
};
