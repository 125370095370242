import { APIConnector } from "../../init";
import { FormEditStore } from "../../components/Form";

import ResetPasswordModel from "./ResetPasswordModel";
import ResetPasswordActions from "./ResetPasswordActions";

class ResetPasswordStore extends FormEditStore.of(ResetPasswordModel) {
    processError(error) {
        error = super.processError(error);

        if (!error.field) {
            error = Object.assign(error, { code: "error-invalid-secure-code" });
        }

        return error;
    }

    reset(item, secure) {
        const url = APIConnector.buildUrl("UserCredentials/reset");

        return APIConnector.fetch(url, {
            method: "POST",
            body: APIConnector.stringify({ password: item.password, secure })
        }).then((data) => APIConnector.response(data));
    }

    resetPassword(item, secure) {
        const error = item.validate();

        return this.invoke("reset", error ? Promise.reject(error) : this.reset(item, secure));
    }
}

export default new ResetPasswordStore().connect(ResetPasswordActions);
