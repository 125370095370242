import React from "react";
import { Route } from "react-router";

import Certificates from "./Certificates";
import CertificateEdit from "./CertificateEdit";
import CertificateDelete from "./CertificateDelete";

export default function route(path) {
    return (
        <Route path={ path } component={ Certificates }>
            <Route path="create" component={ CertificateEdit } />
            <Route path=":itemId/edit" component={ CertificateEdit } />
            <Route path=":itemId/delete" component={ CertificateDelete } />
        </Route>
    );
};
