module.exports = {
    "error-user-not-found": {
        "en-US": "Incorrect Username / Password"
    },
    "error-incorrect-password": {
        "en-US": "Incorrect Username / Password"
    },
    "error-not-found": {
        "en-US": "Internal error"
    },
    "error-email-not-found": {
        "en-US": "Can't find that Email / Username."
    },
    "error-internal-server-error": {
        "en-US": "Internal error"
    },
    "error-invalid-secure-code": {
        "en-US": "Invalid link, please follow the link in the message by clicking it"
    },
    "error-child-contains-parent": {
        "en-US": "Group cannot be parent of itself"
    },
    "ssl-certificate-max-size-exceeded": {
        "en-US": "The provided file exceeds certificate size limit (100kb)"
    },
    "error-ssl-certificate-max-size-exceeded": {
        "en-US": "The provided file exceeds certificate size limit (100kb)"
    },
    "error-user-registration-not-confirmed": {
        "en-US": "Registration not confirmed"
    },

    "error-invalid-name": {
        "en-US": "Invalid name"
    },
    "error-empty-username": {
        "en-US": "Username is required"
    },
    "error-empty-password": {
        "en-US": "Password is required"
    },
    "error-empty-email": {
        "en-US": "Email is required"
    },
    "error-invalid-email": {
        "en-US": "Please include an '@' in the address"
    },

    "error-empty-ldap-config-name": {
        "en-US": "Name is required"
    },
    "error-empty-ldap-config-host": {
        "en-US": "Host is required"
    },
    "error-empty-ldap-config-port": {
        "en-US": "Port is required"
    },
    "error-invalid-ldap-config-port": {
        "en-US": "Invalid Port"
    },
    "error-failed-to-connect": {
        "en-US": "Failed not connect"
    },

    "error-empty-login-username": {
        "en-US": "Username is required"
    },
    "error-empty-login-password": {
        "en-US": "Password is required"
    }
};
