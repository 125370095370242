import { TableStore } from "../../../../../components/Table";
import CertificatesModel from "./CertificatesModel";

export class CertificatesStore extends TableStore.of(CertificatesModel) {
    setSort(prop) {
        return this.setLocalSort(prop);
    }
}

export default new CertificatesStore();
