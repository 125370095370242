import aggregate from "./aggregate";
import { filter } from "./object";

function getName(mod) {
    return typeof mod === "function" ? mod.name : String(mod);
}

function toString(entity, name, ...mods) {
    return "[" + [entity, name, ...(mods.length ? ["<" + mods.map(getName).join(", ") + ">"] : mods)].join(" ") + "]";
}

const $mixins = (...mixins) => () => mixins;

function define(target, props) {
    Object.keys(props).forEach((name) => Object.defineProperty(target, name, { value: props[name], writable: true }));

    return target;
}

function defineOnce(target, props) {
    return define(target, filter(props, (prop) => !target.hasOwnProperty(prop)));
}

export default class Base {
    constructor(...args) {
        this.init(...args);
    }

    init() {}

    define(props) {
        return define(this, props);
    }

    defineOnce(props) {
        return defineOnce(this, props);
    }

    bindTo(method, ...args) {
        let name;

        if (typeof method !== "function" || method.name === "") {
            throw this.getError("#bindTo: named function expected");
        }

        name = method.name;
        if (!this.hasOwnProperty(name)) {
            this[name] = method.bind(this, ...args);
        }

        return this[name];
    }

    getError(desc) {
        return new Error(`${this}: ${desc}`);
    }

    toString(...mods) {
        return toString("object", this.constructor.name, ...mods);
    }

    static $mixins() {
        return [];
    }

    static define(props) {
        return define(this, props);
    }

    static defineOnce(props) {
        return defineOnce(this, props);
    }

    static toString(...mods) {
        return toString("class", this.name, ...mods);
    }

    static aggregate(...mixins) {
        class Aggregation extends this {
        }

        return Object.assign(aggregate(Aggregation, ...mixins), {
            $mixins: $mixins(...this.$mixins(), ...mixins)
        });
    }

    static getError(desc, Type = Error) {
        return new Type(`${this}: ${desc}`);
    }

    static isA(target) {
        return target instanceof this;
    }

    static is(Class) {
        return Class === this || (typeof Class === "function" && (Class.prototype instanceof this));
    }
}
