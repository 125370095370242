import { Base } from "..";

export default class Publisher extends Base {
    init(...args) {
        this.define({ listeners: [] });
    }

    unlisten(handler) {
        this.define({ listeners: this.listeners.filter((listener) => listener !== handler) });
    }

    listen(handler) {
        if (typeof(handler) !== "function") {
            throw this.getError("function expected");
        }

        this.listeners.push(handler);

        return this.unlisten.bind(this, handler);
    }

    trigger(...data) {
        return Promise.all(this.listeners.map((listener) => Promise.resolve(listener(...data))));
    }
}
