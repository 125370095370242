import { setTitle } from "../../../../../components/Header";
import { View } from "../../../../../components/View";

import { AuthStore } from "../../../../../init";
import React from "react";
import { Main, Tree } from "../../../../../ui";

import AffiliationsActions from "./AffiliationsActions";
import AffiliationsStore from "./AffiliationsStore";

export class Affiliations extends View {
    componentWillMount() {
        setTitle(this.formatMessage("title"));
        super.componentWillMount();
    }

    getData() {
        const { data: { item: groups }, auth: { session } } = this.props;
        const user = session.user.set({ groups: session.groups });

        const userGroups = groups.getDescendants(user.groups).map(({ id, groupId, name }, index, userGroups) => ({
            id,
            parentId: userGroups.contains(groupId) ? groupId : "root",
            name: name.split(".").shift(),
            icon: "/images/groups.svg"
        }));

        return userGroups.length
            ? userGroups.concat({ id: "root", name: user.name, icon: "/images/users.svg" })
            : userGroups;
    }

    render() {
        const items = this.getData();

        return (
            <Main status={ this.renderStatus() } className={ this.block() }>
                <Tree { ...{ items } } />
            </Main>
        );
    }
}

export default Affiliations.of(AffiliationsStore, AffiliationsActions).connect({ auth: AuthStore });
