import React from "react";

import { TableView } from "../../../../components/Table";
import { TransactionsSideBar } from "./TransactionsSideBar";

export class Transactions extends TableView.timerOwner() {
    get refreshTimeout() {
        return 2000;
    }

    refresh() {
        super.refresh().then(() => this.setRefreshTimeout());
    }

    setRefreshTimeout() {
        this.setTimeout(() => this.refresh(), this.refreshTimeout);
    }

    renderSideBar() {
        const { intl, data: { filter, item: items } } = this.props;

        return items.length ? <TransactionsSideBar { ...{ intl, filter, items } } /> : null;
    }
}
