import { ViewModel } from "../../../../components/View";
import { Entity, NetworkConfigs, NetworkRequests } from "../../../../model";

class HaceraNetworks extends Entity {
    init(...args) {
        super.init({ networks: [], configs: [] }, ...args);
    }
}

HaceraNetworks.connect({
    networks: NetworkRequests,
    configs: NetworkConfigs
});

export default class HaceraNetworksModel extends ViewModel.of(HaceraNetworks) {
    init(...args) {
        super.init({ item: { networks: [], configs: [] } }, ...args);
    }
}
