import { Action } from "../../../../../utils/dataflow";
import { FormEditActions } from "../../../../../components/Form";

export class CSRCreateActions extends FormEditActions {
    constructor(...args) {
        super(...args);

        Object.assign(this, {
            loadDefault: new Action
        });
    }
}

const Actions = new CSRCreateActions();

export default Actions;
export const saveItem = Actions.saveItem.trigger;
export const setItem = Actions.setItem.trigger;
export const setItemField = Actions.setItemField.trigger;
export const setItemError = Actions.setItemError.trigger;
export const loadDefault = Actions.loadDefault.trigger;
