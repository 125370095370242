import React from "react";
import moment from "moment";

import { Info, InfoBlock } from "./Info";
import { ScrollArea } from "../ScrollArea";

export class Certificate extends Info {
    renderCommon() {
        const { item: { id, creationTime, attributes: { NotAfter, SerialNumber, PublicKey } } } = this.props;

        return (
            <InfoBlock title={ this.renderTitle("common") } className={ this.element("common") }>
                { this.renderRow("issued", moment(creationTime).format("MMM DD, YYYY")) }
                { this.renderRow("expires", moment(NotAfter).format("MMM DD, YYYY")) }

                { this.renderTitle("ssl") }
                { this.renderRow("name", id) }
                { this.renderRow("brand", "-") }
                { this.renderRow("type", "-") }
                { this.renderRow("serial", SerialNumber) }
                { this.renderRow("key-size", PublicKey.BitSize||'-') }
            </InfoBlock>
        );
    }

    renderAttributes() {
        return (
            <InfoBlock title={ this.renderTitle("attributes") } className={ this.element("attributes") }>
                { this.renderLabel("common-name") }
                { this.renderLabel("san") }
                { this.renderLabel("organization") }
                { this.renderLabel("organization-unit") }
                { this.renderLabel("country") }
                { this.renderLabel("state") }
                { this.renderLabel("city") }
                { this.renderLabel("address") }
                { this.renderLabel("postal-code") }
            </InfoBlock>
        );
    }

    renderSubject() {
        const { item: { attributes: { Subject } } } = this.props;

        return (
            <InfoBlock title={ this.renderTitle("subject") } className={ this.element("subject") }>
                { this.renderValue(Subject.CommonName || "-") }
                { this.renderValue(Subject.SerialNumber || "-") }
                { this.renderValue(Subject.Organization || "-") }
                { this.renderValue(Subject.OrganizationalUnit || "-") }
                { this.renderValue(Subject.Country || "-") }
                { this.renderValue(Subject.Province || "-") }
                { this.renderValue(Subject.Locality || "-") }
                { this.renderValue(Subject.StreetAddress || "-") }
                { this.renderValue(Subject.PostalCode || "-") }
            </InfoBlock>
        );
    }

    renderIssuer() {
        const { item: { attributes: { Issuer } } } = this.props;

        return (
            <InfoBlock title={ this.renderTitle("issuer") } className={ this.element("issuer") }>
                { this.renderValue(Issuer.CommonName || "-") }
                { this.renderValue(Issuer.SerialNumber || "-") }
                { this.renderValue(Issuer.Organization || "-") }
                { this.renderValue(Issuer.OrganizationalUnit || "-") }
                { this.renderValue(Issuer.Country || "-") }
                { this.renderValue(Issuer.Province || "-") }
                { this.renderValue(Issuer.Locality || "-") }
                { this.renderValue(Issuer.StreetAddress || "-") }
                { this.renderValue(Issuer.PostalCode || "-") }
            </InfoBlock>
        );
    }

    renderAdvanced() {
        const { item: { pem, attributes } } = this.props;

        return (
            <InfoBlock title={ this.renderTitle("advanced") } className={ this.element("advanced") }>
                { this.renderRow("fingerprint-sha1", attributes["Fingerprint (MD5)"]) }
                { this.renderRow("fingerprint-md5", attributes["Fingerprint (SHA-1)"]) }

                { this.renderTitle("raw") }
                <ScrollArea>
                    { pem }
                </ScrollArea>
            </InfoBlock>
        );
    }

    render() {
        return (
            <Info { ...this.props } className={ this.block() }>
                { this.renderCommon() }
                { this.renderAttributes() }
                { this.renderSubject() }
                { this.renderIssuer() }
                { this.renderAdvanced() }
            </Info>
        );
    }
}
