import CSRCreate from "../../../Main/Settings/Credentials/CSRs/CSRCreate";
import { loadDefault, setItem } from "../../../Main/Settings/Credentials/CSRs/CSRCreateActions";

import { IntlStore } from "../../../../init";
import React from "react";
import { Component, SideBar, ToolBarTitle } from "../../../../ui";

import DelegationsStore from "./DelegationsStore";

const EMPTY = {};

export class DelegationJoin extends Component {
    init(...args) {
        super.init(...args);

        this.onCancel = this.onCancel.bind(this);
    }

    getNetwork(props) {
        const { params: { networkId }, delegations: { item: delegations } } = props;

        return delegations.value(networkId);
    }

    setParams(props) {
        const network = this.getNetwork(props);

        if (network) {
            const { id, formattedName } = network;

            setItem({ groupId: id, alias: formattedName });
        }
    }

    componentWillMount() {
        loadDefault().then(() => {
            this.setParams(this.props);
        });
    }

    componentWillReceiveProps(nextProps) {
        const { delegations: currDelegations, params: { networkId: currNetworkId } } = this.props;
        const { delegations: nextDelegations, params: { networkId: nextNetworkId } } = nextProps;

        if (currNetworkId !== nextNetworkId || currDelegations !== nextDelegations) {
            this.setParams(nextProps);
        }
    }

    onCancel() {
        const { router, params: { networkId } } = this.props;

        router.replace(`/msb/manage/delegations/${networkId}/`);
    }

    renderToolBar() {
        const { params: { networkId } } = this.props;
        const network = this.getNetwork(this.props);

        return (
            <ToolBarTitle retUrl={ `/msb/manage/delegations/${networkId}/` }>
                { this.formatMessage("title", network.getProps("formattedName")) }
            </ToolBarTitle>
        );
    }

    render() {
        const network = this.getNetwork(this.props);

        if (!network) {
            return null;
        }

        return (
            <SideBar toolBar={ this.renderToolBar() } className={ this.block() }>
                <CSRCreate { ...this.props } item={ EMPTY }
                    header={ false } cancel={ false }
                    submit={ this.formatMessage("submit") }
                    close={ false }
                    className={ this.element("form") } />
            </SideBar>
        );
    }
}

export default DelegationJoin.connect({ intl: IntlStore, delegations: DelegationsStore });
