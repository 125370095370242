import React from "react";

import { IntlStore } from "../../../../../init";
import { Confirmation, Component } from "../../../../../ui";

class PasswordSuccess extends Component {
    constructor(...args) {
        super(...args);

        this.onSubmit = this.onSubmit.bind(this);
    }

    onSubmit() {
        this.navigateUp(this.props);
    }

    render() {
        const { intl } = this.props;

        return (
            <Confirmation { ...{ intl } }
                title={ this.formatMessage("title") }
                message={ this.formatMessage("message") }
                submit={ this.formatMessage("ok") } cancel={ false }
                onSubmit={ this.onSubmit } onCancel={ this.onSubmit } />
        );
    }
}

export default PasswordSuccess.connect({ intl: IntlStore });
