import React from "react";
import { Component } from "./Component";

export class ListItem extends Component {
    render() {
        const { children } = this.props;

        return (
            <div { ...this.props } className={ this.cx("list__item item") }>
                <span className="item__title">{ children }</span>
            </div>
        );
    }
}

export default class List extends Component {
    static get Item() {
        return ListItem;
    }

    render() {
        const { constructor: { Item }, props: { children, onClick } } = this;

        return (
            <div className={ this.cx("list") } onClick={ onClick }>
                {
                    React.Children.map(children, (child) => <Item>{ child }</Item>)
                }
            </div>
        );
    }
}
