import React from "react";
import { Component as ReactComponent } from "../utils/react";
import { toKebabCase } from "../utils/string";
import { up } from "../utils/router";

export class Component extends ReactComponent {
    formatMessage(message, params) {
        const { intl } = this.props;

        message = this.className + (message ? "-" + toKebabCase(message) : "");

        return intl ? intl.formatMessage(message, params) : message;
    }

    navigateUp({ location, router }) {
        router.replace(up(location.pathname, router));
    }
}
