import { TableFilterModel, TableModel } from "../../../../components/Table";
import { Networks, Network } from "../../../../model";

class NetworksFilter extends TableFilterModel {
    init(...args) {
        super.init({ sort: { }, limit: null }, ...args);
    }
}

export default class DelegationsModel extends TableModel.of(Networks, NetworksFilter) {
}
