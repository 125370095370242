import PropTypes from "prop-types";
import React from "react";
import { values } from "../../utils/object";

import { Component } from "../Component";

export const READ_FILE_AS = {
    Text: "text",
    DataURL: "dataURL",
    Buffer: "buffer",
    Binary: "binary"
};

export class FileInputReader {
    static readFileAs(fileReader, file, as) {
        switch (as) {
        case READ_FILE_AS.DataURL:
            fileReader.readAsDataURL(file);
            break;
        case READ_FILE_AS.Binary:
            fileReader.readAsBinaryString(file);
            break;
        case READ_FILE_AS.Buffer:
            fileReader.readAsArrayBuffer(file);
            break;
        case READ_FILE_AS.Text:
        default:
            fileReader.readAsText(file);
            break;
        }
    }

    static readFile(input, as) {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();

            fileReader.addEventListener("load", () => resolve(fileReader), false);
            fileReader.addEventListener("error", () => reject(fileReader.error), false);

            this.readFileAs(fileReader, input, as);
        });
    }

    static readFiles(files, as) {
        return Promise.all(files.map((file) => this.readFile(file, as)));
    }
}

export class FormFile extends Component {
    init(...args) {
        super.init(...args);

        this.initState({ visible: false });
        this.onChange = this.onChange.bind(this);
    }

    onChange({ target: input }) {
        const { onChange, onError, name, maxSize, as } = this.props;

        FileInputReader.readFile(input.files[0], as)
            .then((reader) => {
                if (maxSize && reader.result.length > maxSize) {
                    throw new Error((name ? name.toLowerCase() + "-" : "" ) + "max-size-exceeded");
                }

                onChange && onChange(reader.result, name);
            })
            .catch((error) => {
                onError && onError(error);
            })
            .then(() => {
                this.refs.input.value = "";
            });
    }

    renderInput() {
        const { name, accept } = this.props;

        const className = this.cx("form-input form-input_file form-file__field", {
            [`form__input_${name}`.replace(/\W+/g, "-").toLowerCase()]: name
        });

        return (
            <input ref="input" type="file" id={ this.id } accept={ accept } className={ className }
                onChange={ this.onChange } />
        );
    }

    render() {
        const { value, placeholder } = this.props;

        return (
            <div className={ this.block({ selected: Boolean(value) }) }>
                { this.renderInput(this.id) }
                <label htmlFor={ this.id } className="form-file__label">{ placeholder }</label>
            </div>
        );
    }
}

FormFile.initProps({
    as: { type: PropTypes.oneOf(values(READ_FILE_AS)), value: READ_FILE_AS.Text }
});
