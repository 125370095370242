import { TableModel, TableFilterModel } from "../../../../components/Table";
import { Users, Groups } from "../../../../model";

class UsersFilter extends TableFilterModel {
    init(...args) {
        super.init({ groups: [], sort: { name: true } }, ...args);
    }

    toJSON() {
        const { groups } = this;

        return Object.assign(super.toJSON(), {
            groupId: groups.keys
        });
    }
}

UsersFilter.connect({
    groups: Groups
});

export default class UsersModel extends TableModel.of(Users, UsersFilter) {
}
