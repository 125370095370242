import React from "react";

import { IntlStore } from "../../../../init";
import { Confirmation } from "../../../../ui/Form";

import PermissionForm from "./PermissionForm";
import PermissionDeleteStore from "./PermissionDeleteStore";
import { setItem, deleteItem } from "./PermissionDeleteActions";

class PermissionDelete extends PermissionForm {
    setItem(item) {
        setItem(item);
    }

    submit() {
        const { data: { item } } = this.props;

        deleteItem(item).then(() => this.onSubmit());
    }

    renderConfirmation() {
        const { intl, data: { item } } = this.props;

        return (
            <Confirmation intl={ intl }
                title={ intl.formatMessage("manage-permission-delete-title") }
                message={ intl.formatMessage("manage-permission-delete-message", item) }
                onSubmit={ this.submit } onCancel={ this.close } />
        );
    }

    renderError() {
        const { intl, data: { error } } = this.props;

        return (
            <Confirmation { ...{ intl } }
                title={ intl.formatMessage("manage-permission-delete-failed-title") }
                message={ intl.formatMessage(error.message) }
                submit={ intl.formatMessage("alert-ok") } cancel={ false }
                onSubmit={ this.close } onCancel={ this.close } />
        );
    }

    render() {
        const { data: { item, error } } = this.props;

        return item ? error ? this.renderError() : this.renderConfirmation() : null;
    }
}

export default PermissionDelete.connect({ intl: IntlStore, data: PermissionDeleteStore });
