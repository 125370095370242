module.exports = {
    "activity-title": {
        "en-US": "Activity Monitor"
    },
    "activity-menu-title": {
        "en-US": "Activity"
    },
    "activity-reports-menu-title": {
        "en-US": "Reports"
    },
    "activity-reports-title": {
        "en-US": "Activity - Reports"
    },

    "activity-tool-bar-search": {
        "en-US": "Search"
    },
    "activity-tool-bar-actions-label": {
        "en-US": "Export to"
    }
};
