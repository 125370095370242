import React from "react";

import { Component } from "../Component";
import { Markdown } from "../Markdown";

function toMarkdown(value) {
    return String(value).replace(/\[([^\]]+)]\{(\w+)\|([^}]+)}/g,
        ($0, $1, $2, $3) => `[${ $1 }](#${ $2.toLowerCase() }/${ $3 })`);
}

export class NotificationTitle extends Component {
    render() {
        const { item: { title }, children } = this.props;
        const message = toMarkdown(title);

        return (
            <Markdown data-title={ message } className={ this.block() }>{ message }</Markdown>
        );
    }
}

export class NotificationBody extends Component {
    render() {
        const { item: { body } } = this.props;
        const message = toMarkdown(body);

        return (
            <Markdown data-title={ message } className={ this.block() }>{ message }</Markdown>
        );
    }
}
