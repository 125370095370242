import React from "react";
import { Component } from "../Component";

export class FormFields extends Component {
    render() {
        return (
            <div className={ this.block() }>
                { this.props.children }
            </div>
        );
    }
}
