import { IntlStore } from "../../../../init";
import React from "react";
import { Component, Tab, TabBar } from "../../../../ui";

class Credentials extends Component {
    renderToolBar() {
        return (
            <TabBar className={ this.element("toolbar") }>
                <Tab to="/msb/settings/credentials/vault/">{ this.formatMessage("vault") }</Tab>
                <Tab to="/msb/settings/credentials/certificates/">{ this.formatMessage("certificates") }</Tab>
                <Tab to="/msb/settings/credentials/templates/">{ this.formatMessage("templates") }</Tab>
                <Tab to="/msb/settings/credentials/csrs/">{ this.formatMessage("csrs") }</Tab>
                <Tab to="/msb/settings/credentials/managed-csrs/">{ this.formatMessage("managed-csrs") }</Tab>
                <Tab to="/msb/settings/credentials/clrs/">{ this.formatMessage("clrs") }</Tab>
                <Tab to="/msb/settings/credentials/recovery-info/">{ this.formatMessage("recovery-info") }</Tab>
                <Tab to="/msb/settings/credentials/enrollment-certificates/">
                    { this.formatMessage("enrollment-certificates") }
                </Tab>
            </TabBar>
        );
    }

    render() {
        const { children } = this.props;

        return (
            <div className={ this.block() }>
                { this.renderToolBar() }
                { children }
            </div>
        );
    }
}

export default Credentials.connect({ intl: IntlStore });
