import React from "react";
import PropTypes from "prop-types";

import { TimerOwner } from "../utils/react";
import { Component } from "./Component";
import { DropOwner } from "./Drop";
import { Markdown } from "./Markdown";

const ALIGN = [
    { side: "center", to: "center" },
    [
        { side: "bottom", to: "top", offset: 7 },
        { side: "top", to: "bottom", offset: 7 }
    ]
];

function getElementTooltip(target) {
    let tooltip = target.getAttribute("data-tooltip");

    if (!tooltip) {
        let title = target.getAttribute("data-title");

        if (title && (target.scrollWidth > target.clientWidth)) {
            tooltip = title;
        }
    }

    return tooltip || null;
}

function getTooltipTarget(target, depth = Number.POSITIVE_INFINITY) {
    let tooltipTarget = null, i = 0;

    while (target !== document && tooltipTarget === null && i++ < depth) {
        if (getElementTooltip(target)) {
            tooltipTarget = target;
        }

        target = target.parentNode;
    }

    return tooltipTarget;
}

/**
 * @extends DropOwner
 * @extends TimerOwner
 */
export class TooltipManager extends Component.aggregate(DropOwner, TimerOwner) {
    get dropId() {
        return "drop-tooltip";
    }

    init(...args) {
        super.init(...args);

        this.point = null;
        this.initDrop();
        this.onHoverTimeout = this.onHoverTimeout.bind(this);
        this.onShowTimeout = this.onShowTimeout.bind(this);
        this.onMouseMove = this.onMouseMove.bind(this);
        this.onMouseLeave = this.onMouseLeave.bind(this);
    }

    renderTooltip(tooltip) {
        return <Markdown className="tooltip">{ tooltip }</Markdown>;
    }

    showTooltip() {
        const target = this.target;
        const tooltip = getElementTooltip(target);

        if (tooltip) {
            this.showDrop(
                this.renderTooltip(tooltip),
                {
                    to: target,
                    align: ALIGN,
                    className: "drop_tooltip"
                }
            );
        }
    }

    onShowTimeout() {
        this.showTimeout = null;
        this.showTooltip();
    }

    clearShowTimeout() {
        if (this.showTimeout) {
            this.clearTimeout(this.showTimeout);
            this.showTimeout = null;
        }
    }

    setShowTimeout() {
        if (!this.showTimeout) {
            this.showTimeout = this.setTimeout(this.onShowTimeout, this.props.showTimeout);
        }
    }

    getTooltipTarget() {
        const target = this.point && document.elementFromPoint(...this.point) || null;

        return target && getTooltipTarget(target, this.props.depth);
    }

    onHoverTimeout() {
        const target = this.getTooltipTarget();

        if (this.target !== target) {
            this.target = target;
            this.clearShowTimeout();

            if (target) {
                const { drop } = this.state;

                if (drop) {
                    this.showTooltip();
                } else {
                    this.setShowTimeout();
                }
            } else {
                this.hideDrop();
            }
        }

        this.hoverTimeout = null;
        this.setHoverTimeout();
    }

    setHoverTimeout() {
        if (!this.hoverTimeout) {
            this.hoverTimeout = this.setTimeout(this.onHoverTimeout, this.props.hoverTimeout);
        }
    }

    onMouseMove({ clientX, clientY }) {
        this.point = [clientX, clientY];
    }

    onMouseLeave({ target, currentTarget }) {
        if (target === currentTarget) {
            this.point = null;
        }
    }

    componentDidMount() {
        this.addDOMListener(window, "mousemove", this.onMouseMove, true);
        this.addDOMListener(document.body, "mouseleave", this.onMouseLeave, true);
        this.setHoverTimeout();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        delete this.target;
    }

    render() {
        const { children } = this.props;

        return (
            <div ref="root" className={ this.cx("tooltip-manager") }>
                { children }
            </div>
        );
    }
}

TooltipManager.initProps({
    hoverTimeout: { type: PropTypes.number, value: 300 },
    showTimeout: { type: PropTypes.number, value: 700 },
    depth: { type: PropTypes.number, value: 5 }
});
