import { Model } from "../utils/dataflow";
import { v4 as uuid } from "uuid";
import unique from "./unique";

export class Entity extends Model {
    static uid() {
        return uuid.v4();
    }

    static unique() {
        return unique(this);
    }

    uid(...args) {
        return this.constructor.uid(...args);
    }
}
