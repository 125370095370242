import typeOf from "./typeOf";

export default function isEqual(value1, value2) {
    let isEqual = value1 === value2;

    if (!isEqual) {
        const type = typeOf(value1);

        switch (type) {
        case "date":
            value1 = +value1;
            value2 = +value2;
            // fallthrough
        case "number":
            isEqual = value1 === value2 || (value1 !== value1 && value2 !== value2);
            break;
        case "regexp":
            isEqual = String(value1) === String(value2);
            break;
        }
    }

    return isEqual;
}
