import { setTitle } from "./Header";

import { IntlStore } from "../init";
import React from "react";
import { Component } from "../ui/Component";

export class NotImplemented extends Component {
    setTitle(props) {
        const { intl, location: { pathname } } = props;
        const message = pathname.split("/").filter((item) => item && item !== "msb").concat("title").join("-");

        setTitle(intl.formatMessage(message));
    }

    componentWillMount() {
        this.setTitle(this.props);
    }

    componentWillReceiveProps(props) {
        this.setTitle(props);
    }

    render() {
        return (
            <div className="main">
                <div className="notice">Under construction</div>
            </div>
        );
    }
}

export default NotImplemented.connect({
    intl: IntlStore
});
