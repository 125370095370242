import { APIConnector } from "../../init";
import { ViewStore } from "../View";

export class FormStore extends ViewStore {
    setItemField(field, value) {
        let error = this.state.error;

        if (error && error.field === field) {
            error = null;
        }

        this.setState({ item: { [field]: value }, error });

        return this.state.item[field];
    }

    setItemError(field, error) {
        this.setError(error && Object.assign(error, { field }));
    }

    save(item) {
        let error;
        let operation;

        if (item.id) {
            operation = APIConnector.update.bind(APIConnector);
        } else {
            operation = APIConnector.create.bind(APIConnector);
            item = item.set({ id: item.uid() });
        }

        error = item.validate();
        return error ? Promise.reject(error) : operation(item, item.toJSON());
    }

    saveItem(item) {
        return this.invoke("save-item", this.save(item));
    }

    deleteItem(item) {
        return this.invoke("delete-user", APIConnector.destroy(item));
    }
}
