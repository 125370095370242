import React from "react";

import { Component, GenericForm } from "../../ui";
import { compact } from "../../utils/object";

export default class AuthForm extends Component {
    render() {
        return (
            <GenericForm { ...this.props } className={ this.block() }>
                <div className={ this.element("logo") } />
                { this.props.children }
            </GenericForm>
        );
    }
}

AuthForm.propTypes = compact(GenericForm.propTypes);
