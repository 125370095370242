module.exports = {
    "manage-delegations-menu-title": {
        "en-US": "Delegations"
    },

    "delegations-title": {
        "en-US": "Delegations"
    },
    "delegations-root": {
        "en-US": "Networks"
    },
    "delegations-empty-message": {
        "en-US": "Please select network"
    },

    "delegation-managers-tool-bar-search": {
        "en-US": "Search"
    },
    "delegation-managers-join": {
        "en-US": "Join"
    },
    "delegation-managers-add": {
        "en-US": "Add..."
    },
    "delegation-managers-add-manager": {
        "en-US": "Add Manager"
    },
    "delegation-managers-add-participant": {
        "en-US": "Add Participant"
    },
    "delegation-managers-table-column-num": {
        "en-US": "#"
    },
    "delegation-managers-table-column-name": {
        "en-US": "Manager"
    },
    "delegation-managers-table-column-location": {
        "en-US": "Location"
    },

    "delegation-join-title": {
        "en-US": "Join Network: *{formattedName}*"
    },
    "delegation-join-submit": {
        "en-US": "Join"
    },
    "delegation-join-message": {
        "en-US": "Are you sure want to join to *Network {formattedName}*"
    },

    "delegation-add-manager-title": {
        "en-US": "Add Manager to *{formattedName}*"
    },
    "delegation-add-manager-tool-bar-search": {
        "en-US": "Search"
    },
    "delegation-add-manager-table-column-num": {
        "en-US": "#"
    },
    "delegation-add-manager-table-column-name": {
        "en-US": "Participant"
    },
    "delegation-add-manager-table-column-location": {
        "en-US": "Location"
    },

    "delegation-add-participant-title": {
        "en-US": "Add Participant to *{formattedName}*"
    },
    "delegation-add-participant-tool-bar-search": {
        "en-US": "Search"
    },
    "delegation-add-participant-table-column-num": {
        "en-US": "#"
    },
    "delegation-add-participant-table-column-name": {
        "en-US": "User"
    },
    "delegation-add-participant-table-column-location": {
        "en-US": "Location"
    }
};
