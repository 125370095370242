import { ViewStore } from "../../../../components/View";
import { APIConnector, FAPIConnector } from "../../../../init";
import HaceraNetworksModel from "./HaceraNetworksModel";

function parseNode(node) {
    const { nodeType, nodeURL, tlsca, host } = node;

    return Object.assign({
        hostname: nodeURL,
        url: `grpcs://${ host.externalIP }:${ nodeType === "peer" ? 7051 : 7050 }`,
        tlsCACert: tlsca
    });
}

function parseNodes(nodes) {
    const peers = [];
    let orderer;

    nodes.forEach((node) => {
        const { nodeType } = node;
        const config = parseNode(node);

        if (nodeType === "peer") {
            peers.push(config);
        } else {
            orderer = config;
        }
    });

    return { orderer, peers };
}

class ConfigConnector extends APIConnector {
    static buildUrl(path, params) {
        return super.buildUrl("NetworksConfig", params);
    }

    static read(...args) {
        return super.read(...args).then((data) => {
            return data.map((data) => {
                const config = JSON.parse(data.config);
                const { ca, nodes } = config;
                const { admin: { username, password, key, cert } } = ca;
                const { orderer, peers } = parseNodes(nodes);

                return {
                    id: data.id,
                    name: config.networkName,
                    config: {
                        id: config.id,
                        name: config.networkName,
                        mspId: "Org1MSP",
                        channelName: "mychannel",
                        ca: {
                            url: orderer.url.replace(/^grpcs/, "https").replace(/7050$/, 7054),
                            admin: { id: username, password, key, certificate: cert }
                        },
                        orderer,
                        peers
                    }
                };
            });
        });
    }
}

class HaceraNetworksStore extends ViewStore.of(HaceraNetworksModel) {
    load() {
        const { networks, configs } = this.state.item;

        return Promise.all([
                FAPIConnector.read(networks),
                ConfigConnector.read(configs)
            ])
            .then(([networks, configs]) => {
                this.setState({ item: { networks, configs } });
            });
    }
}

export default new HaceraNetworksStore();
