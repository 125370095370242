module.exports = {
    "manage-networks-menu-title": {
        "en-US": "Networks"
    },
    "networks-title": {
        "en-US": "Networks Directory"
    },
    "networks-root": {
        "en-US": "Networks"
    },
    "networks-tree-join": {
        "en-US": "Join ..."
    },
    "networks-tree-add": {
        "en-US": "Add new"
    },

    "networks-empty-message": {
        "en-US": "Please select network"
    },

    "network-join-title": {
        "en-US": "Join Network"
    },
    "network-join-submit": {
        "en-US": "Join"
    },
    "network-add-title": {
        "en-US": "Add Network"
    },
    "network-add-submit": {
        "en-US": "Add"
    },

    "network-title": {
        "en-US": "Network *{name}*"
    },
    "network-join": {
        "en-US": "Join"
    },
    "network-add": {
        "en-US": "Add"
    },
    "network-name-label": {
        "en-US": "Network Name"
    },
    "network-parent-label": {
        "en-US": "Parent Network"
    },
    "network-parent-none": {
        "en-US": "None"
    }
};
