import { Collection } from "../../utils/dataflow";
import ViewModel from "../View/ViewModel";

import TableFilterModel from "./TableFilterModel";

export default class TableModel extends ViewModel {
    get Filter() {
        return this.constructor.Filter;
    }

    init(...args) {
        super.init({ item: [], filter: { page: 0, perPage: 20 } }, ...args);
    }

    toString() {
        return super.toString(this.Item, this.Filter);
    }

    static get Filter() {
        return TableFilterModel;
    }

    static toString() {
        return super.toString(this.Items, this.Filter);
    }

    static of(Item, Filter) {
        if (!Collection.is(Item)) {
            throw this.getError("Collection expected");
        }

        if (!TableFilterModel.is(Filter)) {
            throw this.getError("TableFilterModel expected");
        }

        class TableModel extends ViewModel.of.call(this, Item) {
            static get Filter() {
                return Filter;
            }
        }

        return TableModel.connect({
            filter: Filter
        });
    }
}
