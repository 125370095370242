import PropTypes from "prop-types";
import React from "react";
import { Component } from "./Component";

export class Button extends Component {
    constructor(...args) {
        super(...args);

        this.onClick = this.onClick.bind(this);
    }

    onClick(event) {
        const { onClick } = this.props;

        if (onClick) {
            onClick(event);
        }
    }

    render() {
        const { type, children, disabled, onClick } = this.props;

        return (
            <button type={ type === "submit" || type === "reset" ? type : "button" }
                className={ this.block({ [type]: true, disabled }) } onClick={ onClick }>
                { children }
            </button>
        );
    }
}

Button.initPropTypes({
    type: PropTypes.string,
    disabled: PropTypes.bool,
    onClick: PropTypes.func
});

Button.initDefaultProps({
    type: "button"
});
