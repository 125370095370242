import valueOf from "./valueOf";
import isEqual from "./isEqual";

export default function assign(target, ...next) {
    let proto;

    if (target === null || target === void 0) {
        throw new TypeError("object expected");
    }

    proto = Object.getPrototypeOf(target);

    if (next.length) {
        next.forEach((next) => {
            if (next !== null && next !== void 0) {
                Object.keys(valueOf(next)).forEach((key) => {
                    const nextVal = next[key];

                    if (proto !== null && target.hasOwnProperty(key) && isEqual(proto[key], nextVal)) {
                        delete target[key];
                    } else if (!isEqual(target[key], nextVal)) {
                        target[key] = nextVal;
                    }
                });
            }
        });
    }

    return target;
}
