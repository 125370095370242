import { Collection } from "../utils/dataflow";
import { LDAPConfig } from "./LDAPConfig";

export class LDAPConfigs extends Collection.of(LDAPConfig) {
    static sort(i1, i2) {
        return i1.index - i2.index;
    }

    setItemIndex(item, index) {
        return this
            .update((item) => item.index >= index ? item.set({ index: item.index + 1 }) : item)
            .updateItems(item.set({ index }))
            .sort(LDAPConfigs.sort);
    }
}
