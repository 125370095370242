import { FormEditActions } from "../../components/Form";

const Actions = new FormEditActions();

export default Actions;

export const setItem = Actions.setItem.trigger;
export const setItemField = Actions.setItemField.trigger;
export const setItemError = Actions.setItemError.trigger;
export const saveItem = Actions.saveItem.trigger;
