import { FormEditStore } from "../../../../../components/Form";

import CSRImportModel from "./CSRImportModel";
import CSRImportActions from "./CSRImportActions";

class CSRImportStore extends FormEditStore.of(CSRImportModel) {
    processError(error) {
        const { item } = this.state;

        error = super.processError(error);

        if (error.code === "error-certificate-request-duplicate-key-violation") {
            error = super.processError(item.getError2("exists", "alias"));
        } else if (!error.field) {
            error = super.processError(item.getError2("invalid", "pem"));
        }

        return error;
    }
}

export default new CSRImportStore().connect(CSRImportActions);
