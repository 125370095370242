import { TableStore } from "../../../../components/Table";
import UsersModel from "./UsersModel";
import UserGroupsActions from "./UserGroupsActions";

class UsersStore extends TableStore.of(UsersModel) {
    setUserGroups(user, groups) {
        const users = this.state.item;

        //user = users.value(user.id);
        if (user) {
            this.setState({ item: users.updateItem(user.id, { groups }) });
        }
    }
}

export default new UsersStore().connect(UserGroupsActions);
