import moment from "moment";
import { TableFilterModel, TableModel } from "../../../../components/Table";
import { Users, Groups, Permissions, Transactions } from "../../../../model";

function formatDate(date) {
    return date.utc().format("YYYY-MM-DD[T]HH:mm:ss");
}

function getDateFilter(value) {
    const to = moment().subtract(1, "days").endOf("day");

    const from = value === "1d" ? moment(to).startOf("day")
        : value === "1d" ? moment(to).startOf("day")
        : value === "5d" ? moment(to).subtract(5, "days").startOf("day")
        : value === "1w" ? moment(to).startOf("week")
        : value === "1m" ? moment(to).startOf("month")
        : value === "3m" ? moment(to).subtract(3, "months").startOf("month")
        : value === "ydt" ? moment(to).startOf("year")
        : moment(0);

    return `bt::${formatDate(from)}::${formatDate(to)}`;
}

class HistoricalFilter extends TableFilterModel {
    init(...args) {
        super.init({ users: [], groups: [], permissions: [], created: "", sort: { created: false } }, ...args);
    }

    valueOf() {
        const { users, groups, permissions } = this;

        return Object.assign(super.valueOf(), {
            users: users,
            groups: groups,
            permissions: permissions
        });
    }

    toJSON() {
        const { users, groups, permissions, created } = this;

        return Object.assign(super.toJSON(), {
            userId: users.keys,
            groupId: groups.keys,
            permissionId: permissions.keys,
            created: getDateFilter(created)
        });
    }
}

HistoricalFilter.connect({
    users: Users,
    groups: Groups,
    permissions: Permissions
});

export default class HistoricalModel extends TableModel.of(Transactions, HistoricalFilter) {
}
