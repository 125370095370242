import NotImplemented from "../../../../components/NotImplemented";
import React from "react";
import { IndexRedirect, Route } from "react-router";
import { AffiliationsRoute } from "./Affiliations";
import { InfoRoute } from "./Info";
import { PasswordRoute } from "./Password";

import Preferences from "./Profile";

export default function route(path) {
    return (
        <Route path={ path } component={ Preferences }>
            <IndexRedirect to="info/" />
            <Route path="activity/" component={ NotImplemented } />
            { InfoRoute("info/") }
            { AffiliationsRoute("affiliations/") }
            { PasswordRoute("password/") }
        </Route>
    );
};
