import React from "react";
import { Route, IndexRedirect } from "react-router";

import Manage from "./Manage";
import { UsersRoute } from "./Users";
import { GroupsRoute } from "./Groups";
import { NetworksRoute } from "./Networks";
import { PermissionsRoute } from "./Permissions";
import { DelegationsRoute } from "./Delegations";
import { HaceraNetworksRoute } from "./HaceraNetworks";

export default function route(path) {
    return (
        <Route path={ path } component={ Manage }>
            <IndexRedirect to="users" />
            { UsersRoute("users") }
            { GroupsRoute("groups") }
            { NetworksRoute("networks") }
            { PermissionsRoute("permissions") }
            { DelegationsRoute("delegations") }
            { HaceraNetworksRoute("hacera-networks") }
        </Route>
    );
};
