import PropTypes from "prop-types";
import React from "react";
import MarkdownRenderer from "react-markdown-renderer";

import { Component } from "./Component";

const options = { html: true };

export class Markdown extends Component {
    init(...args) {
        super.init(...args);

        this.onClick = this.onClick.bind(this);
    }

    onClick(event) {
        const { onClick } = this.props;

        event.preventDefault();

        if (onClick) {
            onClick(event);
        }
    }


    render() {
        const { children } = this.props;

        return <MarkdownRenderer { ...Object.assign({}, this.props, { children: void 0 }) }
            markdown={ String(children || "").replace(/\n/g, "\n\n") }
            options={ options }
            className={ this.block() } onClick={ this.onClick } />;
    }
}

Markdown.initProps({
    children: PropTypes.string
});
