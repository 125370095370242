import { FormModel } from "../../components/Form";
import { Entity } from "../../model";

class Credentials extends Entity {
    init(...args) {
        super.init({ username: "", password: "" }, ...args);
    }

    validate() {
        return super.validate()
            || (!this.username && this.getError("Empty login username", "username"))
            || (!this.password && this.getError("Empty login password", "password"))
            || void 0;
    }
}

export default class LoginModel extends FormModel.of(Credentials) {
}
