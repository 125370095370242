import { Model } from "../../utils/dataflow";
import { Session } from "../../model";

export class AuthModel extends Model {
    init(...args) {
        super.init({ session: {} }, ...args);
    }

    get isGuest() {
        return this.session === null || this.session.isGuest;
    }

    get user() {
        return this.session && this.session.user;
    }
}

AuthModel.connect({
    session: Session
});
