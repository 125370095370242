import { APIConnector, AuthStore, AuthActions } from "../../../../../init";
import { FormEditStore } from "../../../../../components/Form";

import GeneralModel from "./GeneralModel";
import GeneralActions from "./GeneralActions";

class GeneralStore extends FormEditStore.of(GeneralModel) {
    save(item) {
        let error, operation;

        item = item.set({ userId: AuthStore.state.user.id });

        if (item.id) {
            operation = APIConnector.update.bind(APIConnector);
        } else {
            operation = APIConnector.create.bind(APIConnector);
            item = item.set({ id: item.uid() });
        }

        error = item.validate();
        return error ? Promise.reject(error) : operation(item, item.toJSON())
           .then((preferences) => AuthActions.updateSession.trigger({ user: { preferences } }));
    }
}

export default new GeneralStore().connect(GeneralActions);
