import { SelectGroups } from "../../../../components/Select";
import { LDAPEntity } from "../../../../model";
import React from "react";

import { Avatar, Component, Info, InfoBlock, InfoRow, InfoTitle, InfoValue, InfoValueRow, ScrollArea } from "../../../../ui";

import UserGroupsActions from "./UserGroupsActions";
import UserGroupsStore from "./UserGroupsStore";

export const DROP_ALIGN = [
    { side: "right", to: "right", offset: -10 },
    [
        { side: "top", to: "bottom", offset: 10 },
        { side: "bottom", to: "top", offset: 10 }
    ]
];

export class UserView extends Component {
    state = { expanded: false };

    onExpandClick = () => {
        this.setState({ expanded: !this.state.expanded });
    };

    onDeleteAffiliationClick = (event) => {
        const id = event.currentTarget.getAttribute("data-id");

        event.preventDefault();
        this.onAffiliationsChange(this.props.item.groups.filter((group) => group.id !== id));
    };

    onAffiliationsChange = (groups) => {
        const { item, actions } = this.props;

        actions.setUserGroups.trigger(item, groups);
    };

    renderGeneral() {
        const { item: { avatar, role, credentials, name, fullName, lastName } } = this.props;

        return (
            <InfoBlock title={ this.formatMessage("general") } className={ this.element("general") }>
                <Avatar image={ avatar } name={ fullName } className={ this.element("avatar") } />
                <InfoValueRow label={ this.formatMessage("first-name") } value={ name } />
                <InfoValueRow label={ this.formatMessage("last-name") } value={ lastName } />
                <InfoValueRow label={ this.formatMessage("username") } value={ credentials && credentials.name } />
                <InfoValueRow label={ this.formatMessage("affiliation") } value={ role } />
            </InfoBlock>
        );
    }

    renderContactInfo() {
        const { item: { company, credentials, fullLocation } } = this.props;

        return (
            <InfoBlock title={ this.formatMessage("contact-info") } className={ this.element("contact-info") }>
                <InfoValueRow label={ this.formatMessage("location") } value={ fullLocation } />
                <InfoValueRow label={ this.formatMessage("company") } value={ company } />
                <InfoValueRow label={ this.formatMessage("email") } value={ credentials && credentials.email } />
                <InfoValueRow label={ this.formatMessage("phone") } value={ credentials && credentials.phone } />
            </InfoBlock>
        );
    }

    renderAffiliation(group) {
        const { id, name } = group;

        return (
            <InfoRow key={ id } className={ this.element("affiliation") }>
                <InfoValue>{ name }</InfoValue>
                <a data-id={ group.id } className={ this.element("delete-affiliation", void 0, "link") }
                    onClick={ this.onDeleteAffiliationClick }>
                    <i className="ico ico-close" />
                </a>
            </InfoRow>
        );
    }

    renderAffiliationsExpand() {
        const { item: { groups } } = this.props;
        const expandable = groups && groups.length > 4;

        return (
            <a className={ this.element("expand", { expandable }, "link") } onClick={ this.onExpandClick }>
                { this.formatMessage("affiliations") }
            </a>
        );
    }

    renderAffiliationsSelect() {
        const { item: { groups } } = this.props;

        return (
            <SelectGroups value={ groups } sort={ LDAPEntity.sort } list={ false } hideValue={ true }
                placeholder={ this.formatMessage("groups-placeholder") }
                dropAlign={ DROP_ALIGN }
                dropClassName="drop_wide drop_user-view-groups"
                className={ this.element("groups") }
                onChange={ this.onAffiliationsChange } />
        );
    }

    renderAffiliationsTitle() {
        return (
            <InfoTitle className={ this.element("affiliations-title") }>
                { this.renderAffiliationsExpand() }
                { this.renderAffiliationsSelect() }
            </InfoTitle>
        );
    }

    renderAffiliations() {
        const { item } = this.props;

        let affiliations;
        if (item.groups) {
            affiliations = item.groups.map(this.renderAffiliation.bind(this));
        }

        return (
            <InfoBlock title={ this.renderAffiliationsTitle() } className={ this.element("affiliations") }>
                <ScrollArea>
                    { affiliations }
                </ScrollArea>
            </InfoBlock>
        );
    }

    render() {
        const { expanded } = this.state;

        return (
            <Info className={ this.block({ expanded }) }>
                { this.renderGeneral() }
                { this.renderContactInfo() }
                { this.renderAffiliations() }
            </Info>
        );
    }
}

export default UserView.connect({ store: UserGroupsStore }, { actions: UserGroupsActions });
