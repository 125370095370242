import { DN, parseDN } from "ldapjs";
import { isDefined } from "../object";

function parse(dn) {
    let parsed;

    try {
        parsed = parseDN(dn);
    } catch (err) {
    }

    return parsed;
}

export default {
    parse(dn) {
        return isDefined(dn) ? (dn instanceof DN ? dn : parse(dn)) : dn;
    },

    stringify(dn) {
        return isDefined(dn) ? this.parse(dn).format({ skipSpace: true }) : dn;
    }
};
