import React from "react";

import { HierarchyToolBar } from "../../../../components/HierarchyToolBar";

export class TransactionsToolBar extends HierarchyToolBar {
    render() {
        const { children } = this.props;

        return (
            <HierarchyToolBar { ...this.props } className={ this.block() }>
                { children }
                { this.renderSearch() }
            </HierarchyToolBar>
        );
    }
}
