import { IntlStore } from "../../init";
import React from "react";
import { FormField, FormPassword } from "../../ui";

import { Component } from "../../ui/Component";

import AuthForm from "./AuthForm";
import { resetPassword, setItem, setItemField } from "./ResetPasswordActions";
import ResetPasswordStore from "./ResetPasswordStore";

function onFieldChange(value, name) {
    if (name) {
        setItemField(name, value);
    }
}


class ResetPassword extends Component {
    init(...args) {
        super.init(...args);

        this.onClose = this.onClose.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentWillMount() {
        setItem(new ResetPasswordStore.Model.Item);
    }

    onSuccess() {
        this.props.router.replace("/auth");
    }

    onClose() {
        this.props.router.replace("/auth");
    }

    onSubmit() {
        const { data: { item }, params: { secure } } = this.props;

        resetPassword(item, secure).then(() => this.onSuccess());
    }

    render() {
        const { data: { busy, error, item }, intl } = this.props;

        return (
            <AuthForm { ...{ intl, busy, error } }
                title={ this.formatMessage("title") }
                submit={ this.formatMessage("submit") }
                cancel={ false }
                className={ this.block() }
                onSubmit={ this.onSubmit }
                onClose={ this.onClose }>

                <FormField name="password" label={ this.formatMessage("password-label") }>
                    <FormPassword name="password" value={ item.password }
                        placeholder={ this.formatMessage("password-placeholder") }
                        onChange={ onFieldChange } />
                </FormField>
            </AuthForm>
        );
    }
}

export default ResetPassword.connect({
    intl: IntlStore,
    data: ResetPasswordStore
});
