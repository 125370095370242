import React from "react";
import { Route, IndexRedirect } from "react-router";

import Main from "./Main";
import { ActivityRoute } from "./Activity";
import { ManageRoute } from "./Manage";
import { SettingsRoute } from "./Settings";
import NotFound from "../NotFound";

export default function route(path) {
    return (
        <Route path={ path } component={ Main }>
            <IndexRedirect to="activity/" />
            { ActivityRoute("activity/") }
            { ManageRoute("manage/") }
            { SettingsRoute("settings/") }
            <Route path="*" component={ NotFound } />
        </Route>
    );
};
