module.exports = {
    "manage-hacera-networks-menu-title": {
        "en-US": "HACERA Networks"
    },
    "hacera-networks-title": {
        "en-US": "HACERA Networks Directory"
    },
    "hacera-networks-root": {
        "en-US": "HACERA Networks"
    },
    "hacera-networks-empty-message": {
        "en-US": "Please select network or node"
    },

    "hacera-network-join": {
        "en-US": "Join"
    },

    "hacera-network-ssh-key-label": {
        "en-US": "SSH key"
    },

    "hacera-network-user-label": {
        "en-US": "User"
    },

    "hacera-network-node-type-label": {
        "en-US": "Type"
    },

    "hacera-network-node-url-label": {
        "en-US": "URL"
    },

    "hacera-network-node-ssh-key-label": {
        "en-US": "SSH key"
    },

    "hacera-network-join-title": {
        "en-US": "Join Network *{name}*"
    },

    "hacera-network-join-back": {
        "en-US": "<"
    },

    "hacera-network-join-submit": {
        "en-US": "Join"
    },

    "hacera-network-join-network-id-label": {
        "en-US": "Network"
    },
    "hacera-network-join-network-id-placeholder": {
        "en-US": "Select Network"
    },

    "error-registration-failed-user-is-already-registered": {
        "en-US": "User is already registered"
    }
};
