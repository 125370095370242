import React from "react";
import PropTypes from "prop-types";

import { Notifications, Notification as NotificationModel } from "../../model";
import { Component } from "../Component";
import { IntlModel } from "../../components/Intl";

import { readNotification } from "../../components/Notifications/NotificationsActions";

import { NotificationActions } from "./NotificationActions";
import { NotificationTitle } from "./NotificationBody";
import { NotificationCategory } from "./NotificationCategory";
import { NotificationTime } from "./NotificationTime";

export class NotificationsPopupItem extends Component {
    init(...args) {
        super.init(...args);

        this.onCloseClick = this.onCloseClick.bind(this);
    }

    onCloseClick() {
        const { item } = this.props;

        readNotification(item);
    }

    render() {
        const { intl, item } = this.props;

        return (
            <div className={ this.block() }>
                <div className={ this.element("header") }>
                    <NotificationCategory { ...{ intl, item } } className={ this.element("category") } />
                    <NotificationTime { ...{ intl, item } } className={ this.element("time") } />
                    <i className={ this.element("close") + " ico ico-close" } onClick={ this.onCloseClick } />
                </div>
                <NotificationTitle { ...{ intl, item } } className={ this.element("title") } />
                <NotificationActions { ...{ intl, item } } className={ this.element("actions") } />
            </div>
        );
    }
}

NotificationsPopupItem.initProps({
    intl: { type: PropTypes.instanceOf(IntlModel).isRequired },
    item: { type: PropTypes.instanceOf(NotificationModel).isRequired }
});

export class NotificationsPopup extends Component {
    renderItem(item) {
        const { intl } = this.props;

        return <NotificationsPopupItem key={ item.id } { ...{ intl, item } } className="notifications-popup__item" />;
    }

    render() {
        const { items } = this.props;

        return (
            <div className={ this.cx("notifications-popup") }>
                { items.sort(NotificationModel.sort).map(this.renderItem.bind(this)) }
            </div>
        );
    }
}

NotificationsPopup.initProps({
    intl: { type: PropTypes.instanceOf(IntlModel).isRequired },
    items: { type: PropTypes.instanceOf(Notifications).isRequired }
});
