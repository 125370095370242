import { SelectGroup } from "../../../../components/Select";
import { IntlStore } from "../../../../init";

import { LDAPEntity } from "../../../../model";
import React from "react";
import { FormField, FormInput, GenericForm } from "../../../../ui";
import { nvl } from "../../../../utils/object";
import { saveItem, setItem, setItemField, setItemGroupId } from "./GroupEditActions";
import GroupEditStore from "./GroupEditStore";

import GroupForm from "./GroupForm";

function onFieldChange(value, name) {
    if (name) {
        setItemField(name, value);
    }
}

class GroupEdit extends GroupForm {
    setItem(item) {
        setItem(item || new GroupEditStore.Model.Item());
    }

    submit() {
        const { data: { item } } = this.props;

        saveItem(item).then(() => this.onSubmit()).catch(() => void 0);
    }

    filterGroups(id, group, index, groups) {
        let isParent = false;

        while (id && group && !isParent) {
            isParent = group.id === id;

            group = groups.value(group.groupId);
        }

        return !isParent;
    }

    render() {
        const { intl, data: { busy, error, item }, header, title, submit, close, cancel } = this.props;

        if (!item) {
            return null;
        }

        return (
            <GenericForm { ...{ intl, busy, error } }
                header={ nvl(header, true) }
                title={ nvl(title, this.formatMessage((item.id ? "" : "create-") + "title", item)) }
                submit={ nvl(submit, true) }
                close={ nvl(close, true) }
                cancel={ nvl(cancel, true) }
                onSubmit={ this.submit } onCancel={ this.close }>

                <FormField name="name" label={ this.formatMessage("name-label") }>
                    <FormInput type="text" name="name" value={ item.name }
                        placeholder={ this.formatMessage("name-placeholder") }
                        onChange={ onFieldChange } />
                </FormField>
                <FormField name="groupId" label={ this.formatMessage("parent-label") }>
                    <SelectGroup intl={ intl } value={ item.groupId }
                        filter={ this.filterGroups.bind(this, item.id) } sort={ LDAPEntity.sort }
                        dropClassName="drop_wide"
                        placeholder={ this.formatMessage("parent-placeholder") }
                        onChange={ setItemGroupId } />
                </FormField>
            </GenericForm>
        );
    }
}

export default GroupEdit.connect({ intl: IntlStore, data: GroupEditStore });
