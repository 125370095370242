import { IntlStore, JSAPIConnector } from "../../init";
import { NetworkConfigs } from "../../model";
import SelectItem from "./SelectItem";

const SelectNetworkConfig = SelectItem.of(NetworkConfigs, JSAPIConnector);

export const SelectNetworkConfigActions = SelectNetworkConfig.Actions;
export const SelectNetworkConfigStore = SelectNetworkConfig.Store;
export const SelectNetworkConfigModel = SelectNetworkConfig.Model;

export default SelectNetworkConfig.connect({ data: SelectNetworkConfigStore, intl: IntlStore });
