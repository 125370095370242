import React from "react";

import { Component } from "../../../../ui";
import { SelectGroupsActions } from "../../../../components/Select";

import { refresh } from "./GroupsActions";

export default class GroupForm extends Component {
    init(...args) {
        super.init(...args);

        this.submit = this.submit.bind(this);
        this.close = this.close.bind(this);
    }

    setItem() {
    }

    componentWillMount() {
        this.setItem(this.props.item);
    }

    componentWillReceiveProps(nextProps) {
        const { item: currItem } = this.props;
        const { item: nextItem } = nextProps;

        if (currItem !== nextItem) {
            this.setItem(nextItem);
        }
    }

    close() {
        this.navigateUp(this.props);
    }

    onSubmit() {
        refresh();
        SelectGroupsActions.refresh.trigger();

        this.close();
    }

    submit() {
    }
}
