import React from "react";

import { Component, Link, Label } from "..";

export class TableFilterItem extends Component {
    render() {
        const { item } = this.props;
        const value = item.value || item.id;

        return (
            <Link to={ value } className={ this.block() }>{ item.name }</Link>
        );
    }
}

export class TableFilter extends Component {
    renderLabel() {
        const { label } = this.props;

        return label ? <Label className={ this.element("label") }>{ label }</Label> : null;
    }

    renderItem(item) {
        const { intl, Item } = this.props;
        const FilterItem = Item || TableFilterItem;

        return <FilterItem { ...{ intl, item } } key={ item.id } />;
    }

    renderItems() {
        return this.props.items.map(this.renderItem.bind(this));
    }

    render() {
        return (
            <div className={ this.block() }>
                { this.renderLabel() }
                { this.renderItems() }
            </div>
        );
    }
}
