import { Entity } from "./Entity";

export const LDAP_CONFIG_UPDATE_FREQUENCIES = [
    { id: "Manual", name: "Manual" },
    { id: "Monthly", name: "Monthly" },
    { id: "Weekly", name: "Weekly" },
    { id: "Daily", name: "Daily" },
    { id: "Hourly", name: "Hourly" }
];

export const LDAP_CONFIG_REPO_TYPES = [
    { id: "OpenLDAP", name: "OpenLDAP" },
    { id: "Apache DS", name: "Apache DS" },
    { id: "MS Active Directory", name: "MS Active Directory" },
    { id: "Red Hat Directory Services", name: "Red Hat Directory Services" },
    { id: "389 Directory Server", name: "389 Directory Server" }
];

const defaults = {
    id: "",
    name: "",
    type: "OpenLDAP",
    host: "",
    port: 389,
    useSSL: false,
    SSLCertificate: "",
    userName: "",
    password: "",
    base: "",
    groupfilter: "(ou=%s)",
    userfilter: "(uid=%s)",
    updateFrequency: "Manual",
    index: 0
};

export class LDAPConfig extends Entity {
    static get name() {
        return "LDAPConfig";
    }

    init(...args) {
        super.init(defaults, ...args);
    }

    validate() {
        return super.validate()
            || (this.name.trim() === "" && this.getError("Empty LDAPConfig name", "name"))
            || (this.host.trim() === "" && this.getError("Empty LDAPConfig host", "host"))
            || (this.port !== null && isNaN(this.port) && this.getError("Invalid LDAPConfig port", "port"))
            || void 0;
    }
}
