module.exports = {
    "manage-title": {
        "en-US": "Management"
    },
    "manage-menu-title": {
        "en-US": "Manage"
    },
    "manage-networks-menu-title": {
        "en-US": "Networks"
    },
    "manage-networks-title": {
        "en-US": "Networks"
    }
};
