import { TableModel, TableFilterModel } from "../../../../components/Table";
import { Groups, PermissionedObjects, PermissionedOperations } from "../../../../model";

class PermissionsFilter extends TableFilterModel {
    init(...args) {
        super.init({ groups: [], objects: [], operations: [], sort: { name: true }, limit: null }, ...args);
    }

    toJSON() {
        const { groups, objects, operations } = this;

        return Object.assign(super.toJSON(), {
            groupId: groups.keys,
            objectId: objects.keys,
            operationId: operations.keys
        });
    }
}

PermissionsFilter.connect({
    groups: Groups,
    objects: PermissionedObjects,
    operations: PermissionedOperations
});


export default class PermissionModel extends TableModel.of(Groups, PermissionsFilter) {
};
