import { IntlStore } from "../../init";
import { Groups } from "../../model";
import SelectItem from "./SelectItem";

const SelectGroup = SelectItem.of(Groups);

export const SelectGroupActions = SelectGroup.Actions;
export const SelectGroupStore = SelectGroup.Store;
export const SelectGroupModel = SelectGroup.Model;

export default SelectGroup.connect({ data: SelectGroupStore, intl: IntlStore });
