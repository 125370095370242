import getRect from "./getRect";

/**
 * Returns bounds of element relative to another one.
 *
 * @param {HTMLElement} el
 * @param {HTMLElement} [relEl]
 * @return {Rect}
 */
export default function getBoundsTo(el, relEl) {
    return getRect(el || document.body).boundsTo(getRect(relEl || document.body));
}
