import { Model } from "../../utils/dataflow";

export default class ViewModel extends Model {
    get Item() {
        return this.constructor.Item;
    }

    set action(action) {
        this.define({ _action: action || null });
    }

    get action() {
        return this._action;
    }

    init(...args) {
        super.init({ action: null, busy: false }, ...args);
    }

    valueOf() {
        return Object.assign(super.valueOf(), {
            action: this.action
        });
    }

    toString() {
        return super.toString(this.Item.name, this.Filter.name);
    }

    static get Item() {
        return Model;
    }

    static toString() {
        return super.toString(this.Item.name);
    }

    static of(Item) {
        if (!Model.is(Item)) {
            throw this.getError("Model expected");
        }

        class ViewModel extends this {
            static get Item() {
                return Item;
            }
        }

        return ViewModel.connect({
            item: Item
        });
    }
}
