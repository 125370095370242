import React from "react";
import { Route } from "react-router";

import Permissions from "./Permissions";
import PermissionEdit from "./PermissionEdit";
import PermissionDelete from "./PermissionDelete";

export default function route(path) {
    return (
        <Route path={ path } component={ Permissions }>
            <Route path="create" component={ PermissionEdit } />
            <Route path=":itemId/edit" component={ PermissionEdit } />
            <Route path=":itemId/delete" component={ PermissionDelete } />
        </Route>
    );
};
