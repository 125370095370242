import React from "react";

import { IntlStore } from "../../../../init";
import { Confirmation } from "../../../../ui/Form";

import RepositoryForm from "./RepositoryForm";
import RepositoryImportStore from "./RepositoryImportStore";
import RepositoriesStore from "./RepositoriesStore";
import { setItem as setRepositoriesItem } from "./RepositoriesActions";


import { setItem, importData } from "./RepositoryImportActions";

class RepositoryImport extends RepositoryForm {
    setItem(item) {
        setItem(item);
    }

    submit() {
        const { data: { item } } = this.props;

        importData(item);
    }

    renderConfirmation() {
        const { intl, data: { item, busy, error } } = this.props;
        const message = intl.formatMessage("settings-repository-import" + (!item ? "-all" : "") + "-message", item);

        return (
            <Confirmation { ...{ intl, busy, error } }
                title={ intl.formatMessage("settings-repository-import-title") } message={ message }
                onSubmit={ this.submit } onCancel={ this.close } />
        );
    }

    renderSuccess() {
        const { intl, data: { item, importedData: { users, groups } } } = this.props;
        const params = Object.assign({}, item && item.valueOf(), {
            usersCount: Object.keys(users).length,
            groupsCount: Object.keys(groups).length
        });

        const message = intl.formatMessage("settings-repository-import-success"
            + (!item ? "-all" : "") + "-message", params);

        return (
            <Confirmation { ...{ intl } }
                title={ intl.formatMessage("settings-repository-import-success-title") }
                message={ message } submit={ intl.formatMessage("alert-ok") } cancel={ false }
                onSubmit={ this.close } onCancel={ this.close } />
        );
    }

    renderError() {
        const { intl, data: { error } } = this.props;

        return (
            <Confirmation { ...{ intl } }
                title={ intl.formatMessage("settings-repository-import-failed-title") }
                message={ intl.formatMessage(error.message) }
                submit={ intl.formatMessage("alert-ok") } cancel={ false }
                onSubmit={ this.close } onCancel={ this.close } />
        );
    }

    render() {
        const { data: { error, importedData } } = this.props;

        return error ? this.renderError() : importedData ? this.renderSuccess() : this.renderConfirmation();
    }
}

export default RepositoryImport.connect({ intl: IntlStore, data: RepositoryImportStore });
