import { Entity } from "./Entity";
import { Groups } from "./Groups";
import { Preferences } from "./Preferences";
import { User } from "./User";

class SessionUser extends User {
    init(...args) {
        super.init({ preferences: {} }, ...args);
    }
}

SessionUser.connect({
    preferences: Preferences
});

export class Session extends Entity {
    static get name() {
        return "Session";
    }

    get isGuest() {
        return !this.user;
    }

    valueOf() {
        const { user, groups } = this;

        return Object.assign(
            super.valueOf(),
            user && { user },
            groups && { groups }
        );
    }
}

Session.connect({
    user: SessionUser,
    groups: Groups
});
