import { Certificate } from "./Certificate";
import { Entity } from "./Entity";
import { NetworkRequest } from "./NetworkRequest";
import { User } from "./User";

export class NetworkCertificate extends Entity {
    get id() {
        return this.network.id + "-" + this.user.id;
    }

    set id(id) {
    }

    valueOf() {
        return Object.assign(super.valueOf(), { id: this.id });
    }
}

NetworkCertificate.connect({
    network: NetworkRequest,
    user: User,
    certificate: Certificate
});
