module.exports = {
    "cs-rs-title": {
        "en-US": "Credentials - CSRs"
    },

    "csr-tool-bar-search": {
        "en-US": "Search CSRs"
    },
    "csr-tool-bar-status": {
        "en-US": ""
    },
    "csr-tool-bar-create": {
        "en-US": "Generate CSR"
    },
    "csr-tool-bar-import": {
        "en-US": "Import CSR"
    },

    "cs-rs-table-column-num": {
        "en-US": "#"
    },
    "cs-rs-table-column-status": {
        "en-US": "S"
    },
    "cs-rs-table-column-name": {
        "en-US": "Alias"
    },
    "cs-rs-table-column-common-name": {
        "en-US": "Common Name"
    },
    "cs-rs-table-column-country": {
        "en-US": "Country"
    },
    "cs-rs-table-column-state": {
        "en-US": "State"
    },
    "cs-rs-table-column-city": {
        "en-US": "City"
    },
    "cs-rs-table-column-organization": {
        "en-US": "Organizational Unit"
    },
    "cs-rs-table-column-department": {
        "en-US": "Department"
    },
    "cs-rs-table-column-creation-time": {
        "en-US": "Issuance Time"
    },
    "cs-rs-table-column-actions": {
        "en-US": ""
    },

    "csr-row-view": {
        "en-US": "View CSR"
    },
    "csr-row-delete": {
        "en-US": "Delete CSR"
    },

    "csr-delete-title": {
        "en-US": "Delete CSR"
    },
    "csr-delete-failed-title": {
        "en-US": "Delete Failed"
    },
    "csr-delete-message": {
        "en-US": "Are you sure you want to delete CSR **{alias}**?"
    },
    "csr-delete-alert-ok": {
        "en-US": "OK"
    },

    "csr-create-title": {
        "en-US": "Generate CSR"
    },
    "settings-credentials-csr-view-title": {
        "en-US": "View CSR"
    },


    "settings-credentials-csr-import-title": {
        "en-US": "Import CSR"
    },
    "settings-credentials-csr-import-submit": {
        "en-US": "Import"
    },

    "settings-credentials-csr-import-name-label": {
        "en-US": "CSR Name"
    },
    "settings-credentials-csr-import-name-placeholder": {
        "en-US": ""
    },

    "settings-credentials-csr-import-pem-label": {
        "en-US": "Certificate"
    },
    "settings-credentials-csr-import-pem-placeholder": {
        "en-US": "Select File..."
    },
    "settings-credentials-csr-import-pem-clear": {
        "en-US": "Clear"
    },

    "settings-credentials-csr-import-pem-view-label": {
        "en-US": ""
    },
    "settings-credentials-csr-import-pem-view-placeholder": {
        "en-US": ""
    },

    "settings-credentials-csr-import-attributes-label": {
        "en-US": "Attributes"
    },


    "settings-credentials-csr-view-submit": {
        "en-US": "OK"
    },

    "settings-credentials-csr-view-name-label": {
        "en-US": "CSR Name"
    },
    "settings-credentials-csr-view-name-placeholder": {
        "en-US": ""
    },

    "settings-credentials-csr-view-pem-label": {
        "en-US": "Certificate"
    },
    "settings-credentials-csr-view-pem-placeholder": {
        "en-US": "Select File..."
    },
    "settings-credentials-csr-view-pem-clear": {
        "en-US": "Clear"
    },

    "settings-credentials-csr-view-pem-view-label": {
        "en-US": ""
    },
    "settings-credentials-csr-view-pem-view-placeholder": {
        "en-US": ""
    },

    "settings-credentials-csr-view-attributes-label": {
        "en-US": "Attributes"
    },

    "settings-credentials-csr-view-common-name-label": {
        "en-US": "Common Name"
    },
    "settings-credentials-csr-view-country-label": {
        "en-US": "Country"
    },
    "settings-credentials-csr-view-state-label": {
        "en-US": "State"
    },
    "settings-credentials-csr-view-city-label": {
        "en-US": "City"
    },
    "settings-credentials-csr-view-organization-label": {
        "en-US": "Organization"
    },
    "settings-credentials-csr-view-department-label": {
        "en-US": "Department"
    },
    "settings-credentials-csr-view-key-label": {
        "en-US": "Key"
    },
    "settings-credentials-csr-view-pending-label": {
        "en-US": "Pending"
    },


    "csr-create-submit": {
        "en-US": "Generate"
    },
    "csr-create-name-label": {
        "en-US": "CSR Name"
    },
    "csr-create-name-placeholder": {
        "en-US": ""
    },

    "csr-create-pem-label": {
        "en-US": "Certificate"
    },
    "csr-create-pem-placeholder": {
        "en-US": "Select File..."
    },
    "csr-create-pem-clear": {
        "en-US": "Clear"
    },

    "csr-create-pem-create-label": {
        "en-US": ""
    },
    "csr-create-pem-create-placeholder": {
        "en-US": ""
    },

    "csr-create-attributes-label": {
        "en-US": "Attributes"
    },

    "csr-create-common-name-label": {
        "en-US": "Common Name"
    },
    "csr-create-country-label": {
        "en-US": "Country"
    },
    "csr-create-state-label": {
        "en-US": "State"
    },
    "csr-create-city-label": {
        "en-US": "City"
    },
    "csr-create-organization-label": {
        "en-US": "Organizational Unit"
    },
    "csr-create-department-label": {
        "en-US": "Department"
    },
    "csr-create-key-type-label": {
        "en-US": "Key Type"
    },
    "csr-create-key-size-label": {
        "en-US": "Key Size"
    },
    "csr-create-pending-label": {
        "en-US": "Pending"
    },
    "csr-create-default-label": {
        "en-US": "Save as default"
    },

    "settings-credentials-csrs-loading": {
        "en-US": "Loading..."
    },

    "error-certificate-request-alias-exists": {
        "en-US": "This CSR alias already exists"
    },
    "error-certificate-request-alias-empty": {
        "en-US": "Name is required"
    },
    "error-certificate-request-common-name-empty": {
        "en-US": "Common Name is required"
    },
    "error-certificate-request-organization-empty": {
        "en-US": "Org. Unit is required"
    },
    "error-certificate-request-department-empty": {
        "en-US": "Department is required"
    },
    "error-certificate-request-country-empty": {
        "en-US": "Country is required"
    },
    "error-certificate-request-state-empty": {
        "en-US": "State is required"
    },
    "error-certificate-request-city-empty": {
        "en-US": "City is required"
    },
    "error-certificate-request-key-type-empty": {
        "en-US": "Key Type is required"
    },
    "error-certificate-request-key-size-empty": {
        "en-US": "Key Size is required"
    },

    "error-certificate-request-pem-empty": {
        "en-US": "Certificate is required"
    },
    "error-certificate-request-pem-invalid": {
        "en-US": "Invalid Certificate provided"
    }
};
