import { IntlStore } from "../../init";
import React from "react";

import { Component, FormField, FormInput, FormPassword, Link } from "../../ui";

import AuthForm from "./AuthForm";
import { login, reset, setItemField } from "./LoginActions";
import LoginStore from "./LoginStore";

function onFieldChange(value, name) {
    if (name) {
        setItemField(name, value);
    }
}

class Login extends Component {
    init(...args) {
        super.init(...args);

        this.onSubmit = this.onSubmit.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }

    componentWillMount() {
        reset();
    }

    onSubmit() {
        const { data: { item } } = this.props;

        login(item).catch(() => void 0);
    }

    onCancel() {
        this.props.router.replace("/auth/register");
    }

    render() {
        const { data: { item, busy, error }, intl } = this.props;

        return (
            <AuthForm { ...{ intl, busy, error } }
                autoComplete={ true }
                title={ this.formatMessage("title") }
                submit={ this.formatMessage("submit") }
                cancel={ this.formatMessage("register") }
                close={ false }
                className={ this.block() }
                onSubmit={ this.onSubmit }
                onCancel={ this.onCancel }>

                <FormField label={ this.formatMessage("username-label") }>
                    <FormInput type="text" name="username" value={ item.username }
                        placeholder={ this.formatMessage("username-placeholder") }
                        onChange={ onFieldChange } />
                </FormField>

                <FormField label={ this.formatMessage("password-label") }>
                    <FormPassword type="password" name="password" value={ item.password }
                        placeholder={ this.formatMessage("password-placeholder") }
                        onChange={ onFieldChange } />
                    <Link to="/auth/forgot-password" className={ this.element("forgot") }>
                        { this.formatMessage("forgot-password") }
                    </Link>
                </FormField>
            </AuthForm>
        );
    }
}

export default Login.connect({
    intl: IntlStore,
    data: LoginStore
});
