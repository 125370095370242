import React from "react";
import moment from "moment";

import { TableRow } from "../../../../ui";

export class TransactionsRow extends TableRow {
    renderUserAffiliationCell() {
        const { item: { user } } = this.props;
        const affiliation = user ? [].concat(user.company || [], user.location || [], user.role || []).join(".") : '';

        return <td data-title={ affiliation } className={ TransactionsRow.element("affiliation") }>{ affiliation }</td>;
    }

    renderUserCell() {
        const { item: { user } } = this.props;

        return <td data-title={ user ? user.name : 'System' } className={ user ? TransactionsRow.element("user") : TransactionsRow.element("system") }>{ user ? user.name : 'System' }</td>;
    }

    renderActionCell() {
        const action = this.props.item.action||'';

        return (
            <td className={ TransactionsRow.element("action", { [action.toLowerCase()]: true }) }>
                { action }
            </td>
        );
    }

    renderStockCell() {
        const stock = this.props.item.stock||'';
        const color = this.props.item.color||'';

        return (
            <td className={ TransactionsRow.element("stock", { [stock.toLowerCase()]: true }) } style={ { color } }>
                { stock }
            </td>
        );
    }

    renderUnitsCell() {
        const units = this.props.item.units||'';

        return <td className={ TransactionsRow.element("units") }>{ units }</td>;
    }

    renderPriceCell() {
        const { item, intl } = this.props;
        const price = this.props.item.price || 0;

        return <td className={ TransactionsRow.element("price") }>{ intl.formatNumber(price / 100) }</td>;
    }

    renderTTLCell() {
        const TTL = this.props.item.TTL || 0;
        return <td className={ TransactionsRow.element("ttl") }>{ TTL }</td>;
    }

    renderCreatedCell(format = "YYYY-MM-DD HH:mm:ss") {
        const date = moment(this.props.item.created).format(format);

        return <td className={ TransactionsRow.element("created") } data-title={ date }>{ date }</td>;
    }

    renderIdCell() {
        const { item: { id } } = this.props;

        return <td className={ TransactionsRow.element("id") } data-title={ id }>{ id }</td>;
    }

    renderTypeCell() {
        const type = this.props.item.type || '';
        const typeColor = ({
            'Transform Data': '#7c89b0',
            'Approve New User': '#5b8b5b'
        })[type];

        return <td className={ TransactionsRow.element("type") } data-title={type} style={{ color: typeColor }}>{ type }</td>
    }

    render() {
        return (
            <TableRow { ...this.props } enumerable={ true } className={ this.block() } />
        );
    }
}
