import { TableActions } from "../../../../components/Table";

const Actions = new TableActions();

export default Actions;
export const refresh = Actions.refresh.trigger;
export const setFilter = Actions.setFilter.trigger;
export const setSort = Actions.setSort.trigger;

export const setPage = setFilter.bind(null, "page");
export const setSearch = setFilter.bind(null, "search");
export const setGroups = setFilter.bind(null, "groups");
