import React from "react";
import moment from "moment";

import { Collection } from "../../../../utils/dataflow";
import {
    Main,
    NotificationActions,
    NotificationBody,
    NotificationCategory,
    NotificationImportance,
    NotificationTitle,
    Table,
    TableFilter,
    TableRow,
    TableToolBar
} from "../../../../ui";

import { TableView } from "../../../../components/Table";
import { setTitle } from "../../../../components/Header";

import NotificationsStore from "./NotificationsStore";
import NotificationsActions, { setCategory, setPage, setSearch, setSort } from "./NotificationsActions";

const CATEGORIES = new Collection([
    { id: "all", name: "All" },
    { id: "CATEGORY_ALARM", name: "Alerts" },
    { id: "request_pending,request_approved,request_denied", name: "Requests" },
    { id: "notification", name: "Notifications" }
]);

function formatDate(date) {
    return moment(date).format("YYYY-MM-DD HH:mm");
}

class NotificationsRow extends TableRow {
    renderCategoryCell() {
        const { intl, item } = this.props;

        return (
            <td className={ this.element("category") }>
                <NotificationCategory { ...{ intl, item } } />
            </td>
        );
    }

    renderImportanceCell() {
        const { intl, item } = this.props;

        return (
            <td className={ this.element("importance") }>
                <NotificationImportance { ...{ intl, item } } />
            </td>
        );
    }

    renderMainCell() {
        const { intl, item, onClick } = this.props;

        return (
            <td data-id={ item.id } className={ this.element("main") } onClick={ onClick }>
                <div className={ this.element("header") }>
                    <NotificationTitle { ...{ intl, item } } className={ this.element("title") } />
                    <NotificationBody { ...{ intl, item } } className={ this.element("body") } />
                </div>
            </td>
        );
    }

    renderTimeCell() {
        const { item: { creationTime } } = this.props;

        return (
            <td className={ this.element("time") }>
                { formatDate(creationTime) }
            </td>
        );
    }

    render() {
        return (
            <TableRow { ...this.props } enumerable={ true } className={ this.block() }>
                { this.renderCategoryCell() }
                { this.renderImportanceCell() }
                { this.renderMainCell() }
                { this.renderTimeCell() }
            </TableRow>
        );
    };
}

class NotificationsExpandedRow extends NotificationsRow {
    renderMainCell() {
        const { intl, item, onClick } = this.props;

        return (
            <td data-id={ item.id } colSpan="2" className={ this.element("main") } onClick={ onClick }>
                <div className={ this.element("header") }>
                    <NotificationTitle { ...{ intl, item } } className={ this.element("title") } />
                    <div className={ this.element("time") }>
                        { formatDate(item.creationTime) }
                    </div>
                </div>
                <NotificationBody { ...{ intl, item } } className={ this.element("body") } />
                <NotificationActions { ...{ intl, item } } className={ this.element("actions") } />
            </td>
        );
    }

    render() {
        return (
            <TableRow { ...this.props } enumerable={ true } className={ this.block() }>
                { this.renderCategoryCell() }
                { this.renderImportanceCell() }
                { this.renderMainCell() }
            </TableRow>
        );
    };
}

class NotificationsTable extends Table {
    init(...args) {
        super.init(...args);

        this.initState({ expanded: {} });
        this.onRowClick = this.onRowClick.bind(this);
    }

    sortByColumn(column) {
        setSort(column);
    }

    setPage(page) {
        setPage(page);
    }

    onRowClick({ currentTarget }) {
        const id = currentTarget.getAttribute("data-id");
        const { expanded } = this.state;

        this.setState({ expanded: Object.assign({}, expanded, { [id]: !expanded[id] }) });
    }

    renderHead() {
        return (
            <tr>
                { this.renderHeadCell("category", "category") }
                { this.renderHeadCell("importance", "importance") }
                { this.renderHeadCell("title") }
                { this.renderHeadCell("time", "creationTime") }
            </tr>
        );
    }

    renderRow(item, index) {
        const { props: { intl }, state: { expanded } } = this;
        const { id } = item;
        const Row = expanded[id] ? NotificationsExpandedRow : NotificationsRow;

        return <Row { ...{ key: id, intl, item, onClick: this.onRowClick } } />;
    }
}

class NotificationsToolBar extends TableToolBar {
    renderCategorySelect() {
        return (
            <TableFilter items={ CATEGORIES } className={ this.element("category-select") } />
        );
    }

    render() {
        return (
            <TableToolBar { ...this.props } className={ this.block() }>
                { this.renderCategorySelect() }
                { this.renderSearch() }
            </TableToolBar>
        );
    }
}

export class Notifications extends TableView.timerOwner() {
    componentWillMount() {
        const { params: { category } } = this.props;

        setTitle(this.formatMessage("title"));
        setCategory(category);

        super.componentWillMount();
    }

    componentWillReceiveProps(nextProps) {
        const { params: { category: currCategory } } = this.props;
        const { params: { category: nextCategory } } = nextProps;

        if (nextCategory !== currCategory) {
            setCategory(nextCategory);
        }
    }

    refresh() {
        super.refresh().then(() => this.setRefreshTimeout()).catch(() => void 0);
    }

    setRefreshTimeout() {
        this.setTimeout(() => this.refresh(), 2000);
    }

    renderToolBar() {
        const { intl, data: { filter } } = this.props;

        return (
            <NotificationsToolBar { ...{ intl, filter } } onSearch={ setSearch } />
        );
    }

    render() {
        const { intl, data: { item: items, filter } } = this.props;

        return (
            <Main toolBar={ this.renderToolBar() } status={ this.renderStatus() }
                className={ this.block() }>
                <NotificationsTable { ...{ intl, filter, items } } className={ this.element("table") } />
            </Main>
        );
    }
}

export default Notifications.of(NotificationsStore, NotificationsActions);
