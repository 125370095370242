import { IntlStore } from "../../../../../init";
import { CSR_KEY_SIZES, CSR_KEY_TYPES } from "../../../../../model";
import React from "react";
import { FormCheckBox, FormField, FormInput, GenericForm, Select, SelectCountry } from "../../../../../ui";
import { Collection } from "../../../../../utils/dataflow";

import { nvl } from "../../../../../utils/object";
import { loadDefault, saveItem, setItem, setItemField } from "./CSRCreateActions";
import CSRCreateStore from "./CSRCreateStore";

import CSRForm from "./CSRForm";

const KEY_TYPES = new Collection(CSR_KEY_TYPES);
const KEY_SIZES = new Collection(CSR_KEY_SIZES);

function onFieldChange(value, name) {
    if (name) {
        setItemField(name, value);
    }
}

function onCountryChange(value) {
    setItemField("country", value);
}

function onKeyTypeChange(value) {
    setItemField("keyType", value);
}

function onKeysSizeChange(value) {
    setItemField("keySize", value);
}

export class CSRCreate extends CSRForm {
    setItem(item) {
        return setItem(item || new CSRCreateStore.Model.Item());
    }

    componentWillMount() {
        super.componentWillMount();

        if (!this.props.item) {
            loadDefault();
        }
    }

    submit() {
        const { data: { item } } = this.props;

        saveItem(item).then(() => this.onSubmit()).catch(() => void 0);
    }

    render() {
        const { intl, data: { busy, error, item }, header, title, submit, cancel, close } = this.props;

        if (!item) {
            return null;
        }

        return (
            <GenericForm { ...{ intl, busy, error, cancel, header } }
                title={ nvl(title, this.formatMessage("title", item)) }
                submit={ nvl(submit, this.formatMessage("submit")) }
                close={ nvl(close, true) }
                className={ this.block() }
                onSubmit={ this.submit } onCancel={ this.close }>

                <FormField name="alias"
                    label={ this.formatMessage("name-label") }>
                    <FormInput type="text" name="alias" value={ item.alias }
                        placeholder={ this.formatMessage("name-placeholder") }
                        onChange={ onFieldChange } />
                </FormField>

                <FormField name="commonName"
                    label={ this.formatMessage("common-name-label") }>
                    <FormInput type="text" name="commonName" value={ item.commonName }
                        onChange={ onFieldChange } />
                </FormField>

                <FormField name="organization"
                    label={ this.formatMessage("organization-label") }>
                    <FormInput type="text" name="organization" value={ item.organization }
                        onChange={ onFieldChange } />
                </FormField>

                <FormField name="department"
                    label={ this.formatMessage("department-label") }>
                    <FormInput type="text" name="department" value={ item.department }
                        onChange={ onFieldChange } />
                </FormField>

                <FormField name="country"
                    label={ this.formatMessage("country-label") }>
                    <SelectCountry intl={ intl } name="country" value={ item.country } nullValue={ false }
                        placeholder={ this.formatMessage("auth-register-country-placeholder") }
                        onChange={ onCountryChange } />
                </FormField>

                <FormField name="state"
                    label={ this.formatMessage("state-label") }>
                    <FormInput type="text" name="state" value={ item.state }
                        onChange={ onFieldChange } />
                </FormField>

                <FormField name="city"
                    label={ this.formatMessage("city-label") }>
                    <FormInput type="text" name="city" value={ item.city }
                        onChange={ onFieldChange } />
                </FormField>

                <FormField name="keyType"
                    label={ this.formatMessage("key-type-label") }>
                    <Select intl={ intl } value={ item.keyType } items={ KEY_TYPES } nullValue={ null } search={ false }
                        placeholder={ "" }
                        onChange={ onKeyTypeChange } />
                </FormField>

                <FormField name="keySize"
                    label={ this.formatMessage("key-size-label") }>
                    <Select intl={ intl } value={ item.keySize } items={ KEY_SIZES } nullValue={ null } search={ false }
                        placeholder={ "" }
                        onChange={ onKeysSizeChange } />
                </FormField>

                <FormField name="default">
                    <FormCheckBox name="default" value={ item.default }
                        placeholder={ this.formatMessage("default-label") }
                        onChange={ onFieldChange } />
                </FormField>
            </GenericForm>
        );
    }
}

export default CSRCreate.connect({ intl: IntlStore, data: CSRCreateStore });
