import { IntlStore } from "../../../../init";
import React from "react";
import { Component, FormField, FormInput, GenericForm, Link, Markdown, SideBar, ToolBar } from "../../../../ui";

import { setItem } from "./NetworkActions";
import NetworksStore from "./NetworksStore";
import NetworkStore from "./NetworkStore";

function setNetworkItem(networkId, networks) {
    setItem(networks.value(networkId));
}

class Network extends Component {
    componentWillMount() {
        const { params: { networkId }, items: { item: networks } } = this.props;

        setNetworkItem(networkId, networks);
    }

    componentWillReceiveProps(nextProps) {
        const { params: { networkId: currNetworkId }, items: { item: currNetworks } } = this.props;
        const { params: { networkId: nextNetworkId }, items: { item: nextNetworks } } = nextProps;

        if (nextNetworkId !== currNetworkId || nextNetworks !== currNetworks) {
            setNetworkItem(nextNetworkId, nextNetworks);
        }
    }

    renderToolBar() {
        const { data: { item } } = this.props;
        const { formattedName } = item;

        return (
            <ToolBar>
                <Markdown className={ this.element("title") }>
                    { this.formatMessage("title", item.valueOf()) }
                </Markdown>
                <Link to={ `join/${formattedName}` } className={ this.element("join") }>
                    { this.formatMessage("join") }
                </Link>
                <Link to={ `add/${formattedName}` } className={ this.element("add") }>
                    { this.formatMessage("add") }
                </Link>
            </ToolBar>
        );
    }

    render() {
        const { data: { item }, intl } = this.props;

        if (!item) {
            return null;
        }

        return (
            <SideBar toolBar={ this.renderToolBar() } className={ this.block() }>
                <GenericForm { ...{ intl } } header={ false } submit={ false } cancel={ false }>
                    <FormField name="name" label={ this.formatMessage("name-label") }>
                        <FormInput type="text" value={ item.name } />
                    </FormField>
                    <FormField name="groupId" label={ this.formatMessage("parent-label") }>
                        <FormInput value={ item.group ? item.group.name : this.formatMessage("parent-none") } />
                    </FormField>
                </GenericForm>
            </SideBar>
        );
    }
}

export default Network.connect({
    intl: IntlStore,
    data: NetworkStore,
    items: NetworksStore
});
