import React from "react";
import { Route, IndexRedirect } from "react-router";

import Layout from "./Settings";
import { RepositoriesRoute } from "./Repositories";
import { ProfileRoute } from "./Profile";
import { PreferencesRoute } from "./Preferences";
import { CredentialsRoute } from "./Credentials";

export default function route(path) {
    return (
        <Route path={ path } component={ Layout }>
            <IndexRedirect to="repositories/" />
            { RepositoriesRoute("repositories/") }
            { CredentialsRoute("credentials/") }
            { PreferencesRoute("preferences/") }
            { ProfileRoute("profile/") }
        </Route>
    );
};
