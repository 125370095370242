import { APIConnector } from "../../../../../init";
import { FormEditStore } from "../../../../../components/Form";

import CSRCreateModel, { CreateCertificateRequest } from "./CSRCreateModel";
import CSRCreateActions from "./CSRCreateActions";

class CSRCreateStore extends FormEditStore.of(CSRCreateModel) {
    processError(error) {
        const { item } = this.state;

        error = super.processError(error);

        if (error.code === "error-certificate-request-duplicate-key-violation") {
            error = super.processError(item.getError2("exists", "alias"));
        }

        return error;
    }

    loadDefault() {
        return APIConnector.fetch(APIConnector.buildUrl("CertificateRequests/default"))
            .then((data) => APIConnector.response(data))
            .then((item) => {
                this.setItem(new CreateCertificateRequest(Object.assign({}, item, { id: null })));
            });
    }
}

export default new CSRCreateStore().connect(CSRCreateActions);
