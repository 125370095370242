import React from "react";

import { LDAPEntity } from "../../../../model";
import { IntlStore } from "../../../../init";
import { SelectGroup, SelectPermissions } from "../../../../components/Select";
import { GenericForm, FormField, FormInput } from "../../../../ui";

import PermissionForm from "./PermissionForm";
import PermissionEditStore from "./PermissionEditStore";
import { setItem, setItemField, setItemGroupId, setItemPermissions, saveItem } from "./PermissionEditActions";

function onFieldChange(value, name) {
    if (name) {
        setItemField(name, value);
    }
}

class PermissionEdit extends PermissionForm {
    setItem(item) {
        setItem(item || new PermissionEditStore.Model.Item());
    }

    submit() {
        const { data: { item } } = this.props;

        saveItem(item).then(() => this.onSubmit()).catch(() => void 0);
    }

    filterGroups(id, group, index, groups) {
        let isParent = false;

        while (id && group && !isParent) {
            isParent = group.id === id;

            group = groups.value(group.groupId);
        }

        return !isParent;
    }

    render() {
        const { intl, data: { busy, error, item } } = this.props;

        if (!item) {
            return null;
        }

        return (
            <GenericForm { ...{ intl, busy, error } }
                title={ intl.formatMessage(`manage-permission-${item.id ? "edit" : "create"}-title`, item) }
                onSubmit={ this.submit } onCancel={ this.close }>

                <FormField name="name" label={ intl.formatMessage("manage-permission-edit-name-label") }>
                    <FormInput type="text" name="name" value={ item.name }
                        placeholder={ intl.formatMessage("manage-permission-edit-name-placeholder") }
                        onChange={ onFieldChange } />
                </FormField>
                <FormField name="groupId" label={ intl.formatMessage("manage-permission-edit-parent-label") }>
                    <SelectGroup value={ item.groupId }
                        filter={ this.filterGroups.bind(this, item.id) } sort={ LDAPEntity.sort }
                        dropClassName="drop_wide"
                        placeholder={ intl.formatMessage("manage-permission-edit-parent-placeholder") }
                        onChange={ setItemGroupId } />
                </FormField>
                <FormField name="permissions">
                    <SelectPermissions value={ item.permissions } sort={ LDAPEntity.sort }
                        placeholder={ intl.formatMessage("manage-permission-edit-permissions-label") }
                        dropClassName="drop_wide"
                        onChange={ setItemPermissions } />
                </FormField>
            </GenericForm >
        );
    }
}

export default PermissionEdit.connect({ intl: IntlStore, data: PermissionEditStore });
