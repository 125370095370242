import { Action } from "../../../../utils/dataflow";
import { TableActions } from "../../../../components/Table";

export class RepositoriesActions extends TableActions {
    constructor() {
        super();

        Object.assign(this, {
            moveRowUp: new Action,
            moveRowDown: new Action,
            placeBefore: new Action,
            moveRowBefore: new Action,
            commitRowPosition: new Action
        });
    }
}

const Actions = new RepositoriesActions();

export default Actions;
export const setItem = Actions.setItem.trigger;
export const refresh = Actions.refresh.trigger;
export const setFilter = Actions.setFilter.trigger;
export const setSort = Actions.setSort.trigger;
export const moveRowUp = Actions.moveRowUp.trigger;
export const moveRowDown = Actions.moveRowDown.trigger;
export const moveRowBefore = Actions.moveRowBefore.trigger;
export const commitRowPosition = Actions.commitRowPosition.trigger;

export const setPage = setFilter.bind(null, "page");
export const setSearch = setFilter.bind(null, "search");
