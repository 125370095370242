import React from "react";
import PropTypes from "prop-types";

import { Notifications } from "../../model";
import { Component } from "../Component";
import { DropOwner, DROP_ALIGN_CENTER, DROP_ALIGN_VERTICAL } from "..";

import { IntlModel } from "../../components/Intl";

import { NotificationsPopup } from "./NotificationsPopup";
import { NotificationsPreview } from "./NotificationsPreview";

const ALIGN = [{ side: "right", to: "right", offset: -36 }, DROP_ALIGN_VERTICAL];

export class NotificationsIcon extends Component.aggregate(DropOwner) {
    get dropId() {
        return "drop-notification";
    }

    init(...args) {
        super.init(...args);

        this.initDrop();
        this.initState({ previewId: null });
        this.onClick = this.onClick.bind(this);
        this.onPreviewSelect = this.onPreviewSelect.bind(this);
    }

    getUnread() {
        return this.props.items.filter((item) => !item.readTime);
    }

    showPopup() {
        const items = this.getUnread();

        if (items.length) {
            const { refs: { icon }, onDropHide } = this;
            const { intl } = this.props;

            this.showDrop(
                <NotificationsPopup { ...{ intl, items, close: onDropHide } } />,
                {
                    id: "popup",
                    to: icon,
                    align: DROP_ALIGN_CENTER,
                    hideOnMouseDown: false,
                    hideOnScroll: false,
                    className: "drop_notifications-popup"
                }
            );
        }
    }

    onPreviewSelect(previewId) {
        this.setState({ previewId });
    }

    showPreview() {
        const { intl, items } = this.props;
        const { previewId: itemId } = this.state;
        const { refs: { icon }, onDropHide } = this;

        this.showDrop(
            <NotificationsPreview { ...{ intl, items, itemId, close: onDropHide, onSelect: this.onPreviewSelect } } />,
            {
                id: "preview",
                to: icon,
                align: ALIGN,
                className: "drop_common drop_arrow drop_notifications-preview"
            }
        );
    }

    showDropDown() {
        const { drop } = this.state;
        const unread = this.getUnread();

        if (unread.length) {
            const showPopup = !drop || drop.props.id === "popup";

            if (showPopup) {
                this.showPopup();
            } else if (drop) {
                this.showPreview();
            }
        } else if (drop) {
            if (drop.props.id === "popup") {
                this.hideDrop();
            } else {
                this.showPreview();
            }
        }
    }

    componentDidMount() {
        this.showDropDown();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.items !== this.props.items || prevState.previewId !== this.state.previewId) {
            this.showDropDown();
        }

        super.componentDidUpdate(prevProps, prevState);
    }

    onClick() {
        const { drop } = this.state;

        if (!drop || drop.props.id === "preview") {
            drop ? this.hideDrop() : this.showPreview(this.props);
        }
    }

    render() {
        const length = this.getUnread(this.props).length;

        return (
            <div ref="icon" className={ this.cx("notification-icon", { "notification-icon_empty": length === 0 }) }
                onClick={ this.onClick }>
                <i className="ico ico-bell" />
                { length ? <span className="notification-icon__count">{ length }</span> : null }
            </div>
        );
    }
}

NotificationsIcon.initProps({
    intl: { type: PropTypes.instanceOf(IntlModel).isRequired },
    items: { type: PropTypes.instanceOf(Notifications).isRequired }
});
