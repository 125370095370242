import { Collection } from "../utils/dataflow";
import { Transaction } from "./Transaction";

function unitsGroupedBy(transactions, field) {
    const groups = {};
    let total = 0;

    transactions.forEach((transaction) => {
        const { units, color } = transaction;
        const groupId = typeof field === "function" ? field(transaction) : transaction[field];

        if (!groups.hasOwnProperty(groupId)) {
            groups[groupId] = { label: groupId, value: 0, color };
        }

        total += units;
        groups[groupId].value += units;
    });

    return {
        items: Object.keys(groups).map((id) => groups[id]).sort((i1, i2) => i2.value - i1.value),
        total: total
    };
}

function define(target, prop, field) {
    if (!target.hasOwnProperty(`_${prop}`)) {
        const { items, total } = unitsGroupedBy(target, field);

        target.define({ [`_${prop}`]: items, [`_${prop}Total`]: total });
    }

    return target;
}

function userName(transaction) {
    return transaction.user ? transaction.user.name : 'System';
}

export class Transactions extends Collection.of(Transaction) {
    get stocksAllocation() {
        return define(this, "stocksAllocation", "stock")._stocksAllocation;
    }

    get stocksAllocationTotal() {
        return define(this, "stocksAllocation", "stock")._stocksAllocationTotal;
    }

    get usersAllocation() {
        return define(this, "usersAllocation", userName)._usersAllocation;
    }

    get usersAllocationTotal() {
        return define(this, "usersAllocation", userName)._usersAllocationTotal;
    }
}
