import { Action } from "../../utils/dataflow";
import { ViewActions } from "../View";

export class FormActions extends ViewActions {
    constructor() {
        super();

        Object.assign(this, {
            setItemField: new Action(),
            setItemError: new Action(),
            saveItem: new Action(),
            deleteItem: new Action()
        });
    }
}
