import "intl";
import "intl/locale-data/jsonp/en-US";

import IntlMessageFormat from "intl-messageformat";
import memoizeIntlConstructor from "intl-format-cache";

import { valueOf } from "../../utils/object";
import { Model } from "../../utils/dataflow";
import { IntlFormats } from "./IntlFormats";

const getDateTimeFormat = memoizeIntlConstructor(Intl.DateTimeFormat);
const getNumberFormat = memoizeIntlConstructor(Intl.NumberFormat);
const getMessageFormat = memoizeIntlConstructor(IntlMessageFormat);

const DEFAULT_FORMATS = {
    date: {
        long: { year: "numeric", month: "long", day: "numeric" },
        short: { year: "numeric", month: "2-digit", day: "2-digit" }
    },
    time: {
        long: { hour: "numeric", minute: "numeric", second: "numeric" },
        short: { hour: "numeric", minute: "numeric" }
    },
    money: {},
    number: {}
};

export class IntlModel extends Model {
    init(...data) {
        super.init({ formats: DEFAULT_FORMATS, messages: {} }, ...data);
    }

    _format(type, preset) {
        let format = this.formats[type];

        if (!format) {
            throw new Error(`${this}: invalid format type`);
        }

        if (preset) {
            format = format[preset];
            if (!format) {
                throw new Error(`${this}: invalid format ${type} preset`);
            }
        }

        return format.valueOf();
    }

    formatDate(value, preset = "short") {
        return getDateTimeFormat(this.locale, this._format("date", preset)).format(value);
    }

    formatTime(value, preset = "short") {
        return getDateTimeFormat(this.locale, this._format("time", preset)).format(value);
    }

    formatNumber(value) {
        return getNumberFormat(this.locale, this._format("number")).format(value);
    }

    formatMoney(value, currency) {
        return getNumberFormat(this.locale, Object.assign(this._format("money"), { currency })).format(value);
    }

    formatMessage(value, opts) {
        const { messages, locale } = this;
        let message = value;

        if (messages.hasOwnProperty(value)) {
            message = messages[value];
            message = getMessageFormat(message[locale], locale).format(valueOf(opts));
        }

        return message;
    }

    valueOf() {
        return Object.assign(super.valueOf(), {
            formats: this.formats,
            messages: this.messages
        });
    }
}

IntlModel.connect({
    formats: IntlFormats,
    messages: Model
});
