import moment from "moment";

import { APIConnector } from "../../init";
import { TableStore } from "../Table";
import { saveItem } from "../Notification";

import NotificationsModel from "./NotificationsModel";

export class NotificationsStore extends TableStore.of(NotificationsModel) {
    setReadTime(item) {
        const { item: items } = this.state;

        item = item.set({ readTime: moment().toISOString() });
        this.setItem(items.updateItems(item));

        return item;
    }

    readNotification(item) {
        item = this.setReadTime(item);

        return saveItem(item, item.valueOf());
    }

    responseRequest(item, choice) {
        this.setReadTime(item);

        return APIConnector.fetch(APIConnector.buildUrl("CSRResponse/"), {
            method: "POST",
            body: APIConnector.stringify({
                notificationID: item.id,
                deviceId: "Web",
                choice
            })
        }).then((data) => APIConnector.response(data));
    }

    acceptRequest(item) {
        return this.responseRequest(item, "Accept");
    }

    denyRequest(item) {
        return this.responseRequest(item, "Deny");
    }

    addToKeyStore(item) {
        this.setReadTime(item);

        return APIConnector.fetch(APIConnector.buildUrl("AddCertificate/"), {
            method: "POST",
            body: APIConnector.stringify({
                notificationID: item.id,
                deviceId: "Web",
                choice: "Add"
            })
        }).then((data) => APIConnector.response(data));
    }
}

export default new NotificationsStore;
