import { warning } from "../console";
import objectMap from "./map";
import typeOf from "./typeOf";

function validate(value, validator, message = "%1", ...params) {
    let err;
    let errType;

    err = validator(value);
    errType = typeOf(err);

    if (err) {
        if (errType !== "error") {
            warning("Checker function must return null or an Error but returned a %1", errType);
        }

        warning(message || "%1", Error.message, ...params);
    }

    return !err;
}

function isDefinedValidator(value) {
    return value === null || value === void 0 ? new TypeError("Value must be defined") : null;
}

function isDefined(value, message) {
    return validate(value, isDefinedValidator, message);
}

function isFunctionValidator(value) {
    return typeof value === "function" ? null : new TypeError("Validator must be a function");
}

function toValidator(value) {
    const err = isFunctionValidator(value);

    return err ? () => err : value;
}

function valueValidator(validator, message) {
    validator = toValidator(validator);

    return (value) => {
        return validate(value, validator, message);
    };
}

function arrayValidator(validator, message) {
    validator = validator.map(toValidator);

    return (value) => isDefined(value, message)
        && validator.every((validator, i) => validate(value[i], validator, message, i));
}

function objectValidator(validator, message) {
    validator = objectMap(validator, toValidator);

    return (value) => isDefined(value, message)
        && Object.keys(validator).every((key) => validate(value[key], validator[key], message, key));
}

export default function(validator, message) {
    const type = typeOf(validator);
    const validate = type === "object" ? objectValidator : type === "array" ? arrayValidator : valueValidator;

    return validate(validator, message);
}
