import { setTitle } from "../../../../../components/Header";

import { IntlStore } from "../../../../../init";
import React from "react";
import { Component, FormField, FormPassword, GenericForm, ModalOwner } from "../../../../../ui";
import { resetPassword, setItem, setItemField } from "./PasswordActions";

import PasswordStore from "./PasswordStore";

function onFieldChange(value, name) {
    if (name) {
        setItemField(name, value);
    }
}

class Password extends Component.aggregate(ModalOwner) {
    get modalId() {
        return this.constructor.name.toLowerCase();
    }

    init(...args) {
        super.init(...args);

        this.onSubmit = this.onSubmit.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }

    setItem() {
        setItem(new PasswordStore.Model.Item());
    }

    componentWillMount() {
        const { intl } = this.props;

        this.setItem();
        setTitle(intl.formatMessage("settings-profile-title")
            + " - " + intl.formatMessage("settings-profile-password-title"));
    }

    close() {
        const { router, location: { pathname } } = this.props;

        this.setItem();
        router.replace(pathname + "/success");
    }

    onSuccess() {
        this.close();
    }

    onSubmit() {
        const { data: { item } } = this.props;

        resetPassword(item).then(() => this.onSuccess()).catch(() => void 0);
    }

    onCancel() {
        this.close();
    }

    render() {
        const { intl, data: { busy, error, item } } = this.props;

        if (!item) {
            return null;
        }

        return (
            <GenericForm { ...{ intl, busy, error } } close={ false } cancel={ false } header={ false }
                className={ this.block() }
                onSubmit={ this.onSubmit } onCancel={ this.onCancel }>

                <FormField name="password"
                    label={ intl.formatMessage("settings-profile-password-curr-password-label") }>
                    <FormPassword name="password" value={ item.password } canBeVisible={ false }
                        onChange={ onFieldChange } />
                </FormField>

                <FormField name="nextPassword"
                    label={ intl.formatMessage("settings-profile-password-next-password-label") }>
                    <FormPassword name="nextPassword" value={ item.nextPassword }
                        onChange={ onFieldChange } />
                </FormField>

                <FormField name="confirmPassword"
                    label={ intl.formatMessage("settings-profile-password-confirm-password-label") }>
                    <FormPassword name="confirmPassword" value={ item.confirmPassword } canBeVisible={ false }
                        onChange={ onFieldChange } />
                </FormField>
            </GenericForm>
        );
    }
}

export default Password.connect({ intl: IntlStore, data: PasswordStore });
