import React from "react";
import { Link, List, Main, Table, TableRow, TableToolBar } from "../../../../ui";

import { TableView } from "../../../../components/Table";
import { setTitle } from "../../../../components/Header";

import GroupsStore from "./GroupsStore";
import GroupsActions, { setPage, setSearch, setSort } from "./GroupsActions";

class GroupRow extends TableRow {
    renderMenu() {
        const { props: { intl, item }, onDropHide } = this;

        return (
            <List onClick={ onDropHide }>
                <Link to={ item.id + "/edit" }>{ intl.formatMessage("manage-groups-edit") }</Link>
                <Link to={ item.id + "/delete" }>{ intl.formatMessage("manage-groups-delete") }</Link>
            </List>
        );
    }

    renderToggleIcon() {
        const { expanded } = this.props;

        let className = "group-title__icon ico " + (expanded ? "ico-minus" : "ico-plus");

        return <i className={ className } />;
    }

    renderLines() {
        const { lasts } = this.props;
        const lines = [];

        for (let i = 1, l = lasts.length; i < l; i++) {
            if (!lasts[i]) {
                lines.push(<div key={ i } className="group-title__line"
                    style={ { left: -i * 30 } } />);
            }
        }

        return lines;
    }

    renderNameCell() {
        const { item, level, expandable } = this.props;
        const name = item.name.split(".").shift();

        return (
            <td data-title={ item.name } className="group-row__name" style={ { paddingLeft: 10 + level * 30 } }>
                <div className="group-title">
                    { expandable ? this.renderToggleIcon() : "" }
                    <span className="group-title__name">{ name }</span>
                    { this.renderLines() }
                </div>
            </td>
        );
    }

    render() {
        const { level, expandable, expanded, lasts } = this.props;
        const last = lasts[0];

        return (
            <TableRow { ...this.props } enumerable={ level === 0 }
                className={ this.block({ expandable, expanded, level: String(level), last }) }>
                { level ? this.renderCell() : this.renderNumCell() }
                { this.renderNameCell() }
                { this.renderMenuCell() }
            </TableRow>
        );
    };
}

class GroupsTable extends Table {
    init(...args) {
        super.init(...args);

        this.initState({ expanded: {} });
        this.onRowClick = this.onRowClick.bind(this);
    }

    sortByColumn(column) {
        setSort(column);
    }

    setPage(page) {
        setPage(page);
    }

    renderHead() {
        return (
            <tr>
                { this.renderHeadNum() }
                { this.renderHeadCell("name", "name") }
                { this.renderHeadCell("actions") }
            </tr>
        );
    }

    toggle(id) {
        const expanded = this.state.expanded;

        this.setState({ expanded: Object.assign({}, expanded, { [id]: !expanded[id] }) });
    }

    onRowClick({ currentTarget }) {
        const id = currentTarget.getAttribute("data-id");

        this.toggle(id);
    }

    renderRow(rows, item, level, ...lasts) {
        const { state: { expanded: expandedState }, props: { items, intl } } = this;
        const children = items.getChildren(item);
        const expandable = children.length !== 0;
        const expanded = expandable && expandedState[item.id];

        const props = { intl, item, level, expandable, expanded, lasts, onClick: this.onRowClick };

        rows.push(<GroupRow key={ item.id } { ...props } />);

        if (expanded) {
            this.renderChildrenRows(rows, children, level + 1, ...lasts);
        }
    }

    renderChildrenRows(rows, items, level, ...lasts) {
        items.forEach((item, index) => {
            this.renderRow(rows, item, level, index === items.length - 1, ...lasts);
        });

        return rows;
    }

    renderRows() {
        return this.renderChildrenRows([], this.props.items.getTopLevel(), 0);
    }
}

class GroupsToolBar extends TableToolBar {
    render() {
        const { intl } = this.props;

        return (
            <TableToolBar className={ this.block() }>
                { this.renderSearch() }
                <Link to="create" className="action action_add link_accent">
                    { intl.formatMessage("manage-groups-create") }
                </Link>
            </TableToolBar>
        );
    }
}

export class Groups extends TableView {
    componentWillMount() {
        super.componentWillMount();

        setTitle(this.formatMessage("title"));
    }

    renderToolBar() {
        const { intl, data: { filter } } = this.props;

        return (
            <GroupsToolBar { ...{ intl, filter } } onSearch={ setSearch } />
        );
    }

    render() {
        const { intl, data: { item: items, filter } } = this.props;

        return (
            <Main className={ this.block() } toolBar={ this.renderToolBar() } status={ this.renderStatus() }>
                <GroupsTable { ...{ intl, filter, items } } />
            </Main>
        );
    }
}

export default Groups.of(GroupsStore, GroupsActions);
