const DOCUMENT_POSITION_CONTAINED_BY = Node.DOCUMENT_POSITION_CONTAINED_BY || 16;

/**
 * Checks whether the given element contains another one.
 *
 * @type {Function}
 * @param {HTMLElement} el
 * @param {HTMLElement} childEl
 * @return {boolean}
 */
export default document.compareDocumentPosition
    ? function(el, childEl) {
        return !!(el && (el.compareDocumentPosition(childEl) & DOCUMENT_POSITION_CONTAINED_BY));
    }
    : function(el, childEl) {
        return el && el.contains(childEl);
    };
