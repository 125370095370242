import { Model } from "../../utils/dataflow";

export default class ScrollBarModel extends Model {
    get relativePos() {
        const { scrollSize, scrollPos } = this;

        return scrollPos / scrollSize;
    }

    get relativeSize() {
        const { clientSize, scrollSize } = this;

        return clientSize / scrollSize;
    }
}
