import { FormEditStore } from "../../components/Form";

import RegisterModel from "./RegisterModel";
import RegisterActions from "./RegisterActions";

class RegisterStore extends FormEditStore.of(RegisterModel) {
    processError(error) {
        const code = (error = super.processError(error)).code || "";
        const { item } = this.state;

        if (code === "error-user-already-exists") {
            error = super.processError(item.getError("already exists", "userName"));
        } else if (code === "error-email-already-exists") {
            error = super.processError(item.getError("already exists", "email"));
        } else if (!error.field) {
            error = Object.assign(error, { code: "error-internal-server-error" });
        }

        return error;
    }
}

export default new RegisterStore().connect(RegisterActions);
