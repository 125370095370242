import { toJSON } from "../../../../utils/object";
import { APIConnector } from "../../../../init";
import { TableStore } from "../../../../components/Table";

import DelegationManagersModel from "./DelegationManagersModel";

export class DelegationManagersConnector extends APIConnector {
    static readTableData(entity) {
        const { item, filter } = entity;

        return this.fetch(this.getUrl(item, filter.group.id, toJSON(filter)))
            .then((response) => this.readTableResponse(response));
    }
}

export class DelegationManagersStore extends TableStore.of(DelegationManagersModel) {
    load() {
        return DelegationManagersConnector.read(this.state).then((state) => this.setState(state));
    }
}

export default new DelegationManagersStore();
