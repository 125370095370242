import React from "react";
import { nvl } from "../../utils/object";

import { Component } from "../Component";
import { Markdown } from "../Markdown";
import { GenericForm } from "./GenericForm";

export class Confirmation extends Component {
    render() {
        const { submit, cancel, message } = this.props;

        return (
            <GenericForm { ...this.props }
                submit={ nvl(submit, this.formatMessage("yes")) }
                cancel={ nvl(cancel, this.formatMessage("no")) }
                className={ this.block() }>
                <Markdown className={ this.element("message") }>{ message }</Markdown>
            </GenericForm>
        );
    }
}

Confirmation.propTypes = GenericForm.propTypes;
