import React from "react";
import { Route } from "react-router";

import Info from "./Info";

export default function route(path) {
    return (
        <Route path={ path } component={ Info }>
        </Route>
    );
};
