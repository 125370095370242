import React from "react";
import PropTypes from "prop-types";

import { IntlStore } from "../../init";
import { Component, ViewStatus } from "../../ui";

import ViewActions from "./ViewActions";
import ViewStore from "./ViewStore";

export default class View extends Component {
    get Store() {
        return this.constructor.Store;
    }

    get Actions() {
        return this.constructor.Actions;
    }

    init(...args) {
        super.init(...args);

        this.refresh = this.refresh.bind(this);
    }

    refresh() {
        return this.Actions && this.Actions.refresh.trigger();
    }

    componentWillMount() {
        this.refresh();
    }

    renderStatus() {
        const { refresh, props: { intl, data: { error, busy } } } = this;

        return <ViewStatus { ...{ intl, error, busy, refresh } } />;
    }

    toString() {
        return super.toString(this.Store, this.Actions);
    }

    static get Store() {
        return null;
    }

    static get Actions() {
        return null;
    }

    static toString() {
        return super.toString(this.Store, this.Actions);
    }

    static of(Store, Actions) {
        if (!ViewStore.isA(Store)) {
            throw this.getError("Instance of ViewStore expected");
        }

        if (!ViewActions.isA(Actions)) {
            throw this.getError("Instance of ViewActions expected");
        }

        class View extends this {
            static get name() {
                return super.name;
            }

            static get Store() {
                return Store;
            }

            static get Actions() {
                return Actions;
            }
        }

        View.initPropTypes({
            data: PropTypes.instanceOf(Store.Model).isRequired,
            intl: PropTypes.instanceOf(IntlStore.Model)
        });

        return View.connect({
            data: Store.connect(Actions),
            intl: IntlStore
        });
    }
}
