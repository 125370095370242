import React from "react";
import { Route, IndexRoute } from "react-router";

import Layout from "./Auth";
import Login from "./Login";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
import Register from "./Register";
import RegisterMessage from "./RegisterMessage";

export default (path = "auth") => (
    <Route path={ path } component={ Layout }>
        <IndexRoute component={ Login } />
        <Route path="forgot-password" component={ ForgotPassword } />
        <Route path="reset/:secure" component={ ResetPassword } />
        <Route path="register" component={ Register } />
        <Route path="complete" component={ RegisterMessage } />
        <Route path="success" component={ RegisterMessage } />
        <Route path="error" component={ RegisterMessage } />
        <Route path="recovery" component={ RegisterMessage } />
        <Route path="*" component={ Login } />
    </Route>
);
