module.exports = {
    "settings-profile-password-title": {
        "en-US": "Password"
    },

    "settings-profile-password-curr-password-label": {
        "en-US": "Current password"
    },
    "settings-profile-password-next-password-label": {
        "en-US": "New Password"
    },
    "settings-profile-password-confirm-password-label": {
        "en-US": "Confirm Password"
    },
    "settings-profile-password-save-password": {
        "en-US": "Save"
    },

    "error-profile-password-empty": {
        "en-US": "Password is required"
    },
    "error-profile-password-incorrect": {
        "en-US": "Incorrect password"
    },
    "error-profile-next-password-empty": {
        "en-US": "Password is required"
    },
    "error-profile-confirm-password-mismatch": {
        "en-US": "The two passwords do not match."
    }

};
