import TableModel from "../../components/Table/TableModel";
import { toJSON } from "../../utils/object";
import { RestConnector } from "../../utils/rest";

export default class APIConnector extends RestConnector {
    static fetch(url, opts) {
        opts = opts || {};

        return this.Session.read().then((session) => {
            if (session.token) {
                opts = Object.assign({}, opts, {
                    headers: new Headers(Object.assign({}, opts.headers, {
                        "Authorization": "Bearer " + session.token
                    }))
                });
            }

            return super.fetch(url, opts);
        });
    }

    static response(response) {
        return super.response(response).then((json) => {
            const ok = response.status >= 200 && response.status < 300;

            if (!ok) {
                throw new Error(json && json.error || response.statusText);
            }

            return json;
        });
    }

    static readTableResponse(response) {
        const total = response.headers.get("x-msb-count");

        return this.response(response).then((data) => ({
            item: data,
            filter: { total: total || data.length }
        }));
    }

    static readTableData(entity) {
        const { item, filter } = entity;

        return this.fetch(this.getUrl(item, null, toJSON(filter)))
            .then((response) => this.readTableResponse(response));
    }

    static read(entity, params) {
        return TableModel.isA(entity) ? this.readTableData(entity) : super.read(entity, params);
    }

    static connect(url, Session) {
        return class APIConnector extends super.connect(url) {
            static get Session() {
                return Session;
            }
        };
    }
}
