import { IntlStore } from "../../init";
import { PermissionedOperations } from "../../model";
import SelectItems from "./SelectItems";

const SelectOperations = SelectItems.of(PermissionedOperations);

export const SelectOperationsActions = SelectOperations.Actions;
export const SelectOperationsStore = SelectOperations.Store;
export const SelectOperationsModel = SelectOperations.Model;

export default SelectOperations.connect({ data: SelectOperationsStore, intl: IntlStore });
