export default Object.assign(
    require("./app"),
    require("./error"),
    require("./certificate"),
    require("./notification"),
    require("./user"),

    require("./activity"),
    require("./manage"),
    require("./auth"),
    require("./settings")
);
