import { Entity } from "./Entity";

export class Preferences extends Entity {
    init(...args) {
        super.init({ theme: "dark" }, ...args);
    }

    validate() {
        const { theme } = this;

        return super.validate()
            || (theme.trim() === "" && this.getError2("empty", "theme"))
            || void 0;
    }
}
