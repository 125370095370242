import { Model } from "../../utils/dataflow";

export default class TableLimitModel extends Model {
    init(...args) {
        super.init({ page: 0, perPage: 20 }, ...args);
    }

    toJSON() {
        const { page, perPage } = this;

        return [page * perPage, perPage].join(",");
    }
}
