import { isArray, map } from "../object";
import getRect from "./getRect";
import getBoundsTo from "./getBoundsTo";

const DEFAULTS = { left: "auto", right: "auto", top: "auto", bottom: "auto", height: "auto", width: "auto" };
const VISIBLE_CLASS = "_visible";

function toPx(style) {
    return map(style, (value) => value + "px");
}

function cleanUp(el, className) {
    el.className = el.className.split(/\s+/).filter((name) => name !== className + VISIBLE_CLASS).join(" ");
    Object.assign(el.style, DEFAULTS);

    return el;
}

function getProp(toBounds, { to }) {
    let prop, value;

    if (to === "center") {
        prop = "left";
        value = toBounds.center.left;
    } else if (to === "middle") {
        prop = "top";
        value = toBounds.center.top;
    } else if (to === "left" || to === "right" || to === "top" || to === "bottom") {
        prop = to;
        value = toBounds[to];
    } else {
        throw new Error("Invalid side");
    }

    return { prop, value };
}

function getValue(elRect, { prop, value }, align) {
    let { side, offset } = align;
    let nextValue, styles;

    offset = +offset || 0;

    if (side === prop) {
        nextValue = value + offset;
    } else if (prop === "left" || prop === "right") {
        nextValue = value - (side === "left" || side === "right" ? elRect.width + offset
            : side === "center" ? elRect.width / 2 - offset : 0);
    } else if (prop === "top" || prop === "bottom") {
        nextValue = value - (side === "top" || side === "bottom" ? elRect.height + offset
            : side === "center" ? elRect.height / 2 - offset : 0);
    }

    if (nextValue < 0) {
        styles = { [prop === "left" || prop === "right" ? "width" : "height"]: value - offset };
    }

    return { prop, value: nextValue, styles, align };
}

function alignSide(elRect, toBounds, align) {
    return getValue(elRect, getProp(toBounds, align), align);
}

function alignSides(elRect, toBounds, aligns) {
    let retValue = null;
    let minValue = Number.NEGATIVE_INFINITY;

    aligns.some((align) => {
        const props = alignSide(elRect, toBounds, align);

        if (props.value > minValue) {
            retValue = props;
            minValue = props.value;
        }

        return retValue.value >= 0;
    });

    retValue.value = Math.max(retValue.value, 0);

    return retValue;
}

/**
 * @param {HTMLElement} el
 * @param {HTMLElement} to
 * @param {Array} aligns
 * @param {string} className
 */
export default function alignTo(el, to, aligns, className) {
    const elRect = getRect(cleanUp(el, className));
    const toBounds = getBoundsTo(to, el.offsetParent);

    const styles = {};
    const classNames = [className, className + VISIBLE_CLASS];

    el.className = el.className.split(/\s+/).filter((name) => name !== className + VISIBLE_CLASS).join(" ");
    Object.assign(el.style, DEFAULTS);

    aligns.map((align) => (isArray(align) ? alignSides : alignSide)(elRect, toBounds, align))
        .forEach((props) => {
            if (props) {
                const { prop, value, styles: auxStyles, align } = props;

                classNames.push(className + "_side_" + align.side, className + "_to_" + align.to);

                Object.assign(styles, auxStyles, {
                    [prop]: value,
                });
            }
        });

    if (classNames.length > 2) {
        className = classNames.join(" ");

        Object.assign(el.style, toPx(styles));
    }

    return Object.assign(el, { className });
}
