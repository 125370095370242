import { Base } from "../../utils";
import { Action } from "../../utils/dataflow";

export default class ViewActions extends Base {
    constructor() {
        super();

        Object.assign(this, {
            setItem: new Action,
            reset: new Action,
            refresh: new Action,
            setError: new Action,
            setBusy: new Action
        });
    }
}
