import { TableStore } from "../../../../../components/Table";
import CSRsModel from "./CSRsModel";

export class CSRsStore extends TableStore.of(CSRsModel) {
    setSort(prop) {
        return this.setLocalSort(prop);
    }
}

export default new CSRsStore();
