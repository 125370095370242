import { valueOf, isDefined } from "../object";
import { ModelConnector } from "../dataflow";

export default class StorageConnector extends ModelConnector {
    static storage() {
        return null;
    }

    static storageKey() {
        return null;
    }

    static parse(value) {
        return isDefined(value) ? JSON.parse(value) : null;
    }

    static stringify(value) {
        return isDefined(value) ? JSON.stringify(valueOf(value)) : null;
    }

    static getItem() {
        return this.parse(this.storage.getItem(this.storageKey));
    }

    static setItem(value) {
        const storage = this.storage;
        const string = this.stringify(value);

        if (string === null) {
            storage.removeItem(this.storageKey);
        } else {
            storage.setItem(this.storageKey, string);
        }

        return value;
    }

    static create(entity, data) {
        return Promise.resolve(this.setItem(entity.set(data)));
    }

    static read(entity) {
        return Promise.resolve(entity.set(this.getItem()));
    }

    static update(entity, data) {
        return Promise.resolve(this.setItem(entity.set(this.getItem(), data)));
    }

    static destroy() {
        return Promise.resolve(this.setItem(null));
    }

    static toString() {
        const { storage, storageKey } = this;

        return super.toString(storage && storage.constructor.name, storageKey);
    }

    static connect(storage, storageKey) {
        if (!storage) {
            throw this.getError("Storage expected");
        }
        if (!storageKey) {
            throw this.getError("Storage key expected");
        }

        return class StorageConnector extends this {
            static get storage() {
                return storage;
            }

            static get storageKey() {
                return storageKey;
            }
        };
    }
}
