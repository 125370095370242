import React from "react";

import { IntlStore } from "../../../../init";
import { Component, SideBar } from "../../../../ui";

export class HaceraNetworksEmpty extends Component {
    render() {
        return (
            <SideBar className={ this.block() }>
                <div className="notice">
                    { this.formatMessage("message") }
                </div>
            </SideBar>
        );
    }
}

export default HaceraNetworksEmpty.connect({
    intl: IntlStore
});
