import { Action } from "../../../../utils/dataflow";
import { FormActions } from "../../../../components/Form";

export class ImportActions extends FormActions {
    constructor() {
        super();

        Object.assign(this, {
            importData: new Action
        });
    }
}

const Actions = new ImportActions();

export default Actions;
export const setItem = Actions.setItem.trigger;
export const importData = Actions.importData.trigger;
