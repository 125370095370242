import PropTypes from "prop-types";
import React from "react";

import { Component } from "../Component";
import { Search } from "../Search";
import { IntlModel } from "../../components/Intl";

export class TableSearch extends Component {
    render() {
        const { intl, value, placeholder, onSearch } = this.props;

        return (
            <div className={ this.block() }>
                <Search { ...{ intl, value, placeholder, onSearch } }
                    className={ this.element("search") } />
            </div>
        );
    }
}

export default TableSearch;

TableSearch.initPropTypes({
    intl: PropTypes.instanceOf(IntlModel).isRequired,
});
