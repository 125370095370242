import {AuthActions, AuthStore, IntlStore} from "../../init";
import React from "react";
import { Link } from "react-router";
import { ScrollArea } from "../../ui";

import { Component } from "../../ui/Component";

const menuItems = require("./main-menu.json");

const STORAGE_KEY = "msb-menu-minimized";

class MenuLink extends Component {
    render() {
        const { to, level, expandable, expanded, children } = this.props;
        const className = this.cx("main-menu__item", `main-menu__item_lvl${level}`, {
            "main-menu__item_expandable": expandable,
            "main-menu__item_expanded": expandable && expanded
        });

        return (
            <Link { ...{ to, className } } activeClassName="main-menu__item_selected">
                { children }
            </Link>
        );
    }
}

class Menu extends Component {
    init(...args) {
        super.init(...args);
        this.initState({
            minimized: sessionStorage.getItem(STORAGE_KEY) === "true",
            collapsed: {},
            companySelector: false
        });

        this.toggle = this.toggle.bind(this);
        this.toggleItem = this.toggleItem.bind(this);
    }

    toggle(event) {
        const { minimized } = this.state;

        event.preventDefault();
        this.setState({ minimized: !minimized });

        sessionStorage.setItem(STORAGE_KEY, !minimized);
    }

    toggleItem(event) {
        const index = event.target.getAttribute("data-index");
        const { collapsed } = this.state;

        event.preventDefault();
        this.setState({ collapsed: Object.assign({}, collapsed, { [index]: !collapsed[index] }) });
    }

    _renderItem(root, level, item, index) {
        const { intl } = this.props;
        const { collapsed } = this.state;
        const { to, title, icon, children } = item;

        const expandable = !!children;
        const expanded = expandable && !collapsed[index];
        const items = [];

        let toggle;
        if (expandable) {
            toggle = <i className="ico ico_toggle" data-index={ index } onClick={ this.toggleItem } />;
        }

        items.push(
            <MenuLink key={ index } to={ `${root}${to}` } { ...{ level, icon, expandable, expanded } }>
                { icon ? <i className={ "ico ico_main ico-" + icon } /> : null }
                <div className="item-title">
                    { intl.formatMessage(title) }
                </div>
                { toggle }
            </MenuLink>
        );

        if (children) {
            items.push(
                <div key={ `menu-${index}` }
                    className={ "main-menu__drop" + (expanded ? " main-menu__drop_expanded" : "") }>
                    { this._renderItems(root + to, children, level + 1) }
                </div>
            );
        }

        return items;
    }

    _renderItems(root, items, level = 1) {
        return [].concat(...items.map(this._renderItem.bind(this, root, level)));
    }

    _onChangeCompany(name) {
        AuthActions.updateCompany.trigger({ company: name });
        this.setState({ companySelector: name==='hacera' });
    }

    _getCompanies() {
        return [{
            name: 'hacera',
            className: 'logo'
        }, {
            name: 'mipasa',
            className: 'mipasa-logo'
        }];
    }

    _renderCompanies() {
        const companies = this._getCompanies();

        return (
            companies.map(x => <div className="logo-wrapper" key={x.name} onClick={()=>this._onChangeCompany(x.name)}><div className={`logo ${x.className}`} /></div>)
        )
    }

    render() {
        const { intl, auth } = this.props;
        const { minimized, companySelector } = this.state;
        const { company } = auth;

        console.log('render company', company);

        const companyForRender = this._getCompanies().find(x => x.name === company);

        return (
            <div className={ this.cx("main-menu", { "main-menu_minimized": minimized }) }>
                <div className="main-menu__logo" onMouseOver={()=>this.setState({ companySelector: true })} onMouseOut={()=>this.setState({ companySelector: false })}>
                    <div className={`logo ${companyForRender.className}`} />
                    <div className={`options ${companySelector?'options-open':''}`}>
                        { this._renderCompanies() }
                    </div>
                </div>
                <div className="main-menu__item main-menu__item_toggle main-menu__item_lvl1"
                    onClick={ this.toggle }>
                    <i className="ico ico_main ico-arrow-left" />
                    <div className="item-title">
                        { intl.formatMessage("menu-collapse") }
                    </div>
                </div>
                <ScrollArea overflowX="hidden" className="menubar">
                    { this._renderItems("/msb", menuItems) }
                </ScrollArea>
            </div>
        );
    }
}

export default Menu.connect({ intl: IntlStore, auth: AuthStore });
