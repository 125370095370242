import { IntlStore } from "../../init";
import { Groups } from "../../model";
import SelectItems from "./SelectItems";


const SelectGroups = SelectItems.of(Groups);

export const SelectGroupsActions = SelectGroups.Actions;
export const SelectGroupsStore = SelectGroups.Store;
export const SelectGroupsModel = SelectGroups.Model;

export default SelectGroups.connect({ data: SelectGroupsStore, intl: IntlStore });
