import { ViewStore } from "../View";
import { APIConnector } from "../../init";

export default class SelectStore extends ViewStore {
    init(connector) {
        super.init();

        this.connector = connector || APIConnector;
    }

    refresh() {
        return this.invoke("refresh", this.connector.read(this.state.items))
            .then((items) => this.setState({ items })).catch(() => void 0);
    }
}
