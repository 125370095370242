import React from "react";

import { IntlStore } from "../../../../../init";
import { GenericForm, FormField, FormInput, FormPreview, FormFile, CertificateField } from "../../../../../ui";

import CSRForm from "./CSRForm";
import CSRImportStore from "./CSRImportStore";
import { setItem, setItemField, setItemError, saveItem } from "./CSRImportActions";

function onFieldChange(value, name) {
    if (name) {
        setItemField(name, value);
    }
}

function onPemChange(value) {
    setItem({ pem: value, attributes: null });
}

function onPEMError(error) {
    setItemError("pem", error);
}

class CSRImport extends CSRForm {
    componentWillMount() {
        setItem(new CSRImportStore.Model.Item());
    }

    submit() {
        const { data: { item } } = this.props;

        saveItem(item).then(() => this.onSubmit()).catch(() => void 0);
    }

    render() {
        const { intl, data: { busy, error, item } } = this.props;

        if (!item) {
            return null;
        }

        const title = intl.formatMessage("settings-credentials-csr-import-title", item);

        return (
            <GenericForm { ...{ intl, busy, error, title } }
                submit={ intl.formatMessage("settings-credentials-csr-import-submit") }
                className={ this.block() }
                onSubmit={ this.submit } onCancel={ this.close }>

                <FormField name="alias"
                    label={ intl.formatMessage("settings-credentials-csr-import-name-label") }>
                    <FormInput type="text" name="alias" value={ item.alias }
                        placeholder={ intl.formatMessage("settings-credentials-csr-import-name-placeholder") }
                        onChange={ onFieldChange } />
                </FormField>

                <CertificateField name="pem"
                    label={ intl.formatMessage("settings-credentials-csr-import-pem-label") }
                    clearLabel={ intl.formatMessage("settings-credentials-csr-import-pem-clear") }
                    placeholder={ intl.formatMessage("settings-credentials-csr-import-pem-placeholder") }
                    onChange={ onPemChange } onError={ onPEMError } />

                <FormField name="pem-view"
                    label={ intl.formatMessage("settings-credentials-csr-import-pem-view-label") }>
                    <FormPreview name="pem-view" value={ item.pem } readOnly={ true }
                        placeholder={ intl.formatMessage("settings-credentials-csr-import-pem-view-placeholder") } />
                </FormField>
            </GenericForm>
        );
    }
}

export default CSRImport.connect({ intl: IntlStore, data: CSRImportStore });
