import { setTitle } from "../../../../components/Header";
import { SelectGroups } from "../../../../components/Select";

import { TableView } from "../../../../components/Table";

import { LDAPEntity } from "../../../../model";
import React from "react";
import { Link, Main, Table, TableRow, TableToolBar } from "../../../../ui";

import UserActions, { setGroups, setPage, setSearch, setSort } from "./UsersActions";
import UsersStore from "./UsersStore";
import UserView from "./UserView";
import {AuthStore} from "../../../../init";

class UserRow extends TableRow {
    render() {
        const { item } = this.props;

        return (
            <TableRow { ...this.props } enumerable={ true } className={ this.block() }>
                { this.renderNumCell() }
                <td>{ item.fullName }</td>
                <td>{ item.role }</td>
                <td>{ item.company }</td>
                <td>{ item.location }</td>
            </TableRow>
        );
    }
}

class UserExtraRow extends TableRow {
    render() {
        const { intl, item } = this.props;

        return (
            <TableRow { ...this.props } className={ this.block() }>
                <td colSpan={ 5 } className={ this.element("extra") }>
                    <UserView { ...{ intl, item } } />
                </td>
            </TableRow>
        );
    }
}

class UsersTable extends Table {
    init(...args) {
        super.init(...args);

        this.initState({ expanded: {} });
        this.onRowClick = this.onRowClick.bind(this);
    }

    sortByColumn(column) {
        setSort(column);
    }

    setPage(page) {
        setPage(page);
    }

    onRowClick(event) {
        const { expanded } = this.state;
        const { currentTarget } = event;

        const id = currentTarget.getAttribute("data-id");
        this.setState({ expanded: Object.assign({}, expanded, { [id]: !expanded[id] }) });
    }

    renderHead() {
        return (
            <tr>
                { this.renderHeadNum() }
                { this.renderHeadCell("name", "name") }
                { this.renderHeadCell("role", "role") }
                { this.renderHeadCell("company", "company") }
                { this.renderHeadCell("location", "location") }
            </tr>
        );
    }

    renderRow(item) {
        const { intl } = this.props;

        return <UserRow key={ item.id } { ...{ intl, item, onClick: this.onRowClick } } />;
    }

    renderExtraRow(item) {
        const { intl } = this.props;

        return <UserExtraRow key={ "extra-" + item.id } { ...{ intl, item } } />;
    }

    renderRows() {
        const { props: { items }, state: { expanded } } = this;
        const rows = [];

        items.forEach((item, index) => {
            rows.push(this.renderRow(item, index));

            if (expanded[item.id]) {
                rows.push(this.renderExtraRow(item, index));
            }
        });

        return rows;
    }
}

class UsersToolBar extends TableToolBar {
    renderGroups() {
        const { filter } = this.props;

        return (
            <SelectGroups value={ filter.groups } list={ false } sort={ LDAPEntity.sort }
                placeholder={ this.formatMessage("groups") }
                dropClassName="drop_wide"
                onChange={ setGroups } />
        );
    }

    render() {
        return (
            <TableToolBar className={ this.block() }>
                { this.renderGroups() }
                { this.renderSearch() }
                <Link to="/msb/manage/users/create" className="action action_add link_accent">
                    { this.formatMessage("create") }
                </Link>
            </TableToolBar>
        );
    }
}

export class Users extends TableView {
    componentWillMount() {
        super.componentWillMount();

        setTitle(this.formatMessage("title"));
    }

    shouldModalRender() {
        return false;
    }

    componentWillReceiveProps(nextProps) {
        const { auth: { company: currCompany } } = this.props;
        const { auth: { company: nextCompany } } = nextProps;

        if (currCompany !== nextCompany) {
            UserActions.refresh.trigger()
        }
    }

    renderToolBar() {
        const { intl, data: { filter } } = this.props;

        return (
            <UsersToolBar { ...{ intl, filter } } onSearch={ setSearch } />
        );
    }

    render() {
        const { intl, data: { item: items, filter } } = this.props;

        return (
            <Main className={ this.block() } toolBar={ this.renderToolBar() } sideBar={ this.renderSideBar() }
                status={ this.renderStatus() }>
                <UsersTable { ...{ intl, filter, items } } />
            </Main>
        );
    }
}

export default Users.of(UsersStore, UserActions).connect({ auth: AuthStore });
