import { setTitle } from "../../../../../components/Header";

import { TableView } from "../../../../../components/Table";
import moment from "moment";
import React from "react";

import { Link, Main, Table, TableRow, TableToolBar } from "../../../../../ui";
import ManagedRequestsActions, { setPage, setSearch, setSort } from "./ManagedRequestsActions";

import ManagedRequestsStore from "./ManagedRequestsStore";

class ManagedRequestsRow extends TableRow {
    renderUserCell() {
        const { item: { userName, userId } } = this.props;

        return (
            <td className={ this.element("user") }>
                { userName || userId }
            </td>
        );
    }

    renderNameCell() {
        const { item: { alias } } = this.props;

        return (
            <td className={ this.element("name") }>
                { alias }
            </td>
        );
    }

    renderCommonNameCell() {
        const { item: { commonName } } = this.props;

        return (
            <td className={ this.element("common-name") }>
                { commonName || "" }
            </td>
        );
    }

    renderCreation() {
        const { item: { creationTime } } = this.props;

        return (
            <td className={ this.element("creation-time") }>
                { creationTime && moment(creationTime).format("YYYY-MM-DD HH:mm:ss") || "" }
            </td>
        );
    }

    renderActionsCell() {
        const { item } = this.props;

        return (
            <td className="repository-row__actions">
                <Link to={ item.id + "/approve" } className="action action_approve link_accent">
                    { this.formatMessage("approve") }
                </Link>
                <Link to={ item.id + "/deny" } className="action action_deny link_accent">
                    { this.formatMessage("deny") }
                </Link>
            </td>
        );
    }

    render() {
        return (
            <TableRow { ...this.props } enumerable={ true } className={ this.block() }>
                { this.renderNumCell() }
                { this.renderUserCell() }
                { this.renderNameCell() }
                { this.renderCommonNameCell() }
                { this.renderCreation() }
                { this.renderActionsCell() }
            </TableRow>
        );
    };
}

class ManagedRequestsTable extends Table {
    sortByColumn(column) {
        setSort(column);
    }

    setPage(page) {
        setPage(page);
    }

    renderHead() {
        return (
            <tr>
                { this.renderHeadNum() }
                { this.renderHeadCell("user", "userId") }
                { this.renderHeadCell("name", "alias") }
                { this.renderHeadCell("common-name", "commonName") }
                { this.renderHeadCell("creation-time", "creationTime") }
                { this.renderHeadCell("actions") }
            </tr>
        );
    }

    renderRow(item) {
        const { intl } = this.props;

        return <ManagedRequestsRow key={ item.id } { ...{ intl, item } } />;
    }
}

class ManagedRequestsToolBar extends TableToolBar {
    render() {
        return (
            <TableToolBar { ...this.props } className={ this.block() }>
                { this.renderSearch() }
            </TableToolBar>
        );
    }
}

export class ManagedRequests extends TableView {
    componentWillMount() {
        super.componentWillMount();

        setTitle(this.formatMessage("title"));
    }

    shouldModalRenderForPath(pathname) {
        return pathname.endsWith("import") || super.shouldModalRenderForPath(pathname);
    }

    renderToolBar() {
        const { intl, data: { filter } } = this.props;

        return (
            <ManagedRequestsToolBar { ...{ intl, filter } } onSearch={ setSearch } />
        );
    }

    render() {
        const { intl, data: { item: items, filter } } = this.props;

        return (
            <Main className={ this.block() } toolBar={ this.renderToolBar() } status={ this.renderStatus() }>
                <ManagedRequestsTable { ...{ intl, filter, items } } />
            </Main>
        );
    }
}

export default ManagedRequests.of(ManagedRequestsStore, ManagedRequestsActions);
