module.exports = {
    "live-title": {
        "en-US": "Activity Monitor"
    },
    "activity-live-menu-title": {
        "en-US": "Live"
    },
    "activity-live-search-transaction": {
        "en-US": "Search"
    },
    "activity-live-export-to": {
        "en-US": "Export to:"
    },

    "live-table-column-num": {
        "en-US": "#"
    },
    "live-table-column-id": {
        "en-US": "UUID"
    },
    "live-table-column-user": {
        "en-US": "User"
    },
    "live-table-column-affiliation": {
        "en-US": "Affiliation"
    },
    "live-table-column-order-type": {
        "en-US": "Order"
    },
    "live-table-column-stock": {
        "en-US": "Stock"
    },
    "live-table-column-type": {
        "en-US": "Transaction Type"
    },
    "live-table-column-units": {
        "en-US": "Units"
    },
    "live-table-column-price": {
        "en-US": "Price"
    },
    "live-table-column-ttl": {
        "en-US": "TTL"
    },
    "live-table-column-date": {
        "en-US": "Time"
    },

    "activity-live-credit-utilization": {
        "en-US": "Credit Utilization"
    },
    "live-tool-bar-users": {
        "en-US": "Users: All"
    },
    "live-tool-bar-groups": {
        "en-US": "Groups: All"
    },
    "live-tool-bar-permissions": {
        "en-US": "Permissions: All"
    },
    "activity-live-message-no-data": {
        "en-US": "Found no data matching your filters"
    }
};
