import { FormView } from "../../../../components/Form";

import { IntlStore } from "../../../../init";
import React from "react";
import { Component, COUNTRIES, FormField, FormInput, FormPassword, FormPhone, SelectPhoneCode } from "../../../../ui";

import UserActions from "./UserCreateActions";
import UsersActions from "./UsersActions";
import UserStore from "./UserCreateStore";

class UserCreate extends Component {
    onCancel = () => {
        const { onCancel, router } = this.props;

        if (onCancel) {
            onCancel();
        } else {
            router.replace("/msb/manage/users/");
        }
    };

    onSubmit = (event) => {
        const { onSubmit } = this.props;

        if (onSubmit) {
            onSubmit(event);
        } else {
            const { data: { item } } = this.props;

            this.form.saveItem(item)
                .then(UsersActions.refresh.trigger)
                .catch(() => void 0);
        }
    };

    render() {
        const { data: { item } } = this.props;

        if (!item) {
            return null;
        }

        return (
            <FormView { ...this.props }
                form={ this }
                ref={ (el) => this.form = el }
                onSubmit={ this.onSubmit }
                onCancel={ this.onCancel }>

                <FormField>
                    <FormInput name="firstName" type="text" />
                </FormField>

                <FormField>
                    <FormInput name="lastName" type="text" />
                </FormField>

                <FormField>
                    <FormInput name="userName" type="text" maxLength={ 50 } />
                </FormField>

                <FormField>
                    <FormInput name="email" type="text"/>
                </FormField>

                <FormField>
                    <FormPassword name="password" />
                </FormField>

                <FormField>
                    <SelectPhoneCode name="country" nullValue={ false } />
                    <FormPhone name="phone" country={ COUNTRIES.value(item.country) } />
                </FormField>
            </FormView>
        );
    }
}


export default UserCreate.connect(
    {
        intl: IntlStore,
        data: UserStore
    },
    {
        actions: UserActions
    }
);

