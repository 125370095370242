import * as asn1js from "asn1js";
import { setTitle } from "../../../../../components/Header";

import { TableView } from "../../../../../components/Table";
import moment from "moment";
import { Certificate } from "pkijs";
import { stringToArrayBuffer } from "pvutils";
import React from "react";

import {
    Component,
    Info,
    InfoBlock,
    InfoLabel,
    InfoRow,
    InfoValue,
    Main,
    ScrollArea,
    Table,
    TableRow,
    TableToolBar
} from "../../../../../ui";


import EnrollmentCertificatesActions, { setPage, setSort } from "./EnrollmentCertificatesActions";
import EnrollmentCertificatesStore from "./EnrollmentCertificatesStore";

function parsePEM(pem) {
    const asn1 = asn1js.fromBER(stringToArrayBuffer(atob(pem.replace(/(-----(BEGIN|END) CERTIFICATE-----|\n)/g, ""))));
    let certificate;
    try {
        certificate = new Certificate({ schema: asn1.result });
    } catch (error) {
        // do nothing here
    }

    return certificate;
}

export function bufferToString(inputBuffer, inputOffset = 0, inputLength = inputBuffer.byteLength) {
    let result = "";

    for (const item of (new Uint8Array(inputBuffer, inputOffset, inputLength))) {
        result += String.fromCharCode(item);
    }

    return result;
}

function parseAttributes(pem) {
    const certificate = parsePEM(pem);
    let attrs;

    if (certificate) {
        const extension = certificate.extensions.find((extn) => extn.extnID === "1.2.3.4.5.6.7.8.1");

        if (extension) {
            try {
                attrs = JSON.parse(bufferToString(extension.extnValue.valueBlock.valueHex)).attrs;
            } catch (error) {
                // do nothing here
            }
        }
    }

    return attrs;
}

class EnrollmentCertificateAttr extends Component {
    render() {
        const { name, value } = this.props;

        return (
            <InfoRow className={ this.block() }>
                <InfoLabel>{ name }</InfoLabel>
                <InfoValue>{ value }</InfoValue>
            </InfoRow>
        );
    }
}

function renderAttrs(attrs) {
    return Object.keys(attrs).map((key) => <EnrollmentCertificateAttr { ...{ key, name: key, value: attrs[key] } } />);
}

export class EnrollmentCertificate extends Info {
    renderNetworkUser() {
        const { item: { user: { name, lastname: lastName, role } } } = this.props;
        const { item: { network: { config: { networkName, creationTime, userId } } } } = this.props;

        return (
            <InfoBlock title={ this.renderTitle("network") } className={ this.element("network-user") }>
                { this.renderRow("network-name", networkName || "-") }
                { this.renderRow("network-user-id", userId || "-") }
                { this.renderRow("network-creation-time", creationTime || "-") }

                { this.renderTitle("user") }
                { this.renderRow("user-first-name", name || "-") }
                { this.renderRow("user-last-name", lastName || "-") }
                { this.renderRow("user-affiliation", role || "-") }
            </InfoBlock>
        );
    }

    renderCertificateCommon() {
        const { item: { certificate: { id, creationTime } } } = this.props;
        const { item: { certificate: { attributes: { NotAfter, SerialNumber, PublicKey } } } } = this.props;

        return (
            <InfoBlock title={ this.renderTitle("common") }
                className={ this.element("certificate-common") }>

                { this.renderRow("issued", moment(creationTime).format("MMM DD, YYYY")) }
                { this.renderRow("expires", moment(NotAfter).format("MMM DD, YYYY")) }

                { this.renderTitle("ssl") }
                { this.renderRow("name", id) }
                { this.renderRow("brand", "-") }
                { this.renderRow("type", "-") }
                { this.renderRow("serial", SerialNumber) }
                { this.renderRow("key-size", PublicKey.Size || "-") }
            </InfoBlock>
        );
    }

    renderCertificateAdvanced() {
        const { item: { certificate: { pem, attributes } } } = this.props;
        const attrs = parseAttributes(pem);

        return (
            <InfoBlock title={ this.renderTitle("advanced") }
                className={ this.element("certificate-advanced") }>

                { this.renderRow("fingerprint-sha1", attributes["Fingerprint (MD5)"]) }
                { this.renderRow("fingerprint-md5", attributes["Fingerprint (SHA-1)"]) }

                { this.renderTitle("attributes") }
                { attrs ? renderAttrs(attrs) : <div className="info-label">No attributes</div> }

                { this.renderTitle("raw") }
                <ScrollArea>
                    { pem }
                </ScrollArea>
            </InfoBlock>
        );
    }

    render() {
        return (
            <Info { ...this.props } className={ this.block() }>
                { this.renderNetworkUser() }
                { this.renderCertificateCommon() }
                { this.renderCertificateAdvanced() }
            </Info>
        );
    }
}

class EnrollmentCertificatesRow extends TableRow {
    renderUserCell() {
        const { item: { user } } = this.props;

        return (
            <td className={ this.element("user") }>
                { user.fullName }
            </td>
        );
    }

    renderNetworkCell() {
        const { item: { network } } = this.props;

        return (
            <td className={ this.element("network") }>
                { network.config.networkName }
            </td>
        );
    }

    renderCertificateCell() {
        const { item: { certificate } } = this.props;

        return (
            <td className={ this.element("certificate") }>
                { certificate.alias }
            </td>
        );
    }

    render() {
        return (
            <TableRow { ...this.props } enumerable={ true } className={ this.block() }>
                { this.renderNumCell() }
                { this.renderNetworkCell() }
                { this.renderUserCell() }
                { this.renderCertificateCell() }
            </TableRow>
        );
    }
}

class EnrollmentCertificateExtraRow extends TableRow {
    render() {
        const { intl, item } = this.props;

        return (
            <TableRow { ...this.props } className={ this.block() }>
                <td colSpan={ 4 } className={ this.element("extra") }>
                    <EnrollmentCertificate { ...{ intl, item } } />
                </td>
            </TableRow>
        );
    }
}

class EnrollmentCertificatesTable extends Table {
    init(...args) {
        super.init(...args);

        this.initState({ expanded: {} });
        this.onRowClick = this.onRowClick.bind(this);
    }

    sortByColumn(column) {
        setSort(column);
    }

    setPage(page) {
        setPage(page);
    }

    renderHead() {
        return (
            <tr>
                { this.renderHeadNum() }
                { this.renderHeadCell("network") }
                { this.renderHeadCell("user") }
                { this.renderHeadCell("certificate") }
            </tr>
        );
    }

    onRowClick(event) {
        const { expanded } = this.state;
        const { currentTarget } = event;

        const id = currentTarget.getAttribute("data-id");
        this.setState({ expanded: Object.assign({}, expanded, { [id]: !expanded[id] }) });
    }

    renderRow(item) {
        const { intl } = this.props;

        return <EnrollmentCertificatesRow key={ item.id } { ...{ intl, item, onClick: this.onRowClick } } />;
    }

    renderExtraRow(item) {
        const { intl } = this.props;

        return <EnrollmentCertificateExtraRow key={ "extra-" + item.id } { ...{ intl, item } } />;
    }

    renderRows() {
        const { props: { items }, state: { expanded } } = this;
        const rows = [];

        items.forEach((item, index) => {
            rows.push(this.renderRow(item, index));

            if (expanded[item.id]) {
                rows.push(this.renderExtraRow(item, index));
            }
        });

        return rows;
    }
}

class EnrollmentCertificatesToolbar extends TableToolBar {
    render() {
        return (
            <TableToolBar className={ this.block() }>
                { this.renderSearch() }
            </TableToolBar>
        );
    }
}

export class EnrollmentCertificates extends TableView {
    componentWillMount() {
        const { intl } = this.props;

        super.componentWillMount();

        setTitle(intl.formatMessage("settings-credentials-title") + " - " + this.formatMessage("title"));
        this.refresh();
    }

    renderToolBar() {
        const { intl, data: { filter } } = this.props;

        return (
            <EnrollmentCertificatesToolbar { ...{ intl, filter } } />
        );
    }

    render() {
        const { intl, data: { item: items, filter } } = this.props;

        return (
            <Main toolBar={ this.renderToolBar() } status={ this.renderStatus() } className={ this.block() }>
                <EnrollmentCertificatesTable { ...{ intl, filter, items } } className={ this.element("table") } />
            </Main>
        );
    }
}

export default EnrollmentCertificates.of(EnrollmentCertificatesStore, EnrollmentCertificatesActions);
