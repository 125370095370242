import { APIConnector } from "../../../../init";
import { FormStore } from "../../../../components/Form";

import RepositoryModel from "./RepositoryModel";
import RepositoryImportActions from "./RepositoryImportActions";

/**
 * @extends FormStore
 */
class RepositoryImportStore extends FormStore.of(RepositoryModel) {
    processError(error) {
        error = super.processError(error);

        if (error.code.startsWith("error-failed-to-connect")) {
            error = Object.assign(error, { code: "error-failed-to-connect" });
        }

        return error;
    }

    setItem(item) {
        this.setState({ item, error: null, importedData: null });
    }

    doImport(itemId, type = "user") {
        return APIConnector.fetch(APIConnector.buildUrl("import/" + type + "/", { repo: itemId }))
            .then((data) => APIConnector.response(data));
    }

    importData(item) {
        const itemId = item ? item.id : "all";

        this.invoke("import", Promise.all([this.doImport(itemId, "user"), this.doImport(itemId, "group")]))
            .then(([users, groups]) => this.setState({ importedData: { users, groups } }));
    }
}

export default new RepositoryImportStore().connect(RepositoryImportActions);
