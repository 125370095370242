import { FormModel } from "../../../../../components/Form";
import { Entity } from "../../../../../model";

const DEFAULTS = {
    firstName: "",
    lastName: "",
    avatar: "",
    country: "US",
    city: "",
    organization: "",
    lang: "en-US"
};

export class Profile extends Entity {
    init(...args) {
        super.init(DEFAULTS, ...args);
    }

    getError(message, field) {
        return super.getError(`${this.constructor.name} ${field} ${message}`, field);
    }

    validate() {
        return super.validate()
            || void 0;
    }
}

export default class InfoModel extends FormModel.of(Profile) {
    init(...args) {
        super.init({ item: {} }, ...args);
    }
}
