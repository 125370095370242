import React from "react";
import { withRouter } from "react-router";

import Url from "../utils/Url";
import { Component } from "./Component";
import { Link } from "./Link";


export const ThemeSelector = withRouter(class ThemeSelector extends Component {
    renderThemeLink(theme) {
        const { location: { pathname, search }, value } = this.props;

        const url = new Url({ pathname, search });
        url.params({ theme });

        return (
            <Link to={ url } className={ this.element("theme", { [theme]: true, active: theme === value }) }>
                { this.formatMessage(theme) }
            </Link>
        );
    }

    render() {
        return (
            <div className={ this.block() }>
                { this.renderThemeLink("light") }
                { this.renderThemeLink("dark") }
            </div>
        );
    }
});
