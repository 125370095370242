import PropTypes from "prop-types";
import React from "react";
import { Select } from "../../ui";
import { Component } from "../../ui/Component";

import { Collection } from "../../utils/dataflow";
import SelectActions from "./SelectActions";

import SelectModel from "./SelectModel";
import SelectStore from "./SelectStore";

export default class SelectItem extends Component {
    static get Items() {
        return Collection;
    }

    static of(Items, Connector) {
        const Model = SelectModel.of(Items);
        const StoreModel = SelectStore.of(Model);

        const Actions = new SelectActions();
        const Store = new StoreModel(Connector).connect(Actions);

        class SelectItem extends this {
            static get Actions() {
                return Actions;
            }

            static get Items() {
                return Items;
            }

            static get Store() {
                return Store;
            }

            componentWillMount() {
                Actions.refresh.trigger();
            }
        }

        SelectItem.initPropTypes({
            data: PropTypes.instanceOf(Model).isRequired,
            value: PropTypes.any
        });

        return SelectItem;
    }

    static toString() {
        return super.toString(this.Items.name);
    }

    render() {
        const { data: { items } } = this.props;

        return (
            <Select { ...this.props } { ...{ items, data: void 0 } } />
        );
    }

    toString() {
        return super.toString(this.Items.name);
    }
}
