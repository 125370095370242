import React from "react";

import { ScrollArea, Table, Main } from "../../../../ui";

import { setTitle } from "../../../../components/Header";
import { TableView } from "../../../../components/Table";

import LiveStore from "./LiveStore";
import LiveActions, { setFilters, setSort, setPage, setSearch } from "./LiveActions";

import { Transactions, TransactionsToolBar, TransactionsRow } from "../Transactions";
import {AuthStore} from "../../../../init";

class LiveRow extends TransactionsRow {
    render() {
        const { auth } = this.props
        return (
            <TransactionsRow { ...this.props } className={ this.block() }>
                { this.renderNumCell() }
                { this.renderUserCell() }
                {(auth.company === 'mipasa')
                    ?
                    <>
                        {this.renderCreatedCell()}
                        {this.renderIdCell()}
                        {this.renderTypeCell()}
                    </>
                    :
                    <>
                        { this.renderUserAffiliationCell() }
                        { this.renderActionCell() }
                        { this.renderStockCell() }
                        { this.renderUnitsCell() }
                        { this.renderPriceCell() }
                        { this.renderTTLCell() }
                        { this.renderCreatedCell("HH:mm") }
                        { this.renderIdCell() }
                    </>
                }
            </TransactionsRow>
        );
    };
}

class LiveTable extends Table {
    sortByColumn(column) {
        setSort(column);
    }

    setPage(page) {
        setPage(page);
    }

    renderHead() {
        const { auth } = this.props
        return (
            <tr>
                { this.renderHeadNum()}
                { this.renderHeadCell("user", "userId") }
                {(auth.company === 'mipasa')
                    ?
                    <>
                        { this.renderHeadCell("date", "created") }
                        { this.renderHeadCell("id", "id") }
                        { this.renderHeadCell("type", "type") }
                    </>
                    :
                    <>
                        { this.renderHeadCell("affiliation") }
                        { this.renderHeadCell("order-type", "action") }
                        { this.renderHeadCell("stock", "stock") }
                        { this.renderHeadCell("units", "units") }
                        { this.renderHeadCell("price", "price") }
                        { this.renderHeadCell("ttl", "TTL") }
                        { this.renderHeadCell("date", "created") }
                        { this.renderHeadCell("id", "id") }
                    </>
                }
            </tr>
        );
    }

    renderRow(item) {
        const { intl, auth } = this.props;

        return <LiveRow key={ item.id } { ...{ item, intl, auth } } />;
    }
}

class LiveToolBar extends TransactionsToolBar {
    render() {
        return (
            <TransactionsToolBar { ...this.props } className={ this.block() }>
                { this.renderPermissionsSelect() }
                { this.renderGroupsSelect() }
                { this.renderUsersSelect() }
            </TransactionsToolBar>
        );
    }
}

export class Live extends Transactions {
    componentWillMount() {
        super.componentWillMount();

        setTitle(this.formatMessage("title"));
    }

    componentWillReceiveProps(nextProps) {
        const { auth: { company: currCompany } } = this.props;
        const { auth: { company: nextCompany } } = nextProps;

        if (currCompany !== nextCompany) {
            LiveActions.reset.trigger();
            LiveActions.refresh.trigger();
        }
    }

    renderToolBar() {
        const { intl, data: { filter } } = this.props;

        return (
            <LiveToolBar { ...{ intl, filter } } onFiltersChange={ setFilters } onSearch={ setSearch } />
        );
    }

    render() {
        const { intl, auth, data: { filter, item: items } } = this.props;

        return (
            <Main toolBar={ this.renderToolBar() } sideBar={ auth.company!=='mipasa'?this.renderSideBar():null } status={ this.renderStatus() }
                className={ this.block() }>
                <LiveTable { ...{ intl, auth, filter, items } } className="live-table" />
            </Main>
        );
    }
}

export default Live.of(LiveStore, LiveActions).connect({ auth: AuthStore });
