import React from "react";
import { Route } from "react-router";

import Live from "./Live";

export default function route(path) {
    return (
        <Route path={ path } component={ Live } />
    );
};
