module.exports = {
    "notification-category-request": {
        "en-US": "Approval Request"
    },
    "notification-category-denied": {
        "en-US": "Request Denied"
    },
    "notification-category-approved": {
        "en-US": "Request Approved"
    },
    "notification-category-alert": {
        "en-US": "System Alert"
    },
    "notification-category-system": {
        "en-US": "User Alert"
    },

    "notification-importance-none": {
        "en-US": ""
    },
    "notification-importance-min": {
        "en-US": "Min"
    },
    "notification-importance-low": {
        "en-US": "Low"
    },
    "notification-importance-default": {
        "en-US": "Default"
    },
    "notification-importance-high": {
        "en-US": "High"
    },
    "notification-importance-max": {
        "en-US": "Max"
    },

    "notification-actions-accept": {
        "en-US": "Accept"
    },
    "notification-actions-reject": {
        "en-US": "Reject"
    },
    "notification-actions-later": {
        "en-US": "Later"
    },
    "notification-actions-add-to-store": {
        "en-US": "Add to vault"
    },

    "notifications-preview-title": {
        "en-US": "Notifications"
    },
    "notifications-preview-title-back": {
        "en-US": "Back to notifications"
    },
    "notifications-preview-show-all": {
        "en-US": "Show All"
    },
    "notifications-preview-empty": {
        "en-US": "You have no notifications"
    }
};
