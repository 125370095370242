import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router";

import { User } from "../model";
import { IntlModel } from "../components/Intl";
import { AuthActions } from "../init";

import { Component } from "./Component";
import Menu from "./Menu";
import { DROP_ALIGN_VERTICAL } from "./Drop";
import { Avatar } from "./Avatar";

const ALIGN = [{ side: "right", to: "right", offset: 17 }, DROP_ALIGN_VERTICAL];

function handleLogoutClick(event) {
    event.preventDefault();
    AuthActions.logout.trigger();
}

export class UserMenu extends Component {
    renderAvatar() {
        const { user: { avatar, name } } = this.props;

        return (
            <Avatar image={ avatar } name={ name } className={ this.element("avatar") }/>
        );
    }

    renderLabel() {
        const { user } = this.props;

        return (
            <div className="user">
                <div className="user__info">
                    <div className="user__name">{ user.name }</div>
                    <div className="user__role">{ user.role }</div>
                </div>
                { this.renderAvatar() }
            </div>
        );
    }

    render() {
        const { intl } = this.props;

        return (
            <Menu label={ this.renderLabel() } align={ ALIGN }
                className={ "user-menu" } dropClassName="user-menu__drop">
                <Link to="/msb/settings/profile/" className="link">
                    { intl.formatMessage("user-menu-profile") }
                </Link>
                <Link to="/logout" className="link" onClick={ handleLogoutClick }>
                    { intl.formatMessage("user-menu-logout") }
                </Link>
            </Menu>
        );
    }
}

UserMenu.initProps({
    intl: { type: PropTypes.instanceOf(IntlModel).isRequired },
    user: { type: PropTypes.instanceOf(User).isRequired }
});
