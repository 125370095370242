import CSRCreate from "../../../Main/Settings/Credentials/CSRs/CSRCreate";
import { loadDefault, setItem } from "../../../Main/Settings/Credentials/CSRs/CSRCreateActions";

import CSRCreateStore from "../../../Main/Settings/Credentials/CSRs/CSRCreateStore";

import { IntlStore } from "../../../../init";
import React from "react";
import { Component, Markdown, SideBar, ToolBarTitle } from "../../../../ui";

const EMPTY = {};

export class NetworkJoin extends Component {
    constructor(...args) {
        super(...args);

        this.onCancel = this.onCancel.bind(this);
    }

    onCancel() {
        const { router, params: { networkId } } = this.props;

        router.replace(`/msb/manage/networks/${networkId}/`);
    }

    setParams(props) {
        const { params: { networkId, alias } } = props;

        setItem({ "groupId": networkId, "alias": alias, "default": true });
    }

    componentWillMount() {
        loadDefault().then(() => {
            this.setParams(this.props);
        });
    }

    componentWillReceiveProps(nextProps) {
        const { params: { networkId: currNetworkId, alias: currAlias } } = this.props;
        const { params: { networkId: nextNetworkId, alias: nextAlias } } = nextProps;

        if (currNetworkId !== nextNetworkId || currAlias !== nextAlias) {
            this.setParams(nextProps);
        }
    }

    renderToolBar() {
        const { params: { networkId } } = this.props;

        return (
            <ToolBarTitle retUrl={ `/msb/manage/networks/${networkId}/` }>
                { this.formatMessage("title") }
            </ToolBarTitle>
        );
    }

    render() {
        return (
            <SideBar toolBar={ this.renderToolBar() } className={ this.block() }>
                <CSRCreate { ...this.props } item={ EMPTY }
                    header={ false } cancel={ false }
                    submit={ this.formatMessage("submit") }
                    className={ this.element("form") } />
            </SideBar>
        );
    }
}

export default NetworkJoin.connect({ intl: IntlStore, data: CSRCreateStore });
