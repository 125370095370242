import { Entity } from "./Entity";
import { NetworkHost } from "./NetworkHost";

export class NetworkNode extends Entity {
    set hostId(hostId) {
    }

    init(...args) {
        super.init({ host: {} }, ...args);
    }
}

NetworkNode.connect({
    host: NetworkHost
});
