import React from "react";
import { Route } from "react-router";

import Affiliations from "./Affiliations";

export default function route(path) {
    return (
        <Route path={ path } component={ Affiliations }>
        </Route>
    );
};
