import { TableModel, TableFilterModel } from "../../../../../components/Table";
import { CertificateRequests } from "../../../../../model";

class ManagedRequestsFilter extends TableFilterModel {
    init(...args) {
        super.init({ status: "Pending", sort: { } }, ...args);
    }

    toJSON() {
        const { status } = this;

        return Object.assign(super.toJSON(), {
            sort__: void 0,
            search__: void 0,
            managed__: true,
            status: status || void 0
        });
    }
}

export default class ManagedRequestsModel extends TableModel.of(CertificateRequests, ManagedRequestsFilter) {
};
