import { FormEditStore } from "../../../../components/Form";
import { JSAPIConnector } from "../../../../init";
import { NetworkCertificate } from "../../../../model";
import HaceraNetworkJoinActions from "./HaceraNetworkJoinActions";

import HaceraNetworkJoinModel from "./HaceraNetworkJoinModel";

class HaceraNetworkJoinStore extends FormEditStore.of(HaceraNetworkJoinModel) {
    save(item, user) {
        const { config } = item;

        return JSAPIConnector.create(new NetworkCertificate(), {
            user: {
                id: user.id,
                affiliation: "org1",
                attributes: {
                    "hacera.relay": "HACERA"
                },
                attributeRequests: {
                    "hacera.relay": false
                }
            },
            network: config
        });
    }

    saveItem(item, user) {
        return this.invoke("save-item", this.save(item, user));
    }
}

export default new HaceraNetworkJoinStore().connect(HaceraNetworkJoinActions);
