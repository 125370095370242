import { AuthStore, IntlStore } from "../../../../init";
import React from "react";
import { Component, FormField, GenericForm, Select, SideBar, ToolBarTitle } from "../../../../ui";

import { saveItem, setItem } from "./HaceraNetworkJoinActions";
import HaceraNetworkJoinStore from "./HaceraNetworkJoinStore";
import HaceraNetworksStore from "./HaceraNetworksStore";

function setConfigItem(networkId, configs) {
    setItem(configs.value(networkId));
}

export class HaceraNetworkJoin extends Component {
    constructor(...args) {
        super(...args);

        this.onSubmit = this.onSubmit.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onNetworkChange = this.onNetworkChange.bind(this);
    }

    componentWillMount() {
        const { params: { networkId }, items: { item: { configs } } } = this.props;

        setConfigItem(networkId, configs);
    }

    componentWillReceiveProps(nextProps) {
        const { params: { networkId: currNetworkId }, items: { item: { configs: currConfigs } } } = this.props;
        const { params: { networkId: nextNetworkId }, items: { item: { configs: nextConfigs } } } = nextProps;

        if (nextNetworkId !== currNetworkId || nextConfigs !== currConfigs) {
            setConfigItem(nextNetworkId, nextConfigs);
        }
    }

    onSubmit() {
        const { data: { item }, auth: { session: user } } = this.props;

        saveItem(item, user.user);
    }

    onCancel() {
        const { router, data: { item } } = this.props;

        router.replace(`/msb/manage/hacera-networks/${item.id}/`);
    }

    onNetworkChange(networkId) {
        const { items: { item: { configs } } } = this.props;

        setConfigItem(networkId, configs);
    }

    renderToolBar() {
        const { data: { item }, params: { networkId } } = this.props;

        return (
            <ToolBarTitle retUrl={ `/msb/manage/hacera-networks/${networkId}/` }>
                { this.formatMessage("title", item.valueOf()) }
            </ToolBarTitle>
        );
    }

    render() {
        const { intl, data: { busy, error, item }, items: { item: { configs } } } = this.props;

        if (!item || !item.id) {
            return null;
        }

        return (
            <SideBar toolBar={ this.renderToolBar() } className={ this.block() }>
                <GenericForm { ...{ intl, busy, error } }
                    header={ false }
                    close={ false }
                    submit={ this.formatMessage("submit") }
                    className={ this.element("form") }
                    onSubmit={ this.onSubmit }>

                    <FormField name="config" label={ this.formatMessage("network-id-label") }>
                        <Select items={ configs } value={ item.id } nullValue={ false }
                            dropClassName="drop_wide"
                            placeholder={ this.formatMessage("network-id-placeholder") }
                            onChange={ this.onNetworkChange } />
                    </FormField>
                </GenericForm>
            </SideBar>
        );
    }
}

export default HaceraNetworkJoin.connect({
    intl: IntlStore,
    data: HaceraNetworkJoinStore,
    items: HaceraNetworksStore,
    auth: AuthStore
});
