import Base from "../Base";
import { assign, clone, isDefined, isEqual, map, toJSON, valueOf } from "../object";

function defineJoin(target, name, Type) {
    const prop = `_${name}`;
    const propId = `${name}Id`;
    const targetProto = target.prototype;

    if (!Model.is(Type)) {
        throw target.error(`Invalid type ${Type}. Model expected.`, TypeError);
    }

    Object.defineProperty(targetProto, name, {
        configurable: true,
        enumerable: true,
        get() {
            return this[prop] || null;
        },
        set(nextValue) {
            const currValue = this[prop];

            if (currValue !== nextValue) {
                if (isDefined(nextValue) && !Type.isA(nextValue)) {
                    nextValue = currValue ? currValue.set(nextValue) : new Type(nextValue);
                }

                if (currValue !== nextValue) {
                    this.define({ [prop]: nextValue });
                }
            }
        }
    });

    if (!targetProto.hasOwnProperty(propId)) {
        Object.defineProperty(targetProto, propId, {
            configurable: true,

            get() {
                return this[prop] && this[prop].id;
            }
        });
    }
}

/**
 * Model object.
 * @param {any} ...data
 */
export default class Model extends Base {
    init(...data) {
        assign(this, ...data);
    }

    validate() {
    }

    getAncestor() {
        const proto = Object.getPrototypeOf(this);

        return proto instanceof this.constructor ? proto : null;
    }

    getRelations() {
        return this.constructor.getRelations();
    }

    isEqual(from) {
        let retValue = from !== null && from !== void 0;

        if (retValue) {
            let keys = Object.keys(this.valueOf());

            from = valueOf(from);
            retValue = keys.length === Object.keys(from).length && keys.every((key) => isEqual(this[key], from[key]));
        }

        return retValue;
    }

    diff(from) {
        from = from !== null && from !== void 0 ? valueOf(from) : {};

        return Object.keys(this.valueOf()).filter((key) => !isEqual(this[key], from[key]));
    }

    set(...data) {
        const next = clone(this, ...data);

        return next.isEqual(this.valueOf()) ? this : next;
    }

    reset() {
        return this.getAncestor() || this;
    }

    isSame(to) {
        return (to instanceof this.constructor) && ((this.getAncestor() || this) === (to.getAncestor() || to));
    }

    getError(message, field) {
        return Object.assign(new Error(message), { model: this, field });
    }

    getError2(message, field) {
        return this.getError(`${this.constructor.name} ${field} ${message}`, field);
    }

    valueOf() {
        return Object.assign({}, this.getAncestor(), this, map(this.getRelations(), (value, prop) => this[prop]));
    }

    getProps(...props) {
        const values = {};

        props.forEach((prop) => {
            values[prop] = this[prop];
        });

        return values;
    }

    toJSON() {
        return map(this.valueOf(), toJSON);
    }

    static from(data) {
        return this.isA(data) ? data : new this(data);
    }

    static create(...data) {
        return data.map((value) => new this(value));
    }

    static getRelations() {
        return this.__relations || {};
    }

    static connect(models) {
        this.__relations = Object.assign({}, this.__relations, models);

        for (let prop in models) {
            if (models.hasOwnProperty(prop)) {
                defineJoin(this, prop, models[prop]);
            }
        }

        return this;
    }
}
