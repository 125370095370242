import React from "react";
import { Link as RouterLink, withRouter } from "react-router";
import { Url } from "../utils";
import { Component } from "./Component";

export const Link = withRouter(class Link extends Component {
    render() {
        const { children, to, notActive, location: { pathname } } = this.props;

        return (
            <RouterLink to={ Url.stringify(to, pathname) }
                className={ this.block() } activeClassName={ notActive ? "" : Link.modifiers({ active: true }) }>
                { children }
            </RouterLink>
        );
    }
});
