import { IntlStore } from "../../init";
import React from "react";
import {
    Component,
    COUNTRIES,
    FormCaptcha,
    FormCheckBox,
    FormField,
    FormInput,
    FormPassword,
    FormPhone,
    Markdown,
    SelectPhoneCode
} from "../../ui";

import theme from "../../utils/theme";

import AuthForm from "./AuthForm";
import { saveItem, setItem, setItemError, setItemField } from "./RegisterActions";
import RegisterStore from "./RegisterStore";

function onFieldChange(value, name) {
    if (name) {
        setItemField(name, value);
    }
}

function onCountryChange(value) {
    setItemField("country", value);
}

function onCaptchaChange(value) {
    setItemField("captcha", value);
}

class Register extends Component {
    init(...args) {
        super.init(...args);

        this.onCancel = this.onCancel.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onCaptchaExpired = this.onCaptchaExpired.bind(this);
    }

    componentWillMount() {
        setItem(new RegisterStore.Model.Item);
    }

    onSuccess() {
        this.props.router.replace("/auth/complete");
    }

    onSubmit() {
        const { data: { item } } = this.props;

        saveItem(item).then(() => this.onSuccess()).catch(() => void 0);
    }

    onCaptchaExpired() {
        const { intl } = this.props;

        setItemError("captcha", new Error(intl.formatMessage("error-registration-captcha-expired")));
    }

    onCancel() {
        this.props.router.replace("/auth");
    }

    render() {
        const { data: { item, busy, error }, intl } = this.props;

        const termsOfUse = <Markdown>{ this.formatMessage("agree-placeholder") }</Markdown>;

        return (
            <AuthForm { ...{ intl, busy, error, disabled: !item.agree, close: false } }
                title={ this.formatMessage("title") }
                cancel={ this.formatMessage("login") }
                submit={ this.formatMessage("submit") }
                className={ this.block() }
                onSubmit={ this.onSubmit }
                onCancel={ this.onCancel }>

                <FormField name="firstName" label={ this.formatMessage("first-name-label") }>
                    <FormInput name="firstName" value={ item.firstName }
                        placeholder={ this.formatMessage("first-name-placeholder") }
                        onChange={ onFieldChange } />
                </FormField>

                <FormField name="lastName" label={ this.formatMessage("last-name-label") }>
                    <FormInput name="lastName" value={ item.lastName }
                        placeholder={ this.formatMessage("last-name-placeholder") }
                        onChange={ onFieldChange } />
                </FormField>

                <FormField name="userName" label={ this.formatMessage("username-label") }>
                    <FormInput name="userName" value={ item.userName } maxLength={ 50 }
                        placeholder={ this.formatMessage("username-placeholder") }
                        onChange={ onFieldChange } />
                </FormField>

                <FormField name="email" label={ this.formatMessage("email-label") }>
                    <FormInput type="text" name="email" value={ item.email }
                        placeholder={ this.formatMessage("email-placeholder") }
                        onChange={ onFieldChange } />
                </FormField>

                <FormField name="password" label={ this.formatMessage("password-label") }>
                    <FormPassword name="password" value={ item.password }
                        placeholder={ this.formatMessage("password-placeholder") }
                        onChange={ onFieldChange } />
                </FormField>

                <FormField name="country phone" label={ this.formatMessage("phone-label") }>
                    <SelectPhoneCode name="country" value={ item.country } nullValue={ false }
                        placeholder={ this.formatMessage("country-placeholder") }
                        theme={ theme }
                        onChange={ onCountryChange } />
                    <FormPhone name="phone" country={ COUNTRIES.value(item.country) } value={ item.phone }
                        placeholder={ this.formatMessage("phone-placeholder") }
                        onChange={ onFieldChange } />
                </FormField>

                <FormField name="captcha">
                    <FormCaptcha siteKey="6LfkN-oSAAAAALc8-xrubo92ORJMSc5h1PZPlkTc" value={ item.captcha }
                        placeholder={ this.formatMessage("captcha-placeholder") }
                        onChange={ onCaptchaChange } onExpired={ this.onCaptchaExpired } />
                </FormField>

                <FormField name="agree">
                    <FormCheckBox name="agree" value={ item.agree }
                        placeholder={ termsOfUse }
                        onChange={ onFieldChange } />
                </FormField>
            </AuthForm>
        );
    }
}

export default Register.connect({
    intl: IntlStore,
    data: RegisterStore
});
