import { setTitle } from "../../../../components/Header";
import { View } from "../../../../components/View";
import React from "react";
import { Main, Tree } from "../../../../ui";

import NetworksActions from "./NetworksActions";
import NetworksStore from "./NetworksStore";

class NetworksTree extends Tree {
    onNodeClick(event) {
        const { onSelect } = this.props;

        if (onSelect) {
            const id = event.currentTarget.getAttribute("data-id");

            if (id !== "root") {
                onSelect(id);
            }
        }
    }
}

export class Networks extends View {
    init(...args) {
        super.init(...args);

        this.onItemSelect = this.onItemSelect.bind(this);
    }

    componentWillMount() {
        setTitle(this.formatMessage("title"));
        super.componentWillMount();
    }

    onItemSelect(networkId) {
        const { router } = this.props;

        router.replace(`/msb/manage/networks/${networkId}/`);
    }

    getData() {
        const { data: { item: groups } } = this.props;
        let items;

        items = groups.map(({ id, groupId, localName, formattedName }) => {
            return {
                id,
                parentId: groupId || "root",
                name: localName,
                path: formattedName
            };
        });

        if (items.length) {
            items.push({
                id: "root",
                name: this.formatMessage("root"),
                icon: "/images/networks.svg"
            });
        }

        return items;
    }

    renderSideBar() {
        return this.props.children;
    }

    render() {
        const { intl, params: { networkId } } = this.props;
        const items = this.getData();

        return (
            <Main sideBar={ this.renderSideBar() } status={ this.renderStatus() } className={ this.block() }>
                <NetworksTree { ...{ intl, items } } selectedId={ networkId } nodeWidth={ 150 }
                    className={ this.element("tree") } onSelect={ this.onItemSelect } />
            </Main>
        );
    }
}

export default Networks.of(NetworksStore, NetworksActions);
