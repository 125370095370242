import React from "react";
import { arc as d3Arc, pie as d3Pie } from "d3-shape";
import { schemeCategory20c } from "d3-scale";

import { Component } from "./Component";

export class PieChart extends Component {
    getLabel(item) {
        return item.label;
    }

    getValue(item) {
        return item.value;
    }

    getColor(item, index) {
        return schemeCategory20c[index % 20];
    }

    renderArc(arc, index, arcs) {
        const { data, radius, innerRadius } = this.props;

        arc = d3Arc()
            .innerRadius(innerRadius)
            .outerRadius(radius)
            .startAngle(index === 0 ? 0 : arc.startAngle)
            .endAngle(arc.endAngle);

        index = arcs.length - 1 - index;

        return (
            <path key={ "arc-" + index } d={ arc() }
                fill={ this.getColor(data[index], index) }
                className="pie-chart__arc" />
        );
    }

    renderLabel(arc, index) {
        const { data, radius, innerRadius } = this.props;
        const center = d3Arc()
            .innerRadius(innerRadius)
            .outerRadius(radius)
            .startAngle(arc.startAngle)
            .endAngle(arc.endAngle)
            .centroid().map((c) => c * 1.08);

        return (
            <text key={ "label-" + index } transform={ "translate(" + center + ")" }
                className="pie-chart__label">
                { this.getLabel(data[index], index) }
            </text>
        );
    }

    getArcs(values) {
        return d3Pie().sort(null)(values);
    }

    render() {
        const { data, width, height } = this.props;
        const arcs = this.getArcs(data.map(this.getValue.bind(this)));

        return (
            <svg { ...{ width, height } } className="pie-chart">
                <g transform={ `translate(${width / 2}, ${height / 2})` } className="pie-chart__pie">
                    { arcs.slice(0).reverse().map(this.renderArc.bind(this)) }
                    { arcs.map(this.renderLabel.bind(this)) }
                </g>
            </svg>
        );
    }
}
