import React from "react";

import { Component } from "./Component";

export class ToolBar extends Component {
    render() {
        const { children } = this.props;

        return (
            <div className={ this.block() }>
                { children }
            </div>
        );
    }
}
