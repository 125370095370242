import React from "react";

import { Component } from "../Component";
import { Collection } from "../../utils/dataflow";
import { Select } from "..";

export const COUNTRIES = new Collection(require("./countries.json"));

export default class SelectCountry extends Component {
    render() {
        return (
            <Select { ...this.props } dropClassName="drop_middle drop_country" items={ COUNTRIES } />
        );
    }
}

SelectCountry.initDefaultProps({
    search: true
});
