module.exports = {
    "managed-requests-title": {
        "en-US": "Credentials - For Review"
    },

    "managed-requests-tool-bar-search": {
        "en-US": "Search CSRs"
    },

    "managed-requests-table-column-num": {
        "en-US": "#"
    },
    "managed-requests-table-column-user": {
        "en-US": "User"
    },
    "managed-requests-table-column-name": {
        "en-US": "Alias"
    },
    "managed-requests-table-column-common-name": {
        "en-US": "Common Name"
    },
    "managed-requests-table-column-creation-time": {
        "en-US": "Issuance Time"
    },
    "managed-requests-table-column-actions": {
        "en-US": ""
    },

    "managed-requests-row-approve": {
        "en-US": "Approve"
    },
    "managed-requests-row-deny": {
        "en-US": "Deny"
    }
};
