import PropTypes from "prop-types";
import React from "react";

import { Component } from "../Component";

export class FormHeader extends Component {
    render() {
        const { children, close, onClose } = this.props;

        return (
            <header className={ this.block() }>
                <h2 className={ this.element("title") }>{ children }</h2>
                {
                    close === true
                        ? <i className={ this.element("close", null, "ico ico-close") } onClick={ onClose } />
                        : close
                }
            </header>
        );
    }
}

FormHeader.initPropTypes({
    close: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
    onClose: PropTypes.func
});
