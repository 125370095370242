import { IntlStore } from "../../init";
import React from "react";
import { Markdown } from "../../ui";
import { Component } from "../../ui/Component";

import AuthForm from "./AuthForm";

class RegisterMessage extends Component {
    init(...args) {
        super.init(...args);

        this.onClose = this.onClose.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onSubmit() {
        this.props.router.replace("/auth");
    }

    onClose() {
        this.props.router.replace("/auth");
    }

    render() {
        const { intl, location: { pathname } } = this.props;

        const message = pathname.endsWith("complete") ? "complete" : pathname.endsWith("success") ? "success"
            : pathname.endsWith("recovery") ? "recovery" : "error";

        return (
            <AuthForm { ...{ intl } }
                title={ this.formatMessage(message + "-title") }
                submit={ this.formatMessage(message + "-submit") }
                cancel={ false }
                className={ this.block() }
                onClose={ this.onClose }
                onSubmit={ this.onSubmit }>

                <Markdown className={ this.element("message") }>
                    { this.formatMessage(message + "-intro") }
                </Markdown>
            </AuthForm>
        );
    }
}

export default RegisterMessage.connect({
    intl: IntlStore
});
