import { TableModel, TableFilterModel } from "../../../../../components/Table";
import { Certificates } from "../../../../../model";

class CertificatesFilter extends TableFilterModel {
    init(...args) {
        super.init({ sort: { } }, ...args);
    }
}

export default class CertificatesModel extends TableModel.of(Certificates, CertificatesFilter) {
};
