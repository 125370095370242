import React from "react";
import { IndexRoute, Route } from "react-router";

import Network from "./Network";
import NetworkAdd from "./NetworkAdd";
import NetworkJoin from "./NetworkJoin";
import Networks from "./Networks";
import NetworksEmpty from "./NetworksEmpty";

export default function route(path) {
    return (
        <Route path={ path } component={ Networks }>
            <IndexRoute component={ NetworksEmpty } />
            <Route path=":networkId/" component={ Network } />
            <Route path=":networkId/join/:alias" component={ NetworkJoin } />
            <Route path=":networkId/add/:alias" component={ NetworkAdd } />
        </Route>
    );
}
