import { TableModel, TableFilterModel } from "../../../../components/Table";
import { LDAPConfigs } from "../../../../model";

class RepositoriesFilter extends TableFilterModel {
    init(...args) {
        super.init({ sort: { index: true } }, ...args);
    }
}

export default class RepositoryModel extends TableModel.of(LDAPConfigs, RepositoriesFilter) {
};
