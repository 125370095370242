import { APIConnector } from "../../init";
import { FormEditStore } from "../../components/Form";

import ForgotPasswordModel from "./ForgotPasswordModel";
import ForgotPasswordActions from "./ForgotPasswordActions";

class ForgotPasswordStore extends FormEditStore.of(ForgotPasswordModel) {
    processError(error) {
        error = super.processError(error);

        if (error.code === "error-email-not-found" || error.code === "error-user-not-found") {
            error = Object.assign(error, { field: "email", code: "error-email-not-found" });
        } else if (!error.field) {
            error = Object.assign(error, { code: "error-internal-server-error" });
        }

        return error;
    }

    forgot(item) {
        const url = APIConnector.buildUrl("UserCredentials/forgot");

        return APIConnector.fetch(url, { method: "POST", body: APIConnector.stringify(item) })
            .then((data) => APIConnector.response(data));
    }

    forgotPassword(item) {
        const error = item.validate();

        return this.invoke("forgot", error ? Promise.reject(error) : this.forgot(item));
    }
}

export default new ForgotPasswordStore().connect(ForgotPasswordActions);
