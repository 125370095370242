import { APIConnector } from "../components/API";
import SessionConnector from "./session";

export default class MockConnector extends APIConnector.connect("/mock/", SessionConnector) {
    static getUrl(entity, id = null, params = null) {
        const name = this.getName(entity);

        return this.baseUrl
            .relative(name + (id !== null ? "/" + id : "") + ".json")
            .params(params)
            .toString();
    }
}
