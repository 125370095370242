import { View } from "../../../../components/View";
import React from "react";
import { DROP_ALIGN_VERTICAL, Link, Menu, SideBar, Table, TableRow, TableToolBar, ToolBar } from "../../../../ui";
import DelegationManagersActions, { setFilter, setPage, setSearch, setSort } from "./DelegationManagersActions";
import DelegationManagersStore from "./DelegationManagersStore";

import DelegationsStore from "./DelegationsStore";

const ALIGN = [{ side: "right", to: "right", offset: 4 }, DROP_ALIGN_VERTICAL];

class DelegationManagersRow extends TableRow {
    renderNameCell() {
        const { item: { name } } = this.props;

        return (
            <td className={ this.element("name") } data-title={ name }>
                { name }
            </td>
        );
    }

    renderLocationCell() {
        const { item: { location } } = this.props;

        return (
            <td className={ this.element("location") } data-title={ location }>
                { location }
            </td>
        );
    }

    render() {
        return (
            <TableRow { ...this.props } enumerable={ true } className={ this.block() }>
                { this.renderNumCell() }
                { this.renderNameCell() }
                { this.renderLocationCell() }
            </TableRow>
        );
    };
}

class DelegationManagersTable extends Table {
    sortByColumn(column) {
        setSort(column);
    }

    setPage(page) {
        setPage(page);
    }

    renderHead() {
        return (
            <tr>
                { this.renderHeadNum() }
                { this.renderHeadCell("name") }
                { this.renderHeadCell("location") }
            </tr>
        );
    }

    renderRow(item) {
        const { intl } = this.props;

        return <DelegationManagersRow key={ item.id } { ...{ intl, item } } />;
    }
}

class DelegationManagersToolBar extends TableToolBar {
    render() {
        return (
            <TableToolBar className={ this.props }>
                { this.renderSearch() }
            </TableToolBar>
        );
    }
}

export class DelegationManagers extends View {
    refresh(group) {
        if (group) {
            setFilter("group", group);
        }
    }

    setItem(networkId) {
        const { delegations: { item: delegations } } = this.props;

        this.refresh(delegations.value(networkId));
    }

    componentWillMount() {
        this.setItem(this.props.params.networkId);
    }

    componentWillReceiveProps({ params: { networkId: nextGroupId }, delegations: nextDelegations }) {
        const { params: { networkId: currGroupId }, delegations: currDelegations } = this.props;

        if (currGroupId !== nextGroupId || nextDelegations !== currDelegations) {
            this.setItem(nextGroupId);
        }
    }

    renderMainToolBar() {
        const { delegations: { item: delegations } } = this.props;
        const { params: { networkId } } = this.props;

        const network = delegations.value(networkId);

        return (
            <ToolBar key="main" className={ this.element("main") }>
                <div className={ this.element("title") }>{ network.formattedName }</div>
                <Link to={ "join" } className={ this.element("join") }>
                    { this.formatMessage("join") }
                </Link>
                <Menu label={ this.formatMessage("add") } align={ ALIGN }
                    className={ this.element("add") } dropClassName={ this.element("add-drop") }>
                    <Link to={ "add-manager" } className={ this.element("add-manager") }>
                        { this.formatMessage("add-manager") }
                    </Link>
                    <Link to={ "add-participant" } className={ this.element("add-participant") }>
                        { this.formatMessage("add-participant") }
                    </Link>
                </Menu>
            </ToolBar>
        );
    }

    renderSearchToolBar() {
        const { intl, data: { filter } } = this.props;

        return (
            <DelegationManagersToolBar key="search" { ...{ intl, filter } } onSearch={ setSearch }
                className={ this.element("toolbar") } />
        );
    }

    renderToolBars() {
        return [
            this.renderMainToolBar(),
            this.renderSearchToolBar()
        ];
    }

    render() {
        const { intl, data: { item: items, filter }, delegations: { item: delegations } } = this.props;
        const { params: { networkId } } = this.props;

        const network = delegations.value(networkId);

        if (!network) {
            return null;
        }

        return (
            <SideBar toolBar={ this.renderToolBars() } className={ this.block() }>
                <DelegationManagersTable { ...{ intl, filter, items } } className={ this.element("table") } />
            </SideBar>
        );
    }
}

export default DelegationManagers.of(DelegationManagersStore, DelegationManagersActions)
    .connect({ delegations: DelegationsStore });
