import PropTypes from "prop-types";
import React from "react";

import { Component } from "../Component";
import { FormInput } from "./FormInput";

export class FormPhone extends Component {
    init(...args) {
        super.init(...args);

        this.onChange = this.onChange.bind(this);
    }

    getCode() {
        return this.props.country.phoneCode;
    }

    getNumber() {
        const { value } = this.props;

        return value && value.replace(/^\+\d+\s+/, "");
    }

    onChange(value) {
        const { onChange, name } = this.props;

        if (onChange) {
            onChange(value ? (this.getCode() + " " + value) : "", name);
        }
    }

    renderInput() {
        const { placeholder } = this.props;

        return <FormInput type="phone" value={ this.getNumber() } placeholder={ placeholder }
            className={ this.element("number") } onChange={ this.onChange } />;
    }

    render() {
        return (
            <div className={ this.block() }>
                { this.renderInput() }
            </div>
        );
    }
}

FormPhone.initProps({
    country: PropTypes.object.isRequired
});
