import { IntlModel } from "../../components/Intl";

import { TableFilterModel } from "../../components/Table";
import PropTypes from "prop-types";
import React from "react";

import { Component } from "../Component";
import { Markdown } from "../Markdown";

class TablePaginationLink extends Component {
    constructor(...args) {
        super(...args);

        this.onClick = this.onClick.bind(this);
    }

    onClick({ currentTarget }) {
        const { onClick } = this.props;

        if (onClick) {
            onClick(Number(currentTarget.getAttribute("data-page")));
        }
    }

    render() {
        const { page, children, disabled } = this.props;
        const className = this.block({ disabled }, "link");

        return (
            <a data-page={ page } className={ className } onClick={ disabled ? null : this.onClick }>
                { children }
            </a>
        );
    }
}

export default class TablePagination extends Component {
    renderLink(page, message, disabled, params) {
        const { onChange } = this.props;

        return (
            <TablePaginationLink page={ page } disabled={ disabled }
                className={ this.element("link", { [message]: true }) } onClick={ onChange }>
                { this.formatMessage(message, params) }
            </TablePaginationLink>
        );
    }

    render() {
        const { filter, filter: { total, perPage } } = this.props;

        const pages = Math.ceil(total / perPage);
        const page = Math.max(Math.min(filter.page, pages - 1), 0);

        const params = {
            page, pages,
            total: total || 0,
            perPage,
            from: page * perPage + 1,
            to: Math.min((page + 1) * perPage, total)
        };

        return (
            <div className={ this.block() }>
                { this.renderLink(page - 1, "prev", page < 1, params) }
                { this.renderLink(page + 1, "next", page > pages - 2, params) }
                <Markdown className={ this.element("current") }>{ this.formatMessage("current", params) }</Markdown>
            </div>
        );
    }
}

TablePagination.initPropTypes({
    intl: PropTypes.instanceOf(IntlModel).isRequired,
    filter: PropTypes.instanceOf(TableFilterModel).isRequired,
    onChange: PropTypes.func.isRequired
});
