import React from "react";
import { Component } from "../Component";
import { FormInput } from "./FormInput";

export class FormPassword extends Component {
    init(...args) {
        super.init(...args);

        this.initState({ visible: false });
        this.onIconClick = this.onIconClick.bind(this);
    }

    onIconClick() {
        this.setState({ visible: !this.state.visible });
    }

    renderIcon(canBeVisible) {
        const { visible } = this.state;
        const className = this.element("icon", null, "ico ico-visibility-" + (visible ? "off" : "on"));

        return canBeVisible ? <i className={ className } onClick={ this.onIconClick } /> : null;
    }

    render() {
        const { canBeVisible } = this.props;
        const { visible } = this.state;

        return (
            <div className={ this.block() }>
                <FormInput { ...this.props } type={ canBeVisible && visible ? "text" : "password" }
                    autoComplete={ "new-password" }
                    className={ this.element("field") } />
                { this.renderIcon(canBeVisible) }
            </div>
        );
    }
}

FormPassword.initProps({
    canBeVisible: { value: true }
});
