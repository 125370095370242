import React from "react";
import { IndexRoute, Route } from "react-router";

import HaceraNetwork from "./HaceraNetwork";
import HaceraNetworkNode from "./HaceraNetworkNode";
import HaceraNetworkJoin from "./HaceraNetworkJoin";

import HaceraNetworks from "./HaceraNetworks";
import HaceraNetworkEmpty from "./HaceraNetworksEmpty";

export default function route(path) {
    return (
        <Route path={ path } component={ HaceraNetworks }>
            <IndexRoute component={ HaceraNetworkEmpty } />
            <Route path=":networkId/" component={ HaceraNetwork } />
            <Route path=":networkId/join" component={ HaceraNetworkJoin } />
            <Route path=":networkId/:nodeId" component={ HaceraNetworkNode } />
        </Route>
    );
}
