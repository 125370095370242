import PropTypes from "prop-types";
import React from "react";
import { Component } from "../Component";

import { getInsideConstraint, MoveHandle, translate } from "../../utils/html";

import ScrollBarModel from "./ScrollBarModel";
import { getClientSize, ORIENTATION_HORIZONTAL, ORIENTATIONS } from "./utils";

export default class ScrollBar extends Component {
    onHandleDragStart = (event) => {
        this.startMove(event);
    };

    componentDidMount() {
        this.moveHandle = new MoveHandle({
            move: (props) => this.move(props),
            constraint: getInsideConstraint()
        });

        this.updateHandle();
    }

    componentDidUpdate() {
        this.updateHandle();
    }

    componentWillUnmount() {
        this.stopMove();
        delete this.moveHandle;
    }

    move(props) {
        const { bar, handle } = this;

        if (handle) {
            const { scroll: { scrollSize }, orientation, onScroll } = this.props;
            const size = getClientSize(bar, orientation);
            const position = props[orientation === ORIENTATION_HORIZONTAL ? "left" : "top"];

            onScroll(orientation, scrollSize * position / size);
        } else {
            this.stopMove();
        }
    }

    render() {
        const { orientation, scroll } = this.props;
        const className = this.cx(
            "scroll-bar", "scroll-bar_" + orientation,
            {
                "scroll-bar_disabled": !scroll
            }
        );

        return (
            <div className={ className }>
                <i className="scroll-bar__icon scroll-bar__icon_prev" />
                <div ref={ (el) => this.bar = el } className="scroll-bar__bar">
                    { scroll ? this.renderHandle() : null }
                </div>
                <i className="scroll-bar__icon scroll-bar__icon_next" />
            </div>
        );
    }

    renderHandle() {
        return (
            <div ref={ (el) => this.handle = el } className="scroll-handle"
                onMouseDown={ this.onHandleDragStart }
                onTouchStart={ this.onHandleDragStart } />
        );
    }

    startMove(event) {
        const { moveHandle, props: { orientation } } = this;

        moveHandle.startMove(event, { direction: orientation });
    }

    stopMove() {
        const { moveHandle } = this;

        if (moveHandle) {
            moveHandle.stopMove();
        }
    }

    updateHandle() {
        const { bar, handle } = this;

        if (handle) {
            const { orientation, scroll: { relativePos, relativeSize } } = this.props;
            const size = getClientSize(bar, orientation);

            translate(handle, {
                [orientation === ORIENTATION_HORIZONTAL ? "left" : "top"]: (size * relativePos),
                [orientation === ORIENTATION_HORIZONTAL ? "width" : "height"]: (relativeSize * 100) + "%"
            });
        }
    }
}

ScrollBar.initPropTypes({
    orientation: PropTypes.oneOf(ORIENTATIONS).isRequired,
    scroll: PropTypes.instanceOf(ScrollBarModel)
});
