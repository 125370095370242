module.exports = {
    "enrollment-certificates-title": {
        "en-US": "Enrollment Certificates"
    },
    "enrollment-certificates-loading": {
        "en-US": "Loading..."
    },

    "enrollment-certificates-toolbar-search": {
        "en-US": "Search Certificates"
    },

    "enrollment-certificates-table-column-num": {
        "en-US": "#"
    },

    "enrollment-certificates-table-column-network": {
        "en-US": "Network"
    },
    "enrollment-certificates-table-column-user": {
        "en-US": "User"
    },
    "enrollment-certificates-table-column-certificate": {
        "en-US": "Certificate"
    },

    "enrollment-certificate-network": {
        "en-US": "Network"
    },
    "enrollment-certificate-network-name": {
        "en-US": "Network Name"
    },
    "enrollment-certificate-network-user-id": {
        "en-US": "Creator"
    },
    "enrollment-certificate-network-creation-time": {
        "en-US": "Creation Time"
    },

    "enrollment-certificate-user": {
        "en-US": "User"
    },
    "enrollment-certificate-user-first-name": {
        "en-US": "First Name"
    },
    "enrollment-certificate-user-last-name": {
        "en-US": "Last name"
    },
    "enrollment-certificate-user-affiliation": {
        "en-US": "Affiliation"
    },

    "enrollment-certificate-common": {
        "en-US": "Certificate"
    },
    "enrollment-certificate-issued": {
        "en-US": "Issued"
    },
    "enrollment-certificate-expires": {
        "en-US": "Expires"
    },

    "enrollment-certificate-ssl": {
        "en-US": "SSL"
    },
    "enrollment-certificate-name": {
        "en-US": "Name"
    },
    "enrollment-certificate-brand": {
        "en-US": "Brand"
    },
    "enrollment-certificate-type": {
        "en-US": "Type"
    },
    "enrollment-certificate-serial": {
        "en-US": "Serial"
    },
    "enrollment-certificate-key-size": {
        "en-US": "Key size"
    },

    "enrollment-certificate-advanced": {
        "en-US": "\u00a0"
    },
    "enrollment-certificate-fingerprint-sha1": {
        "en-US": "Fingerprint (SHA1)"
    },
    "enrollment-certificate-fingerprint-md5": {
        "en-US": "Fingerprint (MD5)"
    },

    "enrollment-certificate-attributes": {
        "en-US": "Attributes"
    },

    "enrollment-certificate-raw": {
        "en-US": "Raw"
    }
};
