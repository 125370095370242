import React from "react";
import { Route, IndexRedirect } from "react-router";

import Preferences from "./Preferences";
import { GeneralRoute } from "./General";

export default function route(path) {
    return (
        <Route path={ path } component={ Preferences }>
            <IndexRedirect to="general/" />
            { GeneralRoute("general/") }
        </Route>
    );
}
