import { TableStore } from "../../../../../components/Table";
import ManagedRequestsModel from "./ManagedRequestsModel";

export class ManagedRequestsStore extends TableStore.of(ManagedRequestsModel) {
    setSort(prop) {
        return this.setLocalSort(prop);
    }
}

export default new ManagedRequestsStore();
