import React from "react";

import { IntlStore } from "../../../../../init";
import { Confirmation } from "../../../../../ui/Form";

import CertificateForm from "./CertificateForm";
import CertificateDeleteStore from "./CertificateDeleteStore";
import { setItem, deleteItem } from "./CertificateDeleteActions";

class CertificateDelete extends CertificateForm {
    setItem(item) {
        setItem(item);
    }

    submit() {
        const { data: { item } } = this.props;

        deleteItem(item).then(() => this.onSubmit());
    }

    renderConfirmation() {
        const { intl, data: { item } } = this.props;

        return (
            <Confirmation intl={ intl }
                title={ this.formatMessage("title") }
                message={ this.formatMessage("message", item) }
                onSubmit={ this.submit } onCancel={ this.close } />
        );
    }

    renderError() {
        const { intl, data: { error } } = this.props;

        return (
            <Confirmation { ...{ intl } }
                title={ this.formatMessage("failed-title") }
                message={ error.message }
                submit={ this.formatMessage("alert-ok") } cancel={ false }
                onSubmit={ this.close } onCancel={ this.close } />
        );
    }

    render() {
        const { data: { item, error } } = this.props;

        return item && item.id ? error ? this.renderError() : this.renderConfirmation() : null;
    }
}

export default CertificateDelete.connect({ intl: IntlStore, data: CertificateDeleteStore });
