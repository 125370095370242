import { IntlStore, JSAPIConnector } from "../../init";
import { NetworkRequests } from "../../model";
import SelectItem from "./SelectItem";

const SelectNetworkRequest = SelectItem.of(NetworkRequests, JSAPIConnector);

export const SelectNetworkRequestActions = SelectNetworkRequest.Actions;
export const SelectNetworkRequestStore = SelectNetworkRequest.Store;
export const SelectNetworkRequestModel = SelectNetworkRequest.Model;

export default SelectNetworkRequest.connect({ data: SelectNetworkRequestStore, intl: IntlStore });
