import React from "react";

import { Component } from "../Component";

export class NotificationImportance extends Component {
    render() {
        const { item: { importance } } = this.props;

        return (
            <div data-tooltip={ this.formatMessage(importance) } className={ this.block({ [importance]: true }) } />
        );
    }
}
