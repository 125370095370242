module.exports = {
    "users-title": {
        "en-US": "User Management"
    },
    "manage-users-menu-title": {
        "en-US": "Users"
    },

    "users-tool-bar-search": {
        "en-US": "Search Users"
    },

    "users-table-column-num": {
        "en-US": "#"
    },
    "users-table-column-name": {
        "en-US": "Name"
    },
    "users-table-column-role": {
        "en-US": "Role"
    },
    "users-table-column-company": {
        "en-US": "Company"
    },
    "users-table-column-location": {
        "en-US": "Location"
    },
    "users-table-column-actions": {
        "en-US": ""
    },

    "user-group-delete-title": {
        "en-US": "Delete affiliation"
    },
    "user-group-delete-message": {
        "en-US": "Are you sure you want to remove **{userName}’s** affiliation with **{groupName}**?"
    },

    "users-tool-bar-groups": {
        "en-US": "Groups: All"
    },
    "users-tool-bar-create": {
        "en-US": "New User"
    },

    "manage-user-delete-title": {
        "en-US": "Delete User"
    },
    "manage-user-delete-message": {
        "en-US": "Are you sure you want to delete User **{name}**?"
    },


    "user-create-title": {
        "en-US": "New User"
    },
    "user-create-submit": {
        "en-US": "Create"
    },

    "user-create-first-name-label": {
        "en-US": "First Name"
    },
    "user-create-first-name-placeholder": {
        "en-US": ""
    },

    "user-create-last-name-label": {
        "en-US": "Last Name"
    },
    "user-create-last-name-placeholder": {
        "en-US": ""
    },

    "user-create-email-label": {
        "en-US": "Email"
    },
    "user-create-email-placeholder": {
        "en-US": ""
    },

    "user-create-user-name-label": {
        "en-US": "Username (alias)"
    },
    "user-create-user-name-placeholder": {
        "en-US": ""
    },

    "user-create-password-label": {
        "en-US": "Password"
    },
    "user-create-password-placeholder": {
        "en-US": ""
    },

    "user-create-country-phone-label": {
        "en-US": "Phone number"
    },
    "user-create-country-placeholder": {
        "en-US": ""
    },
    "user-create-phone-placeholder": {
        "en-US": "333.333.4444"
    },


    "user-edit-title": {
        "en-US": "Edit User"
    },
    "user-edit-name-label": {
        "en-US": "Name"
    },
    "user-edit-name-placeholder": {
        "en-US": ""
    },
    "user-edit-groups-label": {
        "en-US": ""
    },
    "user-edit-groups-placeholder": {
        "en-US": "Groups"
    },

    "error-user-email-empty": {
        "en-US": "Email is required"
    },
    "error-user-firstname-empty": {
        "en-US": "First name is required"
    },
    "error-user-lastname-empty": {
        "en-US": "Last name is required"
    },
    "error-user-username-empty": {
        "en-US": "Username is required"
    },
    "error-user-username-too-short": {
        "en-US": "Must be at least 6 characters long"
    },
    "error-user-username-too-long": {
        "en-US": "Cannot exceed 50 characters"
    },
    "error-user-username-invalid": {
        "en-US": "Cannot contain special characters"
    },
    "error-user-password-empty": {
        "en-US": "Field is required"
    },
    "error-user-email-invalid": {
        "en-US": "Email is invalid"
    },
    "error-user-captcha-empty": {
        "en-US": "CAPTCHA is required"
    },
    "error-user-captcha-expired": {
        "en-US": "Please check again."
    },
    "error-user-phone-empty": {
        "en-US": "Phone is required"
    },
    "error-user-phone-invalid": {
        "en-US": "Phone is invalid"
    },
    "error-user-already-exists": {
        "en-US": "User already exists"
    },

    "error-user-username-already-exists": {
        "en-US": "User already exists"
    },
    "error-user-email-already-exists": {
        "en-US": "Email is already in use"
    }
};
