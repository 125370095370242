import React from "react";
import { Route, IndexRedirect } from "react-router";

import NotImplemented from "../../../components/NotImplemented";
import Activity from "./Activity";
import { LiveRoute } from "./Live";
import { HistoricalRoute } from "./Historical";
import { NotificationsRoute } from "./Notifications";

export default function route(path) {
    return (
        <Route path={ path } component={ Activity }>
            <IndexRedirect to="live" />
            { LiveRoute("live") }
            { HistoricalRoute("historical") }
            { NotificationsRoute("notifications") }

            <Route path="reports" component={ NotImplemented } />
        </Route>
    );
};
