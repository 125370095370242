import React from "react";
import PropTypes from "prop-types";
import { Component } from "./Component";

export class Abbr extends Component {
    render() {
        const { name } = this.props;
        const abbr = String(name || "")
                .split(/\s+/)
                .slice(0, 2)
                .map((part) => part.charAt(0))
                .join("")
                .toUpperCase() || "A";

        return (
            <span className={ this.block({ [abbr.length]: true }) }>{ abbr }</span>
        );
    }
}

export class Avatar extends Component {
    render() {
        const { image, name } = this.props;

        const src = image ? image : (name === "Jonathan" ? "images/avatar.png" : null);

        return (
            <div className={ this.block() }>
                { src ? <img src={ src } alt="" className={ this.element("image") } />
                    : <Abbr name={ name } className={ this.element("abbr") } /> }
            </div>
        );
    }
}

Avatar.initProps({
    image: { type: PropTypes.string },
    name: { type: PropTypes.string }
});
