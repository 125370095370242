import { FormModel } from "../Form";
import { Entity } from "../../model";

export class UserGroup extends Entity {
}

export default class UserGroupModel extends FormModel.of(UserGroup) {
    init(...args) {
        super.init({ item: {} }, ...args);
    }
}
