module.exports = {
    "title": {
        "en-US": "MSB client"
    },
    "menu-collapse": {
        "en-US": "Minimize"
    },
    "menu-expand": {
        "en-US": "Maximize"
    },

    "confirmation-yes": {
        "en-US": "Yes"
    },
    "confirmation-no": {
        "en-US": "No"
    },

    "alert-ok": {
        "en-US": "OK"
    },

    "generic-form-submit": {
        "en-US": "Save"
    },
    "edit-form-submit": {
        "en-US": "Save"
    },

    "generic-form-cancel": {
        "en-US": "Cancel"
    },
    "edit-form-cancel": {
        "en-US": "Cancel"
    },

    "table-pagination-current": {
        "en-US": "Showing record **{from}**-**{to}** out of **{total}**"
    },
    "table-pagination-prev": {
        "en-US": "Prev { perPage }"
    },
    "table-pagination-next": {
        "en-US": "Next { perPage }"
    },

    "select-not-selected": {
        "en-US": "Not selected"
    },
    "select-placeholder": {
        "en-US": ""
    },
    "select-list-not-selected": {
        "en-US": "Not Selected"
    },
    "select-list-empty-message": {
        "en-US": "Found no data matching your search"
    },
    "multi-select-list-empty-message": {
        "en-US": "Found no data matching your search"
    },
    "select-code-list-empty-message": {
        "en-US": "Found no data matching your search"
    },

    "table-menu": {
        "en-US": "•••"
    },
    "table-no-data": {
        "en-US": "Found no data matching your filters"
    },

    "search-placeholder": {
        "en-US": "Search"
    },

    "loading": {
        "en-US": "Loading..."
    },

    "connection-error-title": {
        "en-US": "Cannot Connect to the Server"
    },
    "connection-error-message": {
        "en-US": "Will try to reconnect in {time} seconds…"
    },
    "connection-error-try-again": {
        "en-US": "Try again"
    },
    "connection-error-cancel": {
        "en-US": "Cancel"
    },

    "user-menu-profile": {
        "en-US": "My Profile"
    },
    "user-menu-logout": {
        "en-US": "Log Out"
    },

    "csr-status-all": {
        "en-US": "All"
    },
    "csr-status-pending": {
        "en-US": "Pending"
    },
    "csr-status-accepted": {
        "en-US": "Accepted"
    },
    "csr-status-denied": {
        "en-US": "Denied"
    },
    "csr-status-cancelled": {
        "en-US": "Cancelled"
    },
    "csr-status-expired": {
        "en-US": "Expired"
    },

    "theme-selector-dark": {
        "en-US": "Dark"
    },
    "theme-selector-light": {
        "en-US": "Light"
    }
};
