import { Action } from "../../utils/dataflow";

export class HeaderActions {
    constructor() {
        Object.assign(this, {
            setTitle: new Action
        });
    }
}

const Actions = new HeaderActions();

export default Actions;
export const setTitle = Actions.setTitle.trigger;
