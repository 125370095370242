import { IntlModel } from "../../components/Intl";
import PropTypes from "prop-types";
import React, { Children } from "react";

import { Component } from "../Component";
import { compact } from "../../utils/object";

export class Form extends Component {
    onCancel = () => {
        const { onCancel } = this.props;

        if (onCancel) {
            onCancel();
        }
    };

    onKeyDown = ({ keyCode }) => {
        if (keyCode === 27) {
            this.onCancel();
        }
    };

    onSubmit = (event) => {
        const { onSubmit } = this.props;

        event.preventDefault();
        if (onSubmit) {
            onSubmit(event);
        }
    };

    getErrorFor(field) {
        const { error } = this.props;

        return error && error.field === field ? error : null;
    }

    processChildren(children) {
        const { intl, error } = this.props;

        if (error && error.field) {
            children = Children.map(children, (child) => {
                if (child && child.props) {
                    const { children, name } = child.props;
                    const props = { children: this.processChildren(children) };

                    if (name) {
                        props.error = this.getErrorFor(name);
                    }
                    if (child.type.prototype instanceof Component) {
                        props.intl = intl;
                    }

                    child = React.cloneElement(child, props);
                }

                return child;
            });
        }

        return children;
    }

    renderChildren() {
        return this.processChildren(this.props.children);
    }

    render() {
        const { busy, error, autoComplete } = this.props;

        return (
            <form autoComplete={ autoComplete ? "on" : "off" } className={ this.block({ busy, error: Boolean(error) }) }
                onSubmit={ this.onSubmit } onKeyDown={ this.onKeyDown }>
                { this.renderChildren() }
            </form>
        );
    }
}

Form.propTypes = compact(Component.propTypes, {
    intl: PropTypes.instanceOf(IntlModel).isRequired,
    autoComplete: PropTypes.bool,
    busy: PropTypes.bool,
    error: PropTypes.instanceOf(Error),
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func
});

Form.defaultProps = compact(Component.defaultProps, {
   autoComplete: false
});
