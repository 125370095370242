import { Action } from "../../../../../utils/dataflow";
import { FormEditActions } from "../../../../../components/Form";

export class GeneralActions extends FormEditActions {
    constructor() {
        super();

        Object.assign(this, {
            resetPassword: new Action
        });
    }
}

const Actions = new GeneralActions();

export default Actions;
export const saveItem = Actions.saveItem.trigger;
export const setItem = Actions.setItem.trigger;
export const setItemField = Actions.setItemField.trigger;
