import { Action } from "../../utils/dataflow";
import { ViewActions } from "../View";

export default class TableActions extends ViewActions {
    constructor() {
        super();

        Object.assign(this, {
            setFilter: new Action,
            setFilters: new Action,
            setSort: new Action
        });
    }
}
