import { APIConnector } from "../../../../init";
import { FormEditStore } from "../../../../components/Form";

import RepositoryModel from "./RepositoryModel";
import RepositoryEditActions from "./RepositoryEditActions";

class RepositoryEditStore extends FormEditStore.of(RepositoryModel) {
    processError(error) {
        error = super.processError(error);

        if (error.code === "error-importer-already-exists") {
            error = Object.assign(error, { code: "error-repository-name-exists", field: "name" });
        }

        return error;
    }

    save(item) {
        let error, operation;

        if (item.id) {
            operation = APIConnector.update.bind(APIConnector);
        } else {
            operation = APIConnector.create.bind(APIConnector);
            item = item.set({ id: item.name });
        }

        error = item.validate();
        return error ? Promise.reject(error) : operation(item, item.toJSON());
    }

    test(item) {
        return this.save(item).then((item) => {
            return APIConnector.fetch(APIConnector.buildUrl("LDAPConfigs/" + item.id + "/testConnection"))
                    .then((data) => APIConnector.response(data));
            }
        );
    }

    testConnection(item) {
        return this.invoke("test-connection", this.test(item));
    }
}

export default new RepositoryEditStore().connect(RepositoryEditActions);
