import React from "react";
import { Route } from "react-router";

import { Component } from "../../../../../ui";
import ManagedRequests from "./ManagedRequests";

export default function route(path) {
    return (
        <Route path={ path } component={ ManagedRequests }>
            <Route path=":itemId/approve" component={ Component } />
            <Route path=":itemId/deny" component={ Component } />
        </Route>
    );
};
