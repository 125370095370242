import React from "react";
import { Route, IndexRedirect } from "react-router";

import Historical from "./Historical";

export default function route(path) {
    return (
        <Route path={ path }>
            <IndexRedirect to="all" />
            <Route path=":created" component={ Historical } />
        </Route>
    );
};
