import React from "react";

import { Collection } from "../../../../utils/dataflow";
import { Main, Table, TableFilter } from "../../../../ui";

import { setTitle } from "../../../../components/Header";

import HistoricalStore from "./HistoricalStore";
import HistoricalActions, { setCreated, setFilters, setPage, setSearch, setSort } from "./HistoricalActions";

import { Transactions, TransactionsRow, TransactionsToolBar } from "../Transactions";
import {AuthStore} from "../../../../init";
import LiveActions from "../Live/LiveActions";

const DATES = new Collection([
    { id: "1d", name: "1D" },
    { id: "5d", name: "5D" },
    { id: "1w", name: "1W" },
    { id: "1m", name: "1M" },
    { id: "3m", name: "3M" },
    { id: "ydt", name: "YDT" },
    { id: "all", name: "ALL" }
]);

class HistoricalRow extends TransactionsRow {
    render() {
        const { auth } = this.props;
        return (
            <TransactionsRow { ...this.props } className={ this.block() }>
                { this.renderNumCell() }
                { this.renderUserCell() }
                {(auth.company === 'mipasa')
                    ?
                    <>
                        {this.renderCreatedCell()}
                        {this.renderIdCell()}
                        {this.renderTypeCell()}
                    </>
                    :
                    <>
                        {this.renderUserAffiliationCell()}
                        {this.renderActionCell()}
                        {this.renderStockCell()}
                        {this.renderUnitsCell()}
                        {this.renderPriceCell()}
                        {this.renderCreatedCell()}
                        {this.renderIdCell()}
                    </>
                }
            </TransactionsRow>
        );
    };
}

class HistoricalTable extends Table {
    sortByColumn(column) {
        setSort(column);
    }

    setPage(page) {
        setPage(page);
    }

    renderHead() {
        const { auth } = this.props;
        return (
            <tr>
                { this.renderHeadNum() }
                { this.renderHeadCell("user", "userId") }
                {(auth.company === 'mipasa') ?
                    <>
                        { this.renderHeadCell("date", "created") }
                        { this.renderHeadCell("id", "id") }
                        { this.renderHeadCell("type", "type") }
                    </>
                    :
                    <>
                        { this.renderHeadCell("affiliation") }
                        { this.renderHeadCell("order-type", "action") }
                        { this.renderHeadCell("stock", "stock") }
                        { this.renderHeadCell("units", "units") }
                        { this.renderHeadCell("price", "price") }
                        { this.renderHeadCell("date", "created") }
                        { this.renderHeadCell("id", "id") }
                    </>}
            </tr>
        );
    }

    renderRow(item) {
        const { intl, auth } = this.props;

        return <HistoricalRow key={ item.id } { ...{ intl, auth, item } } />;
    }
}

class HistoricalToolBar extends TransactionsToolBar {
    renderDateSelect() {
        return (
            <TableFilter label={ this.formatMessage("dates-range") } items={ DATES }
                className={ this.element("dates-range") } />
        );
    }

    render() {
        return (
            <TransactionsToolBar { ...this.props } className={ this.block() }>
                { this.renderPermissionsSelect() }
                { this.renderGroupsSelect() }
                { this.renderUsersSelect() }
                { this.renderDateSelect() }
            </TransactionsToolBar>
        );
    }
}

class Historical extends Transactions {
    get refreshTimeout() {
        return 10000;
    }

    componentWillMount() {
        const { params: { created } } = this.props;

        setTitle(this.formatMessage("title"));
        setCreated(created);

        super.componentWillMount();
    }

    componentWillReceiveProps(nextProps) {
        const { params: { created: currCreated }, auth: { company: currCompany } } = this.props;
        const { params: { created: nextCreated }, auth: { company: nextCompany } } = nextProps;

        if (nextCreated !== currCreated) {
            setCreated(nextCreated);
        }

        if (currCompany !== nextCompany) {
            HistoricalActions.reset.trigger();
            HistoricalActions.refresh.trigger();
        }
    }

    renderToolBar() {
        const { intl, data: { filter } } = this.props;

        return (
            <HistoricalToolBar { ...{ intl, filter } } onFiltersChange={ setFilters } onSearch={ setSearch } />
        );
    }

    render() {
        const { intl, auth, data: { filter, item: items } } = this.props;

        return (
            <Main toolBar={ this.renderToolBar() } sideBar={ auth.company!=='mipasa'?this.renderSideBar():null } status={ this.renderStatus() }
                className={ this.block() }>
                <HistoricalTable { ...{ intl, filter, items, auth } } className="historical-table" />
            </Main>
        );
    }
}

export default Historical.of(HistoricalStore, HistoricalActions).connect({ auth: AuthStore });
