import Model from "../utils/dataflow/Model";

function toString(relations) {
    return Object.keys(relations).map((prop) => `${prop}: ${relations[prop].name}`);
}

export class Relation extends Model {
    static connect(entities) {
        class Relation extends this {
        }

        return Model.connect.call(Relation, entities);
    }

    static toString() {
        return super.toString(...toString(this.getRelations()));
    }

    toString() {
        return super.toString(...toString(this.getRelations()));
    }
}
