import { Action } from "../../../../utils/dataflow";
import { FormEditActions } from "../../../../components/Form";

class RepositoryEditActions extends FormEditActions {
    constructor() {
        super();

        Object.assign(this, {
            testConnection: new Action
        });
    }
}
const Actions = new RepositoryEditActions();

export default Actions;
export const saveItem = Actions.saveItem.trigger;
export const setItem = Actions.setItem.trigger;
export const setItemField = Actions.setItemField.trigger;
export const setItemError = Actions.setItemError.trigger;
export const setError = Actions.setError.trigger;
export const testConnection = Actions.testConnection.trigger;
