import { FormEditActions } from "../../../../components/Form";

const Actions = new FormEditActions();

export default Actions;
export const saveItem = Actions.saveItem.trigger;
export const setItem = Actions.setItem.trigger;
export const setItemField = Actions.setItemField.trigger;

export const setItemGroupId = setItemField.bind(null, "groupId");
export const setItemPermissions = setItemField.bind(null, "permissions");
