import { setTitle } from "../../../../../components/Header";

import { TableView } from "../../../../../components/Table";
import moment from "moment";
import React from "react";

import { Certificate, Link, List, Main, Table, TableRow, TableToolBar } from "../../../../../ui";
import CertificatesActions, { setPage, setSearch, setSort } from "./CertificatesActions";

import CertificatesStore from "./CertificatesStore";
import {AuthStore} from "../../../../../init";

class CertificatesRow extends TableRow {
    renderMenu() {
        const { props: { item }, onDropHide } = this;

        return (
            <List onClick={ onDropHide }>
                <Link to={ item.id + "/delete" }>{ this.formatMessage("delete") }</Link>
            </List>
        );
    }

    renderNameCell() {
        const { item: { alias } } = this.props;

        return (
            <td className={ this.element("name") } data-title={ alias }>
                { alias }
            </td>
        );
    }

    renderCommonNameCell() {
        const { item: { commonName } } = this.props;

        return (
            <td className={ this.element("common-name") } data-title={ commonName }>
                { commonName || "" }
            </td>
        );
    }

    renderSerialCell() {
        const { item: { serialNumber } } = this.props;

        return (
            <td className={ this.element("serial") } data-title={ serialNumber }>
                { serialNumber || "-" }
            </td>
        );
    }

    renderNotAfterCell() {
        const { item: { notAfter } } = this.props;

        return (
            <td className={ this.element("not-after") } data-title={ notAfter }>
                { notAfter && moment(notAfter).format("YYYY-MM-DD HH:mm:ss") || "" }
            </td>
        );
    }

    renderRevocationCell() {
        const { item: { revocation } } = this.props;

        return (
            <td className={ this.element("revocation") } data-title={ revocation }>
                { revocation }
            </td>
        );
    }

    render() {
        return (
            <TableRow { ...this.props } enumerable={ true } className={ this.block() }>
                { this.renderNumCell() }
                { this.renderNameCell() }
                { this.renderCommonNameCell() }
                { this.renderSerialCell() }
                { this.renderNotAfterCell() }
                { this.renderRevocationCell() }
                { this.renderMenuCell() }
            </TableRow>
        );
    };
}

class CertificateExtraRow extends TableRow {
    render() {
        const { intl, item } = this.props;

        return (
            <TableRow { ...this.props } className={ this.block() }>
                <td colSpan={ 7 } className={ this.element("extra") }>
                    <Certificate { ...{ intl, item } } />
                </td>
            </TableRow>
        );
    };
}

class CertificatesTable extends Table {
    init(...args) {
        super.init(...args);

        this.initState({ expanded: {} });
        this.onRowClick = this.onRowClick.bind(this);
    }

    sortByColumn(column) {
        setSort(column);
    }

    setPage(page) {
        setPage(page);
    }

    renderHead() {
        return (
            <tr>
                { this.renderHeadNum() }
                { this.renderHeadCell("name", "alias") }
                { this.renderHeadCell("common-name", "commonName") }
                { this.renderHeadCell("serial", "serialNumber") }
                { this.renderHeadCell("not-after", "notAfter") }
                { this.renderHeadCell("revocation", "revocation") }
                { this.renderHeadCell("actions") }
            </tr>
        );
    }

    onRowClick(event) {
        const { expanded } = this.state;
        const { currentTarget } = event;

        const id = currentTarget.getAttribute("data-id");
        this.setState({ expanded: Object.assign({}, expanded, { [id]: !expanded[id] }) });
    }

    renderRow(item) {
        const { intl } = this.props;

        return <CertificatesRow key={ item.id } { ...{ intl, item, onClick: this.onRowClick } } />;
    }

    renderExtraRow(item) {
        const { intl } = this.props;

        return <CertificateExtraRow key={ "extra-" + item.id } { ...{ intl, item } } />;
    }

    renderRows() {
        const { props: { items }, state: { expanded } } = this;
        const rows = [];

        items.forEach((item, index) => {
            rows.push(this.renderRow(item, index));

            if (expanded[item.id]) {
                rows.push(this.renderExtraRow(item, index));
            }
        });

        return rows;
    }
}

class CertificatesToolBar extends TableToolBar {
    render() {
        return (
            <TableToolBar { ...this.props } className={ this.block() }>
                { this.renderSearch() }
                <Link to="create" className="action action_add link_accent">{ this.formatMessage("create") }</Link>
            </TableToolBar>
        );
    }
}

export class Certificates extends TableView {
    componentWillMount() {
        super.componentWillMount();

        setTitle(this.formatMessage("title"));
    }

    componentWillReceiveProps(nextProps) {
        const { auth: { company: currCompany } } = this.props;
        const { auth: { company: nextCompany } } = nextProps;

        if (currCompany !== nextCompany) {
            CertificatesActions.refresh.trigger()
        }
    }

    renderToolBar() {
        const { intl, data: { filter } } = this.props;

        return (
            <CertificatesToolBar { ...{ intl, filter } } onSearch={ setSearch } />
        );
    }

    render() {
        const { intl, data: { item: items, filter } } = this.props;

        return (
            <Main toolBar={ this.renderToolBar() } status={ this.renderStatus() } className={ this.block() }>
                <CertificatesTable { ...{ intl, filter, items } } />
            </Main>
        );
    }
}

export default Certificates.of(CertificatesStore, CertificatesActions).connect({ auth: AuthStore });
