import { setTitle } from "../../../../../components/Header";

import { TableView } from "../../../../../components/Table";
import moment from "moment";
import React from "react";
import { Component, CSRStatus, Link, List, Main, Table, TableFilter, TableRow, TableToolBar } from "../../../../../ui";

import { Collection } from "../../../../../utils/dataflow";
import CSRsActions, { setPage, setSearch, setSort, setStatus } from "./CSRsActions";

import CSRsStore from "./CSRsStore";

const STATUSES = new Collection([
    { id: "all", name: "All" },
    { id: "Pending", name: "Pending" },
    { id: "Accepted", name: "Approved" },
    { id: "Denied", name: "Rejected" },
    { id: "Cancelled", name: "Cancelled" },
    { id: "Expired", name: "Expired" }
]);

class CSRRow extends TableRow {
    renderMenu() {
        const { props: { item: { id } }, onDropHide } = this;

        return (
            <List onClick={ onDropHide }>
                <Link to={ id + "/view" }>{ this.formatMessage("view") }</Link>
                <Link to={ id + "/delete" }>{ this.formatMessage("delete") }</Link>
            </List>
        );
    }

    renderStatusCell() {
        let { intl, item: { status } } = this.props;

        return (
            <td className={ this.element("status") }>
                <CSRStatus { ...{ intl, status } } empty={ true } />
            </td>
        );
    }

    renderNameCell() {
        const { item: { alias } } = this.props;

        return (
            <td className={ this.element("name") }>
                { alias }
            </td>
        );
    }

    renderCommonNameCell() {
        const { item: { commonName } } = this.props;

        return (
            <td className={ this.element("common-name") }>
                { commonName || "" }
            </td>
        );
    }

    renderCountry() {
        const { item: { country } } = this.props;

        return (
            <td className={ this.element("country") }>
                { country }
            </td>
        );
    }

    renderState() {
        const { item: { state } } = this.props;

        return (
            <td className={ this.element("state") }>
                { state }
            </td>
        );
    }

    renderCity() {
        const { item: { city } } = this.props;

        return (
            <td className={ this.element("city") }>
                { city }
            </td>
        );
    }

    renderOrganization() {
        const { item: { organization } } = this.props;

        return (
            <td className={ this.element("organization") }>
                { organization }
            </td>
        );
    }

    renderDepartment() {
        const { item: { department } } = this.props;

        return (
            <td className={ this.element("department") }>
                { department }
            </td>
        );
    }

    renderCreation() {
        const { item: { creationTime } } = this.props;

        return (
            <td className={ this.element("creation-time") }>
                { creationTime && moment(creationTime).format("YYYY-MM-DD HH:mm:ss") || "" }
            </td>
        );
    }

    render() {
        return (
            <TableRow { ...this.props } enumerable={ true } className={ this.block() }>
                { this.renderNumCell() }
                { this.renderStatusCell() }
                { this.renderNameCell() }
                { this.renderCommonNameCell() }
                { this.renderCreation() }
                { this.renderCountry() }
                { this.renderState() }
                { this.renderCity() }
                { this.renderOrganization() }
                { this.renderDepartment() }
                { this.renderMenuCell() }
            </TableRow>
        );
    };
}

class CSRsTable extends Table {
    sortByColumn(column) {
        setSort(column);
    }

    setPage(page) {
        setPage(page);
    }

    renderHead() {
        return (
            <tr>
                { this.renderHeadNum() }
                { this.renderHeadCell("status", "status") }
                { this.renderHeadCell("name", "alias") }
                { this.renderHeadCell("common-name", "commonName") }
                { this.renderHeadCell("creation-time", "creationTime") }
                { this.renderHeadCell("country", "country") }
                { this.renderHeadCell("state", "state") }
                { this.renderHeadCell("city", "city") }
                { this.renderHeadCell("organization", "organization") }
                { this.renderHeadCell("department", "department") }
                { this.renderHeadCell("actions") }
            </tr>
        );
    }

    renderRow(item, index) {
        const { intl } = this.props;

        return <CSRRow key={ item.id } { ...{ intl, item } } />;
    }
}

class CSRToolBarStatus extends Component {
    render() {
        const { intl, item } = this.props;

        return (
            <Link to={ `/msb/settings/credentials/csrs/${item.id}/` }>
                <CSRStatus { ...{ intl, status: item.id } } />
            </Link>
        );
    }
}

class CSRToolBar extends TableToolBar {
    renderStatusSelect() {
        const { intl } = this.props;

        return (
            <TableFilter intl={ intl } items={ STATUSES } Item={ CSRToolBarStatus }
                label={ this.formatMessage("status") } className={ this.element("status") } />
        );
    }

    render() {
        return (
            <TableToolBar { ...this.props } className={ this.block() }>
                { this.renderStatusSelect() }
                { this.renderSearch() }
                <Link to="import" className="action action_export link_accent">{ this.formatMessage("import") }</Link>
                <Link to="create" className="action action_add link_accent">{ this.formatMessage("create") }</Link>
            </TableToolBar>
        );
    }
}

export class CSRs extends TableView {
    componentWillMount() {
        const { params: { status } } = this.props;

        setTitle(this.formatMessage("title"));
        setStatus(status);
    }

    componentWillReceiveProps(nextProps) {
        const { params: { status: currStatus } } = this.props;
        const { params: { status: nextStatus } } = nextProps;

        if (nextStatus !== currStatus) {
            setStatus(nextStatus);
        }
    }

    shouldModalRenderForPath(pathname) {
        return pathname.endsWith("import") || super.shouldModalRenderForPath(pathname);
    }

    renderToolBar() {
        const { intl, data: { filter } } = this.props;

        return (
            <CSRToolBar { ...{ intl, filter } } onSearch={ setSearch } />
        );
    }

    render() {
        const { intl, data: { item: items, filter } } = this.props;

        return (
            <Main className={ this.block() } toolBar={ this.renderToolBar() } status={ this.renderStatus() }>
                <CSRsTable { ...{ intl, filter, items } } />
            </Main>
        );
    }
}

export default CSRs.of(CSRsStore, CSRsActions);
