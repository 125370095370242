import { TableFilterModel, TableModel } from "../../../../components/Table";
import { Networks } from "../../../../model";

class NetworksFilter extends TableFilterModel {
    init(...args) {
        super.init({ sort: { }, limit: null }, ...args);
    }
}

export default class NetworksModel extends TableModel.of(Networks, NetworksFilter) {
}
