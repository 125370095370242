import { TableFilterModel, TableModel } from "../../../../components/Table";
import { Notifications } from "../../../../model";

const defaults = {
    sort: { creationTime: false },
    category: "request_pending,request_approved,request_denied"
};

class NotificationsFilter extends TableFilterModel {
    init(...args) {
        super.init(defaults, ...args);
    }

    set category(category) {
        this.define({ _category: String(category).split(",") });
    }

    get category() {
        return this._category && this._category.join(",") || "";
    }

    valueOf() {
        const { category } = this;

        return Object.assign(super.valueOf(), { category });
    }

    toJSON() {
        const { _category: category } = this;

        return Object.assign(super.toJSON(), {
            category: !category || String(category) === "all" ? void 0 : category
        });
    }
}

export default class NotificationsModel extends TableModel.of(Notifications, NotificationsFilter) {
}
