import { ViewModel } from "../View";
import { Collection } from "../../utils/dataflow";

export default class SelectModel extends ViewModel {
    static get Items() {
        return Collection;
    }

    get Items() {
        return this.constructor.Items;
    }

    static of(Items) {
        if (!Collection.is(Items)) {
            throw new Error(`${SelectModel}: Collection expected`);
        }

        class SelectModel extends this {
            static get Items() {
                return Items;
            }
        }

        return SelectModel.connect({
            items: Items
        });
    }

    static toString() {
        return super.toString(this.Items.name);
    }

    init(...args) {
        super.init({ items: [] }, ...args);
    }

    toString() {
        return super.toString(this.Items.name);
    }
}
