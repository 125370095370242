import React from "react";

import { IntlStore } from "../../../../init";
import { Component, Confirmation } from "../../../../ui";

import RepositoryEditStore from "./RepositoryEditStore";

class RepositoryEditMessage extends Component {
    init(...args) {
        super.init(...args);

        this.onSubmit = this.onSubmit.bind(this);
        this.onClose = this.onClose.bind(this);
    }

    close() {
        this.navigateUp(this.props);
    }

    onSubmit() {
        this.close();
    }

    onClose() {
        this.close();
    }

    render() {
        const { intl, data: { item }, location: { pathname } } = this.props;
        const message = pathname.endsWith("error") ? "settings-repository-error"
            : "settings-repository-success";

        if (!item) {
            return null;
        }

        return (
            <Confirmation intl={ intl }
                title={ intl.formatMessage(message + "-title") }
                message={ intl.formatMessage(message + "-message", item.valueOf()) }
                submit={ intl.formatMessage("alert-ok") } cancel={ false }
                onSubmit={ this.onSubmit } onCancel={ this.onClose } />
        );
    }
}

export default RepositoryEditMessage.connect({ intl: IntlStore, data: RepositoryEditStore });
