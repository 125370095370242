import { Session } from "../../model";
import { Store } from "../../utils/dataflow";
import APIConnector from "../../init/api";
import { AuthModel } from "./AuthModel";

const SessionConnector = APIConnector.Session;

function destroySession(result) {
    return () => {
        SessionConnector.destroy();

        return result;
    };
}


export class AuthStore extends Store.of(AuthModel) {
    init(...args) {
        super.init(...args);

        this.setState({ company: window.localStorage['msb-company']||'hacera' });
        SessionConnector.read().then((session) => this.setState({ session }));
    }

    login(username, password) {
        return APIConnector.create(this.state.session, { username, password })
            .then((session) => SessionConnector.create(new Session(session)))
            .then((session) => this.setState({ session }));
    }

    logout() {
        const { session } = this.state;
        let operation;

        if (session.isGuest) {
            operation = Promise.resolve(false);
        } else {
            this.setState({ session: new Session });

            operation = APIConnector.destroy(session)
                .then(destroySession(true))
                .catch(destroySession(false));
        }

        return operation;
    }

    updateSession(session) {
        this.setState({ session });

        return SessionConnector.update(this.state.session, this.state.session.valueOf());
    }

    updateCompany(data) {
        this.setState(data);
        window.localStorage['msb-company'] = data.company;
        return Promise.resolve(true);
    }
}
