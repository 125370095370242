import React from "react";

import { Component } from "./Component";

export class Label extends Component {
    render() {
        const { title, children } = this.props;

        return (
            <label className={ this.block() }>
                { children }
            </label>
        );
    }
}
